<script>
  import Viewport from "#lib/drawing/ViewportMinimal.svelte";
  import typeDrawing from "@local/extensions/drawing/type-drawing.js";
  import imageUrl from "#src/extensions/image-url.js";

  export let record;
</script>

<div class="w-full h-full relative">
  {#if record.image}
    <img src={imageUrl(record.image)} alt={record.name} class="text-xs w-full h-full object-cover" />
  {:else}
    <Viewport padding={4} drawing={typeDrawing(record)} />
  {/if}
</div>
