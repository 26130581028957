<script>
  import { onMount, getContext } from "svelte";
  import { Link } from "svelte-routing";

  import Datatable from "#lib/datatable/Datatable.svelte";
  import { Modal } from "svelte-utilities";
  import TextInput from "#lib/sidebar/TextInput.svelte";
  import ErrorMessage from "#lib/standard-inputs/ErrorMessage.svelte";
  import InvitationActions from "./InvitationActions.svelte";

  import formatDateTime from "@local/extensions/formatters/format-date-time.js";
  import isEmail from "@local/extensions/validators/is-email";
  import { api, sendInvitation, resendInvitation } from "#src/api";
  import { profile } from "#src/stores/auth.js";

  const org = getContext("org");

  const columns = [
    {
      label: "Email",
      prop: "to_email",
      type: "text",
    },
    {
      label: "Sent",
      prop: "sent_at",
      formatter: formatDateTime,
    },
    {
      label: "Accepted",
      prop: "used_at",
      formatter: (v) => (v ? "✓" : ""),
    },
  ];

  let invitations = [];
  let newInvitation = null;
  let invitationModal;
  let resendModal;
  let resendTo = null;
  let errorMessage = null;

  async function fetchInvitations() {
    const { data, error } = await api.from("invitations").select("*").eq("sender_id", $org.id);

    if (error) return;
    invitations = data;
  }

  onMount(() => {
    fetchInvitations();
  });

  async function removeInvitation(evt) {
    const { id } = evt.detail;
    invitations = invitations.filter((i) => i.id !== id);
    await api.from("invitations").delete().eq("id", id);
    fetchInvitations();
  }

  async function sendNewInvitation() {
    const res = await sendInvitation({
      sender_id: $org.id,
      to_email: newInvitation,
      sender_profile_id: $profile.id,
    });

    newInvitation = "";

    fetchInvitations();

    if (res.error) {
      errorMessage = res.error.message;
    }
  }

  async function resendInvitationRow(e) {
    const { id } = e.detail;
    resendTo = invitations.find((i) => i.id === id);
    resendModal.open();
  }

  async function resend() {
    await resendInvitation({ id: resendTo.id });

    fetchInvitations();
  }
</script>

<div class="px-4 pb-4 border-b border-gray-300">
  <h2>Invitations</h2>
</div>

<div class="py-2 sm:px-4 text-xs">
  <p>Invitations will allow recipients to create their own organization.</p>
  <p>
    To add members to <span>your</span>
    organization, use the
    <Link to="/settings/users" class="text-blue-500">Team</Link>
    tab.
  </p>
</div>

<div class="py-4 sm:px-4 flex justify-end items-center">
  <button class="btn btn-primary" on:click={() => invitationModal.open()}>+ New Invitation</button>
</div>

<ErrorMessage message={errorMessage} on:close={() => (errorMessage = null)} />

<div class="bg-white border rounded-lg overflow-hidden">
  <Datatable
    data={invitations}
    {columns}
    actionsComponent={InvitationActions}
    on:deleterow={removeInvitation}
    on:resendrow={resendInvitationRow}
    emptyText="No invitations" />
</div>

<Modal
  bind:this={invitationModal}
  width="36rem"
  closeable
  on:confirm={sendNewInvitation}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Send", type: "confirm", style: "primary", disabled: !isEmail(newInvitation) },
  ]}>
  <div slot="title">Send Invitation</div>
  <div slot="content" class="space-y-4">
    <TextInput label="Email" border inputOnKeydown bind:value={newInvitation} />
  </div>
</Modal>

<Modal
  bind:this={resendModal}
  width="36rem"
  closeable
  on:confirm={resend}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Send", type: "confirm", style: "primary" },
  ]}>
  <div slot="title">Resend Invitation</div>
  <div slot="content" class="space-y-4">
    Do you want to resend an invitation to <span class="font-bold">{resendTo.to_email}</span>?
  </div>
</Modal>
