<script>
  import { createEventDispatcher, getContext } from "svelte";
  import upperCase from "lodash/upperCase";

  import { Modal } from "svelte-utilities";
  import SelectInput from "#lib/sidebar/SelectInput.svelte";
  import ProfileIcon from "@local/assets/icons/profile.svg";
  import { profile } from "#src/stores/auth.js";
  import getUserInitials from "@local/extensions/formatters/get-user-initials.js";

  const dispatch = createEventDispatcher();
  const org = getContext("org");

  let assignModal;

  export let records;
  export let recordName = "quote";
  let assigned_to_id;

  $: hasNotifications = records.some((r) => r?.notifications?.length > 0);
  $: assignToOptions = getAssignToOptions($profile, $org);

  function clearNotifications() {
    const ids = records.reduce((l, record) => {
      if (record.notifications?.length > 0) {
        l.push(...record.notifications.map((n) => n.id));
      }
      return l;
    }, []);

    dispatch("clear-notifications", { notifications: ids });
  }

  function getAssignToOptions(p, o) {
    if (!o?.profiles) return [];

    const orgProfiles = o.profiles.map((prof) => ({
      label: prof.username || prof.email,
      initials: upperCase(getUserInitials(prof)),
      color: prof.user_color,
      value: prof.id,
    }));

    const present = orgProfiles.some((prof) => prof.value === p.id);

    if (present) {
      return orgProfiles;
    } else {
      return [
        {
          label: p.username || p.email,
          color: p.user_color,
          initials: upperCase(getUserInitials(p)),
          value: p.id,
        },
        ...orgProfiles,
      ];
    }
  }

  function assign() {
    dispatch("assign", { ids: records.map((r) => r.id), assigned_to_id });
  }
</script>

{#if records}
  <div class="w-full h-full p-4 text-center text-sm flex flex-col justify-center items-center">
    <div class="bg-gray-100 rounded-lg p-8 w-72 h-72 flex flex-col justify-between">
      <div>
        {records.length}
        {recordName}s selected.
      </div>
      <div class="text-xs space-y-2">
        <button
          class="rounded-full border py-2 px-4 bg-black text-xs text-white font-bold"
          on:click={() => assignModal.open()}>
          Assign To Team Member
        </button>
        <button
          class="rounded-full border py-2 px-4 bg-black text-xs text-white font-bold"
          on:click={() => dispatch("archive", { ids: records.map((r) => r.id) })}>
          Archive All
        </button>
        {#if hasNotifications}
          <button
            class="rounded-full border py-2 px-4 bg-black text-xs text-white font-bold"
            on:click={clearNotifications}>
            Clear All Notifications
          </button>
        {/if}
      </div>
    </div>
  </div>
{/if}

<Modal
  bind:this={assignModal}
  closeable
  on:confirm={assign}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Assign", type: "confirm", style: "primary", disabled: !assigned_to_id },
  ]}>
  <div slot="title">Assign To Team Member</div>
  <div slot="content" class="space-y-4">
    <div>Who will you assign to?</div>
    <SelectInput
      placeholder="Assigned to"
      labelWidth="1rem"
      bind:value={assigned_to_id}
      options={assignToOptions}>
      <div class="text-black" slot="label">
        <ProfileIcon />
      </div>
      <div slot="option" let:option class="flex items-center gap-1">
        {#if option.initials}
          <div
            class="profile-circle relative"
            style="background-color: {option.color || '#000'}; border-color: {option.color || '#000'}">
            {option.initials}
          </div>
        {:else}
          <div class="text-gray-400">
            <ProfileIcon />
          </div>
        {/if}
        <div>
          {option.label}
        </div>
      </div>
    </SelectInput>
  </div>
</Modal>
