import { writable } from "svelte/store";
import lset from "lodash/set";
import lget from "lodash/get";
import debounce from "lodash/debounce";
import cloneDeep from "lodash/cloneDeep";
import { Revision } from "./revision.js";
import { PendingQueue } from "./pending-queue.js";
import { profile } from "./auth.js";
import {
  hydrateItem,
  hydrateType,
  hydrateGroup,
  hydrateGroupData,
} from "@local/lamina-core";
import {
  addToSortableList,
  removeFromSortableList,
  sortList,
  sortListBy,
} from "@local/extensions/collections/sortable-list.js";
import {
  api,
  updateProp,
  addRecords,
  removeRecords,
  getGroupFromLink,
} from "#src/api";
import { updateCommit } from "./update-commit.js";

let $profile;
profile.subscribe((value) => {
  $profile = value;
});

async function fetchGroup(group_id) {
  const { data, error } = await api
    .from("groups")
    .select(
      `
        *,
        job: job_id(*,quote_requests!quote_requests_job_id_fkey(*),quotes!quotes_job_id_fkey(*)),
        organization: organization_id(*,profiles!profiles_organization_id_fkey(*)),
        items!items_group_id_fkey(*,approver: approval_status_updated_by(*), updater: updated_by_user(*)),
        quote_items(*),
        types!types_group_id_fkey(*,approver: approval_status_updated_by(*), updater: updated_by_user(*)),
        documents(*),
        locations(*),
        comments!comments_group_id_fkey(*,commenter: user_id(*)),
        attachments!attachments_group_id_fkey(*),
        approver: approval_status_updated_by(*),
        updater: updated_by_user(*)
        `,
    )
    .eq("id", group_id)
    .order("index", { referencedTable: "documents" })
    .order("created_at", { referencedTable: "items" })
    .order("created_at", { referencedTable: "types" })
    .order("created_at", { referencedTable: "comments" })
    .order("created_at", { referencedTable: "attachments" })
    .maybeSingle();
  if (error) throw error;

  return data;
}

async function fetchGroupFromLink(link_id) {
  return await getGroupFromLink({ link_id });
}

function createGroup(group, { realtime = false, updateCallback = null } = {}) {
  const g = hydrateGroup(group);

  const { subscribe, set, update } = writable(g);

  let groupValue;
  subscribe((value) => {
    groupValue = value;
  });

  const queue = new PendingQueue(update);

  let revision;
  let room;
  if (realtime) {
    const resolveUpdates = async (broadcast) => {
      const { updated } = broadcast.payload;

      const updateData = updated.map(async (u) => {
        if (u.record === "group") {
          const r = await api
            .from("groups")
            .select("*")
            .eq("id", groupValue.id)
            .single();
          if (r.error) return null;
          const result = r.data;
          hydrateGroupData(result.data);
          return result;
        } else if (u.record === "job") {
          const r = await api
            .from("jobs")
            .select(
              "*,quote_requests!quote_requests_job_id_fkey(*),quotes!quotes_job_id_fkey(*)",
            )
            .eq("id", groupValue.job_id)
            .single();
          if (r.error) return null;
          return r.data;
        } else if (u.record === "items") {
          if (u.type === "UPDATE" || u.type === "INSERT") {
            const r = await api
              .from("items")
              .select(
                "*,approver: approval_status_updated_by(*), updater: updated_by_user(*)",
              )
              .in("id", u.ids);
            if (r.error) return null;
            return r.data.map((item) => hydrateItem(item, groupValue));
          } else {
            return null;
          }
        } else if (u.record === "types") {
          if (u.type === "UPDATE" || u.type === "INSERT") {
            const r = await api
              .from("types")
              .select(
                "*,approver: approval_status_updated_by(*), updater: updated_by_user(*)",
              )
              .in("id", u.ids);
            if (r.error) return null;
            return r.data.map(hydrateType);
          } else {
            return null;
          }
        } else if (u.record === "documents") {
          if (u.type === "UPDATE" || u.type === "INSERT") {
            const r = await api.from("documents").select("*").in("id", u.ids);
            if (r.error) return null;
            return r.data;
          } else {
            return null;
          }
        } else if (u.record === "locations") {
          if (u.type === "UPDATE" || u.type === "INSERT") {
            const r = await api.from("locations").select("*").in("id", u.ids);
            if (r.error) return null;
            return r.data;
          } else {
            return null;
          }
        } else if (u.record === "attachments") {
          if (u.type === "UPDATE" || u.type === "INSERT") {
            const r = await api.from("attachments").select("*").in("id", u.ids);
            if (r.error) return null;
            return r.data;
          } else {
            return null;
          }
        }
      });

      const data = await Promise.all(updateData);

      return (n) => {
        updated.forEach((u, i) => {
          const d = data[i];
          if (u.record === "group") {
            if (u.type === "UPDATE") {
              if (u.paths) {
                u.paths.forEach((path) => {
                  lset(n, path, lget(d, path));

                  if (path === "data.item_order") {
                    n.items.order = lget(d, path);
                  } else if (path === "data.type_order") {
                    n.types.order = lget(d, path);
                  }
                });
              } else {
                Object.entries(d).forEach(([prop, value]) => {
                  n[prop] = value;
                });
              }
            }
          } else if (u.record === "items") {
            if (u.type === "INSERT") {
              addToSortableList(n.items, ...d);
            } else if (u.type === "DELETE") {
              removeFromSortableList(n.items, ...u.ids);
            } else if (u.type === "UPDATE") {
              u.ids.forEach((id, i) => {
                const item = d[i];
                if (u.paths) {
                  u.paths.forEach((path) => {
                    lset(n.items[id], path, lget(item, path));
                  });
                } else {
                  n.items[id] = item;
                }
              });
            }
          } else if (u.record === "types") {
            if (u.type === "INSERT") {
              addToSortableList(n.types, ...d);
            } else if (u.type === "DELETE") {
              removeFromSortableList(n.types, ...u.ids);
            } else if (u.type === "UPDATE") {
              u.ids.forEach((id, i) => {
                const type = d[i];
                if (u.paths) {
                  u.paths.forEach((path) => {
                    lset(n.types[id], path, lget(type, path));
                  });
                } else {
                  n.types[id] = type;
                }
              });
            }
          } else if (u.record === "documents") {
            if (u.type === "INSERT") {
              addToSortableList(n.documents, ...d);
            } else if (u.type === "DELETE") {
              removeFromSortableList(n.documents, ...u.ids);
            } else if (u.type === "UPDATE") {
              u.ids.forEach((id, i) => {
                const document = d[i];
                n.documents[id] = document;
              });
            }
          } else if (u.record === "locations") {
            if (u.type === "INSERT") {
              addToSortableList(n.locations, ...d);
            } else if (u.type === "DELETE") {
              removeFromSortableList(n.locations, ...u.ids);
            } else if (u.type === "UPDATE") {
              u.ids.forEach((id, i) => {
                const location = d[i];
                n.locations[id] = location;
              });
            }
          } else if (u.record === "attachments") {
            if (u.type === "INSERT") {
              n.attachments.push(...d);
            } else if (u.type === "DELETE") {
              n.attachments = n.attachments.filter(
                (a) => !u.ids.includes(a.id),
              );
            }
          }
        });

        return n;
      };
    };

    room = api.channel(`group-changes:${group.id}`);

    room.on("broadcast", { event: "update" }, async (broadcast) => {
      const job = async () => await resolveUpdates(broadcast);
      queue.add(job);
    });

    room.subscribe(async (status) => {
      if (status !== "SUBSCRIBED") return;
    });

    revision = new Revision({ room });
  } else {
    revision = new Revision();
  }

  return {
    subscribe,
    set,
    undoable() {
      return revision.undoable;
    },
    redoable() {
      return revision.redoable;
    },
    undo() {
      update((n) => {
        revision.undo(n);
        return n;
      });
    },
    redo() {
      update((n) => {
        revision.redo(n);
        return n;
      });
    },
    destroy() {
      if (room) {
        room.untrack();
        room.unsubscribe();
      }
    },
    react() {
      update((n) => n);
    },

    updateJobProp(prop, value) {
      let commit;
      if (typeof prop === "object") {
        commit = (n) => {
          const oldValue = Object.keys(prop).reduce((o, p) => {
            const path = p.split(".");
            const [col] = path;
            o[col] = n.job[col];
            return o;
          }, {});

          const newValue = Object.keys(prop).reduce((o, p) => {
            const path = p.split(".");
            const [col, ...rest] = path;

            if (col === "data" && rest.length > 0) {
              if (!o.data) o.data = cloneDeep(n.job.data);
              lset(o.data, rest, prop[p]);
            } else {
              o[p] = prop[p];
            }

            return o;
          }, {});

          Object.entries(newValue).forEach(([key, value]) => {
            n.job[key] = value;
          });

          updateProp("jobs", n.job.id, newValue);

          return (n) => {
            Object.entries(oldValue).forEach(([key, value]) => {
              n.job[key] = value;
            });

            updateProp("jobs", n.job.id, oldValue);
          };
        };
      } else {
        const path = prop.split(".");
        const [col, ...rest] = path;

        commit = (n) => {
          let oldValue;
          let newValue;

          if (col === "data" && rest.length > 0) {
            oldValue = n.job.data;
            const data = cloneDeep(n.job.data);
            lset(data, rest, value);
            newValue = data;
          } else {
            oldValue = n.job[prop];
            newValue = value;
          }

          n.job[col] = newValue;

          updateProp("jobs", n.job_id, col, newValue);

          return (n) => {
            n.job[col] = oldValue;
            updateProp("jobs", n.job_id, col, oldValue);
          };
        };
      }

      update((n) => {
        revision.commitTransaction(commit, n, [
          { record: "job", type: "UPDATE" },
        ]);
        return n;
      });
    },

    // Generic method for performing multiple items/types/group in a single transaction
    update(updates) {
      const commit = updateCommit(updates, $profile);

      const record = {
        group: "group",
        job: "job",
        type: "types",
        item: "items",
        document: "documents",
        location: "locations",
      };

      const actions = {
        add: "INSERT",
        remove: "DELETE",
        update: "UPDATE",
      };

      return new Promise((resolve) => {
        update((n) => {
          revision
            .commitTransaction(
              commit,
              n,
              updates.map((u) => {
                const r = record[u.type];
                const action = u.action ? actions[u.action] : "UPDATE";
                let ids;
                if (action === "DELETE") {
                  ids = u.ids;
                } else if (action === "INSERT") {
                  ids = u.records.map((r) => r.id);
                } else if (action === "UPDATE") {
                  ids = [u.id];
                }

                return {
                  record: r,
                  type: action,
                  ids,
                };
              }),
            )
            .then(() => {
              resolve(true);
            });
          return n;
        });

        if (updateCallback) {
          updateCallback();
        }
      });
    },

    postUpdate(newQuoteItems) {
      return new Promise((resolve) => {
        update((n) => {
          n.quote_items = newQuoteItems;
          return n;
        });

        api
          .from("quote_items")
          .delete()
          .eq("group_id", groupValue.id)
          .then(({ error }) => {
            if (error) console.log(error);
            return api.from("quote_items").insert(newQuoteItems);
          })
          .then(({ error }) => {
            if (error) console.log(error);
            resolve(true);
          });
      });
    },

    updateProp(prop, value) {
      return this.update([{ type: "group", prop, value }]);
    },

    addItem(...items) {
      return this.update([{ type: "item", action: "add", records: items }]);
    },

    removeItem(...ids) {
      return this.update([{ type: "item", action: "remove", ids }]);
    },

    updateItem(id, prop, value, diff) {
      if (prop && typeof prop === "object") {
        return this.update([{ type: "item", id, diff: prop }]);
      } else {
        return this.update([{ type: "item", id, prop, value, diff }]);
      }
    },

    updateItemOrder(order) {
      const commit = (n) => {
        const oldOrder = n.types.order;
        const oldData = n.data;

        sortListBy(n.items, order);
        const data = cloneDeep(n.data);
        lset(data, "item_order", order);

        updateProp("groups", n.id, "data", data);

        return (n) => {
          n.items.order = oldOrder;
          n.data = oldData;
          updateProp("groups", n.id, "data", oldData);
        };
      };

      update((n) => {
        revision.commitTransaction(commit, n, [
          { record: "group", type: "UPDATE", paths: ["data.item_order"] },
        ]);
        return n;
      });
    },

    updateMultipleItems(ids, prop, value, diff) {
      const updates = ids.map((id) => ({
        type: "item",
        id,
        prop,
        value,
        diff,
      }));
      return this.update(updates);
    },

    sortItems(property, direction, ignore) {
      const commit = (n) => {
        const old_data = cloneDeep(n.data);
        old_data.item_order = [...n.items.order];

        sortList(n.items, { property, direction, ignore });
        n.data.item_order = [...n.items.order];
        updateProp("groups", n.id, "data", n.data);

        return (n) => {
          n.items.order = [...old_data.item_order];
          updateProp("groups", n.id, "data", old_data);
        };
      };

      update((n) => {
        revision.commitTransaction(commit, n, [
          { record: "group", type: "UPDATE", paths: ["data.item_order"] },
        ]);
        return n;
      });
    },

    addType(...types) {
      return this.update([{ type: "type", action: "add", records: types }]);
    },

    removeType(...ids) {
      return this.update([{ type: "type", action: "remove", ids }]);
    },

    updateType(id, prop, value, diff) {
      return this.update([{ type: "type", id, prop, value, diff }]);
    },

    updateTypeOrder(order) {
      const commit = (n) => {
        const oldOrder = n.types.order;
        const oldData = n.data;

        sortListBy(n.types, order);
        const data = cloneDeep(n.data);
        lset(data, "type_order", order);

        updateProp("groups", n.id, "data", data);

        return (n) => {
          n.types.order = oldOrder;
          n.data = oldData;
          updateProp("groups", n.id, "data", oldData);
        };
      };

      update((n) => {
        revision.commitTransaction(commit, n, [
          { record: "group", type: "UPDATE", paths: ["data.type_order"] },
        ]);
        return n;
      });
    },

    updateMultipleTypes(ids, prop, value, diff) {
      const updates = ids.map((id) => ({
        type: "type",
        id,
        prop,
        value,
        diff,
      }));
      return this.update(updates);
    },

    updateDocument(id, diff) {
      return this.update([{ type: "document", id, diff }]);
    },

    addDocument(...documents) {
      return this.update([
        { type: "document", action: "add", records: documents },
      ]);
    },

    removeDocument(...ids) {
      return this.update([{ type: "document", action: "remove", ids }]);
    },

    updateDocumentOrder(order) {
      const commit = (n) => {
        const oldOrder = [...n.documents.order];
        sortListBy(n.documents, order);
        order.forEach((id, index) => {
          n.documents[id].index = index;
        });
        const updates = n.documents.order
          .map((id, index) => ({ id, index }))
          .map(async ({ id, index }) => {
            await api.from("documents").update({ index }).eq("id", id);
          });
        Promise.all(updates);

        return (n) => {
          sortListBy(n.documents, oldOrder);
          oldOrder.forEach((id, index) => {
            n.documents[id].index = index;
          });
          const updates = n.documents.order
            .map((id, index) => ({ id, index }))
            .map(async ({ id, index }) => {
              await api.from("documents").update({ index }).eq("id", id);
            });
          Promise.all(updates);
        };
      };

      update((n) => {
        revision.commitTransaction(commit, n, [
          { record: "documents", type: "UPDATE", ids: n.documents.order },
        ]);
        return n;
      });
    },

    updateLocation(id, diff) {
      return this.update([{ type: "location", id, diff }]);
    },

    addLocation(...locations) {
      return this.update([
        { type: "location", action: "add", records: locations },
      ]);
    },

    removeLocation(...ids) {
      return this.update([{ type: "location", action: "remove", ids }]);
    },

    addComment(...comments) {
      const ids = comments.map((c) => c.id);
      const commit = (n) => {
        n.comments.push(...comments);

        const records = comments.map((c) => {
          const { commenter, ...rest } = c;
          return rest;
        });

        addRecords("comments", records);
      };

      update((n) => {
        revision.commitTransactionNotUndoable(commit, n, [
          { record: "comments", type: "INSERT", ids },
        ]);
        return n;
      });
    },

    removeComment(...ids) {
      const commit = (n) => {
        const indexes = ids.map((id) =>
          n.comments.findIndex((i) => i.id === id),
        );
        indexes.sort((a, b) => b - a);
        indexes.forEach((index) => {
          n.comments.splice(index, 1);
        });

        removeRecords("comments", ids);
      };

      update((n) => {
        revision.commitTransactionNotUndoable(commit, n, [
          { record: "comments", type: "DELETE", ids },
        ]);
        return n;
      });
    },

    updateComment(id, prop, value) {
      const commit = (n) => {
        const comment = n.comments.find((i) => i.id === id);
        comment[prop] = value;

        updateProp("comments", comment.id, prop, value);
      };

      update((n) => {
        revision.commitTransactionNotUndoable(commit, n, [
          { record: "comments", type: "UPDATE", ids: [id] },
        ]);
      });
    },

    addAttachment(...attachments) {
      const ids = attachments.map((a) => a.id);
      const commit = async (n) => {
        n.attachments.push(...attachments);

        await addRecords("attachments", attachments);
      };

      return new Promise((resolve) => {
        update((n) => {
          revision
            .commitTransactionNotUndoable(commit, n, [
              { record: "attachments", type: "INSERT", ids },
            ])
            .then(() => {
              resolve(true);
            });
          return n;
        });
      });
    },

    removeAttachment(...ids) {
      const commit = (n) => {
        const indexes = ids.map((id) =>
          n.attachments.findIndex((i) => i.id === id),
        );
        indexes.sort((a, b) => b - a);
        indexes.forEach((index) => {
          n.attachments.splice(index, 1);
        });

        removeRecords("attachments", ids);
      };

      update((n) => {
        revision.commitTransactionNotUndoable(commit, n, [
          { record: "attachments", type: "DELETE", ids },
        ]);
        return n;
      });
    },
  };
}

export { createGroup, fetchGroup, fetchGroupFromLink };
