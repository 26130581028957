<script>
  import { createEventDispatcher } from "svelte";
  import set from "lodash/set";
  import cloneDeep from "lodash/cloneDeep";
  import SidebarSection from "./SidebarSection.svelte";
  import DimtextInput from "./DimtextInput.svelte";
  import SelectInput from "./SelectInput.svelte";
  import DeleteButton from "./DeleteButton.svelte";
  import Viewport from "../drawing/ViewportMinimal.svelte";
  import fabDrawing from "@local/extensions/drawing/fab-drawing.js";
  import { isEdgeFabrication, isCornerFabrication } from "@local/extensions/fabrications/is-fabrication";
  import { orderedList } from "@local/extensions/collections/sortable-list.js";
  import { drawingSrc } from "#src/api";

  export let job;
  export let item;
  export let feature;
  export let disabled;
  export let settings;

  const dispatch = createEventDispatcher();

  $: fab = $job.data.fabrications[feature?.props.fab_id];
  $: dwg = fab && fab.drawing;
  $: drawing = fd(feature, fab);
  $: fabrications = orderedList($job.data.fabrications);
  $: featureType = feature.type === "efab" ? "edge" : "corner";
  $: fabOptions = fabrications
    .filter((f) => (feature.type === "efab" ? isEdgeFabrication(f) : isCornerFabrication(f)))
    .map((f) => ({ label: `${f.mark}${f.name && ` - ${f.name}`}`, value: f.id }));

  function fd(feature, fab) {
    if (!feature) return fabDrawing();

    if (feature.props.orphaned) return fabDrawing(feature.props);

    return fabDrawing(fab);
  }

  function updateFeature(prop, value) {
    const data = cloneDeep(item.data);

    if (Array.isArray(feature.index)) {
      feature.index.forEach((i) => {
        const f = data.fabrications[featureType][i];
        set(f, prop, value);
      });
    } else {
      const f = data.fabrications[featureType][feature.index];
      set(f, prop, value);
    }

    job.updateItem(item.id, { data });
  }

  async function removeFeature() {
    const indices = Array.isArray(feature.index) ? feature.index : [feature.index];
    const features = indices.map((index) => ({ type: feature.type, index }));

    dispatch("deleteFeature", { features });
  }
</script>

<SidebarSection>
  <div class="w-full p-2">
    {#if dwg}
      <div class="w-full border border-gray-300">
        <img class="w-full object-contain" src={drawingSrc(dwg)} alt="" />
      </div>
    {:else}
      <div class="w-full relative h-64 border border-gray-300 bg-gray-100">
        <Viewport padding={0} {drawing} />
      </div>
    {/if}
  </div>
  <SelectInput
    label="Fabrication"
    labelWidth="5.5rem"
    {disabled}
    value={feature.props.fab_id}
    options={fabOptions}
    placeholder="No name"
    on:input={(e) => updateFeature("fab_id", e.detail.value)} />
</SidebarSection>
{#if feature.props.type === "edge-fabrication"}
  <SidebarSection>
    <h3 class="px-2 mb-2">Properties</h3>
    <DimtextInput
      label="Edge distance"
      {disabled}
      labelWidth="5.5rem"
      value={feature.props.reference.length}
      {settings}
      on:input={(e) => updateFeature("reference.length", e.detail.value)} />
  </SidebarSection>
{/if}
{#if !disabled}
  <SidebarSection>
    <DeleteButton on:delete={removeFeature} />
  </SidebarSection>
{/if}
