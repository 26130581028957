<script>
  import { createEventDispatcher, getContext } from "svelte";

  import SidebarSection from "./SidebarSection.svelte";
  import TextInput from "./TextInput.svelte";
  import SelectInput from "./SelectInput.svelte";
  import BooleanInput from "./BooleanInput.svelte";
  import FlagInput from "./FlagInput.svelte";
  import LibraryInput from "./LibraryInput.svelte";
  import DimtextInput from "./DimtextInput.svelte";
  import ContenteditableInput from "./ContenteditableInput.svelte";
  import getTotalThickness from "@local/extensions/formatters/get-total-thickness";
  import MakeupThumbnail from "#lib/MakeupThumbnail.svelte";

  import { genericLibrary } from "#src/extensions/makeup-library.js";

  export let disabled = false;
  export let type;
  export let settings;

  const dispatch = createEventDispatcher();
  const makeupLibrary = getContext("makeupLibrary");
  const supplier = getContext("supplier");
  const suppliers = getContext("suppliers");

  $: totalThickness = getTotalThickness(type, settings);
  $: makeups = $makeupLibrary.map((m) => m.records).flat();

  function updateType(id, prop, value) {
    dispatch("updateType", { id, prop, value });
  }

  function product(type) {
    if (!type.product_id) {
      return null;
    }

    return { id: type.product_id, name: type.name };
  }

  function chooseProduct(e) {
    const makeup = e.detail.value;
    dispatch("updateType", {
      id: type.id,
      diff: {
        name: makeup.name,
        product_id: makeup.id,
        data: {
          product_id: makeup.id,
          ...makeup.data,
        },
      },
    });
  }

  function updateSupplier(e) {
    dispatch("updateSupplier", e.detail);
  }
</script>

<SidebarSection>
  <TextInput
    label="Mark"
    {disabled}
    labelWidth="7.5rem"
    value={type.mark}
    on:input={(evt) => updateType(type.id, "mark", evt.detail.value)} />
  {#if $makeupLibrary?.length}
    <LibraryInput
      label="Product"
      title="Product Library"
      {disabled}
      labelWidth="7.5rem"
      nullText="Generic Product"
      value={product(type)}
      preLibrary={genericLibrary()}
      library={$makeupLibrary}
      on:input={chooseProduct}>
      <div slot="prepend">Choose a generic template to begin:</div>
      <svelte:fragment slot="pend">
        {#if makeups.length || $suppliers?.length > 1}
          <div>Or select a supplier-defined product:</div>
        {/if}
        {#if $suppliers?.length > 1}
          <SelectInput
            border
            label="Supplier"
            value={$supplier?.id}
            options={$suppliers.map((s) => ({ value: s.id, label: s.name }))}
            on:input={updateSupplier} />
        {:else if $suppliers?.length === 1}
          <div class="font-bold mt-2">{$supplier?.name}</div>
        {/if}
      </svelte:fragment>
      <MakeupThumbnail slot="thumbnail" let:record {record} />
      <div slot="caption" let:record>{record?.name}</div>
    </LibraryInput>
  {/if}
  {#if !type.product_id}
    <TextInput
      label="Name"
      {disabled}
      labelWidth="7.5rem"
      value={type.name}
      on:input={(evt) => updateType(type.id, "name", evt.detail.value)} />
  {/if}
  {#if type.data !== "Mixed"}
    {#if !type.product_id}
      <DimtextInput
        label="Desired Thickness"
        {disabled}
        labelWidth="7.5rem"
        value={type.desired_thickness}
        {settings}
        allowNull
        highlightNull={false}
        on:input={(e) => updateType(type.id, "desired_thickness", e.detail.value)} />
    {/if}
    <TextInput
      label="Computed Thickness"
      readonly
      {disabled}
      labelWidth="7.5rem"
      value={totalThickness ?? ""} />
  {/if}
  <FlagInput
    label="Flag"
    {disabled}
    labelWidth="7.5rem"
    value={type.approval_status}
    on:input={(e) => updateType(type.id, "approval_status", e.detail.value)} />
  <BooleanInput
    label="Flip Orientation"
    {disabled}
    labelWidth="7.5rem"
    value={type.data.flip_orientation}
    on:input={(e) => updateType(type.id, "data.flip_orientation", e.detail.value)} />
  <ContenteditableInput
    label="Notes"
    {disabled}
    labelWidth="7.5rem"
    value={type.notes}
    on:input={(evt) => updateType(type.id, "notes", evt.detail.value)} />
</SidebarSection>
