import { api } from "#src/api";
import { writable } from "svelte/store";
import { sortableList } from "@local/extensions/collections/sortable-list.js";

function createFabricationStore() {
  const { subscribe, set, update } = writable(sortableList([]));

  return {
    subscribe,
    set,
    update,
    async fetch() {
      try {
        const { data, error } = await api
          .from("standard_fabrications")
          .select("*");
        if (error) throw error;
        set(sortableList(data));
      } catch (error) {
        console.log(error);
      }
    },
  };
}

export { createFabricationStore };
