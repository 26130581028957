<script>
  import mime from "mime";
  import { downloadBlob } from "downloaders";

  import { Modal } from "svelte-utilities";
  import Dropzone from "#lib/dropzone/Dropzone.svelte";
  import TrashIcon from "@local/assets/icons/trash.svg";
  import PaperclipIcon from "@local/assets/icons/paperclip.svg";
  import CirclePlusIcon from "@local/assets/icons/circle-plus.svg";
  import FileIcon from "@local/assets/icons/file.svg";
  import FilePdfIcon from "@local/assets/icons/file-pdf.svg";
  import FileImgIcon from "@local/assets/icons/file-img.svg";

  import { api } from "#src/api";
  import { profile } from "#src/stores/auth.js";
  import s3SafeKey from "#src/extensions/s3-safe-key.js";
  import extension from "@local/extensions/utilities/extension.js";

  export let group;
  export let attachments = [];
  export let itemid = null;
  export let typeid = null;
  export let disabled = false;

  const fileIcons = {
    file: FileIcon,
    pdf: FilePdfIcon,
    jpg: FileImgIcon,
    jpeg: FileImgIcon,
    png: FileImgIcon,
    svg: FileImgIcon,
  };

  let dropzoneModal;
  let dropzone;

  function upload() {
    dropzoneModal.open();
  }

  function name(filename) {
    const segs = filename.split(".");
    if (segs.length > 1) return segs.slice(0, -1).join(".");
    return segs[0];
  }

  function filename(path) {
    return path.split("/").pop();
  }

  async function uploadFiles(evt) {
    const { file, data } = evt.detail;
    const ext = extension(file.name);
    let fname = s3SafeKey(file.name);
    while (attachments.some((a) => filename(a.name) === fname)) {
      const n = name(fname);
      fname = n + "-copy." + ext;
    }

    let subdir = "";
    if (itemid !== null) {
      subdir = `${itemid}/`;
    } else if (typeid !== null) {
      subdir = `${typeid}/`;
    }

    const awsFilename = `${$group.id}/${subdir}${fname}`;

    await group.addAttachment({
      id: crypto.randomUUID(),
      user_id: $profile.id,
      group_id: $group.id,
      item_id: itemid,
      type_id: typeid,
      object_id: awsFilename,
      name: fname,
    });

    const contentType = mime.getType(ext);

    await api.storage.from("attachments").upload(awsFilename, data, { contentType });

    dropzone.stopLoading();

    dropzoneModal.close();
  }

  async function openAttachment(attachment) {
    try {
      const { data, error } = await api.storage.from("attachments").download(attachment.object_id);
      if (error) throw error;

      downloadBlob(data, attachment.name);
    } catch (error) {
      console.log(error);
    }
  }

  function removeAttachment(attachment) {
    group.removeAttachment(attachment.id);
  }
</script>

<div class="flex items-start gap-2 py-1">
  <div class="py-1">
    <PaperclipIcon />
  </div>
  <div class="space-y-2">
    {#if attachments.length}
      <div class="pt-1 space-y-2">
        {#each attachments as attachment}
          <div class="attachment flex items-center gap-2">
            <button
              class="text-left grow cursor-pointer flex items-center space-x-1"
              on:click={() => openAttachment(attachment)}>
              <div class="flex-none text-gray-400">
                <svelte:component this={fileIcons[extension(attachment.name)] || FileIcon} />
              </div>
              <div class="grow">
                {attachment.name}
              </div>
            </button>
            <button
              class="attachment-delete flex-none text-gray-500"
              on:click={() => removeAttachment(attachment)}>
              <TrashIcon />
            </button>
          </div>
        {/each}
      </div>
    {/if}
    {#if !disabled}
      <button on:click={upload} class="item-list-button">
        <CirclePlusIcon />
        <div>Add Attachment</div>
      </button>
    {/if}
    {#if disabled && !attachments.length}
      <div class="text-gray-400 p-1">No attachments</div>
    {/if}
  </div>
</div>

<Modal bind:this={dropzoneModal} closeable>
  <div slot="title">Upload</div>
  <div slot="content">
    <Dropzone on:drop={uploadFiles} bind:this={dropzone} />
  </div>
</Modal>

<style lang="scss">
  .attachment-delete {
    visibility: hidden;
  }

  .attachment:hover {
    .attachment-delete {
      visibility: visible;
    }
  }
</style>
