<script>
  import { createEventDispatcher, getContext } from "svelte";
  import range from "lodash/range";
  import uniq from "lodash/uniq";
  import { navigate } from "svelte-routing";

  import SpinnerIcon from "@local/assets/icons/spinner-lg.svg";
  import UploadIcon from "@local/assets/icons/upload.svg";
  import CaretDownIcon from "@local/assets/icons/caret-down.svg";
  import CaretRightIcon from "@local/assets/icons/caret-right.svg";
  import Datatable from "#lib/datatable/Datatable.svelte";
  import QuoteListItem from "#lib/home/QuoteListItem.svelte";
  import JobListItem from "#lib/home/JobListItem.svelte";

  import { jobs, filteredJobs, draftList, selectedJobIds } from "#src/stores/jobs.js";
  import { quotes, filteredQuotes, selectedQuoteIds, unsentList, sentList } from "#src/stores/quotes.js";
  import { detailVisible, showDrafts, showUnsent, showSent } from "#src/stores/ui.js";
  import { profile } from "#src/stores/auth.js";
  import eb from "#src/extensions/event-bus.js";

  const dispatch = createEventDispatcher();
  const productUser = getContext("productUser");

  let fetched = false;

  $: unsent = unsentList(filteredQuotes);
  $: sent = sentList(filteredQuotes);
  $: drafts = draftList(filteredJobs);
  $: selectOne($filteredQuotes, $filteredJobs, fetched);
  $: selected = makeSelection($selectedQuoteIds, $selectedJobIds);

  async function fetchQuotes() {
    await Promise.all([quotes.fetch(), jobs.fetch(), quotes.subscribeToDbUpdates()]);
    fetched = true;
  }

  function makeSelection(quoteIds, jobIds) {
    return [...quoteIds, ...jobIds].reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});
  }

  function selectOne(quotes, jobs, fetched) {
    if (!quotes || !jobs || !fetched) return;
    const pj = $selectedJobIds.filter((id) => jobs[id]);
    const pq = $selectedQuoteIds.filter((id) => quotes[id]);
    const all = [...pj, ...pq];
    if (all.length && pq.length === $selectedQuoteIds.length && pj.length === $selectedJobIds.length) {
      return;
    }

    if (!all.length) {
      const fj = jobs[jobs.order[0]];
      const fq = quotes[quotes.order[0]];
      if (!fq && !fj) {
        $selectedQuoteIds = [];
        $selectedJobIds = [];
      } else if (fj) {
        $selectedJobIds = [fj.id];
        $selectedQuoteIds = [];
      } else {
        $selectedJobIds = [];
        $selectedQuoteIds = [fq.id];
      }
    } else {
      $selectedQuoteIds = pq;
    }
  }

  function selectQuote(e, list) {
    if (e.detail.event.shiftKey) {
      let index;
      if (list === "drafts") {
        index = e.detail.index;
      } else if (list === "unsent") {
        index = e.detail.index + $drafts.length;
      } else if (list === "sent") {
        index = e.detail.index + $drafts.length + $unsent.length;
      }

      const draftIndices = $selectedJobIds.map((id) => $filteredJobs.indices[id]);
      const d = $drafts.length;
      const quoteIndices = $selectedQuoteIds.map((id) => $filteredQuotes.indices[id]).map((i) => i + d);
      const min = Math.min(...draftIndices, ...quoteIndices, index);
      const max = Math.max(...draftIndices, ...quoteIndices, index);
      const newIndices = range(min, max + 1);
      const dids = newIndices.filter((i) => i < d);
      const qids = newIndices.filter((i) => i >= d).map((i) => i - d);
      $selectedJobIds = dids.map((i) => $filteredJobs.order[i]);
      $selectedQuoteIds = qids.map((i) => $filteredQuotes.order[i]);
    } else if (e.detail.event.metaKey || e.detail.event.ctrlKey) {
      if ($selectedQuoteIds.includes(e.detail.id)) {
        $selectedQuoteIds = $selectedQuoteIds.filter((id) => id !== e.detail.id);
      } else if ($selectedJobIds.includes(e.detail.id)) {
        $selectedJobIds = $selectedJobIds.filter((id) => id !== e.detail.id);
      } else if ($filteredQuotes[e.detail.id]) {
        $selectedQuoteIds = uniq([...$selectedQuoteIds, e.detail.id]);
      } else if ($filteredJobs[e.detail.id]) {
        $selectedJobIds = uniq([...$selectedJobIds, e.detail.id]);
      }
    } else {
      if (list === "drafts") {
        $selectedJobIds = [e.detail.id];
        $selectedQuoteIds = [];
      } else {
        $selectedJobIds = [];
        $selectedQuoteIds = [e.detail.id];
      }
    }

    if ($selectedQuoteIds.length > 0 || $selectedJobIds.length > 0) {
      $detailVisible = true;
    } else {
      $detailVisible = false;
    }
  }

  function gotoQuote(e) {
    if ($productUser) return;
    const quote = $quotes[e.detail.id];
    if (quote.project_type === "product") return;
    navigate(`versions/${quote.request_group_id}`);
  }

  async function cloneDraft(id) {
    const draft = $jobs[id];
    if (!draft) return;

    const group_id = draft.current_group_id;

    try {
      const assignedTo = $profile?.user_role === "developer" ? null : $profile?.id;
      const job_id = await jobs.cloneRevisionToNewJob(group_id, assignedTo);
      $selectedJobIds = [job_id];
      $selectedQuoteIds = [];
    } catch (error) {
      console.error(error);
    }
  }

  async function cloneQuote(id) {
    const quote = $quotes[id];
    if (!quote) return;

    const group_id = quote.group_id;

    try {
      const assignedTo = $profile?.user_role === "developer" ? null : $profile?.id;
      const job_id = await jobs.cloneRevisionToMyOrg(group_id, assignedTo);
      $selectedJobIds = [job_id];
      $selectedQuoteIds = [];
    } catch (error) {
      console.error(error);
    }
  }

  function gotoJob(e) {
    if ($productUser) return;
    const job = $jobs[e.detail.id];
    if (job.project_type === "product") return;
    navigate(`jobs/${job.id}/current`);
  }

  function confirmDeleteJob(id) {
    dispatch("delete-job", { id });
  }

  async function archiveQuote(e) {
    await quotes.archive(e.detail.id);
  }

  async function archiveJob(e) {
    await jobs.archive(e.detail.id);
  }
</script>

{#if quotes}
  {#await fetchQuotes()}
    <div class="text-gray-600 p-4 h-24 w-24 relative">
      <div class="absolute">
        <div class="animate-spin">
          <SpinnerIcon />
        </div>
      </div>
    </div>
  {:then}
    <div>
      <div class="w-full text-sm flex gap-2">
        <button class="btn btn-primary-alt" on:click={() => eb.dispatch("create-rfq")}>
          + New {$productUser ? "Quote" : "Draft"}</button>
        {#if $profile?.user_role === "developer"}
          <button class="btn btn-icon btn-primary-alt" on:click={() => eb.dispatch("upload-job")}>
            <UploadIcon />
          </button>
        {/if}
      </div>
      <div class="text-sm p-2 flex items-center">
        <button class="p-1" on:click={() => ($showDrafts = !$showDrafts)}>
          {#if $showDrafts}
            <CaretDownIcon />
          {:else}
            <CaretRightIcon />
          {/if}
        </button>
        {#if $showDrafts}
          <div class="text-gray-500">Drafts</div>
        {:else}
          <div class="text-gray-500">Drafts ({$drafts.length})</div>
        {/if}
      </div>

      {#if $showDrafts}
        <div class="bg-white border rounded-lg mb-2">
          <Datatable
            fixed
            rowComponent={JobListItem}
            data={$drafts}
            archivable
            deletable
            visitable={!$productUser}
            clickable
            cloneable
            {selected}
            on:clickrow={(e) => selectQuote(e, "drafts")}
            on:archiverow={archiveJob}
            on:dblclickrow={gotoJob}
            on:clonerow={(e) => cloneDraft(e.detail.id)}
            on:deleterow={(e) => confirmDeleteJob(e.detail.id)}
            on:gotorow={gotoJob}>
            <div slot="nocontent" class="text-xs flex gap-2 py-2 px-2">
              <button class="text-blue-500" on:click={() => eb.dispatch("create-rfq")}> Create New </button>
            </div>
          </Datatable>
        </div>
      {/if}

      {#if $profile?.user_role !== "product_user"}
        <div class="text-sm p-2 flex items-center">
          <button class="p-1" on:click={() => ($showUnsent = !$showUnsent)}>
            {#if $showUnsent}
              <CaretDownIcon />
            {:else}
              <CaretRightIcon />
            {/if}
          </button>
          {#if $showUnsent}
            <div class="text-gray-500">Received</div>
          {:else}
            <div class="text-gray-500">Received ({$unsent.length})</div>
          {/if}
        </div>

        {#if $showUnsent}
          <div class="bg-white border rounded-lg mb-2">
            <Datatable
              fixed
              rowComponent={QuoteListItem}
              data={$unsent}
              archivable
              visitable={!$productUser}
              clickable
              cloneable
              {selected}
              on:clickrow={(e) => selectQuote(e, "unsent")}
              on:dblclickrow={gotoQuote}
              on:archiverow={archiveQuote}
              on:clonerow={(e) => cloneQuote(e.detail.id)}
              on:gotorow={gotoQuote}>
              <div slot="nocontent" class="text-xs flex gap-2 py-2 px-2">
                <div class="italic">No requests received.</div>
              </div>
            </Datatable>
          </div>
        {/if}
      {/if}
    </div>

    {#if $sent.length > 0}
      <div>
        <div class="text-sm p-2 flex items-center">
          <button class="p-1" on:click={() => ($showSent = !$showSent)}>
            {#if $showSent}
              <CaretDownIcon />
            {:else}
              <CaretRightIcon />
            {/if}
          </button>
          {#if $showSent}
            <div class="text-gray-500">Quoted</div>
          {:else}
            <div class="text-gray-500">Quoted ({$sent.length})</div>
          {/if}
        </div>
        {#if $showSent}
          <div class="bg-white border rounded-lg mb-2">
            <Datatable
              fixed
              rowComponent={QuoteListItem}
              archivable
              visitable={!$productUser}
              clickable
              cloneable
              data={$sent}
              {selected}
              on:clickrow={(e) => selectQuote(e, "sent")}
              on:dblclickrow={gotoQuote}
              on:gotorow={gotoQuote}
              on:clonerow={(e) => cloneQuote(e.detail.id)}
              on:archiverow={archiveQuote} />
          </div>
        {/if}
      </div>
    {/if}
  {/await}
{/if}
