<!-- <svelte:options strictprops={false} /> -->

<script>
  import { Link } from "svelte-routing";
  import { onMount } from "svelte";

  import CaretLeftIcon from "@local/assets/icons/caret-left.svg";
  import ProfileSettings from "#lib/settings/ProfileSettings.svelte";

  import { api } from "#src/api";
  import { profile } from "#src/stores/auth.js";

  export let profileid;

  let p;

  $: isUser = $profile && profileid === $profile.id;

  onMount(() => {
    api
      .from("profiles")
      .select("*, organization: organization_id (name, org_type)")
      .eq("id", profileid)
      .single()
      .then(async ({ data, error }) => {
        if (error) {
          console.log(error);
          return;
        }

        p = data;
      });
  });
</script>

<div class="text-blue-500 text-sm mb-4">
  <Link to="/settings/users" class="flex items-center space-x-1">
    <CaretLeftIcon />
    <div>All Users</div>
  </Link>
</div>

{#if $profile && p}
  <ProfileSettings profile={p} {isUser} />
{/if}
