<script>
  import { createEventDispatcher, tick } from "svelte";
  import pasteAsPlaintext from "#src/extensions/dom/paste-as-plaintext.js";

  export let border = false;
  export let label = "Label";
  export let labelWidth = null;
  export let value;
  export let disabled = false;
  export let readonly = false;
  export let placeholder = "";
  export let fullwidth = false;
  export let outerBorder = true;

  let timer;
  let input;

  const dispatch = createEventDispatcher();

  let localValue = value || "";
  let focused = false;

  $: lw = labelWidth ? `width:${labelWidth};` : "";
  $: refresh(value, input);

  function refresh(value, input) {
    if (input && value !== input.innerText.trim()) localValue = value || "";
  }

  function update() {
    if (input) {
      const v = input.innerText.trim();
      if (v !== value) {
        value = v;
        dispatch("input", { value: v });
      }
    }
  }

  function debounce() {
    clearTimeout(timer);
    timer = setTimeout(() => {
      update();
    }, 1000);
  }

  async function focus() {
    await tick();
    if (input) {
      input.focus();
    }
  }

  function focusInput() {
    focused = true;
  }

  function blur(e) {
    update();
    focused = false;
    if (placeholder && !value) {
      e.target.innerHTML = "";
    }
  }
</script>

<div
  class="prop-container"
  class:has-outer-border={outerBorder}
  class:has-inner-border={!outerBorder}
  class:focused
  class:readonly={readonly || disabled}
  class:visible-border={border}
  on:click|stopPropagation={focus}>
  <div class="label" style={lw}>
    <slot name="label">
      {label}
    </slot>
  </div>
  {#if disabled || readonly}
    <div
      bind:this={input}
      class="editable-div whitespace-pre-wrap"
      class:w-72={!fullwidth}
      class:w-full={fullwidth}>
      {localValue}
    </div>
  {:else}
    <div
      bind:this={input}
      contenteditable
      class="editable-div whitespace-pre-wrap"
      class:w-72={!fullwidth}
      class:w-full={fullwidth}
      data-placeholder={placeholder}
      bind:innerHTML={localValue}
      on:focus={focusInput}
      on:blur={blur}
      on:paste={pasteAsPlaintext}
      on:input={() => debounce()} />
  {/if}
</div>

<style lang="scss">
  .editable-div {
    border: none;
    background-color: none;
    outline: none;
    min-width: 0;
    max-width: 100%;
  }

  .prop-container {
    @apply w-full flex items-start rounded;

    &.has-outer-border {
      @apply p-2;

      &:hover:not(.readonly):not(.focused),
      &.visible-border {
        @apply ring-1 ring-inset ring-gray-300;
      }
      &.focused {
        @apply ring-2 ring-inset ring-blue-500;
      }
    }

    .label {
      @apply truncate text-gray-400 flex-none;
    }

    .editable-div {
      @apply min-w-0 grow shrink bg-transparent ml-2;
    }

    &.has-inner-border {
      .editable-div {
        @apply p-1 rounded;
      }

      .label {
        @apply pt-1;
      }
    }

    &.has-inner-border:not(.readonly):not(.focused) {
      .editable-div:hover {
        @apply ring-1 ring-inset ring-gray-300;
      }
    }

    &.has-inner-border.focused {
      .editable-div {
        @apply ring-2 ring-inset ring-blue-500;
      }
    }

    .editable-div:empty:before {
      content: attr(data-placeholder);
      color: #a0aec0;
    }
  }
</style>
