<script>
  import { createEventDispatcher } from "svelte";
  import TextInput from "./TextInput.svelte";
  import ContenteditableInput from "./ContenteditableInput.svelte";
  import ColorInput from "./ColorInput.svelte";
  import ImageInput from "./ImageInput.svelte";
  import BooleanInput from "./BooleanInput.svelte";
  import imageUrl from "#src/extensions/image-url.js";
  import CopyTextField from "./CopyTextField.svelte";

  import { api } from "#src/api";
  import { profile } from "#src/stores/auth.js";

  export let surface;
  export let disabled;
  export let inline = false;

  const dispatch = createEventDispatcher();

  $: multiple = Array.isArray(surface.id);

  function update(prop, value) {
    dispatch("update", { id: surface.id, prop, value });
  }

  async function uploadImage(e) {
    if (e.detail.type && e.detail.data) {
      const uuid = crypto.randomUUID();
      const filename = `${uuid}/${e.detail.filename}`;
      const { data: result, error } = await api.storage.from("images").upload(filename, e.detail.data, {
        contentType: e.detail.contentType,
      });

      if (!error) {
        const image = {
          id: uuid,
          bucket: "images",
          object_id: result.path,
          content_type: e.detail.contentType,
          filename: e.detail.filename,
        };
        dispatch("update", { id: surface.id, prop: "image", value: image });
      }
    }
  }
</script>

<div class="text-xs px-4">
  {#if !multiple}
    <ImageInput
      labelWidth="5rem"
      label="Image"
      value={imageUrl(surface.image)}
      accept="image/png, image/jpeg"
      uploadType="arraybuffer"
      {disabled}
      on:upload={uploadImage}
      on:delete={() => update("image", null)} />
  {/if}
  <ContenteditableInput
    label="Name"
    {disabled}
    labelWidth="5rem"
    value={surface.name}
    on:input={(evt) => update("name", evt.detail.value)} />
  <ContenteditableInput
    label="Description"
    {disabled}
    labelWidth="5rem"
    value={surface.description}
    on:input={(evt) => update("description", evt.detail.value)} />
  <ContenteditableInput
    label="Category"
    {disabled}
    labelWidth="5rem"
    value={surface.category}
    on:input={(evt) => update("category", evt.detail.value)} />
  <TextInput
    label="Classification"
    {disabled}
    labelWidth="5rem"
    value={surface.classification}
    on:input={(evt) => update("classification", evt.detail.value)} />
  <ColorInput
    label="Color"
    {disabled}
    labelWidth="5rem"
    value={surface.color}
    selectionType="native"
    nullable
    on:input={(evt) => update("color", evt.detail.value)} />
  <TextInput
    label="Vendor Code"
    {disabled}
    labelWidth="5rem"
    value={surface.vendor_code}
    on:input={(evt) => update("vendor_code", evt.detail.value)} />
  {#if !inline}
    <BooleanInput
      label="Published"
      {disabled}
      labelWidth="5rem"
      value={surface.published}
      on:input={(evt) => update("published", evt.detail.value)} />
  {/if}
  {#if $profile?.user_role === "developer" && !multiple}
    <CopyTextField label="ID" labelWidth="5rem" value={surface.id} />
  {/if}
</div>
