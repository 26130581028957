<script>
  import { createEventDispatcher } from "svelte";
  import upperCase from "lodash/upperCase";

  import ProfileIcon from "@local/assets/icons/profile.svg";
  import CheckIcon from "@local/assets/icons/check.svg";
  import CircleXIcon from "@local/assets/icons/circle-x-filled.svg";
  import CirclePlusIcon from "@local/assets/icons/circle-plus.svg";
  import CaretDownIcon from "@local/assets/icons/caret-down.svg";

  import SelectInput from "#lib/sidebar/SelectInput.svelte";

  import { currentSupplierid, currentSuppliers } from "#src/stores/ui.js";
  import { clickOutside, Popover } from "svelte-utilities";
  import getUserInitials from "@local/extensions/formatters/get-user-initials.js";
  import { objectify } from "overline";

  export let recipients;
  export let editable = false;
  export let labelWidth = null;
  export let labelStyle = null;
  export let org;
  export let showAttn = false;

  const dispatch = createEventDispatcher();

  let dropdownButton;
  let showDropdown = false;

  $: lw = labelWidth ? `width:${labelWidth};` : "";
  $: lStyle = `${lw} ${labelStyle || ""}`;
  $: supplier = $currentSuppliers[$currentSupplierid];
  $: sp = objectify(supplier?.profiles || []);
  $: recipient = recipients[0];
  $: attnOptions = getAttnOptions(supplier);
  $: recipientPresent = attnOptions.find((o) => o.email === recipient?.email);
  $: allAttnOptions = recipientPresent || !recipient ? attnOptions : [recipient, ...attnOptions];
  $: attnSelectOptions = allAttnOptions.map((o, i) => ({
    label: o.name ? `${o.name} (${o.email})` : o.email,
    value: o.primary_contact_id || o.email,
    initials: o.primary_contact_id ? upperCase(getUserInitials(sp[o.primary_contact_id])) : null,
    color: o.primary_contact_id ? sp[o.primary_contact_id]?.user_color : null,
  }));

  function getAttnOptions(supplier) {
    if (!supplier) return [];

    const primary = {
      type: "org",
      contact_id: supplier.id,
      primary_contact_id: null,
      company_name: supplier.name,
      name: null,
      email: supplier.primary_contact_email,
    };

    const individuals = supplier.profiles
      .filter((p) => p.is_public)
      .map((p) => {
        return {
          type: "org",
          contact_id: supplier.id,
          primary_contact_id: p.id,
          company_name: supplier.name,
          name: p.username,
          email: p.email,
        };
      });

    if (supplier.primary_contact_email) {
      return [primary, ...individuals];
    } else {
      return individuals;
    }
  }

  function changeAttn(e) {
    const { value } = e.detail;
    if (recipient?.primary_contact_id === value || recipient?.email?.trim === value) {
      return;
    }
    const index = attnSelectOptions.findIndex((o) => o.value === value);
    const r = allAttnOptions[index];

    if (r) {
      dispatch("set-recipient", { recipient: r });
    }
  }

  function selectSupplier(id) {
    const newSupplier = $currentSuppliers[id];
    if (!newSupplier) return;

    $currentSupplierid = id;

    const recipient = {
      type: "org",
      contact_id: newSupplier.id,
      primary_contact_id: null,
      company_name: newSupplier.name,
      name: null,
      email: newSupplier.primary_contact_email,
    };

    dispatch("set-recipient", { recipient });

    showDropdown = false;
  }

  function recipientEmail(recipient) {
    if (recipient.name) {
      return `${recipient.name} (${recipient.email})`;
    } else if (recipient.email) {
      return recipient.email;
    }

    return "";
  }
</script>

<div class="flex flex-col items-start sm:items-end">
  {#if org.account_type === "free"}
    {#if supplier}
      <div class="flex items-start">
        <div class="flex flex-col items-end">
          {#if supplier.data.logo}
            <img
              class="block logo-image object-contain"
              src={supplier.data.logo.base64}
              alt="Logo Thumbnail" />
          {/if}
          <div class="text-sm font-bold">
            {supplier.name}
          </div>
          {#if recipient && showAttn}
            {#if attnSelectOptions.length > 1}
              <div class:-mr-5={$currentSuppliers.order.length > 1}>
                <SelectInput
                  labelWidth="1rem"
                  options={attnSelectOptions}
                  value={recipient.primary_contact_id || recipient.email}
                  outerBorder={false}
                  showCaret
                  on:input={changeAttn}>
                  <div class="text-black" slot="label">
                    <ProfileIcon />
                  </div>
                  <div slot="selected" let:option class="flex items-center gap-1">
                    {#if option.initials}
                      <div
                        class="profile-circle relative"
                        style="background-color: {option.color || '#000'}; border-color: {option.color ||
                          '#000'}">
                        {option.initials}
                      </div>
                    {/if}
                    <div class:text-gray-400={!option.value}>
                      {option.label}
                    </div>
                  </div>
                  <div slot="option" let:option class="flex items-center gap-1 overflow-hidden">
                    {#if option.initials}
                      <div
                        class="profile-circle relative"
                        style="background-color: {option.color || '#000'}; border-color: {option.color ||
                          '#000'}">
                        {option.initials}
                      </div>
                    {:else}
                      <div class="flex-none h-4 w-4" />
                    {/if}
                    <div class:text-gray-400={!option.value} class="truncate">
                      {option.label}
                    </div>
                  </div>
                </SelectInput>
              </div>
            {:else}
              <div class="text-gray-500 py-1">
                {recipientEmail(recipient)}
              </div>
            {/if}
          {/if}
        </div>

        {#if $currentSuppliers.order.length > 1}
          <button
            class="relative p-1"
            bind:this={dropdownButton}
            on:click={() => (showDropdown = !showDropdown)}>
            <CaretDownIcon />
            {#if showDropdown}
              <Popover container={dropdownButton} align="right">
                <div
                  class="p-2 text-xs text-black text-left"
                  use:clickOutside={[dropdownButton]}
                  on:outclick={() => (showDropdown = false)}>
                  {#each $currentSuppliers.order as id}
                    <div class="flex items-center justify-start">
                      <div class="w-6 flex-none flex items-center justify-center">
                        {#if id === $currentSupplierid}
                          <CheckIcon />
                        {/if}
                      </div>
                      <button
                        class="dropdown-button-item grow whitespace-nowrap"
                        class:font-bold={id === $currentSupplierid}
                        on:click={() => selectSupplier(id)}>
                        {$currentSuppliers[id].name}
                      </button>
                    </div>
                  {/each}
                </div>
              </Popover>
            {/if}
          </button>
        {/if}
      </div>
    {:else}
      <div>
        This free account is not sponsored by any supplier.<br />
        Please subscribe in order to unlock sharing functionality.
      </div>
    {/if}
  {:else}
    <div style={lStyle} class="mb-2 font-bold text-sm">Recipients</div>
    <div class="flex flex-col items-start sm:items-end gap-2">
      {#each recipients as recipient}
        <div class="item-list-button">
          <div class="flex items-center gap-2">
            <ProfileIcon />
            <div style="max-width:6rem;" class="truncate">{recipient.company_name}</div>
            <div class="text-gray-500 text-xs">{recipientEmail(recipient)}</div>
          </div>
          {#if editable}
            <button
              class="remove-button"
              on:click|stopPropagation={() => dispatch("remove-recipient", { id: recipient.id })}>
              <CircleXIcon />
            </button>
          {/if}
        </div>
      {/each}
      {#if editable}
        <button class="item-list-button" on:click={() => dispatch("add-recipient")}>
          <CirclePlusIcon />
          <div>Add Recipient</div>
        </button>
      {/if}
    </div>
  {/if}
</div>

<style lang="scss">
  .logo-image {
    max-width: 12rem;
    max-height: 2.5rem;
  }
</style>
