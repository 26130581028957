<script>
  import QuoteDetail from "#lib/home/QuoteDetail.svelte";
  import JobDetail from "#lib/home/JobDetail.svelte";
  import MultiDetail from "#lib/home/MultiDetail.svelte";
  import { quotes, filteredQuotes, selectedQuoteIds } from "#src/stores/quotes.js";
  import { jobs, filteredJobs, selectedJobIds } from "#src/stores/jobs.js";
  import { api } from "#src/api";

  $: selectedJob = $selectedJobIds.map((id) => $filteredJobs[id]);
  $: selectedQuote = $selectedQuoteIds.map((id) => $filteredQuotes[id]);
  $: allRecords = [...selectedJob, ...selectedQuote];

  async function archiveSelected(e) {
    const qids = $selectedQuoteIds.filter((id) => $filteredQuotes[id]);
    const jids = $selectedJobIds.filter((id) => $filteredJobs[id]);

    if (qids.length > 0) {
      await quotes.archive(...$selectedQuoteIds);
    }

    if (jids.length > 0) {
      await jobs.archive(...$selectedJobIds);
    }
  }

  async function assignSelected(e) {
    const { ids, assigned_to_id } = e.detail;
    const qids = ids.filter((id) => $filteredQuotes[id]);
    const jids = ids.filter((id) => $filteredJobs[id]);

    if (qids.length > 0) {
      await api.from("quotes").update({ assigned_to_id }).in("id", qids);
      await quotes.fetchById(...$selectedQuoteIds);
    }

    if (jids.length > 0) {
      await api.from("jobs").update({ assigned_to_id }).in("id", jids);
      await jobs.fetchById(...$selectedJobIds);
    }
  }

  async function clearNotifications(e) {
    const { notifications } = e.detail;

    await api.from("notifications").delete().in("id", notifications);

    await quotes.fetchById(...$selectedQuoteIds);
  }
</script>

<div class="h-full overflow-y-auto">
  {#if allRecords.length}
    {#if allRecords.length === 1}
      {@const sel = allRecords[0]}
      {#if sel}
        {#key sel?.id}
          {#if $filteredQuotes[sel.id]}
            <QuoteDetail quoteid={sel.id} />
          {:else}
            <JobDetail jobid={sel.id} />
          {/if}
        {/key}
      {/if}
    {:else if allRecords.length > 1}
      <MultiDetail
        recordName="job"
        records={allRecords}
        on:archive={archiveSelected}
        on:assign={assignSelected}
        on:clear-notifications={clearNotifications} />
    {/if}
  {:else}
    <div class="p-4 italic text-sm">No Quote selected.</div>
  {/if}
</div>
