<script>
  import { navigate } from "svelte-routing";
  import Navbar from "#lib/Navbar.svelte";
  import NavbarUnauthenticated from "#lib/NavbarUnauthenticated.svelte";

  import { user } from "#src/stores/auth.js";
</script>

{#if $user}
  <Navbar />
{:else}
  <NavbarUnauthenticated />
{/if}
<div class="w-full h-full p-12 text-center">
  <h1>404</h1>
  <p>You've found a page that doesn't exist.</p>
  <button class="btn btn-primary my-8" on:click={() => navigate("/")}>Home</button>
</div>
