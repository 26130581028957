<svelte:options strictprops={false} />

<script>
  import { Router, Route, navigate } from "svelte-routing";
  import { nanoid } from "nanoid";
  import { onMount, getContext } from "svelte";

  import Navbar from "#lib/Navbar.svelte";
  import ArchiveIcon from "@local/assets/icons/archive.svg";
  import EditableHeader from "#lib/sidebar/EditableHeader.svelte";
  import CreateModal from "#lib/home/CreateModal.svelte";
  import UploadModal from "#lib/home/UploadModal.svelte";
  import LabeledToggleButton from "#lib/LabeledToggleButton.svelte";
  import FilterInput from "#lib/home/FilterInput.svelte";
  import ActionButtons from "#lib/home/ActionButtons.svelte";
  import { Modal } from "svelte-utilities";
  import XIcon from "@local/assets/icons/x.svg";

  import QuoteList from "./QuoteList.svelte";
  import RfqList from "./RfqList.svelte";

  import QuoteDetailContainer from "./QuoteDetailContainer.svelte";
  import RfqDetailContainer from "./RfqDetailContainer.svelte";

  import { profile } from "#src/stores/auth.js";
  import { rfqs, selectedRfqIds, rfqFilter } from "#src/stores/rfqs.js";
  import { quotes, selectedQuoteIds, quoteFilter } from "#src/stores/quotes.js";
  import { jobs, selectedJobIds, cloneJob } from "#src/stores/jobs.js";
  import { api } from "#src/api";
  import { detailVisible, myRfqsOnly } from "#src/stores/ui.js";

  export let url = "";

  let filter = "";
  let deleteRfqModal;
  let requestToDelete = null;
  let deleteJobModal;
  let jobToDelete = null;

  const org = getContext("org");

  $: isSupplier = $profile?.organization?.org_type === "supplier";
  $: selected = getSelectedRecord(
    isSupplier,
    $quotes,
    $rfqs,
    $jobs,
    $selectedQuoteIds,
    $selectedRfqIds,
    $selectedJobIds,
  );
  $: selectedRecordType = getSelectedRecordType(isSupplier, $selectedQuoteIds, $selectedJobIds);
  $: mineAllValue = $myRfqsOnly ? "left" : "right";

  function getSelectedRecord(isSupplier, q, r, j, sqids, srids, sjids) {
    if (isSupplier) {
      const all = [...sjids, ...sqids];
      if (all.length !== 1) return null;
      if (sqids.length === 1) return q?.[sqids[0]];
      return j?.[sjids[0]];
    } else {
      if (srids.length !== 1) return null;
      return r?.[srids[0]];
    }
  }

  function getSelectedRecordType(isSupplier, sqids, sjids) {
    if (isSupplier) {
      const len = sqids.length + sjids.length;
      if (len !== 1) return null;
      if (sqids.length) return "response";
      return "job";
    } else {
      return "request";
    }
  }

  async function updateJob(update) {
    if (selectedRecordType === "job") {
      await api.from("jobs").update(update).eq("id", selected.id);
      jobs.fetchOne($selectedJobIds[0]);
    } else {
      await api.from("jobs").update(update).eq("id", selected.job_id);
      rfqs.fetchOne($selectedRfqIds[0]);
    }
  }

  function deleteRfq() {
    if (!requestToDelete) return;
    rfqs.remove(requestToDelete.id);
  }

  function confirmDeleteRfq(e) {
    requestToDelete = $rfqs[e.detail.id];
    deleteRfqModal.open();
  }

  function deleteJob() {
    if (!jobToDelete) return;
    jobs.remove(jobToDelete.id);
  }

  function confirmDeleteJob(e) {
    jobToDelete = $jobs[e.detail.id];
    deleteJobModal.open();
  }

  async function cloneRfq(e) {
    const { id } = e.detail;
    const rfq = $rfqs[id];
    if (!rfq) return;

    const group_id = rfq.group_id || rfq.job_current_group_id;

    try {
      const assignedTo = $profile?.user_role === "developer" ? null : $profile?.id;
      const job_id = await cloneJob(group_id, assignedTo);
      const r = await rfqs.add(
        {
          job_id,
          organization_id: $org.id,
          link_nanoid: nanoid(12),
        },
        0,
      );
      $selectedRfqIds = [r.id];
    } catch (error) {
      console.log(error);
    }
  }

  function toggleShowMine(e) {
    const mine = e.detail.value;
    if (mine === "left") {
      $myRfqsOnly = true;
    } else {
      $myRfqsOnly = false;
    }
  }

  onMount(async () => {
    if (isSupplier) {
      filter = $quoteFilter;
    } else {
      filter = $rfqFilter;
    }
  });
</script>

<Navbar page="home" />
{#if $profile && $org}
  <div class="grow bg-gray-100 home-container">
    <div class="list-container">
      <div class="h-full w-full flex flex-col">
        <div class="flex items-center justify-end gap-2 pl-4 pr-4 md:pr-2 h-16 filter-bar">
          <LabeledToggleButton left="Mine" right="All" selected={mineAllValue} on:toggle={toggleShowMine} />
          <FilterInput bind:filter org={$org} />
          <button
            class="border rounded-md h-8 border-gray-400 px-2 hover:border-gray-600"
            on:click={() => navigate(`/archive`)}>
            <ArchiveIcon />
          </button>
        </div>

        <div class="overflow-y-hidden">
          <div class="h-full flex flex-col w-full overflow-hidden">
            <div
              class="overflow-y-auto overflow-x-hidden pb-20 pl-4 pr-4 md:pr-2"
              class:stop-scroll-on-mobile={$detailVisible}>
              <Router {url}>
                {#if isSupplier}
                  <Route>
                    <QuoteList on:delete-job={confirmDeleteJob} />
                  </Route>
                {:else}
                  <Route>
                    <RfqList on:delete-rfq={confirmDeleteRfq} on:clone-rfq={cloneRfq} />
                  </Route>
                {/if}
              </Router>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="detail-container" class:detail-visible={$detailVisible}>
      <div class="absolute md:hidden close-detail-tab">
        <button on:click={() => ($detailVisible = false)}>
          <XIcon />
        </button>
        <div class="top-edge" />
        <div class="bottom-edge" />
      </div>
      <div class="h-full w-full flex flex-col overflow-hidden">
        <div class="detail-title-bar px-4">
          {#if selected}
            {@const name = selectedRecordType === "job" ? selected.name : selected?.job_name}
            <EditableHeader
              value={name}
              on:change={(e) => updateJob({ name: e.detail.value })}
              disabled={(isSupplier && selectedRecordType === "response") || selected?.sent_at} />
          {:else}
            <div />
          {/if}
          <ActionButtons page={isSupplier ? "quotes" : "rfqs"} {selected} {selectedRecordType} />
        </div>

        <div class="grow mt-4 h-full overflow-hidden">
          <div class="h-full w-full">
            {#key $detailVisible}
              <Router {url}>
                {#if isSupplier}
                  <Route>
                    <QuoteDetailContainer />
                  </Route>
                {:else}
                  <Route>
                    <RfqDetailContainer on:delete-rfq={confirmDeleteRfq} on:clone-rfq={cloneRfq} />
                  </Route>
                {/if}
              </Router>
            {/key}
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

<CreateModal {isSupplier} org={$org} />
<UploadModal {isSupplier} org={$org} />

<Modal
  bind:this={deleteRfqModal}
  closeable
  on:confirm={deleteRfq}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Delete", type: "confirm", style: "danger" },
  ]}>
  <div slot="title">Delete Draft</div>
  <div slot="content" class="space-y-4">
    Are you sure you want to delete <span class="font-bold">{requestToDelete?.job_name}</span>?
  </div>
</Modal>

<Modal
  bind:this={deleteJobModal}
  closeable
  on:confirm={deleteJob}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Delete", type: "confirm", style: "danger" },
  ]}>
  <div slot="title">Delete Draft Job</div>
  <div slot="content" class="space-y-4">
    Are you sure you want to delete <span class="font-bold">{jobToDelete?.name}</span>?
  </div>
</Modal>

<style lang="scss">
  .create-button {
    @apply absolute;

    left: 2rem;
    bottom: 2rem;
  }

  .home-container {
    display: block;
    position: relative;
    overflow-y: hidden;

    .list-container {
      width: 100%;
      height: 100%;

      .stop-scroll-on-mobile {
        @media (max-width: 767px) {
          overflow-y: hidden;
        }
      }
    }

    .filter-bar {
      min-height: 4rem;
    }

    .detail-container {
      display: none;
      height: 100%;
      background-color: white;
      border-left: 1px solid #e5e7eb;

      &.detail-visible {
        @media (max-width: 767px) {
          display: block;
          position: absolute;
          right: 0;
          top: 0;
          width: calc(100% - 4rem);
          filter: drop-shadow(0 0.75rem 0.75rem rgb(0 0 0 / 0.2));
        }
      }
    }
    @media (min-width: 768px) {
      display: flex;

      .list-container {
        width: 24rem;
        min-width: 24rem;
      }

      .detail-container {
        display: block;
        min-width: 10rem;
        flex-grow: 1;
      }
    }
  }

  .detail-title-bar {
    height: 4rem;
    min-height: 4rem;

    @apply flex gap-2 items-center justify-between;
  }

  .close-detail-tab {
    @apply bg-white;
    left: -1.75rem;
    top: 0.5rem;
    width: 2rem;
    height: 2rem;

    button {
      @apply m-2;
    }

    .top-edge {
      border-color: transparent white white transparent;
      position: absolute;
      height: 0;
      width: 0;
      top: -0.5rem;
      left: 0;
      border-width: 0.3rem 1rem 0.3rem 1rem;
    }

    .bottom-edge {
      border-color: white white transparent transparent;
      position: absolute;
      height: 0;
      width: 0;
      top: 2rem;
      left: 0;
      border-width: 0.3rem 1rem 0.3rem 1rem;
    }
  }
</style>
