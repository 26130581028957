import isEmpty from "lodash/isEmpty";
import { hashify } from "overline";
import Bugsnag from "#src/bugsnag.js";
import { hydrateType, createMakeup } from "@local/lamina-core";
import { sortArrayBy } from "@local/extensions/collections/sortable-list.js";

export function recordFilter(filter) {
  const f = filter.toLowerCase();
  return (r) => {
    const name = r.name?.toLowerCase() || "";
    const cat = r.category?.toLowerCase() || "";
    const classif = r.classification?.toLowerCase() || "";

    return name.includes(f) || cat.includes(f) || classif.includes(f);
  };
}

export function genericLibrary(filter = "") {
  return [
    {
      records: [
        createMakeup("scratch"),
        createMakeup("mono"),
        createMakeup("lami"),
        createMakeup("igu"),
      ]
        .map((m) => hydrateType(m))
        .filter(recordFilter(filter)),
    },
  ];
}

export function orgLibrary(org, projectType = "unconstrained") {
  if (!org) return [];
  const products = projectType === "product" ? org.item_products : org.makeups;
  if (!products) return [];

  const sortBy =
    projectType === "product"
      ? org.data.item_product_order
      : org.data.makeup_order;

  const m = sortArrayBy(products, sortBy).filter((p) => p.published);
  try {
    const categories = m.reduce((lib, makeup) => {
      if (makeup.category_id) {
        if (!lib[makeup.category_id]) lib[makeup.category_id] = [];
        lib[makeup.category_id].push(makeup);
      } else if (makeup.category) {
        if (!lib[makeup.category]) lib[makeup.category] = [];
        lib[makeup.category].push(makeup);
      } else {
        if (!lib._uncategorized) lib._uncategorized = [];
        lib._uncategorized.push(makeup);
      }
      return lib;
    }, {});

    const u = categories._uncategorized;
    delete categories._uncategorized;

    const lib = [];
    if (Object.keys(categories).length === 0) {
      lib.push({ records: u || [] });
    } else {
      const catOrder = org.data.product_category_order;
      let cats;
      if (catOrder) {
        const coHash = hashify(catOrder);
        const uoCat = Object.keys(categories).filter((c) => !coHash[c]);
        const oCats = catOrder.filter((c) => categories[c]);
        cats = [...oCats, ...uoCat];
      } else {
        cats = Object.keys(categories).sort((a, b) => {
          const acat = org.product_categories[a];
          const bcat = org.product_categories[b];

          if (acat && bcat) {
            return new Date(acat.created_at) - new Date(bcat.created_at);
          }

          const aprop = org.product_categories[a]
            ? org.product_categories[a].name
            : a;
          const bprop = org.product_categories[b]
            ? org.product_categories[b].name
            : b;

          return aprop.localeCompare(bprop);
        });
      }

      lib.push(
        ...cats.map((cat) => {
          const title = org.product_categories[cat]
            ? org.product_categories[cat].name
            : cat;
          return {
            title,
            id: org.product_categories[cat]?.id,
            records: categories[cat],
          };
        }),
      );

      if (u) {
        lib.push({ title: "Uncategorized", records: u });
      }
    }

    try {
      lib.forEach((cat) => {
        cat.records = cat.records
          .map((p) => ({
            ...p,
            data: !isEmpty(p.expanded_data) ? p.expanded_data : p.data,
          }))
          .map((p) => hydrateType(p));
      });
    } catch (e) {
      console.log("error", e);
    }

    return lib;
  } catch (e) {
    const err = new Error(`Makeup library error: ${org?.name}`, { cause: e });
    Bugsnag.notify(err);
    return [];
  }
}
