<svelte:options strictprops={false} />

<script>
  import EllipsisIcon from "@local/assets/icons/ellipsis.svg";

  export let disabled = false;
  export let readOnly = false;
  export let value;
  export let display = (v) => "";
  export let prefix = null;

  $: displayValue = display(value);
</script>

<div class="p-1 flex items-center pointer-events-none w-full">
  <div class="grow truncate" class:italic={readOnly} class:text-gray-500={disabled}>
    {#if prefix}
      {prefix}
    {/if}
    {#if displayValue !== ""}
      {displayValue}
    {:else}
      &nbsp;
    {/if}
  </div>
  {#if !readOnly && !disabled}
    <div class="flex-none">
      <EllipsisIcon />
    </div>
  {/if}
</div>
