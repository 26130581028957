import cloneDeep from "lodash/cloneDeep";
import { checkSuprastoreStatus } from "#src/api";

async function checkDocumentForUpdates(document) {
  const doc = cloneDeep(document);

  let update = false;

  const unreadyFiles = document.data.files.filter((f) => !f.ready);

  const filePromises = unreadyFiles.map((f) =>
    checkSuprastoreStatus("documents", encodeURIComponent(f.object_id)),
  );

  const files = await Promise.all(filePromises);

  files.forEach((file, i) => {
    const { data, error } = file;
    if (error) throw error;
    const f = unreadyFiles[i];
    const index = doc.data.files.findIndex((df) => df.id === f.id);
    const pages = Object.keys(data.status);
    if (!pages.includes("-1")) {
      // Add thumbnail if present
      if (data.status[0]?.state === "completed" && !doc.data.thumbnail) {
        update = true;
        doc.data.thumbnail = {
          path: data.status[0].targetPath,
          bucket: "drawings",
          name: doc.name,
          type: "iiif",
        };
      }

      // Count the number of pages in the file
      if (pages.length !== doc.data.files[index].pages) {
        update = true;
        doc.data.files[index].pages = pages.length;
      }

      // Check the status of each page
      pages.forEach((page) => {
        const status = data.status[page];
        if (status.state !== doc.data.files[index].status[page]?.state) {
          update = true;
          doc.data.files[index].status[page] = status;
        }
      });

      // Check if all pages have been processed
      if (
        pages.every(
          (page) => doc.data.files[index].status[page]?.state === "completed",
        )
      ) {
        update = true;
        doc.data.files[index].ready = true;
      }
    }
  });

  if (doc.data.files.every((f) => f.ready)) {
    update = true;
    doc.ready = true;
  }

  return { update, document: doc };
}

export default checkDocumentForUpdates;
