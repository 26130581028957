<script>
  import { createEventDispatcher } from "svelte";
  import CaretLeftIcon from "@local/assets/icons/caret-left.svg";
  import CaretRightIcon from "@local/assets/icons/caret-right.svg";
  import CaretDoubleLeftIcon from "@local/assets/icons/caret-double-left.svg";
  import CaretDoubleRightIcon from "@local/assets/icons/caret-double-right.svg";

  export let page = 0;
  export let count = 0;
  export let itemsPerPage = 20;

  const dispatch = createEventDispatcher();

  $: first = count > 0 ? page * itemsPerPage + 1 : 0;
  $: last = Math.min(count, first + (itemsPerPage - 1));
  $: pagesBefore = page > 0;
  $: pagesAfter = last < count;
  $: maxPage = Math.floor(count / itemsPerPage);
  $: updateCurrent(page);

  let current = (page + 1).toString();

  function updateCurrent(page) {
    current = (page + 1).toString();
  }

  function prevPage() {
    dispatch("gotopage", page - 1);
  }

  function nextPage() {
    dispatch("gotopage", page + 1);
  }

  function firstPage() {
    dispatch("gotopage", 0);
  }

  function lastPage() {
    dispatch("gotopage", maxPage);
  }

  function inputPage() {
    let parsed = parseInt(current, 10);
    if (!Number.isFinite(parsed)) {
      current = (page + 1).toString();
      return;
    }

    parsed = parsed - 1;

    if (parsed > maxPage) {
      current = maxPage.toString();
    } else if (parsed < 0) {
      current = "0";
    } else {
      dispatch("gotopage", parsed);
    }
  }

  function handleKeydown(evt) {
    if (evt.key === "Enter") {
      evt.stopPropagation();
      inputPage();
    }
  }
</script>

<div class="flex justify-between text-xs py-3 items-center px-4 border-gray-300 border-t">
  <div>
    {first}-{last} of {count}
  </div>
  <div class="flex items-center space-x-2">
    <button class="arrow-button" disabled={!pagesBefore} on:click={firstPage}>
      <CaretDoubleLeftIcon />
    </button>
    <button class="arrow-button" disabled={!pagesBefore} on:click={prevPage}>
      <CaretLeftIcon />
    </button>
    <input
      type="text"
      class="border rounded w-8 p-1 text-center"
      bind:value={current}
      on:keydown={handleKeydown}
      on:blur={inputPage} />
    <button class="arrow-button" disabled={!pagesAfter} on:click={nextPage}>
      <CaretRightIcon />
    </button>
    <button class="arrow-button" disabled={!pagesAfter} on:click={lastPage}>
      <CaretDoubleRightIcon />
    </button>
  </div>
</div>

<style lang="scss">
  .arrow-button {
    @apply p-1 rounded cursor-pointer;

    &:hover {
      @apply bg-gray-100;
    }

    &:disabled {
      @apply text-gray-500 cursor-not-allowed;
    }
  }
</style>
