<script>
  import { createEventDispatcher } from "svelte";
  import TextInput from "./TextInput.svelte";
  import ContenteditableInput from "./ContenteditableInput.svelte";
  import ImageInput from "./ImageInput.svelte";
  import BooleanInput from "./BooleanInput.svelte";
  import LayerProperties from "./LayerProperties.svelte";
  import imageUrl from "#src/extensions/image-url.js";
  import CopyTextField from "./CopyTextField.svelte";

  import { api } from "#src/api";
  import { profile } from "#src/stores/auth.js";

  export let makeup;
  export let disabled;
  export let org;

  const dispatch = createEventDispatcher();

  $: multiple = Array.isArray(makeup.id);

  function update(prop, value) {
    dispatch("update", { id: makeup.id, prop, value });
  }

  async function uploadImage(e) {
    if (e.detail.type && e.detail.data) {
      const uuid = crypto.randomUUID();
      const filename = `${uuid}/${e.detail.filename}`;
      const { data: result, error } = await api.storage.from("images").upload(filename, e.detail.data, {
        contentType: e.detail.contentType,
      });

      if (!error) {
        const image = {
          id: uuid,
          bucket: "images",
          object_id: result.path,
          content_type: e.detail.contentType,
          filename: e.detail.filename,
        };
        dispatch("update", { id: makeup.id, prop: "image", value: image });
      }
    }
  }
</script>

<div class="text-xs px-4">
  {#if !multiple}
    <ImageInput
      label="Image"
      labelWidth="5rem"
      value={imageUrl(makeup.image)}
      accept="image/png, image/jpeg"
      uploadType="arraybuffer"
      {disabled}
      on:upload={uploadImage}
      on:delete={() => update("image", null)} />
  {/if}
  <ContenteditableInput
    label="Name"
    {disabled}
    labelWidth="5rem"
    value={makeup.name}
    on:input={(evt) => update("name", evt.detail.value)} />
  <ContenteditableInput
    label="Description"
    {disabled}
    labelWidth="5rem"
    value={makeup.description}
    on:input={(evt) => update("description", evt.detail.value)} />
  <ContenteditableInput
    label="Category"
    {disabled}
    labelWidth="5rem"
    value={makeup.category}
    on:input={(evt) => update("category", evt.detail.value)} />
  <TextInput
    label="Classification"
    {disabled}
    labelWidth="5rem"
    value={makeup.classification}
    on:input={(evt) => update("classification", evt.detail.value)} />
  <TextInput
    label="Vendor Code"
    {disabled}
    labelWidth="5rem"
    value={makeup.vendor_code}
    on:input={(evt) => update("vendor_code", evt.detail.value)} />
  <BooleanInput
    label="Flip Orientation"
    {disabled}
    labelWidth="5.5rem"
    value={makeup.data.flip_orientation}
    on:input={(evt) => update("data.flip_orientation", evt.detail.value)} />
  <BooleanInput
    label="Published"
    {disabled}
    labelWidth="5.5rem"
    value={makeup.published}
    on:input={(evt) => update("published", evt.detail.value)} />
  {#if $profile?.user_role === "developer" && !multiple}
    <CopyTextField label="ID" labelWidth="5rem" value={makeup.id} />
  {/if}
</div>

{#if !Array.isArray(makeup.id) && makeup.data?.layers}
  <LayerProperties type={makeup} {disabled} group={org} editMakeup />
{/if}
