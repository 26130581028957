<script>
  import ImageInput from "./ImageInput.svelte";
  import ContenteditableInput from "./ContenteditableInput.svelte";
  import TextInput from "./TextInput.svelte";
  import CopyTextField from "./CopyTextField.svelte";

  import { profile } from "#src/stores/auth.js";

  export let fabrication;

  $: multiple = Array.isArray(fabrication.id);
</script>

<div class="text-xs px-4">
  {#if !Array.isArray(fabrication.id)}
    <ImageInput
      label="Image"
      labelWidth="5rem"
      value={`/images/${fabrication.image?.filename}`}
      accept="image/png, image/svg+xml"
      uploadType="arraybuffer"
      disabled />
  {/if}
  <ContenteditableInput label="Name" disabled labelWidth="5rem" value={fabrication.name} />
  <TextInput label="Classification" disabled labelWidth="5rem" value={fabrication.classification} />
  {#if $profile?.user_role === "developer" && !multiple}
    <CopyTextField label="ID" labelWidth="5rem" value={fabrication.id} />
  {/if}
</div>
