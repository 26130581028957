<!-- <svelte:options strictprops={false} /> -->

<script>
  import { navigate } from "svelte-routing";
  import LaminaIcon from "@local/assets/icons/lamina.svg";
  import { user } from "#src/stores/auth.js";
  import Navbar from "#lib/Navbar.svelte";

  let password1 = "";
  let password2 = "";
  let error = null;
  let sending = false;

  async function updatePassword() {
    error = null;
    if (!password1 || !password2) {
      error = "Please enter a new password.";
      return;
    }

    if (password1 !== password2) {
      error = "Passwords must match.";
      return;
    }

    sending = true;
    const { user: updated, error: err } = await user.updatePassword(password1);
    if (err) {
      error = err.message;
    } else {
      navigate("/");
    }
    sending = false;
  }
</script>

<Navbar />
<div class="w-full h-full flex flex-col justify-center">
  <div class="mx-auto w-96 p-4 border rounded bg-white drop-shadow-md text-center">
    <form on:submit|preventDefault={updatePassword} class="space-y-4">
      <div class="flex justify-center my-2">
        <LaminaIcon />
      </div>
      <h1>Welcome to Shape Exchange</h1>
      <p class="text-sm">Update your password.</p>
      <div>
        <input
          class="border rounded w-full p-2"
          class:loading={sending}
          type="password"
          autocomplete="new-password"
          placeholder="New Password"
          disabled={sending}
          bind:value={password1} />
      </div>
      <div>
        <input
          class="border rounded w-full p-2"
          class:loading={sending}
          type="password"
          autocomplete="new-password"
          placeholder="Re-enter Password"
          disabled={sending}
          bind:value={password2} />
      </div>
      {#if error}
        <div class="text-red-600 text-sm">{error}</div>
      {/if}
      <button type="submit" class="btn btn-lg btn-primary w-full" class:loading={sending} disabled={sending}>
        Update Password
      </button>
    </form>
  </div>
</div>

<style>
  .loading {
    opacity: 0.5;
  }
</style>
