import { getPrice, itemPriceEntry } from "./price-totals.js";
import type { ItemRecordCore } from "../entities/item.js";
import type { TablesGenerated } from "../database.js";

type PriceEntry = TablesGenerated<"price_entries">;

interface PricingGroupProperties {
  area: number,
  weight: number,
  quantity: number,
}

interface JobProperties {
  _ungrouped: PricingGroupProperties,
  [key: string]: PricingGroupProperties,
}

function itemFreightProperties(item: ItemRecordCore, priceEntries: Array<PriceEntry> | null, pricingGroup: string, freightGroup: string, jobProperties: JobProperties) {
  const width = item.cache?.width_in ?? 0;
  const height = item.cache?.height_in ?? 0;
  const weight = item.cache?.weight ?? 0;
  let price = 0;
  if (priceEntries) {
    const entry = itemPriceEntry(item, priceEntries, jobProperties, pricingGroup);
    price = getPrice(entry, item);
    price = Math.round(price * 100);
  }

  return { id: item.id, width, height, weight, price, pricing_group: pricingGroup, freight_group: freightGroup };
}

export { itemFreightProperties };
