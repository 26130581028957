<script>
  import { createEventDispatcher } from "svelte";
  import CheckIcon from "@local/assets/icons/check.svg";
  import CaretDownIcon from "@local/assets/icons/caret-down.svg";
  import ArrowUpIcon from "@local/assets/icons/arrow-up.svg";
  import ArrowDownIcon from "@local/assets/icons/arrow-down.svg";

  export let options = [];
  export let label = "Label";
  export let sortBy;
  export let sortDirection;
  export let labelWidth = null;
  export let disabled = false;

  const dispatch = createEventDispatcher();

  let sb = sortBy;
  let sd = sortDirection;

  let input;
  let focused = false;
  let showOptions = false;
  let selectedOption = null;

  $: lw = labelWidth ? `width:${labelWidth};` : "";
  $: currentOption = options.find((o) => o.value === sb);

  function focus() {
    focused = true;
    input.focus();
    selectedOption = null;
    showOptions = true;
  }

  function chooseOption(option) {
    const index = options.findIndex((o) => o.value === option.value);
    selectedOption = index;
    sb = option.value;
    dispatch("input", { sortBy: sb, sortDirection: "asc" });
  }

  function chooseDirection(direction) {
    sd = direction;
    dispatch("input", { sortBy: sb, sortDirection: sd });
    blur();
  }

  function keydown(evt) {
    switch (evt.key) {
      case "Enter":
        if (!showOptions) {
          selectedOption = null;
          showOptions = true;
        } else {
          chooseOption(options[selectedOption]);
        }
        break;
      case "ArrowDown":
        showOptions = true;
        if (selectedOption === null) {
          selectedOption = 0;
        } else if (options.length > selectedOption + 1) {
          selectedOption += 1;
        }
        break;
      case "ArrowUp":
        if (selectedOption > 0) {
          selectedOption -= 1;
        }
        break;
    }
  }

  function blur() {
    focused = false;
    input.blur();
    showOptions = false;
  }
</script>

<div
  class="prop-container"
  class:focused
  on:click={focus}
  on:keydown={keydown}
  tabindex="0"
  bind:this={input}
  on:blur={blur}>
  <div style={lw}>
    <div class="label">
      <slot name="label">
        {label}
      </slot>
    </div>
  </div>
  <div class="relative grow truncate">
    <div class="input" class:text-gray-500={disabled} {disabled}>
      {currentOption ? currentOption.label : ""}
    </div>
  </div>
  <div class="flex-none">
    <CaretDownIcon />
  </div>
  {#if showOptions}
    <div class="w-full absolute rounded bg-white drop-shadow-lg py-1 left-0 top-0 mt-8 z-20">
      {#each options as option, index}
        <li
          class="option-item list-item"
          class:selected={index === selectedOption}
          on:click|stopPropagation={() => chooseOption(option)}>
          <div class="flex-none w-6">
            {#if option.value === sb}
              <CheckIcon />
            {/if}
          </div>
          <div class="truncate">{option.label}</div>
        </li>
      {/each}
      <div class="border-t">
        <div class="list-item" on:click|stopPropagation={() => chooseDirection("asc")}>
          <div class="flex-none w-6">
            <ArrowUpIcon />
          </div>
          <div class="truncate">Ascending</div>
        </div>
        <div class="list-item" on:click|stopPropagation={() => chooseDirection("desc")}>
          <div class="flex-none w-6">
            <ArrowDownIcon />
          </div>
          <div class="truncate">Descending</div>
        </div>
      </div>
    </div>
  {/if}
</div>

<style lang="scss">
  .prop-container {
    @apply px-2 py-2 w-full flex items-center rounded relative;

    &:hover {
      @apply ring-1 ring-inset ring-gray-300;
    }

    &.focused {
      @apply ring-2 ring-inset ring-blue-500;
    }

    .label {
      @apply truncate text-gray-400;
    }

    .input {
      @apply min-w-0 flex-grow bg-transparent ml-2 truncate;
    }
  }

  .list-item {
    @apply flex p-1 items-center cursor-pointer;

    &:hover {
      @apply bg-gray-100;
    }
  }

  li.option-item.selected {
    @apply bg-blue-400 text-white;
  }
</style>
