<script>
  import { setContext } from "svelte";
  import Tool from "./Tool.svelte";
  import PanIcon from "@local/assets/icons/pan.svg";
  import SelectIcon from "@local/assets/icons/select.svg";
  import PenPlusIcon from "@local/assets/icons/pen-plus.svg";
  import PenMinusIcon from "@local/assets/icons/pen-minus.svg";
  import HoleIcon from "@local/assets/icons/hole.svg";
  import NotchIcon from "@local/assets/icons/notch.svg";
  import ZoomIcon from "@local/assets/icons/zoom.svg";
  import ZoomOutIcon from "@local/assets/icons/zoom-out.svg";
  import ZoomFitIcon from "@local/assets/icons/zoom-fit.svg";
  import CornerNotchIcon from "@local/assets/icons/corner-notch.svg";

  import { currentTool } from "#src/stores/ui.js";

  import eb from "#src/extensions/event-bus.js";

  export let disabled;

  setContext("toolbar-color-mode", "light");

  function clickTool(tool) {
    eb.dispatch("click-fab-tool", tool);
  }
</script>

<div class="w-full h-10 border-b flex items-center gap-2 px-2">
  <div class="h-8 flex items-center gap-1">
    <Tool toolid="select" bind:state={$currentTool} tooltip="Select">
      <SelectIcon />
    </Tool>
    <Tool
      toolid="zoom-to-fit"
      type="button"
      tooltip="Zoom to Fit"
      on:click={() => eb.dispatch("zoom-to-fit")}>
      <ZoomFitIcon />
    </Tool>
    <Tool toolid="zoom-in" type="button" tooltip="Zoom In" on:click={() => eb.dispatch("zoom-in")}>
      <ZoomIcon />
    </Tool>
    <Tool toolid="zoom-out" type="button" tooltip="Zoom Out" on:click={() => eb.dispatch("zoom-out")}>
      <ZoomOutIcon />
    </Tool>
    <Tool toolid="pan" bind:state={$currentTool} tooltip="Pan">
      <PanIcon />
    </Tool>
  </div>
  <div class="h-8 flex items-center">
    <Tool toolid="add-point" type="enum" tooltip="Add Vertex" {disabled} bind:state={$currentTool}>
      <PenPlusIcon />
    </Tool>
    <Tool toolid="remove-point" type="enum" tooltip="Remove Vertex" {disabled} bind:state={$currentTool}>
      <PenMinusIcon />
    </Tool>
    <Tool
      toolid="feature-hole"
      type="enum"
      tooltip="Add Hole"
      state={$currentTool}
      {disabled}
      on:click={() => clickTool("feature-hole")}>
      <HoleIcon />
    </Tool>
    <Tool
      toolid="feature-edge-fabrication"
      type="enum"
      tooltip="Add Edge Fabrication"
      state={$currentTool}
      {disabled}
      on:click={() => clickTool("feature-edge-fabrication")}>
      <NotchIcon />
    </Tool>
    <Tool
      toolid="feature-corner-fabrication"
      type="enum"
      tooltip="Add Corner Fabrication"
      state={$currentTool}
      {disabled}
      on:click={() => clickTool("feature-corner-fabrication")}>
      <CornerNotchIcon />
    </Tool>
  </div>
</div>
