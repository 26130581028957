import { SUPABASE_URL as supabaseUrl } from "#src/env";

function imageUrl(image, full = false) {
  if (!image) return null;
  if (full) {
    return `${supabaseUrl}/storage/v1/render/image/public/${image.bucket}/${image.object_id}`;
  } else {
    return `${supabaseUrl}/storage/v1/render/image/public/${image.bucket}/${image.object_id}?width=200&height=200&resize=contain`;
  }
}

export default imageUrl;
