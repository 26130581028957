<script>
  import { createEventDispatcher } from "svelte";
  import LocationTypeIcon from "@local/assets/icons/location-type-clone.svg";
  import LocationTypeFilledIcon from "@local/assets/icons/location-type-filled.svg";
  import LocationItemIcon from "@local/assets/icons/location-item-clone.svg";
  import LocationItemFilledIcon from "@local/assets/icons/location-item-filled.svg";
  import LocationOpeningIcon from "@local/assets/icons/location-opening-clone.svg";
  import LocationOpeningFilledIcon from "@local/assets/icons/location-opening-filled.svg";

  const filled = {
    item: LocationItemFilledIcon,
    type: LocationTypeFilledIcon,
    collection: LocationOpeningFilledIcon,
  };

  const clone = {
    item: LocationItemIcon,
    type: LocationTypeIcon,
    collection: LocationOpeningIcon,
  };

  const dispatch = createEventDispatcher();

  export let record_type = "item";
  export let record_id;
</script>

<div class="flex gap-1">
  <button
    class="hover:brightness-75 hover:saturate-150"
    on:click={() => dispatch("add-location", { record_type, record_id })}>
    <svelte:component this={filled[record_type]} />
  </button>
  <button
    class="hover:brightness-75 hover:saturate-150"
    on:click={() => dispatch("clone-location", { record_type, record_id })}>
    <svelte:component this={clone[record_type]} />
  </button>
</div>
