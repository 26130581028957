<!-- <svelte:options strictprops={false} /> -->

<script>
  import { Link, navigate } from "svelte-routing";
  import { onMount, getContext } from "svelte";

  import CaretLeftIcon from "@local/assets/icons/caret-left.svg";
  import ProfileSettings from "#lib/settings/ProfileSettings.svelte";
  import TextInput from "#lib/sidebar/TextInput.svelte";
  import TextareaInput from "#lib/sidebar/TextareaInput.svelte";
  import SelectInput from "#lib/sidebar/SelectInput.svelte";
  import Datatable from "#lib/datatable/Datatable.svelte";

  import { api, addUser } from "#src/api";
  import { profile } from "#src/stores/auth.js";
  import { objectify } from "overline";

  export let customerid;

  const org = getContext("org");

  let customer;
  let newUser = null;

  $: canAdmin = $profile && ["org_admin", "developer"].includes($profile.user_role);
  $: productLists = objectify($org?.product_lists || []);
  $: userProps = [
    {
      label: "",
      prop: "user_color",
      type: "color",
      editable: false,
    },
    {
      label: "Name",
      prop: "username",
      type: "text",
    },
    {
      label: "Email",
      prop: "email",
      type: "text",
    },
    {
      label: "Role",
      prop: "user_role",
      type: "text",
      editable: false,
    },
    {
      label: "Confirmed",
      prop: "confirmed_at",
      formatter: (v) => (v ? "✓" : ""),
      editable: false,
    },
  ];

  async function init() {
    const c = await api
      .from("business_relations")
      .select("*,buyer: buyer_id(*,profiles(*))")
      .eq("seller_id", $org.id)
      .eq("buyer_id", customerid)
      .single();

    if (c.error) {
      navigate("/settings/customers");
    }

    customer = {
      ...c.data.buyer,
      business_relation: c.data,
    };
  }

  async function updateProp(prop, value) {
    customer.business_relation[prop] = value;
    const update = await api
      .from("business_relations")
      .update({ [prop]: value })
      .eq("id", customer.business_relation.id);

    if (update.error) {
      console.log(update.error);
    }
  }

  function beginAddingUser() {
    newUser = {
      email: "",
      username: "",
      organization_id: customer.id,
    };
  }

  async function addNewUser(evt) {
    const { row } = evt.detail;
    await addUser({
      ...row,
      send_magic_link: true,
    });
    init();
    newUser = null;
  }

  function stopAddingUser() {
    newUser = null;
  }
</script>

<div class="text-blue-500 text-sm mb-4">
  <Link to="/settings/customers" class="flex items-center space-x-1">
    <CaretLeftIcon />
    <div>All Customers</div>
  </Link>
</div>

{#await init() then _}
  <div class="py-4 sm:px-4 space-y-2 text-sm">
    <div class="w-full sm:w-96 space-y-4">
      <TextInput labelWidth="8rem" border label="Name" value={customer.name} disabled />
      <TextInput labelWidth="8rem" border label="Email" value={customer.primary_contact_email} disabled />
      <TextareaInput fullwidth placeholder="Address" value={customer.address} disabled rows={4} />
      <SelectInput
        labelWidth="10rem"
        border
        label="Products"
        value={customer.business_relation.products || "none"}
        disabled={!canAdmin}
        options={[
          { label: "All Published Products", value: "all" },
          { label: "Generic Products Only", value: "none" },
          { label: "Named Product List", value: "product_list" },
        ]}
        on:input={(e) => updateProp("products", e.detail.value)} />
      {#if customer.business_relation.products === "product_list"}
        <SelectInput
          labelWidth="10rem"
          border
          label="Product List"
          value={customer.business_relation.product_list_id}
          disabled={!canAdmin}
          options={$org.product_lists?.map((l) => ({ label: l.name, value: l.id }))}
          on:input={(e) => updateProp("product_list_id", e.detail.value)} />
      {/if}
    </div>
    <div class="space-y-2">
      <div class="py-4 sm:px-4 flex justify-between items-center">
        <h3 class="py-1 px-2">Organization members</h3>
        {#if canAdmin}
          <button class="btn btn-primary" on:click={beginAddingUser}>+ Add User</button>
        {/if}
      </div>
      <div class="bg-white border rounded-lg overflow-hidden">
        <Datatable
          data={customer.profiles}
          columns={userProps}
          newRow={newUser}
          on:addrow={addNewUser}
          on:stopaddingrow={stopAddingUser} />
      </div>
    </div>
  </div>
{/await}
