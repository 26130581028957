<script>
  import { Modal } from "svelte-utilities";
  import ItemRow from "./ItemRow.svelte";
  import XIcon from "@local/assets/icons/x-sm.svg";

  export let group;
  export let items;
  export let collectionItems;
  export let disabled;
  export let collectionId;

  let addItemsModal;
  let unassigned = [];
  let assignedToOther = [];
  let unassignedChecked = [];
  let assignedToOtherChecked = [];

  function beginAddingItems() {
    unassigned = items.filter((item) => !item.collection_id);
    unassignedChecked = unassigned.map((i) => false);
    assignedToOther = items.filter((item) => item.collection_id && item.collection_id !== collectionId);
    assignedToOtherChecked = assignedToOther.map((i) => false);
    addItemsModal.open();
  }

  function addItems() {
    const checked = unassigned.filter((item, i) => unassignedChecked[i]);
    const reassigned = assignedToOther.filter((item, i) => assignedToOtherChecked[i]);

    const updates = [...checked, ...reassigned].map((item) => ({
      type: "item",
      id: item.id,
      prop: "collection_id",
      value: collectionId,
    }));

    group.update(updates);
  }
</script>

<div class="px-6 text-xs">
  <h3 class="mb-2">Items</h3>
  {#if collectionItems?.length > 0}
    <div>
      {#each collectionItems as item}
        <div class="flex justify-between items-center item-row-container">
          <ItemRow {group} {item} />
          {#if !disabled}
            <button
              class="delete-button p-0.5 rounded hover:bg-gray-200"
              on:click={() => group.updateItem(item.id, "collection_id", null)}>
              <XIcon />
            </button>
          {/if}
        </div>
      {/each}
    </div>
  {/if}
  <div class="flex items-center justify-end">
    {#if !collectionItems?.length}
      <div class="grow text-gray-400">No items.</div>
    {/if}
    {#if !disabled}
      <div class="py-1">
        <button on:click={beginAddingItems}>+ Add Items</button>
      </div>
    {/if}
  </div>
</div>

<Modal
  closeable
  on:confirm={addItems}
  bind:this={addItemsModal}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Add", type: "confirm", style: "primary" },
  ]}>
  <div slot="title">Add Items to Opening</div>
  <div slot="content" class="space-y-4 text-xs overflow-y-auto">
    {#if unassigned.length}
      <div>Unassigned Items:</div>
      <div class="px-4 text-xs space-y-1">
        {#each unassigned as item, i}
          <div class="flex gap-2">
            <input type="checkbox" bind:checked={unassignedChecked[i]} />
            <ItemRow {group} {item} />
          </div>
        {/each}
      </div>
    {/if}
    {#if assignedToOther.length}
      <div>Assigned Items</div>
      <div class="px-4 text-xs space-y-1">
        {#each assignedToOther as item, i}
          {@const collection = $group.items[item.collection_id]}
          <div class="flex gap-2">
            <input type="checkbox" bind:checked={assignedToOtherChecked[i]} />
            <div class="opacity-50">
              <ItemRow {group} {item} />
            </div>
            <div>
              ({collection.mark})
            </div>
          </div>
        {/each}
      </div>
    {/if}
  </div>
</Modal>

<style lang="scss">
  .item-row-container {
    border: 1px solid transparent;
    @apply p-1 rounded;

    .delete-button {
      opacity: 0;
    }

    &:hover {
      @apply border-gray-300;
      .delete-button {
        opacity: 1;
      }
    }
  }
</style>
