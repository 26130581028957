<svelte:options strictprops={false} />

<script>
  import { tick, getContext } from "svelte";
  import get from "lodash/get";
  import set from "lodash/set";
  import cloneDeep from "lodash/cloneDeep";
  import isEqual from "lodash/isEqual";
  import { Datagrid } from "datagrid";
  import { createItem as defaultItem } from "@local/lamina-core";
  import { objectify, hashify, bucketArrayMultiple, hashifyRanges } from "overline";
  import ResizablePanes from "#lib/ResizablePanes.svelte";
  import ItemViewgrid from "#lib/drawing/ItemViewgrid.svelte";
  import { Modal } from "svelte-utilities";
  import { user } from "#src/stores/auth.js";
  import {
    selectedCollections as selectedItemsObj,
    showTable,
    showRightPanel,
    groupCollectionsBy as groupBy,
    groupCollectionsByDirection as groupByDirection,
    sortCollectionsBy as sortBy,
    sortCollectionsByDirection as sortByDirection,
    tableLeft,
    settingDisplayShowUnusedTypes,
    refreshItemList,
  } from "#src/stores/ui.js";
  import eb from "#src/extensions/event-bus.js";
  import Sidebar from "#lib/sidebar/Sidebar.svelte";
  import SidebarTitle from "#lib/sidebar/SidebarTitle.svelte";
  import LocationThumbnailViewer from "#lib/sidebar/LocationThumbnailViewer.svelte";
  import CollectionItemList from "#lib/sidebar/CollectionItemList.svelte";
  import Attachments from "#lib/sidebar/Attachments.svelte";
  import JobProperties from "#lib/sidebar/JobProperties.svelte";
  import ItemProperties from "#lib/sidebar/ItemProperties.svelte";
  import SelectedActions from "#lib/SelectedActions.svelte";
  import History from "#lib/sidebar/History.svelte";
  import PrevNext from "#lib/sidebar/PrevNext.svelte";
  import orderCategory from "@local/extensions/collections/order-category.js";
  import groupItems from "@local/extensions/collections/group-items.js";
  import flattenGroups from "@local/extensions/collections/flatten-groups.js";
  import countCategories from "@local/extensions/collections/count-categories.js";
  import { incrementMarkCopy } from "@local/extensions/identifiers/mark.js";
  import SortOptions from "#lib/SortOptions.svelte";
  import FlipIcon from "@local/assets/icons/flip.svg";
  import {
    orderedList,
    sortableList,
    addToSortableList,
    removeFromSortableList,
  } from "@local/extensions/collections/sortable-list.js";
  import { bucketArray } from "overline";
  import { profile } from "#src/stores/auth.js";
  import makeMultiItem from "#src/extensions/multi-item.js";

  export let group;
  export let types;
  export let items;
  export let collections;

  export let colColumns;
  export let standardColumns;
  export let customColColumns;

  export let disabled = false;

  const supplier = getContext("supplier");

  let confirmRemoveModal;
  let itemsToDelete = [];
  let typesToDelete = [];
  let selectedItemIndices = [];
  let vpContainer;
  let width;
  let height;

  function transform(coll, xf, defaultValue = []) {
    return coll ? xf(coll) : defaultValue;
  }

  $: groupedItems = groupItems(collections, $groupBy);
  $: typeObj = objectify(types);
  $: orderedCategory = orderCategory(
    $groupBy,
    groupedItems,
    types,
    collections,
    $supplier?.product_categories,
    $settingDisplayShowUnusedTypes,
    $groupByDirection,
  );
  $: categoryCounts = countCategories(orderedCategory, groupedItems);

  $: itemList = flattenGroups(groupedItems, orderedCategory);
  $: disabledCells = itemList.reduce((o, i, r) => {
    if (i.shape.type === "free") {
      o[`${r}.width`] = { disabled: true };
      o[`${r}.width_offset`] = { disabled: true };
      o[`${r}.height`] = { disabled: true };
      o[`${r}.height_offset`] = { disabled: true };
    }
    return o;
  }, {});
  $: allComments = bucketArrayMultiple($group.comments, "item_id", "type_id");
  $: allAttachments = bucketArrayMultiple($group.attachments, "item_id", "type_id");
  $: attachments = allAttachments.none;
  $: checkSelectedItemObj($group.items, $selectedItemsObj);
  $: selectedItem = findSelectedItem($group.items, $selectedItemsObj, allAttachments, allComments);
  $: selectedItems = itemList.filter((i) => $selectedItemsObj[i.id]);
  $: selectedItemIndex = itemList.indexOf(selectedItems[0]);
  $: findSelectedIndices(itemList, $selectedItemsObj);
  $: multiItem = makeMultiItem($group, selectedItems, customColColumns);
  $: prevItem = selectedItems.length === 1 && itemList[selectedItemIndex - 1];
  $: nextItem = selectedItems.length === 1 && itemList[selectedItemIndex + 1];
  $: warningCells = checkMarks(colColumns, itemList);
  $: collectionItems = bucketArray(items, "collection_id");

  function findSelectedItem(items, si, allAttachments, allComments) {
    const s = Object.keys(si)[0];
    const i = items[s];

    if (!i) return null;

    return {
      ...i,
      attachments: allAttachments[i.id] || [],
      comments: allComments[i.id] || [],
    };
  }

  function findSelectedIndices(itemList, sio) {
    const l = itemList.map((i, index) => index).filter((index) => sio[itemList[index].id]);

    if (!isEqual(l, selectedItemIndices)) {
      selectedItemIndices = l;
    }
  }

  function checkSelectedItemObj(items, sio) {
    if (!selectedItem) selectedItemsObj;

    const deselect = Object.keys(sio).filter((id) => !items[id]);
    if (deselect.length > 0) {
      selectedItemsObj.deselect(...deselect);
    }
  }

  function checkMarks(columns, list) {
    const usedMarks = {};
    const dupMarks = {};
    const markCol = columns.findIndex((c) => c.prop === "mark");
    list.forEach((item, index) => {
      if (usedMarks[item.mark] !== undefined) {
        dupMarks[index] = true;
        dupMarks[usedMarks[item.mark]] = true;
      } else {
        usedMarks[item.mark] = index;
      }
    });

    return Object.keys(dupMarks).map((index) => [index, markCol, index, markCol]);
  }

  function moveColumns(evt) {
    const { columns: cols, target } = evt.detail;

    const prevOrder = colColumns.map((c) => c.prop);
    const l = cols.findIndex((c) => c > target);
    const shiftedTarget = l === -1 ? target - cols.length : target - l;
    const movedProps = cols.map((index) => prevOrder[index]);
    const chash = hashify(movedProps);
    const newOrder = prevOrder.filter((prop) => !chash[prop]);
    newOrder.splice(shiftedTarget, 0, ...movedProps);

    group.updateProp("data.collection_column_order", newOrder);
  }

  async function addDefaultItem(property) {
    const p = {
      ...property,
      is_collection: true,
    };
    const item = defaultItem($group.id, $user.id, collections, types, p);
    group.addItem(item);

    await tick();

    if (!selectedItems.length) {
      selectedItemsObj.selectOnly(item.id);
    }
  }

  function addItem(evt) {
    const newItem = {
      ...defaultItem($group.id, $user.id, collections, types),
      ...evt.detail,
      is_collection: true,
    };

    group.addItem(newItem);
    selectedItemsObj.selectOnly(newItem.id);
  }

  function addColumn(evt) {
    const name = evt.detail;

    const data = cloneDeep($group.data);
    const cc = get(data, "custom_collection_columns") || sortableList([]);

    addToSortableList(cc, {
      id: crypto.randomUUID(),
      name,
      type: "text",
    });

    data.custom_collection_columns = cc;
    group.updateProp("data", data);
  }

  function renameColumn(evt) {
    const { name, index } = evt.detail;
    const col = colColumns[index];

    const data = cloneDeep($group.data);
    set(data, `custom_collection_columns.${col.id}.name`, name);

    group.updateProp("data", data);
  }

  function deleteColumn(evt) {
    const index = evt.detail;
    const col = colColumns[index];

    const data = cloneDeep($group.data);
    removeFromSortableList(data.custom_collection_columns, col.id);

    // Delete data from each item
    const itemUpdates = collections.map((item) => {
      const ccd = cloneDeep(item.custom_column_data);
      if (ccd[col.id] !== undefined) {
        delete ccd[col.id];
      }

      return { type: "item", id: item.id, prop: "custom_column_data", value: ccd };
    });

    group.update([...itemUpdates, { type: "group", id: $group.id, prop: "data", value: data }]);
  }

  function updateItem(evt) {
    const { id, prop, value, diff } = evt.detail;
    group.updateItem(id, prop, value, diff);
  }

  function updateDgValue(evt) {
    const { updates } = evt.detail;

    // Group updates for each item
    const iobj = updates.reduce((u, update) => {
      const id = itemList[update.row]?.id;
      if (id) {
        if (u[id]) {
          u[id][update.prop] = update.value;
        } else {
          u[id] = {
            [update.prop]: update.value,
          };
        }
      }
      return u;
    }, {});

    const u = Object.keys(iobj).map((id) => ({ type: "item", id, diff: iobj[id] }));
    group.update(u);
  }

  function updateMultiItem(evt) {
    const { prop, value, diff } = evt.detail;
    const ids = selectedItems.map((i) => i.id);
    group.updateMultipleItems(ids, prop, value, diff);
  }

  async function resort(evt) {
    const selectId = evt.detail;
    group.react();

    if (selectId) {
      selectedItemsObj.selectOnly(selectId);
    }
  }

  function removeItems() {
    $selectedItemsObj = {};
    group.removeItem(...itemsToDelete);
  }

  async function selectItem(evt) {
    const { selected } = evt.detail;

    selectedItemsObj.selectOnly(...selected);
    if (selected.length === 1) {
      eb.dispatch("scrollto", selected[0]);
    }
  }

  async function updateSelection(evt) {
    const { selectedRows, selectedCols, selected } = evt.detail;
    if (selectedCols.length > 0) {
      selectedItemsObj.select(...itemList.map((i) => i.id));
    } else if (selectedRows.length > 0) {
      const indices = hashifyRanges(selectedRows);
      const ids = Object.keys(indices)
        .filter((i) => itemList[i])
        .map((i) => itemList[i].id);
      selectedItemsObj.selectOnly(...ids);

      if (ids.length === 1) {
        eb.dispatch("scrollto", ids[0]);
      }
    } else {
      const rows = selected.map((s) => [s[0], s[2]]);
      const indices = hashifyRanges(rows);
      const ids = Object.keys(indices)
        .filter((i) => itemList[i])
        .map((i) => itemList[i].id);
      selectedItemsObj.selectOnly(...ids);

      if (ids.length === 1) {
        eb.dispatch("scrollto", ids[0]);
      }
    }
  }

  function confirmRemove() {
    itemsToDelete = selectedItems.map((i) => i.id);
    confirmRemoveModal.open();
  }

  function selectNone() {
    selectItem({ detail: { selected: [] } });
  }

  function gotoPrev() {
    if (prevItem) selectedItemsObj.selectOnly(prevItem.id);
  }

  function gotoNext() {
    if (nextItem) selectedItemsObj.selectOnly(nextItem.id);
  }

  async function cloneItems(itemsToClone) {
    const marks = collections.map((i) => i.mark);

    const cloned = itemsToClone.map((i) => ({
      ...i,
      id: crypto.randomUUID(),
      created_by: $profile.id,
      created_at: new Date(),
      mark: incrementMarkCopy(i.mark, marks),
    }));

    await group.addItem(...cloned);

    selectedItemsObj.selectOnly(...cloned.map((i) => i.id));
  }

  function toggleSubcolVis(e) {
    const { id, value } = e.detail;
    group.updateProp(`data.settings.hide_${id}_offset`, value);
  }
</script>

<div class="w-full h-full" bind:offsetWidth={width} bind:offsetHeight={height}>
  <ResizablePanes
    startLeft={850}
    startBottom={300}
    showPane={$showTable && width >= 640 && height >= 480}
    direction={$tableLeft ? "horizontal" : "vertical"}>
    <div slot="content" class="bg-gray-100 w-full h-full flex">
      <div class="flex-grow relative" on:click={selectNone} bind:this={vpContainer}>
        {#if selectedItems.length > 0}
          <SelectedActions
            cloneable={!disabled}
            deletable={!disabled}
            selected={selectedItems.length}
            on:delete={confirmRemove}
            on:clone={() => cloneItems(selectedItems)} />
        {/if}
        <div class="absolute w-full flex items-center justify-end pr-6 pt-4 z-20">
          <SortOptions
            {group}
            customColumns={customColColumns}
            {standardColumns}
            {groupBy}
            {groupByDirection}
            {sortBy}
            {sortByDirection}
            isCollections />
        </div>
        <div class="h-full w-full overflow-auto pt-6">
          {#key $refreshItemList}
            <ItemViewgrid
              {group}
              items={collections}
              {itemList}
              {orderedCategory}
              {groupedItems}
              {disabled}
              container={vpContainer}
              itemAttachments={allAttachments}
              itemComments={allComments}
              {typeObj}
              groupBy={$groupBy}
              {collectionItems}
              isCollection
              on:newItem={(evt) => addDefaultItem(evt.detail)} />
          {/key}
        </div>
      </div>
      {#if $showRightPanel && width >= 640}
        <Sidebar>
          <svelte:fragment slot="header">
            {#if selectedItems.length === 1 && selectedItem}
              <PrevNext
                prev={prevItem}
                next={nextItem}
                on:gotoprev={gotoPrev}
                on:gotonext={gotoNext}
                title={selectedItem.mark}
                sticky />
            {/if}
          </svelte:fragment>

          <svelte:fragment slot="content">
            <!-- No items selected, show Group properties -->
            {#if selectedItems.length === 0}
              <SidebarTitle title="Job Properties" />
              <JobProperties {disabled} {group} />
              <Attachments {attachments} {group} {disabled} />
              <History updatedBy={$group.updater} updatedOn={$group.updated_at} />
              <!-- One item selected, show that item's properties -->
            {:else if selectedItems.length === 1 && selectedItem}
              {#key selectedItem.id}
                <ItemProperties
                  {group}
                  itemid={selectedItem.id}
                  {disabled}
                  item={selectedItem}
                  customColumns={customColColumns}
                  {types}
                  {standardColumns}
                  isCollection
                  settings={$group.data.settings}
                  on:updateItem={updateItem} />
                <CollectionItemList
                  {group}
                  {items}
                  collectionItems={collectionItems[selectedItem.id]}
                  collectionId={selectedItem.id}
                  {disabled} />
                <LocationThumbnailViewer
                  recordid={selectedItem.id}
                  {group}
                  record={selectedItem}
                  {disabled} />
                <Attachments
                  attachments={selectedItem.attachments}
                  {group}
                  itemid={selectedItem.id}
                  {disabled} />
                <History
                  updatedBy={selectedItem.updater}
                  updatedOn={selectedItem.updated_at}
                  approvedBy={selectedItem.approver}
                  approvalStatus={selectedItem.approval_status}
                  approvedOn={selectedItem.approval_status_updated_at} />
              {/key}

              <!-- Multiple selected, allow multi-edit -->
            {:else}
              <SidebarTitle title="Multiple Items Selected" />
              <ItemProperties
                {group}
                {disabled}
                item={multiItem}
                customColumns={customColColumns}
                {types}
                {standardColumns}
                isCollection
                settings={$group.data.settings}
                multiple
                on:updateItem={updateMultiItem} />
            {/if}
          </svelte:fragment>
        </Sidebar>
      {/if}
    </div>

    <div slot="pane" class="bg-white w-full h-full flex flex-col" class:px-6={$tableLeft}>
      <div class="flex-none">
        <button class="p-1 hover:bg-gray-100 rounded" on:click={() => ($tableLeft = !$tableLeft)}>
          <FlipIcon />
        </button>
      </div>
      <Datagrid
        data={itemList}
        cells={disabledCells}
        delegate
        reorderable={!disabled}
        columns={colColumns}
        divisions={categoryCounts}
        selectedRows={selectedItemIndices}
        {warningCells}
        emptyRow
        columnAddable={!disabled}
        darkCols
        on:moveColumns={moveColumns}
        on:toggleSubcolVis={toggleSubcolVis}
        on:updateSelection={updateSelection}
        on:updateValues={updateDgValue}
        on:addRow={addItem}
        on:addColumn={addColumn}
        on:renameColumn={renameColumn}
        on:deleteColumn={deleteColumn} />
    </div>
  </ResizablePanes>
</div>

<Modal
  bind:this={confirmRemoveModal}
  on:confirm={removeItems}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Delete", type: "confirm", style: "danger" },
  ]}
  closeOnOutclick>
  <div slot="title">Delete Openings</div>
  <div slot="content">
    Are you sure you want to delete {itemsToDelete.length}
    opening{itemsToDelete.length > 1 ? "s" : ""}?
  </div>
</Modal>
