<script>
  import { getContext } from "svelte";
  import cloneDeep from "lodash/cloneDeep";
  import set from "lodash/set";
  import { draggable } from "svelte-utilities";

  import SimpleCheckbox from "./SimpleCheckbox.svelte";
  import EditIcon from "@local/assets/icons/edit.svg";
  import GripIcon from "@local/assets/icons/grip.svg";
  import PlusIcon from "@local/assets/icons/plus.svg";
  import XIcon from "@local/assets/icons/x.svg";

  import { Modal } from "svelte-utilities";
  import TextInput from "#lib/sidebar/TextInput.svelte";
  import SelectInput from "#lib/sidebar/SelectInput.svelte";
  import BooleanInput from "#lib/sidebar/BooleanInput.svelte";
  import ToggleInput from "#lib/sidebar/ToggleInput.svelte";
  import { api } from "#src/api";

  const org = getContext("org");
  const productLists = getContext("productLists");
  const priceEntries = getContext("priceEntries");
  const edgeTreatments = getContext("edgeTreatments");
  const fabrications = getContext("fabrications");

  export let selected;
  export let disabled = false;

  const newEntry = () => ({
    list_id: null,
    product_id: null,
    unit_price: null,
    unit: null,
    formula: null,
  });

  let editModal;
  let entry = newEntry();
  let list = null;
  let defineCosts = "define";
  let dragging = null;
  let listDragging = null;
  let isDragging = false;

  $: cp = sumChildPrice(list, entry, selected);

  function parser(v) {
    if (v === "" || v === undefined || v === null) return null;
    if (typeof v !== "string") throw new Error("Invalid currency value");

    const num = v.match(/\d+(\.?\d+)?/);
    if (!num) throw new Error("Invalid currency value");

    const d = parseFloat(num[0]);
    if (!Number.isFinite(d)) {
      throw new Error("Invalid currency value");
    }

    return d;
  }

  function formatter(v) {
    if (!v) return "";
    if (v === "Mixed") return "Mixed";
    return v.toFixed(4);
  }

  function intParser(num) {
    if (num == null || num === "") return null;
    return parseInt(num);
  }

  function numValidator(num) {
    if (num == null) return true;
    return !Number.isNaN(num);
  }

  function getEntries(list, entries) {
    const pe = entries[selected.id]?.[list.id] || [];
    return pe
      .map((e) => cloneDeep(e || { ...newEntry(), list_id: list.id, product_id: selected.id }))
      .map((e) => {
        delete e.formula_compiled;
        return e;
      });
  }

  function product(id) {
    return (
      $org.makeups[id] ||
      $org.materials[id] ||
      $org.surfaces[id] ||
      $org.item_products[id] ||
      $edgeTreatments[id] ||
      $fabrications[id]
    );
  }

  function isUndefinedOrNull(v) {
    return v === undefined || v === null;
  }

  function editEntry(l, e) {
    list = l;
    entry = { ...e };

    if (selected.application === "makeup" && isUndefinedOrNull(entry.unit_price)) {
      defineCosts = "defer";
    } else {
      defineCosts = "define";
    }
    editModal.open();
  }

  function getPrice(list, entry) {
    if (isUndefinedOrNull(entry.unit_price) || isUndefinedOrNull(entry.unit)) return null;
    if (entry.unit_price === "Mixed" || entry.unit === "Mixed") return "Mixed";
    const currency = list.currency === "USD" ? "$" : "C$";
    return `${currency}${entry.unit_price}/${entry.unit}`;
  }

  function constraint(entry, prop) {
    const max = entry[`maximum_${prop}`];
    const min = entry[`minimum_${prop}`];
    const p = prop.replace("_", " ");
    if (max != null && min != null) {
      return `${min} ≤ ${p} < ${max}`;
    } else if (max != null) {
      return `${p} < ${max}`;
    } else if (min != null) {
      return `${min} ≤ ${p}`;
    }

    return null;
  }

  function getPriceConstraints(entry) {
    const constraints = ["area", "item_area", "item_quantity"]
      .map((prop) => constraint(entry, prop))
      .filter((c) => c);

    if (constraints.length) {
      return `(${constraints.join(", ")})`;
    } else {
      return "";
    }
  }

  function prodPrice(prod, list) {
    if (!prod || !list) return {};
    const entry = list.price_entries[prod.id];
    if (!entry) return {};
    if (entry.unit_price === null || entry.unit === null) return {};
    if (entry.unit_price === "Mixed" || entry.unit === "Mixed") return { Mixed: true };
    return { [entry.unit]: entry.unit_price };
  }

  function addByKey(a, b) {
    Object.keys(b).forEach((key) => {
      if (a[key] === "Mixed" || b[key] === "Mixed") {
        a[key] = "Mixed";
      } else if (a[key] !== undefined) {
        a[key] += b[key];
      } else {
        a[key] = b[key];
      }
    });
  }

  function sumChildPrice(list, entry, product) {
    if (!list || !entry || !product) return null;
    if (product.application !== "makeup") return null;
    if (Array.isArray(product.id)) return "Mixed";
    if (!isUndefinedOrNull(entry.unit_price) || !isUndefinedOrNull(entry.unit)) return null;
    const currency = list.currency === "USD" ? "$" : "C$";
    const s = product.data.layers.reduce((sum, layer) => {
      const mat = prodPrice(layer.material, list);
      const ibsurf = prodPrice(layer.inboard_surface, list);
      const obsurf = prodPrice(layer.outboard_surface, list);

      addByKey(sum, mat);
      addByKey(sum, ibsurf);
      addByKey(sum, obsurf);

      return sum;
    }, {});

    if (s.Mixed) return "Mixed";

    return Object.keys(s).reduce((str, unit) => {
      if (str) str += ", ";
      str += `${currency}${s[unit]}/${unit}`;
      return str;
    }, "");
  }

  function updatePriceEntry() {
    const { product_id, list_id, id, ...rest } = entry;

    if (defineCosts === "defer") {
      rest.unit_price = null;
      rest.unit = null;
      rest.formula = null;
    }

    const index = $priceEntries[product_id][list_id].findIndex((e) => e.id === id);

    if (index === -1) return;

    $priceEntries[product_id][list_id][index] = entry;

    api
      .from("price_entries")
      .update(rest)
      .eq("id", id)
      .then(({ error }) => {
        if (error) console.error(error);
      });
  }

  function addPriceEntry(list) {
    if (!$priceEntries[selected.id]?.[list.id]) {
      set($priceEntries, `${selected.id}.${list.id}`, []);
    }

    const id = crypto.randomUUID();

    const entry = {
      ...newEntry(),
      id,
      list_id: list.id,
      product_id: selected.id,
    };

    $priceEntries[selected.id][list.id].push(entry);
    $priceEntries = $priceEntries;

    api
      .from("price_entries")
      .insert(entry)
      .then(({ error }) => {
        if (error) console.error(error);
      });
  }

  function removePriceEntry(list, entry) {
    const { id } = entry;
    const index = $priceEntries[selected.id][list.id].findIndex((e) => e.id === id);
    if (index === -1) return;

    $priceEntries[selected.id][list.id].splice(index, 1);
    $priceEntries = $priceEntries;

    api
      .from("price_entries")
      .delete()
      .eq("id", id)
      .then(({ error }) => {
        if (error) console.error(error);
      });
  }

  function drag(list, index) {
    dragging = index;
    listDragging = list.id;
    isDragging = true;
  }

  function drop(list, dropIndex) {
    if (!isDragging) return;
    if (listDragging !== list.id) return;
    if (dragging === dropIndex || dropIndex === dragging - 1) return;

    const insertion = dropIndex < dragging ? dropIndex + 1 : dropIndex;
    const entries = cloneDeep($priceEntries[selected.id][list.id]);
    const moved = entries.splice(dragging, 1)[0];
    entries.splice(insertion, 0, moved);

    $priceEntries[selected.id][list.id] = entries;

    const updates = entries
      .map((e, i) => [e.id, { priority: i }])
      .map(async ([id, u]) => {
        const { error } = await api.from("price_entries").update(u).eq("id", id);
        if (error) console.error(error);
      });

    Promise.all(updates).catch((e) => console.error(e));
  }

  function dragend() {
    dragging = null;
    listDragging = null;
    isDragging = false;
  }
</script>

{#if $productLists.length && !Array.isArray(selected.id)}
  <div class="px-6 text-xs">
    <h3 class="mb-2">Product Lists</h3>
    <div class="space-y-2 mb-2">
      {#each $productLists as list}
        {@const entries = getEntries(list, $priceEntries)}
        <div>
          <div class="py-1">
            {list.name}
          </div>
          {#each entries as entry, entryIndex}
            {@const price = getPrice(list, entry)}
            {@const constr = getPriceConstraints(entry)}
            <div
              class="entry-container"
              class:dragging={dragging === entryIndex && list.id === listDragging}
              class:is-dragging={isDragging}>
              {#if entryIndex === 0}
                <!-- svelte-ignore a11y-no-static-element-interactions -->
                <div
                  class="drop-target top-target"
                  class:visible={isDragging}
                  on:mouseup={() => drop(list, -1)}>
                  <div class="drop-target-line" />
                </div>
              {/if}
              <div class="flex justify-between">
                <button
                  class="grip-icon p-1 text-gray-500 hover:text-black"
                  class:disabled
                  use:draggable
                  on:drag={() => !disabled && drag(list, entryIndex)}
                  on:dragend={dragend}>
                  <GripIcon />
                </button>
                <button
                  class="grow text-left p-1 rounded hover:bg-gray-200"
                  on:click={() => editEntry(list, entry)}
                  {disabled}>
                  {price || "No price"}
                  {constr}
                </button>
                <button
                  class="grip-icon flex-none p-1 rounded hover:bg-gray-200 text-gray-500 hover:text-black"
                  on:click={() => removePriceEntry(list, entry)}
                  {disabled}>
                  <XIcon />
                </button>
              </div>
              <!-- svelte-ignore a11y-no-static-element-interactions -->
              <div class="drop-target" class:visible={isDragging} on:mouseup={() => drop(list, entryIndex)}>
                <div class="drop-target-line" />
              </div>
            </div>
          {/each}
          {#if !disabled}
            <div class="flex justify-end p-1">
              <button on:click={() => addPriceEntry(list)}>
                <PlusIcon />
              </button>
            </div>
          {/if}
        </div>
      {/each}
    </div>
  </div>
{/if}

<Modal
  closeable
  on:confirm={() => updatePriceEntry()}
  bind:this={editModal}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Update", type: "confirm", style: "primary" },
  ]}>
  <div slot="title">Edit Product List Entry</div>
  <div slot="content" class="space-y-4 text-sm">
    <div class="text-xs space-y-2">
      {#if list}
        <div>Product list: <span class="font-bold">{list.name}</span></div>
      {/if}
      <div>
        <div class="mb-1">Editing price entry for:</div>
        <div class="px-4">
          {product(entry.product_id)?.name}
        </div>
      </div>
    </div>
    <div class="text-xs">
      Currency: <span class="font-bold">{list.currency}</span>
    </div>
    {#if selected.application === "makeup"}
      <SelectInput
        label="Price Computation"
        border
        bind:value={defineCosts}
        options={[
          { label: "Use price of components", value: "defer" },
          { label: "Define makeup price", value: "define" },
        ]} />
    {/if}
    {#if defineCosts === "define"}
      <TextInput label="Unit Price" border {formatter} {parser} bind:value={entry.unit_price} />
      <SelectInput
        label="Price Unit"
        border
        bind:value={entry.unit}
        options={[
          { label: "Sq. Ft.", value: "sqft" },
          { label: "Sq. In.", value: "sqin" },
          { label: "Sq. Meters", value: "m2" },
          { label: "Item", value: "item" },
        ]} />
      <TextInput
        label="Minimum Area"
        border
        bind:value={entry.minimum_area}
        parser={intParser}
        validator={numValidator} />
      <TextInput
        label="Maximum Area"
        border
        bind:value={entry.maximum_area}
        parser={intParser}
        validator={numValidator} />
      <TextInput
        label="Minimum Item Qty"
        border
        bind:value={entry.minimum_item_quantity}
        parser={intParser}
        validator={numValidator} />
      <TextInput
        label="Maximum Item Qty"
        border
        bind:value={entry.maximum_item_quantity}
        parser={intParser}
        validator={numValidator} />
    {:else if cp}
      <div class="text-xs">
        Price of child products: <span class="font-bold">{cp}</span>
      </div>
    {/if}
  </div>
</Modal>

<style lang="scss">
  .pl-item {
    .ps-button {
      display: none;
    }

    &:hover .ps-button {
      display: block;
    }
  }

  .entry-container {
    @apply relative border border-transparent;

    .grip-icon {
      visibility: hidden;
    }

    &:hover:not(.is-dragging) {
      .grip-icon {
        visibility: visible;
      }
    }
  }

  .drop-target {
    @apply absolute w-full h-full z-30 pointer-events-none;
    bottom: calc(-50%);

    &.top-target {
      top: calc(-50% - 0.25rem);
    }

    &.visible {
      pointer-events: auto;

      &:hover {
        .drop-target-line {
          @apply w-full border-b border-gray-300 absolute;
          top: 50%;
        }
      }
    }
  }
</style>
