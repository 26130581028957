<script>
  import FlagFilledIcon from "@local/assets/icons/flag-filled.svg";
  import Viewport from "#lib/drawing/ViewportMinimal.svelte";
  import typeDrawing from "@local/extensions/drawing/type-drawing.js";
  import getTotalThickness from "@local/extensions/formatters/get-total-thickness.js";
  import imageUrl from "#src/extensions/image-url.js";

  export let group;
  export let type;

  $: typeSettings = {
    display_unit: $group.data.settings.display_unit,
    mm_precision: $group.data.settings.mm_precision,
    decimal_precision: 3,
    fractional_precision: 5,
    dimension_format: $group.data.settings.dimension_format,
  };
  $: totalThickness = getTotalThickness(type, typeSettings);
</script>

<div class="w-full h-full relative">
  {#if type.image}
    <img src={imageUrl(type.image)} alt={type.name} class="text-xs w-full h-full object-cover" />
  {:else}
    <Viewport padding={8} drawing={typeDrawing(type)} />
  {/if}
  {#if type.approval_status && type.approval_status !== "none"}
    <div
      class="p-1 absolute top-0 left-0 text-xs"
      class:text-red-500={type.approval_status === "flagged"}
      class:text-green-500={type.approval_status === "approved"}>
      <FlagFilledIcon />
    </div>
  {/if}
  {#if totalThickness}
    <div class="p-1 absolute top-0 right-0 text-xs bg-gray-100/75 rounded">
      {totalThickness}
    </div>
  {:else}
    <div class="p-1 absolute top-0 right-0 text-xs bg-gray-100/75 rounded">Thickness TBD</div>
  {/if}
</div>
