<script>
  import cloneDeep from "lodash/cloneDeep";
  import isEmpty from "lodash/isEmpty";
  import { profile } from "#src/stores/auth.js";
  import BooleanInput from "#lib/sidebar/BooleanInput.svelte";
  import { api } from "#src/api";

  $: checkPreferences($profile);

  function checkPreferences(p) {
    if (!p) return;
    if (isEmpty(p.email_preferences)) {
      updatePreference("transactions", true);
    }
  }

  async function updatePreference(pref, value) {
    const email_preferences = cloneDeep($profile.email_preferences);
    email_preferences[pref] = value;

    const { data, error } = await api
      .from("profiles")
      .update({ email_preferences })
      .eq("id", $profile.id)
      .select("*")
      .single();

    if (error) {
      console.log(error);
    } else if (data) {
      $profile.email_preferences = data.email_preferences;
    }
  }
</script>

<div class="px-4 pb-4 border-b border-gray-300">
  <h2>Email Preferences</h2>
</div>

{#if $profile}
  <div class="py-4 sm:px-4 text-sm flex">
    <div class="w-full sm:w-96 space-y-4">
      <BooleanInput
        border
        labelWidth="12rem"
        label="Send transactional emails"
        value={$profile.email_preferences?.transactions}
        on:input={(e) => updatePreference("transactions", e.detail.value)} />
    </div>
  </div>
{/if}
