<script>
  import { onMount, tick, getContext } from "svelte";
  import isEmpty from "lodash/isEmpty";
  import merge from "lodash/merge";
  import set from "lodash/set";
  import get from "lodash/get";
  import cloneDeep from "lodash/cloneDeep";
  import { LinearDisplayFormat } from "dimtext";

  import parseCSV from "#src/extensions/importers/csv.js";
  import parseXLS from "#src/extensions/importers/excel.js";

  import { Modal } from "svelte-utilities";
  import Dropzone from "../dropzone/Dropzone.svelte";

  import SelectHeaderRow from "./SelectHeaderRow.svelte";
  import MapColumns from "./MapColumns.svelte";
  import MapTemplateParameters from "./MapTemplateParameters.svelte";
  import MapProperties from "./MapProperties.svelte";
  import VerifyData from "./VerifyData.svelte";
  import Paste from "./Paste.svelte";
  import SelectSheet from "./SelectSheet.svelte";
  import { nextTypeMark } from "@local/extensions/identifiers/mark.js";

  import NewItemIcon from "@local/assets/icons/new-item-lg.svg";
  import TableIcon from "@local/assets/icons/table-lg.svg";
  import eb from "#src/extensions/event-bus.js";
  import parseInteger from "@local/extensions/parsers/parse-pos-integer.js";
  import parseDim from "@local/extensions/parsers/parse-dim.js";
  import unparseDim from "@local/extensions/parsers/unparse-dim.js";
  import { hydrateItem, createItem as defaultItem, createType as defaultType } from "@local/lamina-core";
  import removeEmptyDims from "#src/extensions/remove-empty-dims";
  import { profile } from "#src/stores/auth.js";
  import {
    orderedList,
    sortArrayBy,
    sortableList,
    addToSortableList,
  } from "@local/extensions/collections/sortable-list.js";
  import { guessRecords } from "#src/extensions/guess-records.js";
  import { nextMark } from "@local/extensions/identifiers/mark.js";
  import dimSettings from "@local/extensions/utilities/dim-settings.js";

  export let group;
  export let items;
  export let collections;
  export let types;
  export let tab;

  const org = getContext("groupOrg");

  function validateDimtext(v) {
    if (!v) return false;
    const val = v.toNumber("inches");
    if (val > 300) return false;
    if (val <= 0) return false;
    return true;
  }

  function removeNonDbProps(item) {
    const { approver, updater, cache, ...rest } = item;
    return rest;
  }

  const accept = [
    ".csv",
    "text/csv",
    ".xls",
    "application/vnd.ms-excel",
    ".xlsx",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ".xlsm",
    "application/vnd.ms-excel.sheet.macroenabled.12",
  ].join(",");

  let flow = "uploading";
  let importModal;
  let dropzone;
  let state = "load";
  let data = { data: null, errors: [] };
  let templateObj = {};
  let progress = {
    source: null,
    multiSheet: false,
    ordered: null,
    mapped: null,
    mapped_with_templates: null,
    mapped_with_template_data: null,
    columns_used: null,
    placeholder_types: {},
    placeholder_collections: {},
    mapped_with_types: null,
    mapped_with_collections: null,
    verified: null,
  };
  let showPasteOption = true;
  let newJobEntry = false;
  let importUnit = "inches";
  let pasteImportType = "paste_header_data";
  let hiddenColumns = {};
  let flagged = [];

  $: settings = $group?.data.settings;
  $: ds = dimSettings($group.data.settings);
  $: states =
    flow === "uploading"
      ? [
          "load",
          ...(progress.multiSheet ? ["select_sheet"] : []),
          "select_header_row",
          "map_columns",
          ...(tab === "items"
            ? ["map_templates", "map_template_parameters", "map_types", "map_collections"]
            : []),
          "verify_data",
        ]
      : [
          "load",
          "paste",
          ...(pasteImportType === "paste_header_data" ? ["map_columns"] : []),
          ...(tab === "items"
            ? ["map_templates", "map_template_parameters", "map_types", "map_collections"]
            : []),
          "verify_data",
        ];
  $: formatDimtext = (v) => {
    if (!v) return "";
    return v.format(LinearDisplayFormat[ds.dimFormat], ds.dimPrecision, { displayUnit: ds.displayUnit });
  };
  $: width = ["load", "map_types", "map_collections"].includes(state) ? "36rem" : "80%";
  $: title = {
    load: newJobEntry ? "Enter Sizes" : "Bulk Upload",
    select_sheet: "Select Sheet",
    select_header_row: "Select Header Row",
    map_columns: "Map Columns",
    map_types: "Map Types",
    map_collections: "Map Openings",
    map_templates: "Map Templates",
    map_template_parameters: "Map Template Parameters",
    verify_data: "Verify Data",
    paste: "Paste Into Spreadsheet",
  }[state];
  $: orgCols = ($org.data.custom_columns && orderedList($org.data.custom_columns)) || [];
  $: groupCols = ($group.data.custom_columns && orderedList($group.data.custom_columns)) || [];
  $: customColumns = orgCols.concat(groupCols);
  $: sc = $org.data.standard_columns || {};
  $: templates = $org.data.templates
    ? $org.data.templates.map((t) => ({
        id: t.path,
        mark: t.name,
        label: t.name,
        name: t.name,
        value: t.path,
      }))
    : [];
  $: dimParser = parseDim(importUnit);
  $: schema = makeSchema(types, progress, $group, orgCols, groupCols, importUnit);

  function makeSchema(types, progress, j, orgCols, groupCols, importUnit) {
    const standardCols = [
      {
        label: "Mark",
        prop: "mark",
        type: "string",
      },
      {
        label: "Width",
        type: "multi-column",
        id: "width",
        prop: "width",
        primary: 0,
        separateHeaders: true,
        hideSubcolumns: settings.hide_width_offset,
        subcolumns: [
          {
            prop: "width",
            parser: dimParser,
            formatter: formatDimtext,
            unparser: unparseDim,
            required: true,
            validator: validateDimtext,
          },
          {
            label: "+/-",
            prop: "width_offset",
            parser: dimParser,
            formatter: formatDimtext,
            unparser: unparseDim,
            default: () => dimParser("0"),
          },
        ],
      },
      {
        label: "Height",
        type: "multi-column",
        id: "height",
        prop: "height",
        primary: 0,
        separateHeaders: true,
        hideSubcolumns: settings.hide_height_offset,
        subcolumns: [
          {
            prop: "height",
            parser: dimParser,
            formatter: formatDimtext,
            unparser: unparseDim,
            required: true,
            validator: validateDimtext,
          },
          {
            label: "+/-",
            prop: "height_offset",
            parser: dimParser,
            formatter: formatDimtext,
            unparser: unparseDim,
            default: () => dimParser("0"),
          },
        ],
      },
      ...(tab === "items"
        ? [
            {
              label: "Type",
              prop: "type_id",
              type: "select",
              optionMap: types.reduce(
                (o, t) => {
                  o[t.id] = `${t.mark}${t.name && ` - ${t.name}`}`;
                  return o;
                },
                { [null]: "None", ...progress.placeholder_types },
              ),
              options: [null, ...Object.keys(progress.placeholder_types)].concat(types.map((t) => t.id)),
              default: null,
            },
            {
              label: "Opening",
              prop: "collection_id",
              type: "select",
              optionMap: collections.reduce(
                (o, c) => {
                  o[c.id] = c.mark;
                  return o;
                },
                { [null]: "None", ...progress.placeholder_collections },
              ),
              options: [null, ...Object.keys(progress.placeholder_collections)].concat(
                collections.map((c) => c.id),
              ),
              default: null,
            },
          ]
        : []),
      {
        label: "Description",
        prop: "description",
        type: "string",
      },
      ...(tab === "items"
        ? [
            {
              label: "Quantity",
              prop: "quantity",
              type: "integer",
              parser: parseInteger,
              min: 0,
              default: 1,
            },
          ]
        : []),
      ...(tab === "items" && templates?.length
        ? [
            {
              label: "Item Template",
              prop: "item_template",
              type: "select",
              optionMap: templates.reduce(
                (obj, t) => {
                  obj[t.id] = t.name;
                  return obj;
                },
                { [null]: "None" },
              ),
              options: [null, ...templates.map((t) => t.id)],
              default: null,
            },
          ]
        : []),
    ]
      .filter((col) => {
        if (sc[col.prop]) return sc[col.prop].visible;
        return true;
      })
      .map((col) => {
        if (sc[col.prop] && sc[col.prop].override) {
          col.label = sc[col.prop].override;
        }

        return col;
      });

    const cols = standardCols.concat(
      customColumns
        .filter((c) => c.id !== "item_template")
        .map((c) => ({
          label: c.name,
          prop: `custom_column_data.${c.id}`,
          type: "string",
        })),
    );

    const order = $group.data.column_order || [];
    return sortArrayBy(cols, order, "prop");
  }

  async function openImporter(path) {
    importModal.open();
    showPasteOption = true;
    newJobEntry = false;
    importUnit = $group?.data.settings?.display_unit || "inches";

    if ($org.data.templates) {
      const t = $org.data.templates.map((t) => t.path);
      importTemplates(t).then((mods) => {
        templateObj = mods.reduce((obj, mod, i) => {
          const path = $org.data.templates[i].path;
          obj[path] = mod;
          return obj;
        }, {});
      });
    } else {
      templateObj = {};
    }

    if (path === "new-job-entry") {
      newJobEntry = true;
    } else if (path === "file") {
      showPasteOption = false;
    } else if (path === "spreadsheet") {
      pasteIntoSpreadsheet();
    }
  }

  function moveState(n) {
    const i = states.indexOf(state);
    if (states[i + n]) state = states[i + n];
  }

  const paramParsers = {
    dimtext: dimParser,
    number: parseFloat,
    integer: parseInteger,
    boolean: (v) => v.toLowerCase() === "true",
    json: JSON.parse,
  };

  const paramFormatters = {
    dimtext: formatDimtext,
    json: JSON.stringify,
    boolean: (v) => (v ? "true" : "false"),
  };

  const paramValidators = {
    dimtext: validateDimtext,
  };

  function getTemplateParams(templates, list) {
    return list.reduce((params, t) => {
      const template = templates[t.path];
      if (!template?.input) return params;
      const tparams = Object.keys(template.input).map((key) => ({
        template: t.path,
        parser: template.input[key].parser || paramParsers[template.input[key].type] || ((v) => v),
        formatter: template.input[key].formatter || paramFormatters[template.input[key].type] || ((v) => v),
        validator: template.input[key].validator || paramValidators[template.input[key].type] || (() => true),
        label: `${t.name}: ${key}`,
        default: template.input[key].default,
        prop: `item_template_data.${t.path}.${key}`,
        key,
      }));
      params.push(...tparams);

      return params;
    }, []);
  }

  function nextState(evt) {
    progress = {
      ...progress,
      ...evt.detail,
    };

    moveState(1);

    if (state === "map_columns" && flow === "pasting" && pasteImportType === "show_column_header") {
      moveState(1);
    }

    if (state === "map_templates") {
      if (!progress.columns_used?.includes("item_template")) {
        progress.mapped_with_templates = progress.mapped;
        moveState(1);
      } else {
        progress.template_columns = getTemplateParams(templateObj, $org.data.templates);

        const usedTemplates = progress.mapped.map((r) => r.item_template);
        const templateNames = $org.data.templates.reduce((a, t) => {
          a[t.name] = t.path;
          return a;
        }, {});

        if (usedTemplates.every((t) => !t || templateNames[t])) {
          progress.mapped_with_templates = progress.mapped.map((r) => ({
            ...r,
            item_template: templateNames[r.item_template],
          }));
          moveState(1);
        }
      }
    }

    if (state === "map_template_parameters") {
      if (!progress.columns_used?.includes("item_template")) {
        progress.mapped_with_template_data = progress.mapped_with_templates;
        moveState(1);
      } else {
        const usedTemplates = progress.mapped_with_templates
          .map((r) => r.item_template)
          .filter((t) => t)
          .reduce((obj, t) => {
            obj[t] = true;
            return obj;
          }, {});
        progress.template_columns = progress.template_columns.filter((c) => usedTemplates[c.template]);
      }
    }

    if (state === "map_types") {
      if (!progress.columns_used?.includes("type_id")) {
        const usedMarks = items.reduce((obj, item) => {
          obj[item.mark] = true;
          return obj;
        }, {});
        const allMarksAreDuplicates = progress.mapped.every((row) => usedMarks[row.mark]);
        if (allMarksAreDuplicates) {
          progress.mapped_with_types = progress.mapped_with_template_data;
          moveState(1);
        }
      } else {
        const typemap = guessRecords(progress.mapped_with_template_data, types);
        if (Object.values(typemap).every((v) => v !== "placeholder")) {
          progress.placeholder_types = {};
          progress.mapped_with_types = progress.mapped_with_template_data.map((row) => ({
            ...row,
            type_id: typemap[row.type_id],
          }));
          moveState(1);
        }
      }
    }

    if (state === "map_collections" && !progress.columns_used?.includes("collection_id")) {
      progress.mapped_with_collections = progress.mapped_with_types;
      moveState(1);
    }
  }

  function prevState(evt) {
    progress = {
      ...progress,
      ...evt.detail,
    };
    moveState(-1);

    if (state === "map_collections" && !progress.columns_used?.includes("collection_id")) {
      moveState(-1);
    }

    if (state === "map_types" && !progress.columns_used?.includes("type_id")) {
      const usedMarks = items.reduce((obj, item) => {
        obj[item.mark] = true;
        return obj;
      }, {});
      const allMarksAreDuplicates = progress.mapped.every((row) => usedMarks[row.mark]);
      if (allMarksAreDuplicates) {
        moveState(-1);
      }
    }

    if (state === "map_template_parameters") {
      if (!progress.columns_used?.includes("item_template")) {
        moveState(-1);
      } else {
        const usedTemplates = progress.mapped.map((r) => r.item_template);
        const templateNames = $org.data.templates.reduce((a, t) => {
          a[t.name] = t.path;
          return a;
        }, {});

        if (usedTemplates.every((t) => !t || templateNames[t])) {
          moveState(-1);
        }
      }
    }

    if (state === "map_templates" && !progress.columns_used?.includes("item_template")) {
      moveState(-1);
    }
  }

  async function finish(evt) {
    const { verified, updateDuplicates } = evt.detail;
    progress.verified = verified;
    progress.updateDuplicates = updateDuplicates;

    let newItemList = [];
    let updatedItemList = [];

    let newIndices = [];
    let updatedIndices = [];

    flagged = new Array(verified.length).fill(null);

    if (updateDuplicates) {
      const usedMarks = items.reduce((obj, item) => {
        obj[item.mark] = true;
        return obj;
      }, {});

      progress.verified.forEach((item, i) => {
        if (usedMarks[item.mark]) {
          updatedItemList.push(item);
          updatedIndices.push(i);
        } else {
          newItemList.push(item);
          newIndices.push(i);
        }
      });
    } else {
      newItemList = progress.verified;
      newIndices = new Array(newItemList?.length || 0).fill(0).map((v, i) => i);
      updatedItemList = [];
    }

    // If necessary, add a new custom column for item template data
    if (newItemList.some((item) => item.item_template)) {
      const gdata = cloneDeep($group.data);
      const cc = get(gdata, "custom_columns") || sortableList([]);

      if (!cc.item_template) {
        addToSortableList(cc, {
          id: "item_template",
          name: "Item Template",
          type: "text",
        });

        gdata.custom_columns = cc;

        await group.updateProp("data", gdata);
      }
    }

    const newItems = newItemList
      .map((row, i) => {
        const { item_template, item_template_data, ...rest } = row;

        let item = defaultItem($group.id, $profile.id);
        merge(item, rest);
        if (item_template) {
          const template = templateObj[item_template];
          const params = item_template_data?.[item_template] || {};
          try {
            item = template.run(item, params);
          } catch (error) {
            const index = newIndices[i];
            flagged[index] = { message: error?.message || "Invalid template parameters" };
          }
          set(item, "custom_column_data.item_template", item_template);
        }

        return {
          ...item,
          is_collection: tab === "openings",
        };
      })
      .map((item) => hydrateItem(item, $group));

    const newTypeMap = progress.verified
      .filter((i) => !$group.types[i.type_id] && i.type_id)
      .reduce((obj, i) => {
        obj[i.type_id] = progress.placeholder_types[i.type_id];
        return obj;
      }, {});

    const newTypes = Object.entries(newTypeMap).map(([id, mark], index) => {
      const m = mark === "New Type" ? nextTypeMark(types) : mark;

      return {
        ...defaultType($group.id),
        id,
        mark: m,
        name: `Type ${index}`,
      };
    });

    const newCollectionMap = progress.verified
      .filter((i) => i.collection_id && !$group.items[i.collection_id])
      .reduce((obj, i) => {
        obj[i.collection_id] = progress.placeholder_collections[i.collection_id];
        return obj;
      }, {});

    const newCollections = Object.entries(newCollectionMap).map(([id, mark], index) => {
      const m = mark === "New Opening" ? nextMark(collections) : mark;

      return {
        ...defaultItem($group.id, $profile.id),
        is_collection: true,
        id,
        mark: m,
      };
    });

    const updatedItems = updatedItemList
      .map((row, i) => {
        const existing = items.find((i) => i.mark === row.mark);
        const { item_template, item_template_data, ...rest } = row;

        let item;
        if (item_template) {
          item = removeNonDbProps(cloneDeep(existing));
          merge(item, rest);
          const template = templateObj[item_template];
          const params = item_template_data?.[item_template] || {};

          try {
            item = template.run(item, params);
          } catch (error) {
            const index = updatedIndices[i];
            flagged[index] = { message: "Invalid template parameters" };
          }
          set(item, "custom_column_data.item_template", item_template);
        } else {
          item = Object.entries(rest).reduce((obj, [key, val]) => {
            if (existing[key] !== val && val !== undefined) obj[key] = val;
            return obj;
          }, {});

          if (item.width || item.height || item.width_offset || item.height_offset) {
            // If the width or height is updated, we will overwrite the existing shape data
            item.shape = {};
          }
        }

        return {
          type: "item",
          id: existing.id,
          diff: item,
        };
      })
      .filter((update) => !isEmpty(update.diff));

    if (flagged.some((f) => f)) {
      return;
    } else {
      const updates = [];

      if (newTypes.length) {
        updates.push({ type: "type", action: "add", records: newTypes, block: true });
      }

      if (newCollections.length) {
        updates.push({ type: "item", action: "add", records: newCollections });
      }

      if (newItems.length) {
        updates.push({ type: "item", action: "add", records: newItems });
      }

      if (updatedItems.length) {
        updates.push(...updatedItems);
      }

      if (updates.length) {
        await group.update(updates);
      }

      resetImport();
      importModal.close();
    }
  }

  async function uploadFiles(evt) {
    flow = "uploading";
    await tick();

    const { file, data: rawData } = evt.detail;

    let data;

    // console.log('MIME', file.type);

    switch (file.type) {
      case "text/csv":
        const csvData = await parseCSV(rawData);
        if (csvData) {
          nextState({ detail: { source: removeEmptyDims(csvData) } });
        }
        break;
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "application/vnd.ms-excel.sheet.macroenabled.12":
        progress = { ...progress, multiSheet: true };
        const xlsData = await parseXLS(rawData);
        if (xlsData) {
          nextState({ detail: { excel: xlsData } });
        }
        break;
      default:
        console.warn("Unknown file type", file.type);
        break;
    }
    dropzone.stopLoading();
  }

  function resetImport() {
    state = "load";
    progress = {
      source: null,
      multiSheet: false,
      ordered: null,
      mapped: null,
      columns_used: null,
      placeholder_types: {},
      placeholder_collections: {},
      mapped_with_types: null,
      mapped_with_collections: null,
      verified: null,
      excel: null,
    };
  }

  async function pasteIntoSpreadsheet() {
    flow = "pasting";
    await tick();

    const source = Array.apply(null, Array(4)).map(() => {
      return schema.reduce((d, e) => {
        d[e.prop] = undefined;
        return d;
      }, {});
    });

    nextState({ detail: { source } });
  }

  async function importTemplates(tList) {
    return Promise.all(
      tList.map((t) => {
        return new Promise((res, rej) => {
          import(`../../extensions/item-templates/${t}.js`).then((mod) => {
            res(mod.default);
          });
        });
      }),
    );
  }

  onMount(() => {
    eb.on("import-csv", openImporter);

    return () => {
      eb.unsubscribe("import-csv", openImporter);
    };
  });
</script>

<Modal bind:this={importModal} closeable {width} on:close={resetImport}>
  <div slot="title">{title}</div>
  <div slot="content" class="flex flex-col overflow-hidden">
    {#if state === "load"}
      {#if data.errors.length > 0}
        <div class="text-red-500">
          <div>An error occurred:</div>
          {#each data.errors as err}
            <div>{err}</div>
          {/each}
        </div>
      {/if}
      <div class="w-full flex items-stretch gap-2 text-gray-400">
        {#if newJobEntry}
          <button
            class="w-full h-28 rounded border border-gray-300 border-dashed flex items-center cursor-pointer hover:bg-gray-100"
            on:click={() => importModal.close()}>
            <div class="text-gray-400 w-full flex flex-col items-center gap-2">
              <div class="text-center">Add manually</div>
              <div>
                <NewItemIcon />
              </div>
            </div>
          </button>
        {/if}
        <Dropzone bind:this={dropzone} text="Upload a spreadsheet" on:drop={uploadFiles} {accept} />
        {#if showPasteOption}
          <button
            class="w-full h-28 rounded border border-gray-300 border-dashed flex items-center cursor-pointer hover:bg-gray-100"
            on:click={pasteIntoSpreadsheet}>
            <div class="text-gray-400 w-full flex flex-col items-center gap-2">
              <div class="text-center">Paste data into a spreadsheet</div>
              <div>
                <TableIcon />
              </div>
            </div>
          </button>
        {/if}
      </div>
    {:else if state === "select_sheet"}
      <SelectSheet data={progress.excel} on:next={nextState} on:prev={prevState} />
    {:else if state === "select_header_row"}
      <SelectHeaderRow data={progress.source} on:next={nextState} on:prev={prevState} />
    {:else if state === "map_columns"}
      <MapColumns
        data={progress.ordered}
        {items}
        {schema}
        {group}
        bind:hiddenColumns
        bind:importUnit
        orgCols={sc}
        on:next={nextState}
        on:prev={prevState} />
    {:else if state === "paste"}
      <Paste
        data={progress.source}
        {group}
        {items}
        {schema}
        bind:importUnit
        bind:pasteImportType
        on:prev={prevState}
        on:next={nextState} />
    {:else if state === "map_templates"}
      <MapProperties
        data={progress.mapped}
        records={templates}
        prop="item_template"
        recname="templates"
        allowPlaceholder={false}
        on:next={nextState}
        on:prev={prevState}>
        <p>Map template names with available templates.</p>
      </MapProperties>
    {:else if state === "map_template_parameters"}
      <MapTemplateParameters
        data={progress.mapped_with_templates}
        columns={progress.template_columns}
        rawData={progress.ordered}
        {schema}
        schemaMap={progress.schema_map}
        {group}
        bind:hiddenColumns
        on:next={nextState}
        on:prev={prevState} />
    {:else if state === "map_types"}
      <MapProperties
        data={progress.mapped_with_template_data}
        records={types}
        prop="type_id"
        recname="types"
        on:next={nextState}
        on:prev={prevState}>
        <p>Map type names present in the imported data to types existing in this job.</p>
      </MapProperties>
    {:else if state === "map_collections"}
      <MapProperties
        data={progress.mapped_with_types}
        records={collections}
        prop="collection_id"
        recname="collections"
        on:next={nextState}
        on:prev={prevState}>
        <p>Map opening names present in the imported data to openings existing in this job.</p>
      </MapProperties>
    {:else if state === "verify_data"}
      <VerifyData
        data={tab === "items" ? progress.mapped_with_collections : progress.mapped}
        {group}
        {schema}
        {items}
        bind:flagged
        columns_used={progress.columns_used}
        template_columns={progress.template_columns}
        on:prev={prevState}
        on:finish={finish} />
    {/if}
  </div>
</Modal>
