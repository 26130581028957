<script>
  import { createEventDispatcher } from "svelte";
  import upperFirst from "lodash/upperFirst";
  import AttachmentList from "./AttachmentList.svelte";
  import formatHumanTime from "@local/extensions/formatters/format-human-time";
  import stringIsWhitespace from "@local/extensions/utilities/string-is-whitespace";
  import { profile } from "#src/stores/auth.js";

  export let quote;
  export let rfq;
  export let quoter = false;

  const dispatch = createEventDispatcher();

  let showActionMenu = false;
  let hamburger;
  let showSuperseded = true;

  $: canceled = rfq.status === "canceled" || quote.status === "canceled";

  function formatCents(cents) {
    return (cents / 100).toFixed(2);
  }

  function access(quote) {
    if (quote.organization_id) return "Direct Invitation";
    if (quote.email) return "Email Invitation";
    return "Link";
  }

  function description(quote) {
    if (quote.organization) {
      return quote.organization.name;
    }

    if (quote.email) {
      return quote.email;
    }

    return quote.name;
  }

  function quoterEmail(quote) {
    const sender = quote.sender;
    if (!sender) return quote.organization?.primary_contact_email || "";

    if (sender.username && sender.username !== sender.email) {
      return `${sender.username} (${sender.email})`;
    }

    return sender.email;
  }

  function status(quote) {
    if (quote.is_instant) return "Instant Quote";
    if (quote.status === "pending")
      return rfq.request_for === "order" ? "Awaiting Response" : "Awaiting Quote";
    if (quote.status === "submitted") return `Submitted ${formatHumanTime(quote.sent_at)}`;
    return upperFirst(quote.status);
  }
</script>

<div class="border rounded-md text-xs py-4" class:border-blue-400={quote.status === "received"}>
  {#if $profile?.user_role !== "product_user"}
    {#if quote.superseded_quotes.length}
      {#if showSuperseded}
        <div class="px-4 pb-2 flex justify-between">
          <button class="btn-text" on:click={() => (showSuperseded = false)}>Hide previous</button>
          {#if quoter}
            <button class="btn-text" on:click={() => dispatch("revise-quote")}>Revise</button>
          {/if}
        </div>
        <div class="px-4 border-t border-b bg-gray-50 text-gray-500 divide-y">
          {#each quote.superseded_quotes as sq}
            <div class="space-y-2 py-2">
              <div class="flex items-start justify-between">
                <div>
                  <div>
                    <div class="italic">Submitted {formatHumanTime(sq.sent_at)}</div>
                  </div>
                  <div>
                    {quoterEmail(sq)}
                  </div>
                </div>
                <div class="font-bold">
                  ${formatCents(sq.price)}
                </div>
              </div>
              <div class="flex gap-2 justify-end">
                {#if sq.data.attachments}
                  <AttachmentList attachments={sq.data.attachments} />
                {:else}
                  <span class="italic">No attachments</span>
                {/if}
              </div>
              <div>
                {#if sq.days_valid && !stringIsWhitespace(sq.days_valid)}
                  <div><span class="font-bold">Days Valid: </span>{sq.days_valid}</div>
                {/if}
                {#if sq.sent_at && !stringIsWhitespace(sq.notes)}
                  <div><span class="font-bold whitespace-pre-line">Notes: </span>{sq.notes}</div>
                {/if}
              </div>
            </div>
          {/each}
        </div>
      {:else}
        <div class="px-4 flex justify-between">
          <button class="btn-text" on:click={() => (showSuperseded = true)}>Show previous</button>
          {#if quoter}
            <button class="btn-text" on:click={() => dispatch("revise-quote")}>Revise</button>
          {/if}
        </div>
      {/if}
    {:else if quoter}
      <div class="px-4 flex justify-end">
        <button class="btn-text" on:click={() => dispatch("revise-quote")}>Revise</button>
      </div>
    {/if}
  {/if}
  <div class="px-4 pt-2 space-y-2" class:text-gray-500={canceled}>
    <div class="flex items-start justify-between">
      <div>
        <div class="font-bold">
          {description(quote)}
        </div>
        <div class="text-gray-500">
          {quoterEmail(quote)}
        </div>
      </div>

      <div class="font-bold text-lg">
        {#if (quote.sent_at || quote.is_instant) && quote.price}
          {#if canceled}
            <span class="line-through text-gray-500">
              ${formatCents(quote.price)}
            </span>
          {:else}
            ${formatCents(quote.price)}
          {/if}
        {:else}
          <span class="text-gray-500 italic">$--</span>
        {/if}
      </div>
    </div>

    <div class="flex items-start justify-between">
      <div>
        <!-- <div>{access(quote)}</div> -->
        <div class="italic">{status(quote)}</div>
      </div>
      <div class="flex gap-2 justify-end">
        {#if (quote.sent_at || quote.is_instant) && quote.data.attachments}
          <AttachmentList attachments={quote.data.attachments} />
        {:else}
          <span class="italic">No attachments</span>
        {/if}
      </div>
    </div>
    {#if quote.days_valid && !stringIsWhitespace(quote.days_valid)}
      <div><span class="font-bold">Days Valid: </span>{quote.days_valid}</div>
    {/if}
    {#if (quote.sent_at || quote.is_instant) && !stringIsWhitespace(quote.notes)}
      <div><span class="font-bold whitespace-pre-line">Notes: </span>{quote.notes}</div>
    {/if}
  </div>
</div>
