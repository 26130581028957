<svelte:options strictprops={false} />

<script>
  import { createEventDispatcher } from "svelte";
  import upperFirst from "lodash/upperFirst";
  import LibraryInput from "./LibraryInput.svelte";
  import Viewport from "#lib/drawing/ViewportMinimal.svelte";
  import materialDrawing from "@local/extensions/drawing/material-drawing";
  import imageUrl from "#src/extensions/image-url.js";

  export let product;
  export let application;
  export let disabled;
  export let options = [];

  const dispatch = createEventDispatcher();

  function chooseDefault(record) {
    const newProduct = {
      ...product,
      ...record,
      value: record.id,
    };

    dispatch("update-product", newProduct);
  }
</script>

<div class="pl-4">
  <LibraryInput
    label="Product"
    disabled={disabled || !options.length}
    labelWidth="4rem"
    value={product}
    nullText="Undefined Product"
    library={[{ records: options }]}
    title={upperFirst(application) + "s"}
    on:input={(e) => chooseDefault(e.detail.value)}>
    <svelte:fragment let:record slot="thumbnail">
      {#if application === "surface"}
        <div class="w-full h-full" style="background-color: {record?.color || '#FFFFFF'}">
          {#if record.image}
            <img src={imageUrl(record.image)} alt={record.name} class="text-xs w-full h-full object-cover" />
          {/if}
        </div>
      {:else}
        <Viewport padding={4} drawing={materialDrawing(record)} />
      {/if}
    </svelte:fragment>
    <div slot="caption" let:record>{record?.name}</div>
  </LibraryInput>
</div>
