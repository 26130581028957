<script>
  import { createEventDispatcher, tick } from "svelte";
  import get from "lodash/get";

  import TextInput from "./TextInput.svelte";
  import BooleanInput from "./BooleanInput.svelte";
  import SearchSelect from "./SearchSelect.svelte";
  import SelectInput from "./SelectInput.svelte";
  import EditMenu from "./EditMenu.svelte";

  import SpinnerIcon from "@local/assets/icons/spinner.svg";
  import CheckIcon from "@local/assets/icons/check-filled.svg";
  import CaretDownIcon from "@local/assets/icons/caret-down.svg";
  import CaretUpIcon from "@local/assets/icons/caret-up.svg";
  import CircleXIcon from "@local/assets/icons/circle-x.svg";

  export let data = [];
  export let columns = [];
  export let newRow = null;
  export let headers = true;
  export let archivable = false;
  export let unarchivable = false;
  export let deletable = false;
  export let cloneable = false;
  export let clickable = false;
  export let editable = false;
  export let visitable = false;
  export let sortable = false;
  export let sortBy = null;
  export let sortDirection = null;
  export let size = "sm";
  export let sticky = false;
  export let selected = {};
  export let loadingNewRow = false;
  export let rowComponent = null;
  export let actionsComponent = null;
  export let emptyText = "No data";
  export let fixed = false;

  const dispatch = createEventDispatcher();
  const inputOptions = {
    text: TextInput,
    boolean: BooleanInput,
    search: SearchSelect,
    select: SelectInput,
  };

  const fontSizes = {
    xxxs: "0.55rem",
    xxs: "0.65rem",
    xs: "0.75rem",
    sm: "0.875rem",
    base: "1rem",
    lg: "1.125rem",
  };

  const lineHeights = {
    xxxs: "0.65rem",
    xxs: "0.75rem",
    xs: "1rem",
    sm: "1.25rem",
    base: "1.5rem",
    lg: "1.75rem",
  };

  const padding = {
    xxs: "0.3rem",
    xs: "0.4rem",
    sm: "0.5rem",
    base: "0.6rem",
    lg: "0.75rem",
  };

  const hdrSize = {
    xxs: "xxxs",
    xs: "xxs",
    sm: "xs",
    base: "sm",
    lg: "base",
  };

  const rowHeights = {
    xxxs: "1.1rem",
    xxs: "1.15rem",
    xs: "1.4rem",
    sm: "2rem",
    base: "2.1rem",
    lg: "2.5rem",
  };

  let inputElements = [];
  let inputElementsTouched = [];
  let newRowLocal = null;
  let confirm;

  $: sortedData = data || [];
  $: makeNewRow(newRow);
  $: valid = validate(inputElements, columns, newRowLocal);
  $: setInputState(inputElements);
  $: showEditMenu =
    actionsComponent ||
    deletable ||
    cloneable ||
    visitable ||
    archivable ||
    unarchivable ||
    $$slots.rowactions;

  function validate(inputElements, columns, newRowLocal) {
    return inputElements.every((e, i) => {
      if (!e) return true;
      const column = columns[i];
      if (!column.required) return true;
      const value = newRowLocal && newRowLocal[column.key || column.prop];
      return !!value;
    });
  }

  function setInputState(inputElements) {
    inputElementsTouched = inputElements.map((e) => false);
  }

  function clickHeader(column) {
    if (!sortable) return;
    if (column.sortable !== undefined && !column.sortable) return;
    dispatch("sort", { column });
  }

  function makeNewRow(r) {
    if (r) {
      newRowLocal = Object.keys(r).reduce((obj, key) => {
        const column = columns.find((c) => c.key === key || c.prop === key);
        if (!column || column.editable === false) {
          return { ...obj, [key]: r[key] };
        }

        return { ...obj, [key]: undefined };
      }, {});
      focusNext();
    }
  }

  async function focusNext(evt) {
    const key = evt && evt.detail && evt.detail.key;

    await tick();
    const element = inputElements.find((e, i) => {
      if (!e) return false;
      if (inputElementsTouched[i]) return false;
      const column = columns[i];
      const value = newRowLocal[column.key || column.prop];
      return !value;
    });

    if (element) {
      element.focus();
    } else if (valid && key === "Enter") {
      addRow();
    }
  }

  function touch(index) {
    inputElementsTouched[index] = true;
  }

  function keys(e) {
    return {
      ctrlKey: e.ctrlKey,
      shiftKey: e.shiftKey,
      metaKey: e.metaKey,
    };
  }

  function clickRow(id, index, evt) {
    dispatch("clickrow", { id, index, event: keys(evt) });
  }

  function dblclickRow(id, index, evt, datum) {
    dispatch("dblclickrow", { id, index, event: keys(evt), row: datum });
  }

  function addRow() {
    if (valid) {
      dispatch("addrow", { row: newRowLocal });
    } else {
      focusNext();
    }
  }

  function stopAddingRow() {
    dispatch("stopaddingrow");
  }

  function format(datum, column) {
    const v = get(datum, column.prop);
    if (column.formatter) return column.formatter(v, datum);
    if (column.type === "boolean") return v ? "✓" : "";
    if (v === null || v === undefined) return "";
    return v;
  }

  function updateValue(id, prop, value) {
    dispatch("updaterow", { id, prop, value });
  }

  function clickEditMenu(id, action, index, datum) {
    dispatch(action, { id, index, row: datum });
  }
</script>

<table
  class="w-full border-collapse"
  class:table-auto={!fixed}
  class:table-fixed={fixed}
  style:font-size={fontSizes[size]}
  style:line-height={lineHeights[size]}>
  <colgroup>
    {#each columns as column}
      <col class={column.class} />
    {/each}
  </colgroup>
  {#if columns?.length && headers}
    <tr class="bg-white">
      {#each columns as column}
        <th
          class="text-black text-left top-0 bg-white z-10 p-0"
          class:sticky
          style:font-size={fontSizes[hdrSize[size]]}
          on:click={() => clickHeader(column)}
          style:line-height={lineHeights[hdrSize[size]]}>
          <div
            class="flex w-full h-full items-center justify-between border-b border-gray-400"
            style:padding={padding[size]}>
            <div class="truncate">
              {#if column.label}
                {column.label.toUpperCase()}
              {:else}
                &nbsp;
              {/if}
            </div>
            {#if sortBy === column.prop}
              {#if sortDirection === "asc"}
                <div><CaretUpIcon /></div>
              {:else}
                <div><CaretDownIcon /></div>
              {/if}
            {/if}
          </div>
        </th>
      {/each}
      {#if showEditMenu}
        <th
          class="text-right top-0 bg-white z-10 p-0"
          class:sticky
          style:font-size={fontSizes[hdrSize[size]]}
          style:line-height={lineHeights[hdrSize[size]]}>
          <div class="flex w-full h-full items-center border-b border-gray-400" style:padding={padding[size]}>
            <div>&nbsp;</div>
          </div>
        </th>
      {/if}
    </tr>
  {/if}

  {#if newRow}
    <tr class="border-b bg-amber-50">
      {#each columns as column, index}
        {#if column.editable !== undefined ? column.editable : true}
          <td>
            <svelte:component
              this={inputOptions[column.type]}
              {size}
              bind:this={inputElements[index]}
              bind:value={newRowLocal[column.key || column.prop]}
              bind:defaultValue={newRow[column.key || column.prop]}
              newRow
              {column}
              on:next={focusNext}
              on:touch={() => touch(index)} />
          </td>
        {:else if column.type === "color"}
          <td style:padding={padding[size]}>
            <div
              class="w-4 h-4 rounded"
              style="background-color:{newRowLocal[column.key || column.prop] || '#FFF'};" />
          </td>
        {:else}
          <td style:padding={padding[size]}>
            {format(newRow, column)}
          </td>
        {/if}
      {/each}
      <td style:padding={padding[size]}>
        <div class="px-2 w-full h-full flex gap-2 justify-end">
          <button class="text-green-500" on:click={addRow} bind:this={confirm}>
            <CheckIcon />
          </button>
          <button class="text-red-500" on:click={stopAddingRow}>
            <CircleXIcon />
          </button>
        </div>
      </td>
    </tr>
  {:else if loadingNewRow}
    <tr class="border-b bg-amber-50">
      <td colspan={columns.length + 1} style:padding={padding[size]}>
        <div class="flex items-center gap-2" style:height={rowHeights[size]}>
          <div class="relative animate-spin">
            <SpinnerIcon />
          </div>
          <div>Loading...</div>
        </div>
      </td>
    </tr>
  {/if}
  {#if sortedData.length === 0 && !newRow}
    <tr class="border-b">
      <td style:padding={padding[size]}>
        {#if $$slots.nocontent}
          <slot name="nocontent" />
        {:else}
          <div class="italic">{emptyText}</div>
        {/if}
      </td>
    </tr>
  {/if}
  {#each sortedData as datum, index (datum.id)}
    {#if rowComponent}
      <tr
        class="border-b"
        class:selected={selected[datum.id]}
        on:click={(e) => clickRow(datum.id, index, e)}
        on:dblclick={(e) => dblclickRow(datum.id, index, e, datum)}>
        <svelte:component this={rowComponent} {datum} selected={selected[datum.id]} />
        {#if showEditMenu}
          <td style:padding-left={padding[size]} style:padding-right={padding[size]} class="text-right w-8">
            <EditMenu
              {datum}
              {actionsComponent}
              {archivable}
              {unarchivable}
              {deletable}
              {cloneable}
              {visitable}
              on:click={(e) => clickEditMenu(datum.id, e.detail.value, index, datum)}>
              <slot name="rowactions" item={datum} />
            </EditMenu>
          </td>
        {/if}
      </tr>
    {:else}
      <tr
        class="border-b"
        class:clickable
        class:selected={selected[datum.id]}
        on:click={(e) => clickRow(datum.id, index, e)}
        on:dblclick={(e) => dblclickRow(datum.id, index, e, datum)}>
        {#each columns as column}
          {#if editable && column.editable}
            <td>
              <svelte:component
                this={inputOptions[column.type] || TextInput}
                {size}
                bind:value={datum[column.key || column.prop]}
                bind:defaultValue={datum[column.key || column.prop]}
                {column}
                on:update={(e) => updateValue(datum.id, column.prop, e.detail)} />
            </td>
          {:else if column.type === "color"}
            <td style:padding={padding[size]}>
              <div
                class="w-4 h-4 rounded"
                style="background-color:{datum[column.key || column.prop] || '#FFF'};" />
            </td>
          {:else}
            <td style:padding={padding[size]}>
              {format(datum, column)}
            </td>
          {/if}
        {/each}
        {#if showEditMenu}
          <td style:padding={padding[size]} class="text-right">
            <EditMenu
              {datum}
              {actionsComponent}
              {archivable}
              {unarchivable}
              {deletable}
              {cloneable}
              {visitable}
              on:click={(e) => clickEditMenu(datum.id, e.detail.value, index, datum)}>
              <slot name="rowactions" item={datum} />
            </EditMenu>
          </td>
        {/if}
      </tr>
    {/if}
  {/each}
</table>

<style lang="scss">
  tr {
    &.clickable:hover:not(.selected) {
      @apply bg-gray-100 cursor-pointer;
    }

    &.selected {
      @apply bg-blue-100;
    }
  }
</style>
