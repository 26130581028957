<script>
  import { createEventDispatcher } from "svelte";
  import FlagIcon from "@local/assets/icons/flag.svg";
  import FlagFilledIcon from "@local/assets/icons/flag-filled.svg";

  export let border = false;
  export let label = "Label";
  export let labelWidth = null;
  export let value;
  export let disabled = false;
  export let readonly = false;

  const dispatch = createEventDispatcher();
  let button;
  let focused = false;

  $: lw = labelWidth ? `width:${labelWidth};` : "";

  export const focus = () => {
    button.focus();
  };

  function handleFocus(evt) {
    focused = true;
  }

  function blur() {
    focused = false;
  }

  function toggleValue() {
    let newValue = "approved";
    if (!value || value === "none") {
      newValue = "approved";
    } else if (value === "approved") {
      newValue = "flagged";
    } else {
      newValue = "none";
    }
    value = newValue;
    dispatch("input", { value: newValue });
  }
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class="prop-container"
  class:focused
  class:readonly
  class:visible-border={border}
  on:click|stopPropagation={focus}>
  {#if label !== null}
    <div class="label" style={lw}>
      <slot name="label">
        {label}
      </slot>
    </div>
  {/if}
  <button
    class="button"
    {disabled}
    bind:this={button}
    on:focus={handleFocus}
    on:blur={blur}
    on:click={toggleValue}
    class:text-gray-500={!value || value === "none" || value === "Mixed"}
    class:text-red-500={value === "flagged"}
    class:text-green-500={value === "approved"}>
    {#if value && value !== "none"}
      <FlagFilledIcon />
    {:else}
      <FlagIcon />
    {/if}
  </button>
</div>

<style lang="scss">
  .prop-container {
    @apply px-2 py-2 w-full flex items-center rounded bg-white;
    &:hover:not(.readonly):not(.focused),
    &.visible-border {
      @apply ring-1 ring-inset ring-gray-300;
    }
    &.focused {
      @apply ring-2 ring-inset ring-blue-500;
    }
    .label {
      @apply truncate text-gray-400 flex-none;
    }
    .button {
      @apply min-w-0 bg-transparent ml-2;
    }
  }
</style>
