<script>
  import isEmpty from "lodash/isEmpty";
  import { Link } from "svelte-routing";
  import CircleIcon from "@local/assets/icons/circle.svg";
  import CircleFilledIcon from "@local/assets/icons/circle-filled.svg";

  export let assignments = null;
  export let record;
  export let group = null;
  export let wrapText = false;

  let showAllAssigned = false;

  $: assignedItems = assignments ? Object.keys(assignments) : [];
  $: visibleAssigned = showAllAssigned ? assignedItems : assignedItems.slice(0, 5);

  function description(record) {
    if (record.mark && record.name) {
      return `${record.mark} - ${record.name}`;
    }

    if (record.mark) {
      return record.mark;
    }

    if (record.name) {
      return record.name;
    }

    return "Unnamed";
  }
</script>

<div class="relative h-24 sm:h-36 w-full bg-gray-100 border border-gray-300 content-box">
  <slot />
</div>
<div class="flex items-center text-xs gap-1">
  {#if assignments}
    <div
      class="relative in-use-container"
      class:text-blue-500={!isEmpty(assignments)}
      class:text-gray-300={isEmpty(assignments)}>
      {#if !isEmpty(assignments)}
        <CircleFilledIcon />
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <div
          class="in-use-list absolute z-20 w-48 top-0 left-0 p-4 -ml-4"
          on:mouseleave={() => (showAllAssigned = false)}
          on:touchend={() => (showAllAssigned = false)}>
          <div class="w-full rounded bg-white border drop-shadow-lg p-2 text-xs text-black space-y-2">
            <div>
              <div class="flex flex-wrap">
                <div class="mr-1">Used by:</div>
                {#each visibleAssigned as itemid, index}
                  <Link to={`items/${itemid}`} class="text-blue-500">{$group.items[itemid].mark}</Link>
                  {#if index < visibleAssigned.length - 1}<div>,&nbsp;</div>{/if}
                {/each}
              </div>
              {#if assignedItems.length > visibleAssigned.length}
                <button class="cursor-pointer" on:click={() => (showAllAssigned = true)}>
                  ...and {assignedItems.length - visibleAssigned.length} more.
                </button>
              {/if}
            </div>
          </div>
        </div>
      {:else}
        <CircleIcon />
      {/if}
    </div>
  {/if}
  <div class="grow" class:truncate={!wrapText}>{description(record)}</div>
</div>

<style lang="scss">
  .in-use-list {
    display: none;
  }

  .in-use-container:hover {
    .in-use-list {
      display: block;
    }
  }
</style>
