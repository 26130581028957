<script>
  import { createEventDispatcher } from "svelte";
  import SendIcon from "@local/assets/icons/share.svg";
  import TrashIcon from "@local/assets/icons/trash.svg";

  export let datum;

  const dispatch = createEventDispatcher();
</script>

{#if !datum.used_at}
  <button class="dropdown-button-item" on:click={() => dispatch("click", { value: "resendrow" })}>
    <SendIcon />
    Resend
  </button>
{/if}
<button class="dropdown-button-item" on:click={() => dispatch("click", { value: "deleterow" })}>
  <TrashIcon />
  Delete
</button>
