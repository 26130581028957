<svelte:options strictprops={false} />

<script>
  import { getContext, createEventDispatcher, tick } from "svelte";
  import { Popover } from "svelte-utilities";
  import CheckIcon from "@local/assets/icons/check.svg";
  import OptionRenderer from "./OptionRenderer.svelte";
  import { exitEvents, directions } from "./constants.js";

  const dispatch = createEventDispatcher();

  export let value;
  export let optionMap;
  export let readOnly = false;
  export let disabled = false;
  export let options;
  export const focus = () => {
    container.focus();
  };

  const { editing, focused } = getContext("datagrid");

  const editingExitEvents = { ArrowRight: true, ArrowLeft: true };

  let container;
  let selectedOption = null;

  async function edit() {
    $editing = true;
    await tick();
    container.focus();
  }

  function click(e) {
    edit();
  }

  function keydown(evt) {
    if (evt.key !== "Tab") evt.preventDefault();
    if ($editing) {
      if (evt.key === "Enter") {
        if (selectedOption !== null) {
          chooseOption(options[selectedOption]);
        } else {
          $editing = false;
        }
      } else if (evt.key === "ArrowDown") {
        if (selectedOption === null) {
          selectedOption = 0;
        } else if (options.length > selectedOption + 1) {
          selectedOption += 1;
        }
      } else if (evt.key === "ArrowUp") {
        if (selectedOption > 0) selectedOption -= 1;
      } else if (editingExitEvents[evt.key]) {
        focused.move(directions[evt.key], evt.shiftKey);
      }
    } else {
      if (evt.key === "Enter") {
        edit();
      } else if (exitEvents[evt.key]) {
        focused.move(directions[evt.key], evt.shiftKey);
      }
    }
  }

  function chooseOption(option) {
    if (!readOnly && !disabled) {
      value = option;
      dispatch("updateValue", { value: option });
    }
    $editing = false;
  }

  async function blur(e) {
    // $editing = false;
    // TODO: find a way to make this behave correctly in both FF
    // and chrome.
    // if ($focused.row === row && $focused.col === column) {
    //   focused.blur();
    // }
  }
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<div
  class="container absolute w-full h-full select-none"
  bind:this={container}
  tabindex="0"
  on:keydown={keydown}
  on:click={click}
  on:blur={blur}>
  <OptionRenderer {value} {optionMap} {readOnly} {disabled} />

  {#if $editing && !(readOnly || disabled)}
    <Popover {container}>
      <div class="rounded border bg-white drop-shadow-lg py-1 text-xs">
        {#each options as option, index}
          <li
            class="option-item flex p-1 hover:bg-gray-100 items-center cursor-pointer"
            class:selected={index === selectedOption}
            on:mousedown|stopPropagation
            on:click|stopPropagation={() => chooseOption(option)}>
            <div class="flex-none w-6">
              {#if option === value}
                <CheckIcon />
              {/if}
            </div>
            <div class="truncate">{optionMap[option]}</div>
          </li>
        {/each}
      </div>
    </Popover>
  {/if}
</div>

<style lang="scss">
  .container {
    &:focus {
      outline: none;
    }
  }

  li.option-item.selected {
    @apply bg-blue-400 text-white;
  }
</style>
