<script>
  import { createEventDispatcher } from "svelte";
  import TextInput from "./TextInput.svelte";
  import ContenteditableInput from "./ContenteditableInput.svelte";
  import ImageInput from "./ImageInput.svelte";
  import BooleanInput from "./BooleanInput.svelte";
  import JSONInput from "./JSONInput.svelte";
  import CategoryInput from "#lib/CategoryInput.svelte";
  import imageUrl from "#src/extensions/image-url.js";
  import CopyTextField from "./CopyTextField.svelte";

  import { api } from "#src/api";
  import { profile } from "#src/stores/auth.js";

  export let product;
  export let disabled;
  export let org;

  const dispatch = createEventDispatcher();

  let categoryModal;

  $: multiple = Array.isArray(product.id);

  function update(prop, value) {
    dispatch("update", { id: product.id, prop, value });
  }

  async function uploadImage(e) {
    if (e.detail.type && e.detail.data) {
      const uuid = crypto.randomUUID();
      const filename = `${uuid}/${e.detail.filename}`;
      const { data: result, error } = await api.storage.from("images").upload(filename, e.detail.data, {
        contentType: e.detail.contentType,
      });

      if (!error) {
        const image = {
          id: uuid,
          bucket: "images",
          object_id: result.path,
          content_type: e.detail.contentType,
          filename: e.detail.filename,
        };
        dispatch("update", { id: product.id, prop: "image", value: image });
      }
    }
  }
</script>

<div class="text-xs px-4">
  {#if !multiple}
    <ImageInput
      label="Image"
      labelWidth="6rem"
      value={imageUrl(product.image)}
      accept="image/png, image/jpeg"
      uploadType="arraybuffer"
      {disabled}
      on:upload={uploadImage}
      on:delete={() => update("image", null)} />
  {/if}
  <ContenteditableInput
    label="Name"
    {disabled}
    labelWidth="6rem"
    value={product.name}
    on:input={(evt) => update("name", evt.detail.value)} />
  <ContenteditableInput
    label="Description"
    {disabled}
    labelWidth="6rem"
    value={product.description}
    on:input={(evt) => update("description", evt.detail.value)} />
  <CategoryInput
    label="Category"
    {disabled}
    labelWidth="6rem"
    {product}
    orgid={$org.id}
    on:input={(e) => update(e.detail.prop, e.detail.value)} />
  <!-- <div class="flex items-center overflow-hidden">
    <div class="grow overflow-hidden">
      <ContenteditableInput
        label="Category"
        {disabled}
        labelWidth="6rem"
        value={product.category}
        on:input={(evt) => update("category", evt.detail.value)} />
    </div>
    {#if !disabled}
      <button class="flex-none px-1" on:click={() => categoryModal.open()}>
        <EllipsisIcon />
      </button>
    {/if}
  </div> -->
  <TextInput
    label="Classification"
    {disabled}
    labelWidth="6rem"
    value={product.classification}
    on:input={(evt) => update("classification", evt.detail.value)} />
  <TextInput
    label="Vendor Code"
    {disabled}
    labelWidth="6rem"
    value={product.vendor_code}
    on:input={(evt) => update("vendor_code", evt.detail.value)} />
  <BooleanInput
    label="Published"
    {disabled}
    labelWidth="6rem"
    value={product.published}
    on:input={(evt) => update("published", evt.detail.value)} />
  <BooleanInput
    label="Item Drawing"
    {disabled}
    labelWidth="6rem"
    value={product.use_item_drawing_as_thumbnail}
    on:input={(evt) => update("use_item_drawing_as_thumbnail", evt.detail.value)} />
  <BooleanInput
    label="Type Image"
    {disabled}
    labelWidth="6rem"
    value={product.use_type_image_as_thumbnail}
    on:input={(evt) => update("use_type_image_as_thumbnail", evt.detail.value)} />
  <JSONInput
    label="Data"
    {disabled}
    labelWidth="6rem"
    value={product.data}
    on:input={(evt) => update("data", evt.detail.value)} />
  <JSONInput
    label="Item Template"
    {disabled}
    labelWidth="6rem"
    value={product.item_template}
    on:input={(evt) => update("item_template", evt.detail.value)} />
  <JSONInput
    label="Template Mask"
    {disabled}
    labelWidth="6rem"
    value={product.item_template_mask}
    on:input={(evt) => update("item_template_mask", evt.detail.value)} />
  {#if $profile?.user_role === "developer"}
    <TextInput
      label="Script ID"
      {disabled}
      labelWidth="6rem"
      parser={(v) => (v ? v : null)}
      value={product.item_script_id}
      on:input={(evt) => update("item_script_id", evt.detail.value)} />
  {/if}
  {#if $profile?.user_role === "developer" && !multiple}
    <!-- <JSONInput label="Item Script" disabled labelWidth="6rem" value={product.item_script} /> -->
    <CopyTextField label="ID" labelWidth="6rem" value={product.id} />
  {/if}
  {#if $profile?.user_role === "developer"}
    <TextInput
      label="Pricing Group"
      {disabled}
      labelWidth="6rem"
      value={product.pricing_group}
      on:input={(evt) => update("pricing_group", evt.detail.value)} />
    <TextInput
      label="Freight Group"
      {disabled}
      labelWidth="6rem"
      value={product.freight_group}
      on:input={(evt) => update("freight_group", evt.detail.value)} />
  {/if}
</div>
