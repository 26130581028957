<script>
  import RfqDetail from "#lib/home/RfqDetail.svelte";
  import ProductRfqDetail from "#lib/home/ProductRfqDetail.svelte";
  import MultiDetail from "#lib/home/MultiDetail.svelte";

  import { rfqs, filteredRfqs, selectedRfqIds } from "#src/stores/rfqs.js";
  import { api } from "#src/api";

  $: selectedRfq = $selectedRfqIds.map((id) => $filteredRfqs[id]);

  async function archiveSelected() {
    await rfqs.archive(...$selectedRfqIds);
  }

  async function assignSelected(e) {
    const { ids, assigned_to_id } = e.detail;
    await api.from("quote_requests").update({ assigned_to_id }).in("id", ids);
    await rfqs.fetchById(...$selectedRfqIds);
  }

  async function clearNotifications(e) {
    const { notifications } = e.detail;

    await api.from("notifications").delete().in("id", notifications);

    await rfqs.fetchById(...$selectedRfqIds);
  }
</script>

<div class="h-full overflow-y-auto">
  {#if selectedRfq}
    {#if selectedRfq.length === 1}
      {@const sel = selectedRfq[0]}
      {#if sel}
        {#key sel?.id}
          {#if sel?.project_type === "product"}
            <ProductRfqDetail rfqid={sel.id} on:delete-rfq on:clone-rfq />
          {:else}
            <RfqDetail rfqid={sel.id} on:delete-rfq on:clone-rfq />
          {/if}
        {/key}
      {/if}
    {:else if selectedRfq.length > 1}
      <MultiDetail
        records={selectedRfq}
        on:archive={archiveSelected}
        on:assign={assignSelected}
        on:clear-notifications={clearNotifications} />
    {/if}
  {:else}
    <div class="p-4 italic text-sm">No RFQ selected.</div>
  {/if}
</div>
