import { VANDELAY_URL /* DXF_URL, DXF_DEBUG, DXF_USE_R12 */ } from "#src/env";
import { Drawing } from "drawing";
import Polyface from "@local/extensions/geometry/polyface.js";
import itemTagGenerator from "@local/extensions/utilities/item-tag.js";

const OutputLayer = {
  GLASS: "Glass",
  FAB: "FAB",
}

export function drawingsToDXM(group) {
  const { fabrications } = group.data;
  const { items } = group;

  const itemTag = itemTagGenerator(items);

  const drawings = items.order.reduce((res, id) => {
    const item = items[id];

    if (item.width && item.height) {
      const filename = itemTag(id);

      const polyface = new Polyface(item, fabrications);
      const ctx = {};

      const pf = polyface.perimeterFabs;
      const voids = polyface.voidEntities;

      const outline = new Drawing().polyface(polyface.shape).layer(OutputLayer.GLASS);
      const ppls = pf.paths.map((v) => new Drawing().polyline(v));
      const vpgs = pf.voidpaths.map((v) => new Drawing().polygon(v));
      const ventities = voids.map((v) => {
        if (v.type === "polygon") {
          return new Drawing().polygon(v.vertices);
        } else if (v.type === "circle") {
          return new Drawing().circle(v.center, v.radius);
        }
      });
      const fabs = new Drawing()
        .add(...ppls, ...vpgs, ...ventities)
        .layer(OutputLayer.FAB);

      const drawing = new Drawing().add(outline, fabs);
      drawing.render({
        ctx,
        type: "segment-list",
      });

      ctx.units = "Inches";
      ctx.filename = `drawings/${filename}.dxf`;
      ctx.explode_all = true;
      ctx.layers = [
        { name: OutputLayer.GLASS, color: 0 },
        { name: OutputLayer.FAB, color: 2 },
      ];
      res.push(ctx);
    }

    return res;
  }, []);

  return drawings;
}

export async function createDrawingSet(drawings) {
  const response = await fetch(`${VANDELAY_URL}/dxf/drawings/bulk`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(drawings),
  });

  return await response.json();
}

export async function fetchDrawingSet(drawings) {
  const dxfResponse = await fetch(`${VANDELAY_URL}/dxf/drawings/bulk`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(drawings),
  });

  const members = await dxfResponse.json();

  const zipRequest = {
    members,
  };

  const zipResponse = await fetch(`${VANDELAY_URL}/zip/archives`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(zipRequest),
  });
  console.log(zipResponse);

  if (zipResponse.status == 201) {
    const location = zipResponse.headers.get("location");

    return await fetch(`${VANDELAY_URL}/${location}`, {
      method: "GET",
    });
  } else {
    const responseText = await zipResponse.text();
    console.log("ZIP", zipResponse.status);
    console.log("ZIP", responseText);
  }
}
