<script>
  import { onMount, getContext } from "svelte";
  import { some } from "overline/iterable";

  import { Modal } from "svelte-utilities";
  import FileIcon from "@local/assets/icons/file.svg";
  import FileIconPdf from "@local/assets/icons/file-pdf.svg";
  import SpinnerIcon from "@local/assets/icons/spinner-lg.svg";
  import FileIconMultiple from "@local/assets/icons/file-multiple.svg";
  import { downloadJobBundle } from "#src/extensions/download-job-bundle";
  import eb from "#src/extensions/event-bus.js";
  import { profile } from "#src/stores/auth.js";

  export let title = "Download Job Contents";
  export let group;
  export let types;
  export let name;
  export let rfq = null;
  export let quote = null;
  export const open = () => openModal;

  const edgeTreatments = getContext("edgeTreatments");

  let modal;
  let downloading = false;
  let includeSummary = false;
  let includeData = true;
  let includeCAD = false;
  let includeBOM = false;

  $: groupHasAttachments = hasAttachments($group) || hasDrawings($group);

  let includeAttachments = groupHasAttachments;

  function hasAttachments(group) {
    return group?.attachments?.length > 0;
  }

  function hasDrawings(group) {
    if (group && group.items) {
      return some(group.items, (item) => !!item.drawing);
    }
    return false;
  }

  async function openModal() {
    modal.open();
  }

  async function downloadBundle() {
    downloading = true;
    await downloadJobBundle(
      $group,
      types,
      { edgeTreatments: $edgeTreatments },
      {
        productQuote: $group?.project_type === "product",
        rfq,
        quote,
        filename: `${name}`,
        includeSummary,
        includeData,
        includeCAD,
        includeAttachments,
        includeBOM,
      },
      $profile,
    );
    modal.close();
    downloading = false;
  }

  onMount(() => {
    eb.on("open-download", openModal);

    return () => {
      eb.unsubscribe("open-download", openModal);
    };
  });
</script>

<Modal
  bind:this={modal}
  closeable
  on:confirm={downloadBundle}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Download", type: "confirm", style: "primary", closeOnClick: false },
  ]}>
  <div slot="title">{title}</div>
  <div slot="content" class="space-y-4">
    {#if downloading}
      <div>
        Downloading...
        <!-- <div class="animate-spin w-4 h-4">
      <SpinnerIcon />
    </div> -->
      </div>
    {:else}
      <div>Include the following in the download bundle:</div>
      <div class="flex gap-2 justify-center items-center text-xs">
        <div class="flex flex-col items-center w-16 gap-2">
          <FileIconPdf />
          <div>Summary</div>
          <input type="checkbox" bind:checked={includeSummary} />
        </div>
        <div class="flex flex-col items-center w-16 gap-2">
          <FileIcon />
          <div>Excel</div>
          <input type="checkbox" bind:checked={includeData} />
        </div>
        <div class="flex flex-col items-center w-16 gap-2">
          <FileIconMultiple />
          <div>CAD</div>
          <input type="checkbox" bind:checked={includeCAD} />
        </div>
        <div class="flex flex-col items-center w-16 gap-2">
          <FileIcon />
          <div>BOM</div>
          <input type="checkbox" bind:checked={includeBOM} />
        </div>
        <div class="flex flex-col items-center w-16 gap-2">
          <FileIconMultiple />
          <div>Attachments</div>
          <input type="checkbox" bind:checked={includeAttachments} disabled={!groupHasAttachments} />
        </div>
      </div>
    {/if}
  </div>
</Modal>
