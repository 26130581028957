<script>
  import { createEventDispatcher } from "svelte";
  import EditIcon from "@local/assets/icons/edit.svg";
  import ExitIcon from "@local/assets/icons/exit.svg";
  import ExportIcon from "@local/assets/icons/export.svg";
  import DownloadIcon from "@local/assets/icons/download.svg";
  import DuplicateIcon from "@local/assets/icons/duplicate.svg";
  import TrashIcon from "@local/assets/icons/trash.svg";
  import ArchiveIcon from "@local/assets/icons/archive.svg";
  import UnarchiveIcon from "@local/assets/icons/unarchive.svg";
  import XIcon from "@local/assets/icons/x.svg";
  import PaperclipIcon from "@local/assets/icons/paperclip.svg";

  export let icon = null;
  export let label = "";

  const icons = {
    edit: EditIcon,
    exit: ExitIcon,
    export: ExportIcon,
    download: DownloadIcon,
    duplicate: DuplicateIcon,
    trash: TrashIcon,
    archive: ArchiveIcon,
    unarchive: UnarchiveIcon,
    link: PaperclipIcon,
    x: XIcon,
  };

  const dispatch = createEventDispatcher();
</script>

<button class="dropdown-button-item" on:click={() => dispatch("click")}>
  <slot>
    {#if icon}
      <svelte:component this={icons[icon]} />
    {/if}
    <div class="whitespace-nowrap">{label}</div>
  </slot>
</button>
