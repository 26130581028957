<script>
  import isEmpty from "lodash/isEmpty";
  import CircleXIcon from "@local/assets/icons/circle-x.svg";
  import HideIcon from "@local/assets/icons/hide.svg";
  import ShowIcon from "@local/assets/icons/show.svg";
  import { draggable } from "svelte-utilities";

  export let data = [];
  export let options;
  export let values;
  export let fixed = [];
  export let columns;
  export let allowMultiple = false;
  export let hiddenColumns = {};

  let draggingCol = null;
  let draggingRow = null;
  let dragging = null;
  let dropRow;

  $: unassigned = options.map((s, index) => ({ ...s, index })).filter((s, i) => values[i] === null);
  $: headers = data[0].map((label, index) => ({ label, index })).filter((h, i) => !hiddenColumns[i]);
  $: rest = data.slice(1);
  $: isDragging = dragging !== null;
  $: assignments = values.reduce((a, v, index) => {
    if (v !== null) {
      a[v] = { col: columns[index], index };
    }
    return a;
  }, {});
  $: mAssignments = getAssignments(fixed, values);
  $: maxAssignments = Math.max(...Object.values(mAssignments).map((a) => a.length), 0);
  $: dropRows = Array.from({ length: maxAssignments + 1 }, (_, i) => i);

  function getAssignments(fixed, values) {
    const fa = fixed.reduce((a, c) => {
      if (!a[c.index]) {
        a[c.index] = [];
      }
      a[c.index].push({ col: c, index: c.index, fixed: true });

      return a;
    }, {});
    return values.reduce((a, v, index) => {
      if (v !== null) {
        if (!a[v]) {
          a[v] = [];
        }
        a[v].push({ col: columns[index], index });
      }

      return a;
    }, fa);
  }

  function tableMouseup(e) {
    const td = e.target.closest("td, th");
    let col;
    const tdCol = td && td.getAttribute("data-col");
    if (tdCol !== undefined && tdCol !== null) {
      col = parseInt(tdCol);
    }

    if (col !== undefined && !isNaN(col) && isDragging) {
      if (!isDragging) return;

      if (values.includes(col) && !allowMultiple) {
        const r = values.indexOf(col);
        values[col] = null;
        values[dragging] = col;
      } else {
        values[dragging] = col;
      }

      dragging = null;
    }
  }

  function drag(colIndex) {
    if (!assignments[colIndex]) return;
    draggingCol = colIndex;
  }

  function dragend(e) {
    const droppedInside = dropRow.contains(e.detail.event.target);

    if (draggingCol !== null && !droppedInside) {
      const a = assignments[draggingCol];
      values[a.index] = null;
    }

    draggingCol = null;
    dragging = null;
  }

  async function drop(dropCol) {
    if (draggingCol === null) return;
    if (dragging !== null) return;

    if (draggingCol === dropCol) {
      draggingCol = null;
      return;
    }

    const a = assignments[draggingCol];
    const b = assignments[dropCol];

    values[a.index] = dropCol;
    if (b) values[b.index] = null;

    draggingCol = null;
    dragging = null;
  }

  function multipleDrag(header, stack) {
    if (!mAssignments[header]?.[stack]) return;
    draggingCol = header;
    draggingRow = stack;
  }

  function multipleDragend(e) {
    const droppedInside = dropRow.contains(e.detail.event.target);
    const inDocument = document.contains(e.detail.event.target);

    if (draggingCol !== null && draggingRow !== null && !droppedInside && inDocument) {
      const a = mAssignments[draggingCol]?.[draggingRow];
      values[a.index] = null;
    }

    draggingCol = null;
    draggingRow = null;
    dragging = null;
  }

  function multipleDrop(header, stack) {
    if (draggingCol === null) return;
    if (draggingRow === null) return;
    if (dragging !== null) return;

    if (draggingCol === header && draggingRow === stack) {
      draggingCol = null;
      draggingRow = null;
      return;
    }

    const a = mAssignments[draggingCol]?.[draggingRow];
    values[a.index] = header;
    draggingCol = null;
    draggingRow = null;
    dragging = null;
  }

  function dragOption(index) {
    dragging = index;
  }

  function dragendOption() {
    dragging = null;
  }

  function removeColumnAssignment(index) {
    values[index] = null;
    dragging = null;
  }

  function hideColumn(index) {
    hiddenColumns[index] = true;
  }

  function unhideAll() {
    hiddenColumns = {};
  }
</script>

<div class="ml-12 flex items-center flex-wrap mb-4 gap-2 text-xs">
  {#each unassigned as field (field.index)}
    <button
      class="p-1 rounded border border-gray-300 cursor-grab flex items-center space-x-2"
      class:border-dashed={dragging === field.index}
      class:cursor-grabbing={dragging !== null}
      use:draggable
      on:drag={() => dragOption(field.index)}
      on:dragend={dragendOption}>
      <div class:invisible={dragging === field.index}>
        {field.label}
      </div>
    </button>
  {/each}
  <slot name="new-button" />
</div>

<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<div class="overflow-auto pt-2">
  <table class="w-full table-fixed border-collapse text-xs" on:mouseup={tableMouseup}>
    <colgroup>
      <col class="bg-gray-100 w-12" />
      {#each headers as col}
        <col style="min-width:6rem;" class="hover:bg-gray-200" />
      {/each}
    </colgroup>
    {#if allowMultiple}
      <thead bind:this={dropRow}>
        {#each dropRows as d}
          {@const stack = maxAssignments - d}
          <tr>
            <td class="bg-white">
              {#if !isEmpty(hiddenColumns) && d === 0}
                <button class="w-full flex justify-center cursor-pointer text-gray-600" on:click={unhideAll}>
                  <ShowIcon />
                </button>
              {/if}
            </td>
            {#each headers as header (header.index)}
              {@const dropStack = mAssignments[header.index]
                ? mAssignments[header.index].length === stack
                : stack === 0}
              {@const assignment = mAssignments[header.index]?.[stack]}
              {@const fixed = assignment?.fixed}
              <td class="pr-1 pb-1" data-col={header.index} data-row={stack}>
                <button
                  class:dropzone={dropStack || assignment}
                  class:fixed
                  class:blank={!dropStack}
                  class:something-is-dragging={isDragging || draggingCol !== null}
                  class:dragging={draggingCol === header.index && draggingRow === stack}
                  class:assigned={assignment}
                  use:draggable
                  on:drag={() => !fixed && multipleDrag(header.index, stack)}
                  on:dragend={multipleDragend}
                  on:mouseup={() => !fixed && multipleDrop(header.index, stack)}>
                  {#if assignment}
                    <div
                      class="truncate"
                      class:cursor-grab={!fixed}
                      class:invisible={draggingCol === header.index}>
                      {assignment.col.label}
                    </div>
                    {#if !fixed}
                      <button
                        class="absolute top-0 left-0 -mt-2 -ml-2 cursor-pointer rounded-xl bg-white text-black"
                        on:mousedown|stopPropagation
                        on:mousemove|stopPropagation
                        on:mouseup|stopPropagation
                        on:click={() => removeColumnAssignment(assignment.index)}>
                        <CircleXIcon />
                      </button>
                    {/if}
                  {:else if !isDragging && stack === 0}
                    <div class="hide-button flex justify-center w-full">
                      <button class="text-gray-600 cursor-pointer" on:click={() => hideColumn(header.index)}>
                        <HideIcon />
                      </button>
                    </div>
                  {:else}
                    &nbsp;
                  {/if}
                </button>
              </td>
            {/each}
          </tr>
        {/each}
      </thead>
    {:else}
      <tr bind:this={dropRow}>
        <td class="bg-white">
          {#if !isEmpty(hiddenColumns)}
            <button class="w-full flex justify-center cursor-pointer text-gray-600" on:click={unhideAll}>
              <ShowIcon />
            </button>
          {/if}
        </td>
        {#each headers as header (header.index)}
          <td class="pr-1 pb-1" data-col={header.index}>
            <button
              class="dropzone"
              class:something-is-dragging={isDragging || draggingCol !== null}
              class:dragging={draggingCol === header.index}
              class:assigned={assignments[header.index]}
              use:draggable
              on:drag={() => drag(header.index)}
              on:dragend={dragend}
              on:mouseup={() => drop(header.index)}>
              {#if assignments[header.index]}
                <div class="cursor-grab truncate" class:invisible={draggingCol === header.index}>
                  {assignments[header.index].col.label}
                </div>
                <button
                  class="absolute top-0 left-0 -mt-2 -ml-2 cursor-pointer rounded-xl bg-white text-black"
                  on:mousedown|stopPropagation
                  on:mousemove|stopPropagation
                  on:mouseup|stopPropagation
                  on:click={() => removeColumnAssignment(assignments[header.index].index)}>
                  <CircleXIcon />
                </button>
              {:else if !isDragging}
                <button
                  class="hide-button flex justify-center w-full"
                  on:click={() => hideColumn(header.index)}>
                  <div class="text-gray-600">
                    <HideIcon />
                  </div>
                </button>
              {:else}
                &nbsp;
              {/if}
            </button>
          </td>
        {/each}
      </tr>
    {/if}
    <tr>
      <th class="border-t border-l border-b" />
      {#each headers as header (header.index)}
        <th
          class="relative p-1 border-l border-t border-b text-center text-xs bg-gray-100 last-of-type:border-r overflow-hidden"
          data-col={header.index}
          class:dragging={isDragging}
          class:text-gray-500={!assignments[header.index]}
          class:text-gray-400={assignments[header.index]}>
          {header.label.toUpperCase()}
        </th>
      {/each}
    </tr>
    {#each rest as row, rowIndex}
      <tr>
        <th class="p-1 text-center border-b border-l">
          {rowIndex + 1}
        </th>
        {#each headers as header (header.index)}
          <td
            class="relative px-1 border-l border-b relative last-of-type:border-r overflow-hidden"
            class:dragging={isDragging}
            class:text-gray-400={assignments[header.index]}
            class:bg-gray-100={assignments[header.index]}
            data-col={header.index}>
            {row[header.index]}
          </td>
        {/each}
      </tr>
    {/each}
  </table>
</div>

<style lang="scss">
  th {
    font-weight: normal;
  }

  .blank {
    @apply relative w-full p-1;
  }

  .dropzone {
    @apply relative w-full border border-gray-300 rounded text-xs p-1 text-center;

    &.assigned {
      @apply text-white;
    }

    &.dragging {
      @apply bg-gray-200 border-gray-400;
    }

    &:not(.assigned):not(.dragging) {
      @apply border-dashed;
    }

    &.assigned:not(.dragging) {
      @apply bg-blue-500;

      &.fixed {
        @apply bg-gray-500;
      }
    }
  }

  .dropzone.something-is-dragging:hover {
    @apply bg-blue-100;
  }

  .hide-button {
    visibility: hidden;
    width: 100%;
  }

  .dropzone:hover:not(.something-is-dragging) {
    .hide-button {
      visibility: visible;
    }
  }

  td.dragging:hover,
  th.dragging:hover {
    @apply bg-gray-100;
  }
</style>
