<script>
  import NavbarUnauthenticated from "#lib/NavbarUnauthenticated.svelte";
  import LaminaIcon from "@local/assets/icons/lamina.svg";
  import isEmail from "@local/extensions/validators/is-email";
  import TextInput from "#lib/sidebar/TextInput.svelte";
  import { useInvitation } from "#src/api";

  export let invitationid;
  export let queryParams;

  let errorMessage = null;
  let username = "";
  let email = queryParams.email || "";
  let company_name = "";
  let sent = false;

  $: isValid = isEmail(email) && !!username && !!company_name;

  async function submit() {
    if (!isValid) return;

    const res = await useInvitation({
      id: invitationid,
      username,
      company_name,
      email,
      org_type: "recipient",
    });

    if (res.error) {
      errorMessage = res.error.message;
    } else {
      sent = true;
    }
  }
</script>

<NavbarUnauthenticated />
<div class="w-full h-full flex flex-col justify-center">
  <div class="mx-auto w-96 p-4 border rounded bg-white drop-shadow-md">
    <form on:submit|preventDefault={submit} class="space-y-4 text-sm">
      <div class="flex justify-center my-2">
        <LaminaIcon />
      </div>
      <h1 class="text-center">Welcome to Shape Exchange</h1>
      {#if sent}
        <div class="text-center">Request sent! Check your email for login details.</div>
      {:else}
        {#if errorMessage}
          <div class="text-red-500">{errorMessage}</div>
        {/if}
        <TextInput labelWidth="7rem" border label="Email" bind:value={email} inputOnKeydown />
        <TextInput labelWidth="7rem" border label="Name" bind:value={username} inputOnKeydown />
        <TextInput labelWidth="7rem" border label="Company Name" bind:value={company_name} inputOnKeydown />
        <button type="submit" class="btn btn-lg btn-primary w-full" disabled={!isValid}>
          Join Shape Exchange
        </button>
      {/if}
    </form>
  </div>
</div>
