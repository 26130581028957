<script>
  import { createEventDispatcher } from "svelte";
  import formatHumanTime from "@local/extensions/formatters/format-human-time";
  import XIcon from "@local/assets/icons/x.svg";
  import { api } from "#src/api";

  export let notifications;

  const dispatch = createEventDispatcher();

  async function clear() {
    await api
      .from("notifications")
      .delete()
      .in(
        "id",
        notifications.map((n) => n.id)
      );

    dispatch("clear");
  }
</script>

<div class="bg-amber-50 border-amber-200 border p-4 rounded flex justify-between items-start">
  <div>
    {#each notifications as notification}
      <div class="flex justify-between gap-2 text-xs items-center">
        <div class="flex gap-2 items-center">
          <div class="rounded-full w-2 h-2 bg-blue-500" />
          <div>
            {notification.data.message}
          </div>
        </div>
        <div class="italic text-gray-500">
          {formatHumanTime(notification.received_at)}
        </div>
      </div>
    {/each}
  </div>
  <div class="flex-none text-gray-500">
    <button
      class="-m-2 p-2 rounded hover:text-black hover:bg-amber-100 border-transparent hover:border-amber-200"
      on:click={clear}>
      <XIcon />
    </button>
  </div>
</div>
