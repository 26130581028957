<script>
  import DocumentIcon from "@local/assets/icons/file.svg";
  import { Link } from "svelte-routing";

  export let group;
  export let disabled = false;
  export let sent = false;

  $: linkPrefix = sent ? `/versions/${$group?.id}` : `/jobs/${$group?.job_id}/current`;

  // $: console.log("job", job);
  // $: console.log("group", $group);
</script>

{#if $group?.documents?.order.length}
  <div class="flex gap-2 py-1">
    <div class="flex-none">
      <DocumentIcon />
    </div>
    <div class="grow space-y-1.5">
      {#each $group.documents.order as docid}
        {@const doc = $group.documents[docid]}
        {#if doc}
          <div class="text-blue-600 underline">
            <Link to={`${linkPrefix}/documents/${docid}`}>
              {doc.name}
            </Link>
          </div>
        {/if}
      {/each}
    </div>
  </div>
{/if}
