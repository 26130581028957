<script>
  import { tick } from "svelte";
  import { darken } from "drawing";
  import { createEventDispatcher } from "svelte";
  import XIcon from "@local/assets/icons/x.svg";
  import { clickOutside, Popover } from "svelte-utilities";

  export let border = false;
  export let label = "Label";
  export let labelWidth = null;
  export let value;
  export let disabled = false;
  export let selectionType = "grid"; // grid | native
  export let nullable = false;

  const dispatch = createEventDispatcher();

  const colors = [
    "#6b7280",
    "#ef4444",
    "#f97316",
    "#f59e0b",
    "#eab308",
    "#84cc16",
    "#22c55e",
    "#10b981",
    "#14b8a6",
    "#06b6d4",
    "#0ea5e9",
    "#3b82f6",
    "#6366f1",
    "#8b5cf6",
    "#a855f7",
    "#d946ef",
    "#ec4899",
    "#f43f5e",
  ];

  let showOptions = false;
  let container;
  let popover;
  let nativeInput;

  $: lw = labelWidth ? `width:${labelWidth};` : "";
  $: displayColor = !value || value === "Mixed" ? "#ffffff" : value;

  function blur() {
    if (popover && popover.contains(document.activeElement)) {
      return;
    }

    showOptions = false;
  }

  function chooseColor(color) {
    value = color;
    dispatch("input", { value: color });
  }

  function chooseNativeColor(e) {
    value = e.target.value;
    dispatch("input", { value: e.target.value });
  }

  function setNull() {
    value = null;
    dispatch("input", { value: null });
  }

  async function togglePicker() {
    showOptions = !showOptions;

    if (selectionType === "native") {
      await tick();
      nativeInput.click();
    }
  }
</script>

<div
  class="prop-container"
  class:readonly={disabled}
  class:visible-border={border}
  bind:this={container}
  use:clickOutside
  on:outclick={blur}>
  <div class="label" style={lw}>
    <slot name="label">
      {label}
    </slot>
  </div>
  <button class="grow flex px-2 items-center justify-start gap-2" {disabled} on:click={togglePicker}>
    <div
      class="rounded w-5 h-5"
      class:no-color-bg={!value || value === "Mixed"}
      style="
        background-color: {displayColor};
        border: 1px solid {darken(-0.5, displayColor)};
      " />
    {#if value && nullable && !disabled}
      <button {disabled} class="p-0.5 border rounded remove-button z-10" on:click|stopPropagation={setNull}>
        <XIcon />
      </button>
    {/if}
  </button>
  {#if showOptions}
    {#if selectionType === "native"}
      <input
        bind:this={nativeInput}
        type="color"
        class="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        value={displayColor}
        on:change={chooseNativeColor} />
    {:else}
      <Popover {container} fit>
        <div class="w-full p-2 flex gap-1 flex-wrap" bind:this={popover}>
          {#each colors as color}
            <button
              class="p-0.5 rounded border border-transparent hover:border-gray-500"
              on:click={() => chooseColor(color)}>
              <div
                class="rounded w-5 h-5"
                style="
              background-color: {color};
              border: 1px solid {darken(-0.5, color)};
              " />
            </button>
          {/each}
        </div>
      </Popover>
    {/if}
  {/if}
</div>

<style lang="scss">
  .prop-container {
    @apply px-2 py-2 w-full flex items-center rounded bg-white relative;

    .remove-button {
      visibility: hidden;
    }

    &:hover:not(.readonly):not(.focused),
    &.visible-border {
      @apply ring-1 ring-inset ring-gray-300;
    }

    &:hover:not(.readonly):not(.focused) {
      .remove-button {
        @apply border-transparent;
        visibility: visible;

        &:hover {
          @apply border-gray-400;
        }
      }
    }

    .label {
      @apply truncate text-gray-400 flex-none;
    }
  }

  .no-color-bg {
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 10 10'> <path d='M0 10 L10 0' stroke='red' /></svg>");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%, auto;
  }
</style>
