import { Drawing, darken } from "drawing";
import glassLayer from "./glass-layer.js";
import markColor from "./mark-color.js";
import { cutColor, shapeColor } from "./type-colors.js";

function typeDrawing(type, { showMark = true, image = null } = {}) {
  let dwg = new Drawing();

  if (!type.data?.layers || type.use_type_image_as_thumbnail) {
    if (type.image && image) {
      const bb = 50;

      const ar = image.width / image.height;
      const w = ar > 1 ? bb : ar * bb;
      const h = ar > 1 ? bb / ar : bb;
      const x = (bb - w) / 2;

      dwg = dwg.add(
        new Drawing().image(image.image, { x, y: h }, w, h, {
          format: image.format,
        }),
      );
    }

    return dwg;
  }

  const thickness =
    type.data.layers.length === 0 ? 1.5 : type.cache.total_thickness;

  const height = 3.5; // to revisit if types are not glass types in inches!
  const xOffset = 1;
  const yOffset = 2;
  const flip = type.data.flip_orientation;
  const layers = [...type.data.layers];
  if (flip) layers.reverse();

  const layerThicknesses = type.data.layers.reduce((t, layer, index) => {
    let lt = type.cache.layers[index].thickness_in;
    if (!Number.isFinite(lt)) {
      lt = layer.type === "interlayer" ? 0.03 : 0.375;
    }

    t.push(lt);
    return t;
  }, []);

  const layerX = layerThicknesses.reduce((t, lt, i, arr) => {
    const last = t[i - 1] || 0;
    const prev = arr[i - 1] || 0;

    t.push(last + prev);

    return t;
  }, []);

  layers.forEach((layer, i) => {
    const lIndex = flip ? layers.length - i - 1 : i;
    let lt = layerThicknesses[lIndex];
    const x = layerX[lIndex];

    if (!Number.isFinite(lt)) {
      lt = layer.type === "interlayer" ? 0.03 : 0.375;
    }

    const cc = cutColor(layer);
    let ht = height;
    if (layer.type === "spacer") ht = lt;

    const topColor = darken(0.4, cc);
    const sc = shapeColor(layer, layers[i - 1]);

    const layerDwg = glassLayer(
      x,
      lt,
      ht,
      xOffset,
      yOffset,
      cc,
      sc,
      topColor,
      "#333333",
      false,
      flip,
    );

    dwg = dwg.add(layerDwg);
  });

  if (layers.length === 0) {
    dwg = dwg
      .polygon([
        { x: 0, y: height },
        { x: 0, y: 0 },
        { x: thickness, y: 0 },
        { x: thickness + xOffset, y: yOffset },
        { x: thickness + xOffset, y: height + yOffset },
        { x: xOffset, y: height + yOffset },
      ])
      .style({
        stroke: "#888",
        fill: "#ddd",
        lineDash: [3, 2],
      });
  }

  if (showMark) {
    const mark = new Drawing()
      .mark({ x: thickness / 2, y: 0 }, type.mark, {
        shape: "circle",
        offset: { x: 0, y: -30 },
      })
      .style(markColor(type));

    dwg = dwg.add(mark);
  }

  return dwg;
}

export default typeDrawing;
