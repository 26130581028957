<script>
  import EllipsisIcon from "@local/assets/icons/ellipsis.svg";
  import { clickOutside } from "svelte-utilities";

  let show = false;
  let hamburger;

  function toggleDropdown() {
    show = !show;
  }
</script>

<button class="relative p-2" on:click|stopPropagation={toggleDropdown} bind:this={hamburger}>
  <EllipsisIcon />
  {#if show}
    <div
      class="dropdown-container mt-2 right-0"
      use:clickOutside={[hamburger]}
      on:outclick={() => (show = false)}>
      <slot />
    </div>
  {/if}
</button>
