<script>
  import { createEventDispatcher } from "svelte";
  import CircleXIcon from "@local/assets/icons/circle-x.svg";

  export let message = null;
  export let padding = true;

  const dispatch = createEventDispatcher();
</script>

{#if message}
  <div class="px-4" class:py-4={padding}>
    <div class="bg-red-50 p-2 rounded relative text-red-500 inline-block">
      {message}
      <div
        class="absolute top-0 left-0 -mt-3 -ml-3 p-1 cursor-pointer bg-white rounded-xl"
        on:click={() => dispatch("close")}
      >
        <CircleXIcon />
      </div>
    </div>
  </div>
{/if}
