import { isReduced } from "../reduced.js";
export { reduced, isReduced} from "../reduced.js";

/**
 * @template T
 * @template U
 * @param {Iterable<T>} src
 * @param {(value: T, index: number) => U} fn
 */
export function* map(src, fn) {
  let ix = 0;
  for (const x of src) {
    yield fn(x, ix);
  }
}

/**
 * @template U
 * @typedef {import('../reduced.js').Reduced<U>} Reduced
 */

/**
 * @template T
 * @template U
 * @param {Iterable<T>} iter
 * @param {(prev: U, curr: T) => U | Reduced<U>} fn
 * @param {U} initial
 */
export function reduce(iter, fn, initial){
  let acc = initial;
  for (const x of iter) {
    const res = fn(acc, x);
    if (isReduced(res)) {
      acc = res.value;
      break;
    }
    acc = res;
  }
  return acc;
}

/**
 * @template T

 * @param {Iterable<T>} iterable
 * @returns {Generator<[number, T]>}
 */
export function* enumerate(iterable) {
  let i = 0;

  for (const x of iterable) {
    yield [i, x];
    i++;
  }
}

/**
 * @template T
 * @param {Iterable<T>} iter
 * @param {(x: T) => boolean} pred
 */
export function* takeWhile(iter, pred) {
  for (const x of iter) {
    if (pred(x)) {
      yield x;
    } else return;
  }
}

/**
 * @template T
 * @param {Iterable<T>} iter
 * @param {(x: T) => boolean} pred
 */
export function* filter(iter, pred) {
  for (const elem of iter) {
    if (pred(elem)) {
      yield elem;
    }
  }
}

/**
 * @template T
 * @param {Iterator<T>} source
 * @param {(x: T) => boolean} pred
 * @param {Array<T>} ours
 * @param {Array<T>} theirs
 */
function* copartition(source, pred, ours, theirs) {
  while (true) {
    const { value, done } = source.next();

    if (!done) {
      if (pred(value)) {
        ours.push(value);
      } else {
        theirs.push(value);
      }
    }

    while (ours.length) {
      yield ours.shift();
    }

    if (done) break;
  }
}

/**
 * @template T
 * @param {Iterable<T>} iter
 * @returns {[Generator<T>, Generator<T>]}
 */
export function partition(iter, pred) {
  const trueBuffer = [];
  const falseBuffer = [];
  const it = iter[Symbol.iterator]();

  return [
    copartition(it, pred, trueBuffer, falseBuffer),
    copartition(it, (x) => !pred(x), falseBuffer, trueBuffer),
  ];
}

/**
 *
 * @template T
 * @param {Iterable<T>} iter
 * @param {number} num
 */
export function* partitionAll(iter, num) {
  let buf = [];
  for (const elem of iter) {
    buf.push(elem);
    if (buf.length == num) {
      yield buf;
      buf = [];
    }
  }
  if (buf.length > 0) {
    yield buf;
  }
}

/**
 * @template T
 * @param {Iterable<number>} ixs
 * @param {Array<T>} arr
 */
export function* pick(ixs, arr) {
  const max = arr.length;
  for (const ix of ixs) {
    if (ix < max) {
      yield arr[ix];
    } else return;
  }
}

/**
 * @template T
 * @param {Array<T>} iter
 * @param {(x: T) => boolean} pred
 * @returns {boolean}
 */
export function some(iter, pred) {
  for (const elem of iter) {
    if (pred(elem)) {
      return true;
    }
  }
  return false;
}

export const any = some;

/**
 * @template T
 * @param {Array<T>} iter
 * @param {(x: T) => boolean} pred
 * @returns {boolean}
 */
export function every(iter, pred) {
  for (const elem of iter) {
    if (!pred(elem)) {
      return false;
    }
  }
  return true;
}

/**
 * @template {string} K
 * @template V
 * @param {Iterable<K>} props
 * @param {Iterable<V>} values
 * @returns {Record<K,V>}
 */
export function zipObject(props, values) {
  const res = /** @type {Record<K,V>} */ ({});

  const it = values[Symbol.iterator]();

  for (const prop of props) {
    const { value, done } = it.next();
    if (done) {
      break;
    }
    res[prop] = value;
  }

  return res;
}

/**
 * @template A
 * @template B
 * @template T
 * @param {Iterable<A>} a
 * @param {Iterable<B>} b
 * @param {(a: A, b: B) => T} fn
 */
export function* zipWith(a, b, fn) {
  const ai = a[Symbol.iterator]();
  const bi = b[Symbol.iterator]();

  while (true) {
    const ax = ai.next();
    const bx = bi.next();

    if (ax.done || bx.done) {
      break;
    }

    yield fn(ax.value, bx.value);
  }
}

/**
 * @template T
 * @template G
 * @param {Iterable<T>} iter
 * @param {(x: T) => G} pred
 * @returns {Map<G, Array<T>>}
 */
export function groupBy(iter, pred) {
  /** @type {Map<G, Array<T>>} */
  const map = new Map();
  // const grouper = typeof pred === "string" ? (e) => e[pred] : pred;

  for (const elem of iter) {
    const group = pred(elem);

    if (map.has(group)) {
      map.get(group).push(elem);
    } else {
      map.set(group, [elem]);
    }
  }

  return map;
}

/**
 * @template T
 * @param {Iterable<T>} iter
 * @returns {[T, Iterable<T>]}
 */
export function decons(iter) {
  const it = iter[Symbol.iterator]();

  const { value: head } = it.next();
  const tail = it;

  return [head, tail];
}

/**
 * @template T
 * @param {Iterable<T>} iter
 * @param {string} sep
 */
export function join(iter, sep) {
  const [head, tail] = decons(iter);
  let res = String(head);

  for (const x of tail) {
    res += sep;
    res += String(x);
  }
  return res;
}

/**
 * @template T
 * @param {Iterable<T>} iter
 * @returns {[Generator<T>, Generator<T>]}
 */
export function tee(iter) {
  const buffer = [];

  function* it() {
    for (const elem of buffer) {
      yield elem;
    }
    for (const elem of iter) {
      buffer.push(elem);
      yield elem;
    }
  }

  return [it(), it()];
}

/**
 * @template T
 * @param {Iterable<T>} iter
 * @returns {Generator<[a: T, b: T]>}
 */
export function* pairwise(iter) {
  const [first, it] = decons(iter);
  let prev = first;

  for (const elem of it) {
    yield [prev, elem];

    prev = elem;
  }
}

/**
 * @template T
 * @param {Iterable<T>} iter
 */
export function* cycleOnce(iter) {
  const [head, tail] = decons(iter);

  yield head;

  for (const elem of tail) {
    yield elem;
  }

  yield head;
}

/**
 * @template T
 * @param {Iterable<T>} iter
 */
export function* traverse(iter) {
  yield* pairwise(cycleOnce(iter));
}

export const all = every;
