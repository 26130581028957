<script>
  import { Link, navigate } from "svelte-routing";
  import { fly } from "svelte/transition";
  import { linear } from "svelte/easing";
  import { loadImage } from "isomorphs/canvas";

  import LayerProperties from "#lib/sidebar/LayerProperties.svelte";
  import LocationThumbnailViewer from "#lib/sidebar/LocationThumbnailViewer.svelte";
  import typeDrawing from "@local/extensions/drawing/type-drawing-detailed.js";
  import Viewport from "#lib/drawing/Viewport.svelte";
  import Attachments from "#lib/sidebar/Attachments.svelte";
  import CaretLeftIcon from "@local/assets/icons/caret-left.svg";
  import Sidebar from "#lib/sidebar/Sidebar.svelte";
  import SidebarTitle from "#lib/sidebar/SidebarTitle.svelte";
  import History from "#lib/sidebar/History.svelte";
  import TypeProperties from "#lib/sidebar/TypeProperties.svelte";
  import PrevNext from "#lib/sidebar/PrevNext.svelte";

  import { selectedTypes, showRightPanel } from "#src/stores/ui.js";
  import imageUrl from "#src/extensions/image-url.js";

  export let group;
  export let typeid;
  export let disabled = false;
  export let types;

  let width;
  let height;
  let slide = "none";
  let image;

  $: type = $group.types[typeid];
  $: padding = width >= 640 ? 150 : 60;
  $: sidebarPosition = getSidebarPosition(width, height);
  $: setSelected(typeid);
  $: drawing = type && typeDrawing(type, { image });
  $: redirect(type);
  $: attachments = $group.attachments.filter((c) => c.type_id === typeid);
  $: itemIndex = types.findIndex((i) => i.id === typeid);
  $: nextType = types[itemIndex + 1];
  $: prevType = types[itemIndex - 1];
  $: getImage(type);
  $: typeSettings = {
    display_unit: $group.data.settings.display_unit,
    mm_precision: $group.data.settings.mm_precision,
    decimal_precision: 3,
    fractional_precision: 5,
    dimension_format: $group.data.settings.dimension_format,
  };

  async function getImage(type) {
    if (!type.image) {
      image = null;
      return;
    }

    const url = imageUrl(type.image, true);
    if (!url) {
      image = null;
      return;
    }

    try {
      const img = await loadImage(url);
      image = {
        image: img,
        width: img.width,
        height: img.height,
      };
    } catch (e) {
      image = null;
    }
  }

  function getSidebarPosition(width, height) {
    if (width >= 640) {
      return "right";
    }

    return height > width ? "bottom" : "right";
  }

  function setSelected(typeid) {
    selectedTypes.selectOnly(typeid);
  }

  function redirect(type) {
    if (!type) navigate("../types");
  }

  function updateType(evt) {
    const { id, prop, value, diff } = evt.detail;
    group.updateType(type.id, prop, value, diff);
  }

  function updateTypeStatus(evt) {
    const val = evt.detail;
    group.updateType(type.id, "approval_status", val);
  }

  function gotoNext() {
    slide = "left";
    navigate(`./${nextType.id}`);
  }

  function gotoPrev() {
    slide = "right";
    navigate(`./${prevType.id}`);
  }

  function slideAnimation(node, options) {
    if (slide === "none") return null;

    if (options.type === "in") {
      return fly(node, {
        x: slide === "right" ? -50 : 50,
        delay: 100,
        easing: linear,
        duration: 100,
      });
    }

    return fly(node, {
      x: slide === "right" ? 50 : -50,
      easing: linear,
      duration: 100,
    });
  }
</script>

<div class="p-4 absolute z-10 space-y-2">
  <div class="text-blue-500 text-sm">
    <Link to={"types"} class="flex items-center space-x-1">
      <CaretLeftIcon />
      <div>All Types</div>
    </Link>
  </div>
</div>

{#if type}
  <div class="absolute w-full h-full overflow-hidden">
    {#key type.id}
      <div
        class="w-full h-full flex relative"
        bind:offsetWidth={width}
        bind:offsetHeight={height}
        class:flex-col={sidebarPosition === "bottom"}>
        <div class="flex-grow relative">
          <div class="absolute w-full h-full overflow-hidden">
            {#key typeid}
              <div
                class="absolute w-full h-full"
                in:slideAnimation={{ type: "in" }}
                out:slideAnimation={{ type: "out" }}>
                <Viewport {padding} {drawing} />
              </div>
            {/key}
          </div>
        </div>
        {#if $showRightPanel}
          <Sidebar position={sidebarPosition}>
            <svelte:fragment slot="header">
              <PrevNext
                prev={prevType}
                next={nextType}
                on:gotoprev={gotoPrev}
                on:gotonext={gotoNext}
                sticky
                title={type.mark} />
            </svelte:fragment>
            <svelte:fragment slot="content">
              <SidebarTitle title={`${type.mark}${type.name && ` - ${type.name}`}`} />
              <TypeProperties
                {disabled}
                {type}
                settings={typeSettings}
                on:updateType={updateType}
                on:updateSupplier />
              {#if type?.data?.layers}
                <LayerProperties {type} {group} {disabled} />
              {/if}
              <LocationThumbnailViewer recordid={typeid} {group} record={type} {disabled} />
              <Attachments {attachments} {group} {typeid} {disabled} />
              <History
                updatedBy={type.updater}
                updatedOn={type.updated_at}
                approvedBy={type.approver}
                approvalStatus={type.approval_status}
                approvedOn={type.approval_status_updated_at} />
            </svelte:fragment>
          </Sidebar>
        {/if}
      </div>
    {/key}
  </div>
{/if}
