<script>
  import { onMount, getContext } from "svelte";
  import cloneDeep from "lodash/cloneDeep";
  import set from "lodash/set";
  import get from "lodash/get";
  import ThumbnalView from "./ThumbnailView.svelte";
  import { Modal } from "svelte-utilities";
  import Datatable from "../datatable/Datatable.svelte";
  import SelectInput from "../sidebar/SelectInput.svelte";
  import BooleanInput from "../sidebar/BooleanInput.svelte";
  import { profile } from "#src/stores/auth.js";
  import { settingDisplayShowUnusedTypes, settingDisplayShowSpanningDims } from "#src/stores/ui.js";
  import { sortableList, addToSortableList, removeFromSortableList } from "@local/extensions/collections/sortable-list.js";
  import eb from "#src/extensions/event-bus.js";
  import PlusIcon from "@local/assets/icons/plus.svg";

  export let group;
  export let standardColumns;
  export let orgColumns;
  export let customColumns;
  export let disabled = false;

  const org = getContext("org");

  let settingsModal;
  let tab = disabled ? "user_settings" : "job_settings";
  let newOrgColumn = null;

  const standardColumnProps = [
    {
      label: "Name",
      prop: "label",
      editable: false,
    },
    {
      label: "Name override",
      prop: "override",
      editable: true,
    },
    {
      label: "Visible",
      prop: "visible",
      editable: true,
      type: "boolean",
    },
  ];

  const orgColProps = [
    {
      label: "Name",
      prop: "name",
      editable: true,
      type: "text",
    },
  ];

  const standardCols = (hasTemplates = false) => [
    { label: "Mark", id: "mark" },
    { label: "Width", id: "width" },
    { label: "Height", id: "height" },
    { label: "Type", id: "type_id" },
    { label: "Description", id: "description" },
    { label: "Quantity", id: "quantity" },
    { label: "Est. Area", id: "cache.area" },
    { label: "Est. Wt.", id: "cache.weight" },
    { label: "Notes", id: "notes" },
    ...(hasTemplates ? [{ label: "Item Template", id: "item_template" }] : []),
  ];

  $: canAdmin = $profile && ["org_admin", "developer"].includes($profile.user_role);
  $: sc = getStandardColumns(standardColumns, $org);
  $: labelOptions = makeLabelOptions(customColumns, sc);

  function openSettings() {
    if (disabled) tab = "user_settings";
    settingsModal.open();
  }

  function makeLabelOptions(customColumns, sc) {
    const standard = sc
      .filter((c) => ["mark", "description", "seller_reference", "buyer_reference"].includes(c.id))
      .map((c) => ({
        label: c.override || c.label,
        value: c.id,
      }));

    return [
      ...standard,
      ...customColumns.map((c) => ({
        label: c.name,
        value: `custom_column_data.${c.id}`,
      })),
    ];
  }

  function updateJobSetting(setting, value) {
    group.updateProp(`data.settings.${setting}`, value);
  }

  function getStandardColumns(standardColumns, org) {
    const hasTemplates = !!org?.data?.templates?.length;

    return standardCols(hasTemplates).map((c) => {
      return (
        standardColumns[c.id] || {
          ...c,
          override: null,
          visible: true,
        }
      );
    });
  }

  function updateStandardColumn(e) {
    const { id, prop, value } = e.detail;
    const data = cloneDeep($org.data);
    const newSc = sortableList(sc);
    set(newSc[id], prop, value);
    data.standard_columns = newSc;
    org.updateProp("data", data);
  }

  function beginAddingOrgColumn() {
    newOrgColumn = { name: "New Column", type: "text" };
  }

  function addNewOrgColumn(evt) {
    const { row } = evt.detail;

    const data = cloneDeep($org.data);
    const cc = get(data, "custom_columns") || sortableList([]);

    addToSortableList(cc, {
      id: crypto.randomUUID(),
      ...row,
    });

    data.custom_columns = cc;
    org.updateProp("data", data);
    newOrgColumn = null;
  }

  function removeOrgColumn(evt) {
    const { id } = evt.detail;
    const data = cloneDeep($org.data);
    const deletedCols = data.deleted_columns || {};
    deletedCols[id] = data.custom_columns[id];
    removeFromSortableList(data.custom_columns, id);
    data.deleted_columns = deletedCols;

    org.updateProp("data", data);
  }

  function stopAddingOrgColumn() {
    newOrgColumn = null;
  }

  function updateOrgColumn(e) {
    const { id, prop, value } = e.detail;
    const data = cloneDeep($org.data);
    const cc = get(data, "custom_columns") || sortableList([]);
    set(cc[id], prop, value);
    data.custom_columns = cc;
    org.updateProp("data", data);
  }

  onMount(() => {
    eb.on("open-settings", openSettings);

    return () => {
      eb.unsubscribe("open-settings", openSettings);
    };
  });
</script>

<Modal bind:this={settingsModal} closeable fullframe width="36rem" height="26rem">
  <div slot="title">Settings</div>
  <div slot="content" class="text-xs flex h-full">
    <div class="flex-none w-36 bg-gray-100 p-2 space-y-1">
      {#if !disabled}
        <button
          class="setting-tab"
          class:selected={tab === "job_settings"}
          on:click={() => (tab = "job_settings")}>
          Job Settings
        </button>
      {/if}
      <button
        class="setting-tab"
        class:selected={tab === "user_settings"}
        on:click={() => (tab = "user_settings")}>
        User Settings
      </button>
      <button
        class="setting-tab"
        class:selected={tab === "org_settings"}
        on:click={() => (tab = "org_settings")}>
        Org. Settings
      </button>
    </div>
    <div class="grow flex flex-col space-y-2 p-4 h-full overflow-auto">
      {#if tab === "default_job_settings"}
        <div class="flex items-center justify-between" />
      {:else if tab === "job_settings"}
        <div class="flex items-center justify-between">
          <div>Display unit:</div>
          <div class="flex-none w-56">
            <SelectInput
              options={[
                { label: "Inches, sf", value: "inches" },
                { label: "Millimeters, m²", value: "millimeters" },
              ]}
              value={$group.data.settings.display_unit}
              label={null}
              on:input={(e) => updateJobSetting("display_unit", e.detail.value)} />
          </div>
        </div>
        {#if $group.data.settings.display_unit === "inches"}
          <div class="flex items-center justify-between">
            <div>Dimension format:</div>
            <div class="flex-none w-56">
              <SelectInput
                options={[
                  { label: "Decimal", value: "DECIMAL" },
                  { label: "Fractional", value: "FRACTIONAL" },
                ]}
                value={$group.data.settings.dimension_format}
                label={null}
                on:input={(e) => updateJobSetting("dimension_format", e.detail.value)} />
            </div>
          </div>
        {/if}
        {#if $group.data.settings.display_unit === "inches"}
          {#if $group.data.settings.dimension_format === "DECIMAL"}
            <div class="flex items-center justify-between">
              <div>Display precision:</div>
              <div class="flex-none w-56">
                <SelectInput
                  options={[
                    { label: "0", value: 0 },
                    { label: "1", value: 1 },
                    { label: "2", value: 2 },
                    { label: "3", value: 3 },
                    { label: "4", value: 4 },
                  ]}
                  value={$group.data.settings.decimal_precision}
                  label={null}
                  on:input={(e) => updateJobSetting("decimal_precision", e.detail.value)} />
              </div>
            </div>
          {:else if $group.data.settings.dimension_format === "FRACTIONAL"}
            <div class="flex items-center justify-between">
              <div>Display precision:</div>
              <div class="flex-none w-56">
                <SelectInput
                  options={[
                    { label: '1"', value: 0 },
                    { label: '1/2"', value: 1 },
                    { label: '1/4"', value: 2 },
                    { label: '1/8"', value: 3 },
                    { label: '1/16"', value: 4 },
                    { label: '1/32"', value: 5 },
                  ]}
                  value={$group.data.settings.fractional_precision}
                  label={null}
                  on:input={(e) => updateJobSetting("fractional_precision", e.detail.value)} />
              </div>
            </div>
          {/if}
        {:else}
          <div class="flex items-center justify-between">
            <div>Display precision:</div>
            <div class="flex-none w-56">
              <SelectInput
                options={[
                  { label: "0", value: 0 },
                  { label: "1", value: 1 },
                  { label: "2", value: 2 },
                ]}
                value={$group.data.settings.mm_precision}
                label={null}
                on:input={(e) => updateJobSetting("mm_precision", e.detail.value)} />
            </div>
          </div>
        {/if}

        <div class="flex items-center justify-between">
          <div>Label items by:</div>
          <div class="flex-none w-56">
            <SelectInput
              options={labelOptions}
              value={$group.data.settings.label_by}
              label={null}
              on:input={(e) => updateJobSetting("label_by", e.detail.value)} />
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div>Label openings by:</div>
          <div class="flex-none w-56">
            <SelectInput
              options={labelOptions}
              value={$group.data.settings.collections_label_by}
              label={null}
              on:input={(e) => updateJobSetting("collections_label_by", e.detail.value)} />
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div>Show Width Offset</div>
          <div class="flex-none w-56">
            <BooleanInput
              value={!$group.data.settings.hide_width_offset}
              label={null}
              on:input={(e) => updateJobSetting("hide_width_offset", !e.detail.value)} />
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div>Show Height Offset</div>
          <div class="flex-none w-56">
            <BooleanInput
              value={!$group.data.settings.hide_height_offset}
              label={null}
              on:input={(e) => updateJobSetting("hide_height_offset", !e.detail.value)} />
          </div>
        </div>
      {:else if tab === "user_settings"}
        <div class="flex items-center justify-between">
          <div>Show unused types:</div>
          <div class="flex-none w-56 px-4 py-2">
            <input type="checkbox" bind:checked={$settingDisplayShowUnusedTypes} />
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div>Show redundant dims:</div>
          <div class="flex-none w-56 px-4 py-2">
            <input type="checkbox" bind:checked={$settingDisplayShowSpanningDims} />
          </div>
        </div>

        <div class="pt-2">
          <div>Thumbnail display:</div>
          <div class="flex justify-center">
            <ThumbnalView />
          </div>
        </div>
      {:else if tab === "org_settings"}
        {#if canAdmin}
          <div class="italic">Changes to these settings will affect all jobs.</div>
        {/if}
        <div>
          <div class="mb-2">Standard Columns</div>
          <div class="data-table-container">
            <Datatable
              sticky
              size="xxs"
              data={sc}
              columns={standardColumnProps}
              editable={canAdmin}
              on:updaterow={updateStandardColumn} />
          </div>
        </div>
        <div>
          <div class="mb-2 flex items-center">
            <div class="grow">Organization Columns</div>
            {#if canAdmin}
              <button class="w-8 flex-none" on:click={beginAddingOrgColumn}>
                <PlusIcon />
              </button>
            {/if}
          </div>
          <div class="data-table-container">
            <Datatable
              sticky
              size="xxs"
              data={orgColumns}
              columns={orgColProps}
              newRow={newOrgColumn}
              deletable
              editable={canAdmin}
              on:addrow={addNewOrgColumn}
              on:deleterow={removeOrgColumn}
              on:updaterow={updateStandardColumn}
              on:stopaddingrow={stopAddingOrgColumn}
              on:updaterow={updateOrgColumn} />
          </div>
        </div>
      {/if}
    </div>
  </div>
</Modal>

<style lang="scss">
  .setting-tab {
    @apply p-2 cursor-pointer w-full text-left;

    &:hover,
    &.selected {
      @apply bg-gray-300 rounded;
    }
  }

  .data-table-container {
    @apply border overflow-y-auto relative;
    max-height: 9rem;
  }
</style>
