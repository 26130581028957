<script>
  import CircleXIcon from "@local/assets/icons/circle-x.svg";
  import SearchIcon from "@local/assets/icons/search.svg";

  export let filter;

  let focused;

  function removeFilter() {
    filter = "";
  }

  function handleFocus() {
    focused = true;
  }

  function handleBlur() {
    focused = false;
  }
</script>

<div class="relative text-sm">
  <div class="bg-white rounded p-1 flex items-center gap-2 border border-gray-300" class:focused>
    <div class="text-gray-400"><SearchIcon /></div>
    <input class="bg-transparent" on:focus={handleFocus} on:blur={handleBlur} bind:value={filter} />
  </div>
  {#if !!filter}
    <button class="absolute right-1 top-1.5 text-gray-400" on:click={removeFilter}>
      <CircleXIcon />
    </button>
  {/if}
</div>

<style lang="scss">
  input {
    border: none;
    outline: none;
    min-width: 0;
    max-width: 100%;
    width: 100%;
  }

  .focused {
    @apply ring-2 ring-inset ring-blue-500;
  }
</style>
