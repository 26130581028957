import { darken } from "drawing";

function cutColor(layer) {
  if (!layer) return null;

  if (layer.type === "glass") {
    return layer.material.color || "#BED9F5";
  } else if (layer.type === "spacer") {
    return layer.material.color || "#888888";
  } else if (layer.type === "interlayer") {
    return layer.material.color || "#FFE497";
  } else {
    return layer.material.color || "#888888";
  }
}

function shapeColor(layer, layerBehind) {
  const cc = cutColor(layer);

  if (layer.type !== "glass") {
    return darken(0.6, cc);
  } else if (layer.inboard_surface?.color) {
    return layer.inboard_surface.color;
  } else if (layerBehind?.type === "generic") {
    const nextColor = cutColor(layerBehind);
    return darken(0.6, nextColor);
  } else {
    return darken(0.6, cc);
  }
}

export { cutColor, shapeColor };
