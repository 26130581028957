<script>
  import ExpandLeftIcon from "@local/assets/icons/expand-left.svg";
  import CollapseRightIcon from "@local/assets/icons/collapse-right.svg";

  export let type = "flex";
  export let position = "right";
  export let expanded = false;
  export let expandable = false;
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  class="flex-none relative h-full bg-white border-l flex flex-col justify-between"
  class:expanded={expanded && position === "right"}
  class:vertical={position === "right"}
  class:relative={type === "flex"}
  class:flex-none={type === "flex"}
  class:absolute={type === "absolute"}
  class:h-full={position === "right"}
  class:right-0={type === "absolute" && position === "right"}
  class:top-0={type === "absolute" && position === "right"}
  class:bottom-0={position === "bottom"}
  class:w-full={position === "bottom"}
  class:left-0={position === "bottom"}
  class:h-56={position === "bottom"}
  on:mousedown|stopPropagation
  on:click|stopPropagation>
  {#if $$slots.header}
    <div class="empty:hidden -mb-4">
      <slot name="header" />
    </div>
  {/if}
  <div class="grow overflow-auto space-y-4 py-4 content-section">
    <slot name="content" />
  </div>
  {#if $$slots.footer}
    <div class="px-2 mb-2 sm:mb-6 pt-2 sm:pt-6 empty:hidden border-t">
      <slot name="footer" />
    </div>
  {/if}
  {#if expandable && position === "right"}
    <button
      class="absolute bottom-0 left-0 m-1.5 p-1.5 bg-white rounded hover:bg-gray-200"
      on:click={() => (expanded = !expanded)}>
      {#if expanded}
        <CollapseRightIcon />
      {:else}
        <ExpandLeftIcon />
      {/if}
    </button>
  {/if}
</div>

<style lang="scss">
  .vertical {
    width: 20rem;

    &.expanded {
      width: 40rem;
    }
  }
</style>
