<!-- <svelte:options strictprops={false} /> -->

<script>
  import LaminaIcon from "@local/assets/icons/lamina.svg";
  import { requestDemo } from "#src/api";
  import NavbarUnauthenticated from "#lib/NavbarUnauthenticated.svelte";

  let email = "";
  let sent = false;

  async function send() {
    await requestDemo(email);
    sent = true;
  }
</script>

<NavbarUnauthenticated />
<div class="w-full h-full flex flex-col justify-center">
  <div class="mx-auto w-96 p-4 border rounded bg-white drop-shadow-md text-center">
    <form on:submit|preventDefault={send} class="space-y-4">
      <div class="flex justify-center my-2">
        <LaminaIcon />
      </div>
      <h1>Welcome to Shape Exchange</h1>
      {#if !sent}
        <p class="text-sm">
          Shape Exchange is currently in a limited beta. <br />Please enter your email address to request
          access.
        </p>
        <input
          class="border rounded w-full p-2"
          type="email"
          placeholder="name@email.com"
          bind:value={email} />
        <button type="submit" class="btn btn-lg btn-primary w-full">Request Access</button>
      {:else}
        <p class="text-sm mb-4">
          Request sent! We will reach out to you shortly regarding access to the site.
        </p>
      {/if}
    </form>
  </div>
</div>
