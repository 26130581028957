import distance from "@turf/distance";
import { bucketArray } from "overline";
import type { OrderedMap } from "../ordered-map.js";
import type { TablesGenerated } from "../database.js";
import type { ItemRecordCore } from "../entities/item.js";

interface Job {
  location_coordinates: {
    longitude: number;
    latitude: number;
  } | null;
  [key: string]: any;
}

interface PricingGroupProperties {
  area: number,
  weight: number,
  quantity: number,
}

interface JobProperties {
  _ungrouped: PricingGroupProperties,
  [key: string]: PricingGroupProperties,
}

interface Supplier {
  address_coordinates: {
    longitude: number;
    latitude: number;
  } | null;
  [key: string]: any;
}

interface Group {
  items: OrderedMap<string, ItemRecordCore>;
  types: OrderedMap<string, Type>;
  [key: string]: any;
}

type Type = TablesGenerated<"types">;
type Item = TablesGenerated<"items">;
type ProductList = TablesGenerated<"product_lists">;
type PriceEntry = TablesGenerated<"price_entries">;

interface ProductPrices {
  [key: string]: Array<PriceEntry>;
}

interface PriceLibrary {
  [key: string]: ProductPrices;
}

function pricingGroupProps(group: Group) {
  const jp: JobProperties = {
    _ungrouped: {
      area: 0,
      weight: 0,
      quantity: 0,
    },
  };

  if (!group) return jp;

  group.items.order.forEach((id) => {
    const item = group.items[id];
    if (!item.is_collection) {
      const type = group.types[item.type_id];
      if (!type || !type?.pricing_group) {
        jp._ungrouped.area += item.cache?.area || 0;
        jp._ungrouped.weight += item.cache?.weight || 0;
        jp._ungrouped.quantity += item.quantity || 0;
      } else if (type?.pricing_group) {
        if (!jp[type.pricing_group]) {
          jp[type.pricing_group] = {
            area: 0,
            weight: 0,
            quantity: 0,
          };
        }
        jp[type.pricing_group].area += item.cache?.area || 0;
        jp[type.pricing_group].weight += item.cache?.weight || 0;
        jp[type.pricing_group].quantity += item.quantity || 0;
      }
    }
  });

  return jp;
}

function orderProductLists(productLists: Array<ProductList>) {
  const assignments = bucketArray(productLists, "parent_id");
  const unparented = productLists.filter((pl) => !pl.parent_id);
  return unparented.reduce((ordered, pl) => {
    ordered.push(pl);

    if (assignments[pl.id]) {
      ordered.push(...assignments[pl.id]);
    }

    return ordered;
  }, []);
}

function makePriceLibrary(priceEntries: Array<PriceEntry>) {
  const library: PriceLibrary = {};

  return priceEntries.reduce((acc, e) => {
    if (!acc[e.product_id]) {
      acc[e.product_id] = {};
    }

    if (!acc[e.product_id][e.list_id]) {
      acc[e.product_id][e.list_id] = [];
    }

    acc[e.product_id][e.list_id].push(e);

    return acc;
  }, library);
}

function itemArea(item: ItemRecordCore) {
  return item.width && item.height ? (item.cache.width / 12) * (item.cache.height / 12) : 0;
}

function itemPriceEntry(item: ItemRecordCore, entries: Array<PriceEntry>, jobProperties: JobProperties, pricingGroup: string) {
  const jp = pricingGroup ? jobProperties[pricingGroup] : jobProperties._ungrouped;

  if (!entries?.length) return null;

  return entries.find((e) => {
    if (e.minimum_area != null && jp) {
      if (jp.area < e.minimum_area) return false;
    }

    if (e.maximum_area != null && jp) {
      if (jp.area >= e.maximum_area) return false;
    }

    if (e.minimum_item_quantity != null) {
      if (item.quantity < e.minimum_item_quantity) return false;
    }

    if (e.maximum_item_quantity != null) {
      if (item.quantity >= e.maximum_item_quantity) return false;
    }

    if (e.minimum_item_area != null) {
      if (itemArea(item) < e.minimum_item_area) return false;
    }

    if (e.maximum_item_area != null) {
      if (itemArea(item) >= e.maximum_item_area) return false;
    }

    return true;
  });
}

function getPrice(entry: PriceEntry | undefined | null, item: ItemRecordCore) {
  if (!entry) return 0;
  if (!entry.unit_price || !entry.unit) return 0;

  if (entry.unit === "item") {
    return entry.unit_price;
  } else if (entry.unit === "sqft") {
    return entry.unit_price * itemArea(item);
  } else if (entry.unit === "sqin") {
    return entry.unit_price * itemArea(item) * 144;
  } else if (entry.unit === "m2") {
    return entry.unit_price * itemArea(item) * 0.092903;
  } else {
    return 0;
  }
}

function itemPrice(
  type: Type,
  item: ItemRecordCore,
  productList: ProductList,
  priceEntries: PriceLibrary,
  jobProperties: JobProperties,
) {
  if (!type || !item || !productList?.id) return 0;

  if (item.price_override != null) return item.price_override / 100;

  const productPriceEntries = priceEntries[type.product_id]?.[productList.id];

  if (!productPriceEntries) return 0;

  const pricingGroup = type.pricing_group;
  const entry = itemPriceEntry(item, productPriceEntries, jobProperties, pricingGroup);
  return getPrice(entry, item);
}

function getItemPrices(group: Group, productList: ProductList, priceLibrary: PriceLibrary, jobProperties: JobProperties) {
  if (!group || !productList || !jobProperties) return {};

  return group.items.order.reduce((acc, itemId) => {
    const item = group.items[itemId];
    if (!item) return acc;

    if (item.price_override != null) {
      acc[item.id] = item.price_override / 100;
      return acc;
    }

    const type = group.types[item.type_id];
    if (!type?.product_id) return acc;

    const productEntries = priceLibrary[type.product_id]?.[productList.id];
    const priceEntry = itemPriceEntry(item, productEntries, jobProperties, type.pricing_group);

    if (!priceEntry) return acc;

    const priceEach = getPrice(priceEntry, item);

    acc[item.id] = priceEach;

    return acc;
  }, {} as Record<string, number>);
}

function priceSubtotal(
  group: Group,
  items: Array<ItemRecordCore>,
  productList: ProductList,
  priceEntries: PriceLibrary,
  jobProperties: JobProperties,
) {
  const itemPrices = items.reduce((acc, item) => {
    const type = group.types[item.type_id];
    if (!type || !priceEntries || !productList) return acc;
    const price = itemPrice(type, item, productList, priceEntries, jobProperties) * item.quantity;
    return acc + price;
  }, 0);

  const quoteItemPrices = group.quote_items.reduce((sum, qi) => sum + qi.total_price, 0);
  return itemPrices + quoteItemPrices;
}

export type { ProductList, PriceEntry, PriceLibrary };
export {
  pricingGroupProps,
  itemPriceEntry,
  getPrice,
  makePriceLibrary,
  orderProductLists,
  itemPrice,
  getItemPrices,
  priceSubtotal,
};
