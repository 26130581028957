<script>
  import range from "lodash/range";
  import { createEventDispatcher, onDestroy } from "svelte";
  import SpinnerIcon from "@local/assets/icons/spinner-lg.svg";
  import ProgressBar from "#lib/GenericProgressBar.svelte";
  import checkDocumentForUpdates from "#src/extensions/check-document-for-updates.js";
  import { api } from "#src/api";
  import { TRIOPS_URL as triopsUrl } from "#src/env";

  export let document;

  const dispatch = createEventDispatcher();

  let poller;
  let progress = 0;

  $: setup(document);

  async function setup(document) {
    if (poller) {
      clearInterval(poller);
    }

    if (document.ready) {
      progress = 1;
      return;
    }

    const { update, document: d } = await checkDocumentForUpdates(document);
    if (update) {
      dispatch("update", { id: document.id, diff: { data: d.data, ready: d.ready } });
      if (d.ready) {
        progress = 1;
        return;
      }
    }

    progress = document.data.files.reduce((p, f) => {
      const max = 1 / document.data.files.length;
      if (f.ready) {
        return p + max;
      }

      if (f.pages) {
        const r = range(0, f.pages);
        const completed = r.filter((i) => f.status[i]?.state === "completed");
        const q = completed.length / r.length;
        return p + max * q;
      }

      return p;
    }, 0);

    if (poller) clearInterval(poller);
    poller = setInterval(poll, 5000);
  }

  async function thumbnailSrc() {
    const thumbnail = document.data.thumbnail;
    if (thumbnail) {
      if (thumbnail.type === "image") {
        const { data, error } = await api.storage
          .from(thumbnail.bucket)
          .createSignedUrl(thumbnail.path, 60 * 60);

        if (error) return null;
        return data.signedUrl;
      } else {
        const path = encodeURIComponent(thumbnail.path);
        return `${triopsUrl}/iiif/2/${thumbnail.bucket}/${path}/full/540,/0/default.jpg`;
      }
    }
  }

  async function poll() {
    try {
      const { update, document: d } = await checkDocumentForUpdates(document);

      if (update) {
        dispatch("update", { id: document.id, diff: { data: d.data, ready: d.ready } });
      }
    } catch (error) {
      console.log(error);
    }
  }

  onDestroy(() => {
    if (poller) {
      clearInterval(poller);
    }
  });
</script>

<div class="relative w-full h-full">
  <div class="w-full h-full flex items-center justify-center bg-white" style="padding:4px;">
    {#if document.data.thumbnail}
      {#await thumbnailSrc() then src}
        <img class="w-full h-full object-cover text-xs" {src} alt={document.name} />
      {/await}
    {:else}
      <div class="w-full h-full flex flex-col justify-center items-center">
        <div class="animate-spin">
          <SpinnerIcon />
        </div>
      </div>
    {/if}
  </div>
  {#if progress < 0.99999}
    <div class="absolute bottom-0 left-0 w-full">
      <div class="p-2 text-xs text-gray-500">Processing: {Math.round(progress * 100)}%</div>
      <ProgressBar value={progress} max={1} />
    </div>
  {/if}
</div>
