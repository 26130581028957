<svelte:options strictprops={false} />

<script>
  import { createEventDispatcher, getContext } from "svelte";
  import EllipsisIcon from "@local/assets/icons/ellipsis.svg";
  import { exitEvents, metaKeys, directions } from "./constants.js";

  const dispatch = createEventDispatcher();

  export let value;
  export let rowData;
  export let display = (v) => "";

  export let disabled = false;
  export let readOnly = false;
  export let prefix = null;
  export let emptyRow = false;

  const { focused } = getContext("datagrid");

  let container;

  $: displayValue = display(value);

  export const focus = () => {
    container.focus();
  };

  function keydown(e) {
    if (exitEvents[e.key]) {
      e.preventDefault();
      focused.move(directions[e.key], e.shiftKey);
    } else if (!metaKeys[e.key] && !e.metaKey && !e.ctrlKey && !readOnly && !disabled) {
      if (e.key === "Enter") {
        open();
      }
    }
  }

  function open() {
    dispatch("open", { value, cell: container });
  }
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<div
  bind:this={container}
  class="p-1 flex items-center w-full editor-container outline-none"
  class:text-gray-500={disabled}
  on:keydown={keydown}
  tabindex="0">
  <div class="grow truncate" class:italic={readOnly} class:text-gray-500={disabled}>
    {#if prefix}
      {prefix}
    {/if}
    {#if displayValue !== ""}
      {displayValue}
    {:else}
      &nbsp;
    {/if}
  </div>
  {#if !readOnly && !disabled}
    <button class="flex-none" on:click={open} tabindex="-1">
      <EllipsisIcon />
    </button>
  {/if}
</div>
