import Bugsnag from "@bugsnag/js";
import { profile } from "./stores/auth";
import {
  BUGSNAG_API_KEY as apiKey,
  BUGSNAG_RELEASE_STAGE as releaseStage,
  BUGSNAG_APP_VERSION as appVersion,
} from "#src/env.js";

let userID, userName, userEmail;

profile.subscribe((profile) => {
  const { id, username, email } = profile ?? {};
  userID = id;
  userName = username || "anonymous";
  userEmail = email;
});

if (apiKey) {
  Bugsnag.start({
    apiKey,
    releaseStage,
    appVersion,
    onError(event) {
      event.setUser(userID, userEmail, userName);
    },
  });

  globalThis.bugsnag = {
    /**
     *
     * @param {string | Error} message
     */
    notify(message = "Test notification") {
      Bugsnag.notify(message, function (event) {
        event.severity = "info";
      });
    },
  };
}

export default Bugsnag;
