<script>
  import { getContext, tick, createEventDispatcher } from "svelte";
  import { tooltip } from "svelte-utilities";
  import EditIcon from "@local/assets/icons/edit.svg";
  import EditCancelIcon from "@local/assets/icons/edit-cancel.svg";
  import { itemPrice } from "@local/lamina-core/reporters";

  export let type;
  export let item;
  export let productList;
  export let jobProperties;
  export let disabled;
  export let computedPrice;

  const dispatch = createEventDispatcher();
  const priceEntries = getContext("priceEntries");

  let overriding = item.price_override != null;
  let input;
  let localValue = setLocal(item.price_override);

  function setLocal(price_override) {
    return price_override ? (price_override / 100).toFixed(2) : null;
  }

  async function addOverride() {
    overriding = true;
    await tick();
    input.focus();
  }

  function updateOverride(e) {
    if (localValue != null) {
      const number = parseFloat(localValue) * 100;
      if (isNaN(number)) {
        localValue = setLocal(item.price_override);
      } else if (number !== item.price_override) {
        const newValue = Math.round(number);
        dispatch("input", { value: newValue });
        localValue = setLocal(newValue);
      }
    }
  }

  function keydown(e) {
    if (e.key === "Enter") {
      updateOverride();
    }
  }

  function removeOverride() {
    localValue = null;
    overriding = false;

    if (item.price_override != null) {
      dispatch("input", { value: null });
    }
  }
</script>

{#if overriding}
  {#if disabled}
    <div>{(item.price_override / 100).toFixed(2)}</div>
  {:else}
    <input
      size="1"
      type="text"
      placeholder={computedPrice?.toFixed(2) || "0.00"}
      bind:this={input}
      bind:value={localValue}
      on:blur={updateOverride}
      on:keydown={keydown} />
    <button use:tooltip={{ text: "Remove Override" }} class="button-icon" on:click={removeOverride}>
      <EditCancelIcon />
    </button>
  {/if}
{:else}
  <div class="aligned-text">
    {#if computedPrice != null}
      {computedPrice.toFixed(2)}
    {:else}
      <span class="text-red-500 font-bold">N/A</span>
    {/if}
  </div>
  {#if !disabled}
    <button use:tooltip={{ text: "Override Price" }} class="button-icon" on:click={addOverride}>
      <EditIcon />
    </button>
  {/if}
{/if}

<style>
  input {
    border: none;
    outline: none;
    min-width: 0;
    max-width: 100%;

    @apply flex-1 bg-transparent text-right;
  }

  .button-icon {
    @apply text-gray-400 p-0.5 rounded;

    &:hover {
      @apply bg-gray-200 text-black;
    }
  }

  .aligned-text {
    @apply py-0.5;
  }
</style>
