<script>
  import { getContext } from "svelte";

  import ProductSummaryTableType from "./ProductSummaryTableType.svelte";
  import NewProductModal from "#lib/NewProductModal.svelte";
  import groupItems from "@local/extensions/collections/group-items.js";
  import sortByCategory from "@local/extensions/utilities/sort-by-category.js";
  import { rfqs } from "#src/stores/rfqs.js";
  import { jobs } from "#src/stores/jobs.js";
  import { quotes } from "#src/stores/quotes.js";
  import { pricingGroupProps } from "@local/lamina-core/reporters";

  export let org;
  export let group;
  export let items;
  export let types;
  export let sent = false;
  export let job;
  export let quote;
  export let recordId;
  export let recordType = "rfq";
  export let itemPrices;

  const productLists = getContext("productLists");
  const supplier = getContext("supplier");
  const categories = getContext("categories");

  let newProductModal;

  $: productGroup = $group?.project_type === "product";
  $: grouped = groupItems(items);
  $: ordered = getOrderedItems(grouped, types, productGroup, $supplier, $categories);
  $: counts = getCounts(ordered);
  $: fabrications = sumFabrications(items);
  $: areaNumeric = sumArea(items);
  $: area = `${areaNumeric.toFixed(2)} sf`;
  $: weightNumeric = sumWeight(items);
  $: weight = weightNumeric === 0 ? "" : `${weightNumeric.toFixed(0)} lbs`;
  $: qty = items.reduce((acc, item) => acc + item.quantity, 0);
  $: jobProperties = pricingGroupProps($group);
  $: shipping = getShipping(job, quote, $supplier, weight);
  $: currentPl = $productLists.find((pl) => pl.id === job?.product_list_id);
  $: price = sumPrice($group, items, itemPrices, quote);
  $: totalPrice = computeTotalPrice(quote, sent, price, shipping);
  $: unPricedItems = items.reduce((sum, i) => {
    if (itemPrices?.[i.id] == null) return sum + 1;
    return sum;
  }, 0);

  function sumFabrications(items) {
    return items.reduce((sum, item) => {
      if (!item.data.fabrications) return sum;

      const voids = item.data.fabrications.voids?.length || 0;
      const edge = item.data.fabrications.edge?.length || 0;
      const corner = item.data.fabrications.corner?.length || 0;
      const virtual = item.data.fabrications.virtual?.length || 0;

      return sum + voids + edge + corner + virtual;
    }, 0);
  }

  function getShipping(job, quote, $supplier, weight) {
    if (sent && quote?.data?.shipping != null) {
      return quote.data.shipping / 100;
    }

    return null;
  }

  function sumArea(items) {
    return items.reduce((acc, item) => {
      return acc + (item.cache?.area || 0);
    }, 0);
  }

  function sumWeight(items) {
    return items.reduce((acc, item) => {
      return acc + (item.cache?.weight || 0);
    }, 0);
  }

  function sumPrice(grp, items, itemPrices, quote) {
    if (sent && quote?.data?.price_subtotal != null) {
      return quote.data.price_subtotal / 100;
    }

    const subtotal = items.reduce((acc, item) => {
      return acc + (itemPrices[item.id] || 0) * item.quantity;
    }, 0);

    const qip = grp.quote_items.reduce((sum, qi) => sum + qi.total_price, 0);
    return subtotal + qip;
  }

  function computeTotalPrice(quote, sent, price, shipping) {
    if (sent && quote?.price) {
      return quote.price / 100;
    }

    return (price || 0) + (shipping || 0);
  }

  function getOrderedItems(grouped, types, productGroup, supplier, categories) {
    const o = [];
    let index = 0;

    let t;

    if (productGroup) {
      if (categories) {
        t = sortByCategory(types, categories);
      } else {
        t = types;
      }

      t = t.filter((t) => grouped[t.id]?.length);
    } else {
      t = types;
    }

    t.forEach((type) => {
      const items = grouped[type.id] || [];

      o.push({
        type,
        items,
        index,
      });

      index += items.length;
    });

    if (grouped.untyped.length) {
      o.push({
        type: null,
        items: grouped.untyped,
        index,
      });
    }

    return o;
  }

  function getCounts(ordered) {
    const counts = [0];
    ordered.slice(1).forEach((t, ti) => {
      const current = counts[ti];
      const prev = ordered[ti].items.length;
      counts.push(current + prev);
    });
    return counts;
  }

  function updateItem(e) {
    const { item, ...update } = e.detail;
    group.updateItem(item.id, { ...update });
  }

  function deleteItem(e) {
    const { item } = e.detail;
    group.removeItem(item.id);
  }

  function beginAddingProduct() {
    newProductModal.open({ category: null });
  }

  function formatPrice(price) {
    if (price == null) return "";
    return price.toFixed(2);
  }

  async function addProduct(e) {
    const { item_id, type_id, product, item } = e.detail;

    if (item_id) {
      const i = $group.items[item_id];
      await group.updateItem(item_id, "quantity", (i?.quantity || 0) + 1);
    } else if (type_id) {
      await group.addItem(item);
    } else {
      await group.update([
        { type: "type", action: "add", records: [product], block: true },
        { type: "item", action: "add", records: [item] },
      ]);
    }

    if (recordType === "rfq") {
      rfqs.fetchOne(recordId);
    } else if (recordType === "quote") {
      quotes.fetchOne(recordId);
    } else if (recordType === "job") {
      jobs.fetchOne(recordId);
    }
  }
</script>

<div class="px-4 text-xs overflow-x-auto">
  <table class="outline-none w-full">
    <tr class="border-b border-t">
      <th>Line</th>
      <th>Product Info</th>
      <th class="numeric">Area</th>
      <th class="numeric">Weight</th>
      {#if fabrications}
        <th class="numeric">Fabs</th>
      {/if}
      <th class="numeric">Price (EA)</th>
      <th class="numeric">Qty</th>
      <th class="numeric">Price</th>
      {#if !sent}
        <th></th>
      {/if}
    </tr>
    {#each ordered as t, ti (t.type?.id)}
      {#if t.type}
        <ProductSummaryTableType
          {sent}
          {group}
          type={t.type}
          items={t.items}
          typeIndex={counts[ti]}
          index={t.index}
          productList={currentPl}
          {itemPrices}
          {jobProperties}
          {fabrications}
          {job}
          on:update-item={updateItem}
          on:delete-item={deleteItem} />
      {/if}
    {/each}
    {#each $group.quote_items as quoteItem, qii}
      <tr>
        <td>{qii + ordered.length + 1}</td>
        <td colspan="5">
          <div class="px-2 font-bold">
            {quoteItem.description}
          </div>
        </td>
        <td class="text-right"> {formatPrice(quoteItem.total_price)}</td>
      </tr>
    {/each}
    {#if !sent}
      <tr>
        <td colspan="8" class="text-center p-2">
          <button
            class="h-16 w-full rounded border text-blue-500 border-dashed border-gray-300 hover:bg-gray-100"
            on:click={beginAddingProduct}>
            + Add Product
          </button>
        </td>
      </tr>
    {/if}
    <tr class="border-b border-t">
      <th></th>
      <th></th>
      <th class="numeric">Area</th>
      <th class="numeric">Weight</th>
      {#if fabrications}
        <th class="numeric">Fabs</th>
      {/if}
      <th></th>
      <th class="numeric">Qty</th>
      <th class="numeric">Price</th>
    </tr>
    <tr class="totals">
      <td colspan="2">Subtotal</td>
      <td class="numeric">{area}</td>
      <td class="numeric">{weight}</td>
      {#if fabrications}
        <td class="numeric">{fabrications}</td>
      {/if}
      <td class="numeric"></td>
      <td class="numeric">{qty}</td>
      <td class="numeric">{price?.toFixed(2) ?? ""}</td>
    </tr>
    {#if sent && quote}
      <tr class="totals">
        <td colspan="2">Shipping</td>
        <td></td>
        <td></td>
        <td></td>
        {#if fabrications}
          <td></td>
        {/if}
        <td colspan="2" class="numeric">
          {shipping?.toFixed(2) ?? "N/A"}
        </td>
      </tr>
      <tr class="totals">
        <td colspan="2">Total</td>
        <td></td>
        <td></td>
        <td></td>
        {#if fabrications}
          <td></td>
        {/if}
        <td colspan="2" class="numeric">
          {totalPrice?.toFixed(2) ?? ""}
        </td>
      </tr>
    {:else}
      <tr class="totals">
        <td colspan="2"></td>
        <td></td>
        <td></td>
        <td></td>
        {#if fabrications}
          <td></td>
        {/if}
        <td colspan="2" class="numeric">Shipping TBD</td>
      </tr>
    {/if}
  </table>
  {#if unPricedItems}
    <div class="text-red-500 font bold">There are {unPricedItems} item(s) without a defined price.</div>
  {/if}
</div>

<NewProductModal
  bind:this={newProductModal}
  {group}
  {items}
  {types}
  on:updateSupplier
  on:addProduct={addProduct} />

<style lang="scss">
  table {
    min-width: 36rem;
    margin: 0 auto;
  }

  th {
    text-align: left;
    padding: 0.25rem;
    white-space: nowrap;

    &.numeric {
      text-align: right;
    }
  }

  .totals td {
    @apply whitespace-nowrap;
    padding: 0rem 0.25rem 0rem 0.25rem;
    font-weight: bold;
    height: 2rem;

    &.numeric {
      text-align: right;
    }
  }
</style>
