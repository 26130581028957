<script>
  import { createEventDispatcher } from "svelte";
  import { Modal } from "svelte-utilities";
  import EditIcon from "@local/assets/icons/edit.svg";

  export let border = false;
  export let label = "Label";
  export let labelWidth = null;
  export let value;
  export let disabled = false;
  export let readonly = false;
  export let password = false;
  export let placeholder = "";
  export let warningText = `Are you sure you want to update ${label}?`;
  export let formatter = (v) => v;
  export let validator = (v) => v;
  export let parser = (v) => v;

  const dispatch = createEventDispatcher();

  let input;
  let localValue = value;
  let displayValue = value === "Mixed" ? value : formatter(value);
  let focused = false;
  let modal;

  $: formattedValue = value === "Mixed" ? value : formatter(value);
  $: lw = labelWidth ? `width:${labelWidth};` : "";
  $: refresh(value, formatter);

  function refresh(v, formatter) {
    localValue = v;
    displayValue = v === "Mixed" ? v : formatter(v);
  }

  function parseInput(val) {
    try {
      val = parser(val);
      val = validator(val);
      localValue = val;
      displayValue = formatter(localValue);
    } catch (err) {
      displayValue = localValue === "Mixed" ? localValue : formatter(localValue);
      dispatch("error", err);
    }
  }

  function focus() {
    if (disabled || readonly) return;
    focused = true;
    displayValue = formattedValue;
  }

  function click() {
    focus();
    modal.open();
  }

  function keydown(evt) {
    if (evt.key === "Enter") {
      evt.stopPropagation();
      click();
    }
  }

  function blur(evt) {
    focused = false;
  }

  function blurInput(evt) {
    parseInput(evt.target.value);
  }

  function updateValue() {
    if (localValue !== value) {
      dispatch("input", { value: localValue });
    }
  }
</script>

<div
  class="prop-container"
  class:focused
  class:readonly
  class:visible-border={border}
  on:click|stopPropagation={focus}
  on:keydown={focus}>
  <div class="label" style={lw}>
    <slot name="label">
      {label}
    </slot>
  </div>
  {#if readonly || disabled}
    <div class="input">{displayValue || ""}</div>
  {:else}
    <button
      class="input grow flex justify-between items-center"
      class:text-gray-500={disabled}
      {disabled}
      on:focus={focus}
      on:blur={blur}
      on:keydown={keydown}
      on:click={click}>
      {formattedValue || ""}

      {#if !readonly && !disabled}
        <div>
          <EditIcon />
        </div>
      {/if}
    </button>
  {/if}
</div>

<Modal
  bind:this={modal}
  closeable
  on:confirm={updateValue}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Update", type: "confirm", style: "danger" },
  ]}>
  <div slot="title">Update {label}</div>
  <div slot="content">
    <slot>
      {warningText}
    </slot>
    <div class="prop-container mt-2 visible-border">
      {#if password}
        <input
          bind:this={input}
          type="password"
          class="input"
          {placeholder}
          bind:value={displayValue}
          on:blur={blurInput} />
      {:else}
        <input
          bind:this={input}
          type="text"
          class="input"
          {placeholder}
          bind:value={displayValue}
          on:blur={blurInput} />
      {/if}
    </div>
  </div>
</Modal>

<style lang="scss">
  .input,
  input {
    border: none;
    background-color: none;
    outline: none;
    min-width: 0;
    max-width: 100%;
  }

  .prop-container {
    @apply px-2 py-2 w-full flex items-center rounded bg-white;

    &:hover:not(.readonly),
    &.visible-border {
      @apply ring-1 ring-inset ring-gray-300;
    }

    &.focused {
      @apply ring-2 ring-inset ring-blue-500;
    }

    .label {
      @apply truncate text-gray-400 flex-none;
    }

    .input {
      @apply min-w-0 grow shrink bg-transparent ml-2;
    }
  }
</style>
