<script>
  import { onMount, onDestroy } from "svelte";
  import { navigate } from "svelte-routing";
  import UndoIcon from "@local/assets/icons/undo.svg";
  import RedoIcon from "@local/assets/icons/redo.svg";
  import SelectIcon from "@local/assets/icons/select.svg";
  import ZoomIcon from "@local/assets/icons/zoom.svg";
  import ZoomOutIcon from "@local/assets/icons/zoom-out.svg";
  import ZoomFitIcon from "@local/assets/icons/zoom-fit.svg";
  import TableIcon from "@local/assets/icons/table.svg";
  import PanelRightIcon from "@local/assets/icons/panel-right.svg";
  import PanelLeftIcon from "@local/assets/icons/panel-left.svg";
  import GearIcon from "@local/assets/icons/gear.svg";
  import UploadIcon from "@local/assets/icons/upload.svg";
  import PanIcon from "@local/assets/icons/pan.svg";
  import PenPlusIcon from "@local/assets/icons/pen-plus.svg";
  import PenMinusIcon from "@local/assets/icons/pen-minus.svg";
  import HoleIcon from "@local/assets/icons/hole.svg";
  import NotchIcon from "@local/assets/icons/notch.svg";
  import CaretDownIcon from "@local/assets/icons/caret-down.svg";
  import CornerNotchIcon from "@local/assets/icons/corner-notch.svg";
  import LocationIcon from "@local/assets/icons/location.svg";
  import LocationItemIcon from "@local/assets/icons/location-item.svg";
  import LocationTypeIcon from "@local/assets/icons/location-type.svg";
  import LocationOpeningIcon from "@local/assets/icons/location-opening.svg";
  import ShareIcon from "@local/assets/icons/share.svg";

  import Tool from "./Tool.svelte";
  import { Modal, Popover } from "svelte-utilities";

  import { currentTool, showTable, showSummarySplitscreen, showRightPanel } from "#src/stores/ui.js";
  import eb from "#src/extensions/event-bus.js";
  import { isEdgeFabrication, isCornerFabrication } from "@local/extensions/fabrications/is-fabrication.js";
  import { orderedList } from "@local/extensions/collections/sortable-list.js";
  import { profile } from "#src/stores/auth.js";
  import { clickOutside } from "svelte-utilities";
  import { tooltip } from "svelte-utilities";

  export let tab;
  export let path;
  export let group;
  export let disabled = false;

  let fabwarning;
  let fabPosition;
  let quote;
  let job;
  let toolDropdown;
  let showToolPanel;

  const tools = {
    itemsList: [["select", "zoom-in", "zoom-out"]],
    itemsEditor: [
      ["select", "zoom-to-fit", "zoom-in", "zoom-out", "pan"],
      ["add-point", "remove-point", "feature-hole", "feature-edge-fabrication", "feature-corner-fabrication"],
    ],
    openingsList: [["select", "zoom-in", "zoom-out"]],
    typesList: [["select"]],
    typesEditor: [["select"]],
    fabricationsList: [["select"]],
    fabricationsEditor: [["select", "zoom-to-fit", "zoom-in", "zoom-out", "pan"]],
    openingsEditor: [
      ["select", "zoom-to-fit", "zoom-in", "zoom-out", "pan"],
      ["add-point", "remove-point"],
    ],
    documentsList: [["select"]],
    documentsEditor: [
      ["select", "zoom-to-fit-doc", "zoom-in-doc", "zoom-out-doc", "pan"],
      ["location-item", "location-collection", "location-type"],
    ],
  };

  const toolNames = {
    select: "Select",
    pan: "Pan",
    "zoom-to-fit": "Zoom to Fit",
    "zoom-to-fit-doc": "Zoom to Fit",
    "zoom-in": "Zoom In",
    "zoom-in-doc": "Zoom In",
    "zoom-out": "Zoom Out",
    "zoom-out-doc": "Zoom Out",
    "add-point": "Add Vertex",
    "remove-point": "Remove Vertex",
    "feature-hole": "Add Hole",
    "feature-edge-fabrication": "Add Edge Fabrication",
    "feature-corner-fabrication": "Add Corner Fabrication",
    location: "Add Location",
    "location-item": "Add Item Marker",
    "location-type": "Add Type Marker",
    "location-collection": "Add Opening Marker",
  };

  const toolIcons = {
    select: SelectIcon,
    pan: PanIcon,
    "zoom-to-fit": ZoomFitIcon,
    "zoom-to-fit-doc": ZoomFitIcon,
    "zoom-in": ZoomIcon,
    "zoom-in-doc": ZoomIcon,
    "zoom-out": ZoomOutIcon,
    "zoom-out-doc": ZoomOutIcon,
    "add-point": PenPlusIcon,
    "remove-point": PenMinusIcon,
    "feature-hole": HoleIcon,
    "feature-edge-fabrication": NotchIcon,
    "feature-corner-fabrication": CornerNotchIcon,
    location: LocationIcon,
    "location-item": LocationItemIcon,
    "location-type": LocationTypeIcon,
    "location-collection": LocationOpeningIcon,
  };

  const toolIsStateful = {
    select: true,
    pan: true,
    location: true,
    "location-item": true,
    "location-type": true,
    "location-collection": true,
    "add-point": true,
    "remove-point": true,
    "feature-hole": true,
    "feature-edge-fabrication": true,
    "feature-corner-fabrication": true,
  };

  const toolEditsShape = {
    "add-point": true,
    "remove-point": true,
    "feature-hole": true,
    "feature-edge-fabrication": true,
    "feature-corner-fabrication": true,
  };

  $: undoable = $group && group.undoable();
  $: redoable = $group && group.redoable();
  $: pathSegments = (path && path.split("/")) || [];
  $: page = `${tab}${pathSegments[1] ? "Editor" : "List"}`;
  $: toolset = filterTools(page, disabled, $group?.project_type);
  $: statefulTools = toolset.flat().filter((tool) => toolIsStateful[tool]);
  $: statelessTools = toolset.flat().filter((tool) => !toolIsStateful[tool]);
  $: itemEditor = ["items", "openings"].includes(tab) && pathSegments[1];
  $: currentItem = $group?.items[itemEditor];
  $: itemUndefined = !(currentItem?.width && currentItem?.height);
  $: updateCurrentTool(toolset);

  function filterTools(page, disabled, pt) {
    if (!page) return [];
    if (!disabled && pt !== "product") return tools[page];

    return tools[page].map((t) => t.filter((tool) => !toolEditsShape[tool])).filter((t) => t.length > 0);
  }

  function updateCurrentTool(toolset) {
    const tools = toolset.flat();
    if (!tools.includes($currentTool)) {
      $currentTool = "select";
    }
  }

  function clickStatefulTool(tool) {
    if (tool === "feature-edge-fabrication") {
      fabPosition = "edge";
      const fabrications = orderedList($group.data.fabrications).filter(isEdgeFabrication);
      if (!fabrications.length) {
        fabwarning.open();
        return;
      }
    } else if (tool === "feature-corner-fabrication") {
      fabPosition = "corner";
      const fabrications = orderedList($group.data.fabrications).filter(isCornerFabrication);
      if (!fabrications.length) {
        fabwarning.open();
        return;
      }
    }

    $currentTool = tool;
    eb.dispatch(tool);
  }

  onMount(async () => {
    if ($profile.organization?.org_type === "supplier") {
      quote = $group.job.quotes.find((q) => q.organization_id === $profile.organization_id);
      job = $group.job.quotes.length === 0;
    } else {
      quote = $group.job.quote_requests.find((qr) => qr.group_id === null || qr.group_id === $group.id);
    }

    eb.on("click-fab-tool", clickStatefulTool);
  });

  onDestroy(() => {
    eb.unsubscribe("click-fab-tool", clickStatefulTool);
  });
</script>

<div class="w-full h-10 border-t border-gray-700 flex-none bg-black text-gray-200 z-10">
  <div class="flex items-center justify-between h-10 px-2">
    <div class="flex items-center flex-none gap-3">
      <div class="h-8 hidden sm:flex items-center">
        {#if tab === "documents"}
          <Tool
            toolid="panel-left"
            type="toggle"
            tooltip="Show Table"
            state={$showSummarySplitscreen}
            on:click={() => ($showSummarySplitscreen = !$showSummarySplitscreen)}>
            <PanelLeftIcon />
          </Tool>
        {:else}
          <Tool
            toolid="panel-left"
            type="toggle"
            tooltip="Show Table"
            state={$showTable}
            on:click={() => ($showTable = !$showTable)}>
            <TableIcon />
          </Tool>
        {/if}
      </div>

      <div class="h-8 flex items-center gap-1">
        <Tool toolid="undo" type="button" tooltip="Undo" disabled={!undoable} on:click={group.undo()}>
          <UndoIcon />
        </Tool>
        <Tool toolid="redo" type="button" tooltip="Redo" disabled={!redoable} on:click={group.redo()}>
          <RedoIcon />
        </Tool>
      </div>

      <!-- Narrow screens: collapse stateful tools into a dropdown -->
      <div class="flex sm:hidden" bind:this={toolDropdown}>
        <div class="relative mr-2">
          {#if statefulTools.length > 1}
            <div class="h-8 flex items-center">
              <Tool toolid={$currentTool} bind:state={$currentTool} tooltip={toolNames[$currentTool]}>
                <svelte:component this={toolIcons[$currentTool]} />
              </Tool>
              <button class="text-gray-500" on:click={() => (showToolPanel = !showToolPanel)}>
                <CaretDownIcon />
              </button>
            </div>

            {#if showToolPanel}
              <Popover container={toolDropdown}>
                <div
                  class="tool-dropdown-container"
                  use:clickOutside={[toolDropdown]}
                  on:outclick={() => (showToolPanel = false)}>
                  {#each statefulTools as toolid}
                    <Tool
                      {toolid}
                      state={$currentTool}
                      tooltip={toolNames[toolid]}
                      on:click={() => clickStatefulTool(toolid)}
                      disabled={itemUndefined && toolEditsShape[toolid]}>
                      <svelte:component this={toolIcons[toolid]} />
                    </Tool>
                  {/each}
                </div>
              </Popover>
            {/if}
          {:else}
            <div class="h-8 flex items-center">
              <Tool toolid={$currentTool} bind:state={$currentTool} tooltip={toolNames[$currentTool]}>
                <svelte:component this={toolIcons[statefulTools[0]]} />
              </Tool>
            </div>
          {/if}
        </div>

        {#if statelessTools.length > 0}
          <div class="h-8 flex gap-1 items-center">
            {#each statelessTools as toolid}
              <Tool
                {toolid}
                type="button"
                tooltip={toolNames[toolid]}
                on:click={() => eb.dispatch(toolid)}
                disabled={itemUndefined && toolEditsShape[toolid]}>
                <svelte:component this={toolIcons[toolid]} />
              </Tool>
            {/each}
          </div>
        {/if}
      </div>

      <!-- Wider screens: display tools in order given -->
      <div class="hidden sm:flex gap-3">
        {#each toolset as tools}
          <div class="h-8 flex items-center gap-1">
            {#each tools as toolid}
              {@const isStateful = toolIsStateful[toolid]}
              <Tool
                {toolid}
                type={isStateful ? "enum" : "button"}
                tooltip={toolNames[toolid]}
                state={$currentTool}
                on:click={() => (isStateful ? clickStatefulTool(toolid) : eb.dispatch(toolid))}
                disabled={itemUndefined && toolEditsShape[toolid]}>
                <svelte:component this={toolIcons[toolid]} />
              </Tool>
            {/each}
          </div>
        {/each}
      </div>
    </div>

    <div class="flex items-center flex-none gap-3">
      <div class="flex items-center text-xs gap-2">
        {#if !disabled && ["items", "openings"].includes(tab)}
          <button
            class="action-button"
            on:click={() => eb.dispatch("import-csv")}
            use:tooltip={{ text: "Bulk Import" }}>
            <UploadIcon />
          </button>
        {/if}
        {#if quote}
          <button class="action-button flex gap-1" on:click={() => navigate(`/quotes/${quote.id}`)}>
            <ShareIcon />
            <div class="hidden md:block">Go to Overview</div>
          </button>
        {:else if job}
          <button class="action-button flex gap-1" on:click={() => navigate(`/jobs/${$group.job_id}`)}>
            <ShareIcon />
            <div class="hidden md:block">Go to Overview</div>
          </button>
        {/if}
      </div>

      <div class="flex items-center gap-1">
        <Tool
          toolid="settings"
          type="button"
          tooltip="Settings"
          on:click={() => eb.dispatch("open-settings")}>
          <GearIcon />
        </Tool>
      </div>

      <div class="h-8 hidden sm:flex items-center">
        <Tool
          toolid="panel-right"
          type="toggle"
          tooltip="Show Properties Panel"
          state={$showRightPanel}
          on:click={() => ($showRightPanel = !$showRightPanel)}>
          <PanelRightIcon />
        </Tool>
      </div>
    </div>
  </div>
</div>

<Modal bind:this={fabwarning} closeOnOutclick closeable>
  <div slot="title">Add Fabrications</div>
  <div slot="content" class="space-y-2">
    <div>
      This Shape List does not have any valid {fabPosition} fabrications. Add a fabrication to the job before placing
      it on an item.
    </div>
  </div>
</Modal>

<style lang="scss">
  button {
    @apply opacity-50 px-2 text-white font-semibold;

    &:hover {
      @apply opacity-100;
    }
  }

  button.action-button {
    @apply opacity-100 rounded-md py-1 bg-blue-600 border border-blue-700 text-white;

    &:hover {
      @apply bg-blue-500;
    }
  }

  .tool-dropdown-container {
    @apply p-1 rounded drop-shadow-lg border border-gray-700;
    @apply bg-black text-xs text-gray-200 flex gap-1 z-50;
  }
</style>
