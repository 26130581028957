import { api, headers, jsonResult, jsonHeaders } from "./index.js";
import { BACKEND_PREFIX as backendPrefix } from "#src/env.js";

async function sendQuoteRequest(request) {
  const { data } = await api.auth.getSession();

  const res = await fetch(`${backendPrefix}/requests/send`, {
    method: "POST",
    headers: headers(data.session.access_token),
    body: JSON.stringify(request),
  });

  return jsonResult(res);
}

async function selfSendRequest({ job_id, sender_id }) {
  const { data } = await api.auth.getSession();

  const res = await fetch(`${backendPrefix}/requests/self-send`, {
    method: "POST",
    headers: headers(data.session.access_token),
    body: JSON.stringify({ job_id, sender_id }),
  });

  return jsonResult(res);
}

async function addQuoteRequestRecipients(request) {
  const { data } = await api.auth.getSession();

  const res = await fetch(`${backendPrefix}/requests/add-recipients`, {
    method: "POST",
    headers: headers(data.session.access_token),
    body: JSON.stringify(request),
  });

  return jsonResult(res);
}

async function cancelQuoteRequest({ id }) {
  const { data } = await api.auth.getSession();

  const res = await fetch(`${backendPrefix}/requests/cancel`, {
    method: "POST",
    headers: headers(data.session.access_token),
    body: JSON.stringify({ id }),
  });

  return jsonResult(res);
}

async function cancelQuote({ quote_id }) {
  const { data } = await api.auth.getSession();

  const res = await fetch(`${backendPrefix}/requests/cancel-quote`, {
    method: "POST",
    headers: headers(data.session.access_token),
    body: JSON.stringify({ quote_id }),
  });

  return jsonResult(res);
}

async function reviseQuote({
  quote_id,
  sender_id,
  price,
  notes,
  days_valid,
  attachments,
}) {
  const { data } = await api.auth.getSession();
  const { access_token } = data.session;

  const res = await fetch(`${backendPrefix}/requests/revise-quote`, {
    method: "POST",
    body: JSON.stringify({
      quote_id,
      sender_id,
      price,
      notes,
      days_valid,
      attachments,
    }),
    headers: headers(access_token),
  });

  return jsonResult(res);
}

async function submitQuote(request) {
  const { data } = await api.auth.getSession();
  const h = data.session ? headers(data.session.access_token) : jsonHeaders();
  delete h["Content-Type"];

  const { attachments, ...rest } = request;
  const attachmentJson = attachments.map((a) => {
    const { data, ...rest } = a;
    return rest;
  });

  const body = new FormData();
  body.append(
    "json_data",
    JSON.stringify({ ...rest, attachments: attachmentJson }),
  );
  attachments.forEach((a) => {
    body.append("quotes", new Blob([a.data]), a.name);
  });

  const res = await fetch(`${backendPrefix}/requests/submit`, {
    method: "POST",
    body,
    headers: h,
  });

  return jsonResult(res);
}

async function submitQuoteOnly({
  quote_id,
  sender_id,
  price,
  notes,
  days_valid,
  attachments,
}) {
  const { data } = await api.auth.getSession();
  const { access_token } = data.session;

  const res = await fetch(`${backendPrefix}/requests/submit-quote-only`, {
    method: "POST",
    body: JSON.stringify({
      quote_id,
      sender_id,
      price,
      notes,
      days_valid,
      attachments,
    }),
    headers: headers(access_token),
  });

  return jsonResult(res);
}

async function createSampleRfq({ organization_id }) {
  const { data } = await api.auth.getSession();
  const { access_token } = data.session;

  const res = await fetch(`${backendPrefix}/requests/sample`, {
    method: "POST",
    body: JSON.stringify({ organization_id }),
    headers: headers(access_token),
  });

  return jsonResult(res);
}

async function getRfqFromLink({ id, email = null, organization_id = null }) {
  const { data } = await api.auth.getSession();
  const h = data.session ? headers(data.session.access_token) : jsonHeaders();

  const res = await fetch(`${backendPrefix}/requests/get`, {
    method: "POST",
    body: JSON.stringify({ id, email, organization_id }),
    headers: h,
  });

  return jsonResult(res);
}

async function shareDraft({ job_id, buyer_id, contact_id }) {
  const { data } = await api.auth.getSession();
  const { access_token } = data.session;

  const res = await fetch(`${backendPrefix}/requests/share-draft`, {
    method: "POST",
    body: JSON.stringify({
      job_id,
      buyer_id,
      contact_id,
    }),
    headers: headers(access_token),
  });

  return jsonResult(res);
}

async function processRfq({ job_id, buyer_id, request_for, buyer_reference }) {
  const { data } = await api.auth.getSession();
  const { access_token } = data.session;

  const res = await fetch(`${backendPrefix}/requests/process`, {
    method: "POST",
    body: JSON.stringify({
      job_id,
      buyer_id,
      request_for,
      buyer_reference,
    }),
    headers: headers(access_token),
  });

  return jsonResult(res);
}

export {
  sendQuoteRequest,
  selfSendRequest,
  addQuoteRequestRecipients,
  cancelQuoteRequest,
  cancelQuote,
  reviseQuote,
  submitQuote,
  submitQuoteOnly,
  createSampleRfq,
  getRfqFromLink,
  shareDraft,
  processRfq,
};
