import {
  createGroup as defaultGroup,
  createItem as defaultItem,
  createType as defaultType,
  createJob as defaultJob,
} from "@local/lamina-core";
import { api } from "#src/api";
import { orderedList } from "@local/extensions/collections/sortable-list.js";

function jd(jobdata) {
  const { name, location, data } = jobdata;
  return { name, location, data };
}

function gd(data, itemMap, typeMap) {
  if (!data) return;

  // Update sort order for types and items
  if (data.type_order) {
    data.type_order = data.type_order
      .map((type_id) => typeMap[type_id])
      .filter((id) => id);
  }

  if (data.item_order) {
    data.item_order = data.item_order
      .map((item_id) => itemMap[item_id])
      .filter((id) => id);
  }
}

async function importJob(jobdata, orgid, userid) {
  const nj = defaultJob(orgid, jd(jobdata));

  try {
    const { data: j, error: je } = await api
      .from("jobs")
      .insert(nj)
      .select("*")
      .single();
    if (je) throw je;

    const typeMap = jobdata.current_group.types.order.reduce((m, id) => {
      m[id] = crypto.randomUUID();
      return m;
    }, {});
    const itemMap = jobdata.current_group.items.order.reduce((m, id) => {
      m[id] = crypto.randomUUID();
      return m;
    }, {});

    const { data, notes } = jobdata.current_group;

    const ng = defaultGroup(orgid, nj.id, { data, notes });

    gd(ng.data, itemMap, typeMap);

    const types = orderedList(jobdata.current_group.types).map((type) => {
      const { id, data, mark, name } = type;

      return {
        ...defaultType(ng.id),
        id: typeMap[id],
        data,
        mark,
        name,
      };
    });

    const items = orderedList(jobdata.current_group.items).map((item) => {
      const {
        id,
        data,
        description,
        width,
        height,
        mark,
        quantity,
        type_id,
        is_collection,
        collection_id,
      } = item;

      return {
        ...defaultItem(ng.id, userid),
        id: itemMap[id],
        is_collection,
        collection_id: is_collection ? null : itemMap[collection_id] || null,
        data,
        description,
        width,
        height,
        mark,
        quantity,
        type_id: typeMap[type_id] || null,
      };
    });

    const documentMap = jobdata.current_group.documents.order.reduce(
      (m, id) => {
        m[id] = crypto.randomUUID();
        return m;
      },
      {},
    );

    const documents = orderedList(jobdata.current_group.documents).map(
      (document) => ({
        ...document,
        id: documentMap[document.id],
        group_id: ng.id,
      }),
    );

    const locations = orderedList(jobdata.current_group.locations).map(
      (location) => ({
        ...location,
        group_id: ng.id,
        document_id: documentMap[location.document_id],
      }),
    );

    const attachments = jobdata.current_group.attachments.map((attachment) => {
      const { name, item_id, type_id, object_id, metadata } = attachment;

      return {
        name,
        group_id: ng.id,
        item_id: itemMap[item_id] || null,
        type_id: typeMap[type_id] || null,
        object_id,
        metadata,
      };
    });

    const { error: ge } = await api.from("groups").insert(ng);
    if (ge) throw ge;

    const { error: jue } = await api
      .from("jobs")
      .update({ current_group_id: ng.id })
      .eq("id", nj.id);
    if (jue) throw jue;

    const { error: de } = await api.from("documents").insert(documents);
    if (de) throw de;

    const { error: le } = await api.from("locations").insert(locations);
    if (le) throw le;

    const { error: te } = await api.from("types").insert(types);
    if (te) throw te;

    const { error: ti } = await api.from("items").insert(items);
    if (ti) throw ti;

    const { error: ae } = await api.from("attachments").insert(attachments);
    if (ae) throw ae;

    return j;
  } catch (error) {
    console.log("error", error);

    // If an error occurred, attempt to delete any partially completed imports
    const { data } = await api
      .from("jobs")
      .select("*")
      .eq("id", nj.id)
      .maybeSingle();

    if (data) {
      await api.from("jobs").delete().eq("id", data.id);
    }

    throw "An error occurred importing this file.";
  }
}

export default importJob;
