<svelte:options strictprops={false} />

<script>
  import CaretDownIcon from "@local/assets/icons/caret-down.svg";

  export let disabled = false;
  export let readOnly = false;
  export let value = "";
  export let optionMap = {};
  export let prefix = null;
</script>

<div class="p-1 flex items-center pointer-events-none w-full">
  <div class="grow truncate" class:italic={readOnly} class:text-gray-500={disabled}>
    {#if prefix}
      {prefix}
    {/if}
    {optionMap[value] || ""}
  </div>
  {#if !readOnly && !disabled}
    <div class="flex-none">
      <CaretDownIcon />
    </div>
  {/if}
</div>
