<svelte:options strictprops={false} />

<script>
  import { createEventDispatcher } from "svelte";
  import set from "lodash/set";
  import cloneDeep from "lodash/cloneDeep";
  import { Dimension, Quantity } from "dimtext";
  import SidebarSection from "./SidebarSection.svelte";
  import SelectInput from "./SelectInput.svelte";
  import DimtextInput from "./DimtextInput.svelte";
  import DeleteButton from "./DeleteButton.svelte";
  import { tempFeatureHole } from "#src/stores/ui.js";
  import { createCircularHole, createRectangularHole } from "@local/lamina-core";

  export let job;
  export let item;
  export let feature;
  export let disabled;
  export let settings;

  const dispatch = createEventDispatcher();

  function updateFeature(prop, value) {
    const data = cloneDeep(item.data);

    if (Array.isArray(feature.index)) {
      feature.index.forEach((i) => {
        const f = data.fabrications.voids[i];
        set(f, prop, value);
      });
    } else {
      const f = data.fabrications.voids[feature.index];
      set(f, prop, value);
    }

    if ($tempFeatureHole) {
      $tempFeatureHole[prop] = value;
    }

    job.updateItem(item.id, { data });
  }

  function updateFeatureType(value) {
    const data = cloneDeep(item.data);

    if (Array.isArray(feature.index)) {
      feature.index.forEach((i) => {
        const f = data.fabrications.voids[i];

        if (value === "circular-hole") {
          data.fabrications.voids[i] = {
            ...createCircularHole(),
            type: value,
            reference: f.reference,
          };
          $tempFeatureHole = createCircularHole();
        } else if (value === "rectangular-hole") {
          data.fabrications.voids[i] = {
            ...createRectangularHole(),
            type: value,
            reference: f.reference,
          };
          $tempFeatureHole = createRectangularHole();
        }
      });
    } else {
      const f = data.fabrications.voids[feature.index];

      if (value === "circular-hole") {
        data.fabrications.voids[feature.index] = {
          ...createCircularHole(),
          type: value,
          reference: f.reference,
        };
        $tempFeatureHole = createCircularHole();
      } else if (value === "rectangular-hole") {
        data.fabrications.voids[feature.index] = {
          ...createRectangularHole(),
          type: value,
          reference: f.reference,
        };
        $tempFeatureHole = createRectangularHole();
      }
    }

    job.updateItem(item.id, { data });
  }

  function radCoercer(feature) {
    return (v) => {
      const width = feature.props.width.toNumber("inches");
      const height = feature.props.height.toNumber("inches");
      const max = Math.min(width, height) / 2;
      const rad = v.toNumber("inches");

      if (rad > max) {
        return new Dimension(new Quantity(max, "inches"));
      } else if (rad < 0) {
        return new Dimension(new Quantity(0, "inches"));
      }
      return v;
    };
  }

  async function removeFeature() {
    const indices = Array.isArray(feature.index) ? feature.index : [feature.index];
    const features = indices.map((index) => ({ type: "void", index }));

    dispatch("deleteFeature", { features });
  }
</script>

<SidebarSection>
  <SelectInput
    label="Hole Type"
    {disabled}
    labelWidth="5.5rem"
    value={feature.props.type}
    options={[
      { label: "Circular", value: "circular-hole" },
      { label: "Rectangular", value: "rectangular-hole" },
      ...(feature.props.type === "mixed" ? [{ label: "Mixed", value: "mixed" }] : []),
    ]}
    on:input={(e) => updateFeatureType(e.detail.value)} />
  {#if feature.props.type === "circular-hole"}
    <DimtextInput
      label="Diameter"
      {disabled}
      labelWidth="5.5rem"
      value={feature.props.diameter}
      {settings}
      on:input={(e) => updateFeature("diameter", e.detail.value)} />
  {:else if feature.props.type === "rectangular-hole"}
    <DimtextInput
      label="Width"
      {disabled}
      labelWidth="5.5rem"
      value={feature.props.width}
      {settings}
      on:input={(e) => updateFeature("width", e.detail.value)} />
    <DimtextInput
      label="Height"
      {disabled}
      labelWidth="5.5rem"
      value={feature.props.height}
      {settings}
      on:input={(e) => updateFeature("height", e.detail.value)} />
    <DimtextInput
      label="Radius"
      {disabled}
      labelWidth="5.5rem"
      value={feature.props.radius}
      coercer={radCoercer(feature)}
      {settings}
      on:input={(e) => updateFeature("radius", e.detail.value)} />
    <SelectInput
      label="Dimension to"
      {disabled}
      labelWidth="5.5rem"
      value={feature.props.alignment}
      options={[
        { label: "Center", value: "center" },
        { label: "Inside Edge", value: "inside-edge" },
      ]}
      on:input={(e) => updateFeature("alignment", e.detail.value)} />
    <SelectInput
      label="Orientation"
      {disabled}
      labelWidth="5.5rem"
      value={feature.props.orientation}
      options={[
        { label: "Orthogonal", value: "orthogonal" },
        { label: "Edge-aligned", value: "edge-aligned" },
      ]}
      on:input={(e) => updateFeature("orientation", e.detail.value)} />
  {/if}
  <DimtextInput
    label="Edge distance"
    {disabled}
    labelWidth="5.5rem"
    value={feature.props.reference.length}
    {settings}
    on:input={(e) => updateFeature("reference.length", e.detail.value)} />
  <DimtextInput
    label="Offset distance"
    {disabled}
    labelWidth="5.5rem"
    value={feature.props.reference.offset}
    {settings}
    on:input={(e) => updateFeature("reference.offset", e.detail.value)} />
</SidebarSection>
<SidebarSection>
  <DeleteButton on:delete={removeFeature} />
</SidebarSection>
