<script>
  import { createEventDispatcher } from "svelte";
  import { LinearDisplayFormat, DimText } from "dimtext";

  import upperFirst from "lodash/upperFirst";
  import LayerPropsSingle from "./LayerPropsSingle.svelte";
  import LayerPropsMakeup from "./LayerPropsMakeup.svelte";

  import EditIcon from "@local/assets/icons/edit.svg";
  import CheckIcon from "@local/assets/icons/check.svg";

  export let record;
  export let index;
  export let layer;
  export let disabled;
  export let dragging;
  export let obSurface;
  export let settings;
  export let editMakeup;
  export let group;

  const dt = new DimText();
  const dispatch = createEventDispatcher();

  let editing = false;
  let thicknessUnit = setThicknessUnit(layer);

  export const minimize = () => {
    editing = false;
  };

  const defaultColor = {
    glass: "#93c5fd",
    interlayer: "#fbbf24",
    spacer: "#6b7280",
  };

  $: setThicknessUnit(layer);

  function setThicknessUnit(layer) {
    const quant = layer.thickness?.quants?.[0];
    if (quant?.unit === "inches") return "inches";
    if (quant?.unit === "millimeters") return "millimeters";
    return "inches";
  }

  function edit() {
    dispatch("edit");
    editing = true;
  }

  function formatThickness(val, settings, thicknessUnit) {
    const df = thicknessUnit === "inches" ? "FRACTIONAL" : "DECIMAL";
    const precision = thicknessUnit === "inches" ? 5 : 2;

    if (!val) return "Thickness TBD";

    const v = val.format(LinearDisplayFormat[df], precision, { displayUnit: thicknessUnit });

    return `${v}${thicknessUnit === "inches" ? '"' : "mm"}`;
  }
</script>

<div
  class={`input-inner-container hover-container ${layer.type}-layer`}
  class:dragging
  style="border-color: {layer.material.color || defaultColor[layer.type]}">
  {#if editing}
    <div class="relative">
      <div class="flex justify-between items-center w-full p-2">
        <div class="font-bold">{layer.type === "generic" ? "Layer" : upperFirst(layer.type)}</div>
        <div>{formatThickness(layer.material?.data?.thickness, settings, thicknessUnit)}</div>
      </div>

      {#if editMakeup}
        <LayerPropsMakeup {layer} {disabled} {obSurface} {settings} {record} {index} org={group} on:update />
      {:else}
        <LayerPropsSingle {layer} {disabled} {obSurface} on:update />
      {/if}

      <div class="flex justify-end items-center">
        <button class="p-1 rounded hover:bg-gray-100" on:click={() => (editing = false)}>
          <CheckIcon />
        </button>
      </div>
    </div>
  {:else}
    <button class="w-full relative text-left space-y-2 p-2" on:click={edit}>
      <div class="flex justify-between items-center w-full">
        <div class="font-bold">{layer.type === "generic" ? "Layer" : upperFirst(layer.type)}</div>
        <div>{formatThickness(layer.material?.data?.thickness, settings, thicknessUnit)}</div>
      </div>
      {#if layer.material?.name}
        <div>{layer.material.name}</div>
      {/if}
      {#if layer.outboard_surface?.name}
        <div><span class="font-bold">#{obSurface}:</span> {layer.outboard_surface.name}</div>
      {/if}
      {#if layer.inboard_surface?.name}
        <div><span class="font-bold">#{obSurface + 1}:</span> {layer.inboard_surface.name}</div>
      {/if}
      {#if !layer.material?.name && !layer.outboard_surface?.name && !layer.inboard_surface?.name}
        <div class="italic">No description</div>
      {/if}
      {#if !disabled}
        <button
          class="absolute right-0 bottom-0 hover-item cursor-pointer p-1 rounded hover:bg-gray-100"
          on:click={edit}>
          <EditIcon />
        </button>
      {/if}
    </button>
  {/if}
</div>

<style lang="scss">
  .input-inner-container {
    @apply p-2;
    border-left-width: 6px;
  }

  .dragging {
    visibility: hidden;
  }

  .unit-button {
    @apply p-1 rounded text-gray-500;

    &:hover,
    &.active {
      @apply text-black bg-gray-100;
    }
  }
</style>
