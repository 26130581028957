import { Drawing, darken } from "drawing";
import glassLayer from "./glass-layer.js";
import markColor from "./mark-color.js";
import { cutColor, shapeColor } from "./type-colors.js";
import stringIsWhitespace from "../utilities/string-is-whitespace.js";

function typeDrawing(type, { image = null } = {}) {
  let dwg = new Drawing();

  let thickness;
  if (!type.data?.layers) {
    if (type.image && image) {
      const bb = 50;

      const ar = image.width / image.height;
      const w = ar > 1 ? bb : ar * bb;
      const h = ar > 1 ? bb / ar : bb;
      const x = (bb - w) / 2;

      dwg = dwg.add(
        new Drawing().image(image.image, { x, y: h }, w, h, {
          format: image.format,
        }),
      );
    }

    return dwg;
  }

  if (type.data.layers.length === 0) {
    thickness = 1.5;
  } else {
    thickness = type.data.layers.reduce((t, l, index) => {
      let lt = type.cache.layers[index].thickness_in;
      if (Number.isFinite(lt)) {
        return t + lt;
      }

      if (l.type === "glass") {
        return t + 0.375;
      }

      if (l.type === "interlayer") {
        return t + 0.03;
      }

      if (l.type === "spacer") {
        return t + 0.375;
      }
    }, 0);
  }

  const height = 3.5; // to revisit if types are not glass types in inches!
  const xOffset = 1;
  const yOffset = 2;
  const flip = type.data.flip_orientation;

  const obText = new Drawing()
    .text(
      "OUTBOARD",
      { x: flip ? 0 : xOffset, y: height + yOffset },
      {
        offset: { x: flip ? -10 : -20, y: -10 },
        alignment: "right",
      },
    )
    .style({ textColor: "black" });

  const ibText = new Drawing()
    .text(
      "INBOARD",
      {
        x: flip ? thickness : thickness + xOffset,
        y: height + yOffset,
      },
      {
        offset: { x: flip ? 20 : 10, y: -10 },
        alignment: "left",
      },
    )
    .style({ textColor: "black" });

  dwg = dwg.add(obText, ibText);

  const layerThicknesses = type.data.layers.reduce((t, layer, index) => {
    let lt = type.cache.layers[index].thickness_in;
    if (!Number.isFinite(lt)) {
      lt = layer.type === "interlayer" ? 0.03 : 0.375;
    }

    t.push(lt);
    return t;
  }, []);

  const layerX = layerThicknesses.reduce((t, lt, i, arr) => {
    const last = t[i - 1] || 0;
    const prev = arr[i - 1] || 0;

    t.push(last + prev);

    return t;
  }, []);

  const surfaces = type.data.layers.reduce((s, layer, i, arr) => {
    const last = s[i - 1] || 1;
    const prev = arr[i - 1];
    if (prev?.type === "glass") {
      s.push(last + 2);
    } else {
      s.push(last);
    }

    return s;
  }, []);

  const layers = [...type.data.layers];
  if (flip) layers.reverse();

  layers.forEach((layer, index) => {
    const lIndex = flip ? layers.length - index - 1 : index;
    const lt = layerThicknesses[lIndex];
    const x = layerX[lIndex];
    const surf = surfaces[lIndex];

    const cc = cutColor(layer);
    let ht = height;
    if (layer.type === "spacer") ht = lt;

    const topColor = darken(0.4, cc);
    const sc = shapeColor(layer, layers[lIndex - 1]);

    const layerDwg = glassLayer(
      x,
      lt,
      ht,
      xOffset,
      yOffset,
      cc,
      sc,
      topColor,
      "#333333",
      false,
      flip,
    );

    const dwgs = [layerDwg];
    if (layer.type === "glass") {
      let lshift = 0;
      const pl = type.data.layers[lIndex - 1];
      const pt = layerThicknesses[lIndex - 1];
      if (pl && (pt < 0.05 || pl?.type === "glass")) {
        lshift = 4;
      }

      const ibCoating = !stringIsWhitespace(layer.inboard_surface?.name);
      const obCoating = !stringIsWhitespace(layer.outboard_surface?.name);

      const surfaceA = new Drawing()
        .text(
          surf,
          { x: flip ? x + xOffset : x, y: 0 },
          { offset: { x: lshift, y: -10 } },
        )
        .style({ textColor: "black" });

      let rshift = 0;
      const nl = type.data.layers[lIndex + 1];
      const nt = layerThicknesses[lIndex + 1];
      if (nl && (nt < 0.05 || nl?.type === "glass")) {
        rshift = -4;
      }
      const surfaceB = new Drawing()
        .text(
          surf + 1,
          { x: flip ? x + lt + xOffset : x + lt, y: 0 },
          {
            offset: {
              x: rshift,
              y: -10,
            },
          },
        )
        .style({ textColor: "black" });

      dwgs.push(surfaceA, surfaceB);

      if (ibCoating) {
        if (flip) {
          dwgs.push(
            new Drawing()
              .polyline([
                { x: x + lt, y: height + yOffset },
                { x: x + lt + xOffset, y: height },
                { x: x + lt + xOffset, y: 0 },
              ])
              .style({
                stroke: "black",
                lineWidth: 2,
              })
              .shift({ x: -1 }),
          );
        } else {
          dwgs.push(
            new Drawing()
              .polyline([
                { x: x + lt, y: 0 },
                { x: x + lt, y: height },
                { x: x + lt + xOffset, y: height + yOffset },
              ])
              .polyline([
                { x: x + lt, y: 0 },
                { x: x + lt + xOffset, y: yOffset },
                { x: x + lt + xOffset, y: height + yOffset },
              ])
              .style({
                stroke: "black",
                lineWidth: 2,
              })
              .shift({ x: -1 }),
          );
        }
      }

      if (obCoating) {
        if (flip) {
          dwgs.push(
            new Drawing()
              .polyline([
                { x: x, y: height + yOffset },
                { x: x + xOffset, y: height },
                { x: x + xOffset, y: 0 },
              ])
              .polyline([
                { x: x + xOffset, y: 0 },
                { x: x, y: yOffset },
                { x: x, y: yOffset + height },
              ])
              .style({
                stroke: "black",
                lineWidth: 2,
              })
              .shift({ x: 1 }),
          );
        } else {
          dwgs.push(
            new Drawing()
              .polyline([
                { x: x, y: 0 },
                { x: x, y: height },
                { x: x + xOffset, y: height + yOffset },
              ])
              .style({
                stroke: "black",
                lineWidth: 2,
              })
              .shift({ x: 1 }),
          );
        }
      }
    }

    dwg = dwg.add(...dwgs);
  });

  if (type.data.layers.length === 0) {
    dwg = dwg
      .polygon([
        { x: 0, y: height },
        { x: 0, y: 0 },
        { x: thickness, y: 0 },
        { x: thickness + xOffset, y: yOffset },
        { x: thickness + xOffset, y: height + yOffset },
        { x: xOffset, y: height + yOffset },
      ])
      .style({
        stroke: "#888",
        fill: "#ddd",
        lineDash: [3, 2],
      });
  }

  const markFill =
    {
      none: "#FFFFFF",
      flagged: "#EF4444",
      approved: "#16A34A",
    }[type.approval_status] || "#FFFFFF";

  const mark = new Drawing()
    .mark(
      {
        x: flip ? thickness / 2 + xOffset : thickness / 2,
        y: 0,
      },
      type.mark,
      {
        shape: "circle",
        offset: { x: 0, y: -40 },
      },
    )
    .style(markColor(type));

  dwg = dwg.add(mark);

  return dwg;
}

export default typeDrawing;
