<script>
  import { createEventDispatcher } from "svelte";

  import QueryProductProps from "./QueryProductProps.svelte";
  import FixedProductProps from "./FixedProductProps.svelte";
  import ReferenceProductProps from "./ReferenceProductProps.svelte";
  import SelectInput from "./SelectInput.svelte";

  import { orderedList } from "@local/extensions/collections/sortable-list.js";
  import { hydrateMaterial, createSurface, createMaterial, createNullFields } from "@local/lamina-core";
  import { api } from "#src/api";

  export let record;
  export let index;
  export let layer;
  export let disabled;
  export let obSurface;
  export let settings;
  export let org;

  const dispatch = createEventDispatcher();

  const productProps = {
    query: QueryProductProps,
    fixed: FixedProductProps,
    reference: ReferenceProductProps,
  };

  let materialOptions = [];
  let ibSurfaceOptions = [];
  let obSurfaceOptions = [];

  $: updateOptions(layer);

  function transform(coll, xf, defaultValue = []) {
    return coll ? xf(coll) : defaultValue;
  }

  async function updateProductType(key, application, type) {
    let p;
    if (type === "query") {
      const options = await api.rpc("product_query_options", {
        makeup: { type: "query", value: { classification: "*", application }, vendor_id: $org.id },
      });

      const def = options?.data[0];

      p = {
        ...def,
        type,
        application,
        value: { classification: "*", application },
        default: def?.id,
        vendor_id: $org.id,
      };
    } else if (type === "reference") {
      const def =
        application === "material"
          ? $org.materials[$org.materials.order[0]]
          : $org.surfaces[$org.surfaces.order[0]];

      p = {
        ...def,
        type,
        application,
        value: def?.id,
        vendor_id: $org.id,
      };
    } else if (type === "fixed") {
      const value = application === "material" ? createMaterial() : createSurface();
      p = {
        ...value,
        type,
        value,
        vendor_id: $org.id,
      };
    } else if (type === "none") {
      p = { type };
    } else if (type === "text") {
      p = { ...createNullFields(), application, type };
    }

    if (p.application === "material") {
      hydrateMaterial(p);
    }

    await org.update([{ type: "makeup", id: record.id, prop: `data.layers.${index}.${key}`, value: p }]);
  }

  async function updateProduct(key, application, product) {
    if (application === "material") {
      hydrateMaterial(product);
    }

    await org.update([
      { type: "makeup", id: record.id, prop: `data.layers.${index}.${key}`, value: product },
    ]);
  }

  async function updateOptions(layer) {
    if (layer.material.type === "query") {
      const materials = await api.rpc("product_query_options", { makeup: layer.material });
      materialOptions = materials.data.map((m) => hydrateMaterial(m));
    } else if (layer.material.type === "reference") {
      materialOptions = transform($org.materials, orderedList).filter((m) => m.published);
    } else if (layer.material.type === "option") {
      materialOptions = layer.material.value.options;
    }

    if (layer.inboard_surface?.type === "query") {
      const ibSurfaces = await api.rpc("product_query_options", { makeup: layer.inboard_surface });
      ibSurfaceOptions = ibSurfaces.data;
    } else if (layer.inboard_surface?.type === "reference") {
      ibSurfaceOptions = transform($org.surfaces, orderedList).filter((s) => s.published);
    } else if (layer.inboard_surface?.type === "option") {
      ibSurfaceOptions = layer.inboard_surface.value.options;
    }

    if (layer.outboard_surface?.type === "query") {
      const obSurfaces = await api.rpc("product_query_options", { makeup: layer.outboard_surface });
      obSurfaceOptions = obSurfaces.data;
    } else if (layer.outboard_surface?.type === "reference") {
      obSurfaceOptions = transform($org.surfaces, orderedList).filter((s) => s.published);
    } else if (layer.outboard_surface?.type === "option") {
      obSurfaceOptions = layer.outboard_surface.value.options;
    }
  }
</script>

<SelectInput
  label="Material"
  {disabled}
  labelWidth="5rem"
  value={layer.material.type}
  options={[
    { value: "reference", label: "Reference" },
    { value: "query", label: "Query" },
    // { value: "option", label: "Option" },
    { value: "fixed", label: "Fixed" },
  ]}
  on:input={(e) => updateProductType("material", "material", e.detail.value)} />
<svelte:component
  this={productProps[layer.material.type]}
  product={layer.material}
  application="material"
  {disabled}
  {settings}
  {org}
  options={materialOptions}
  on:update-product={(e) => updateProduct("material", "material", e.detail)} />
{#if layer.type === "glass"}
  <SelectInput
    label={`#${obSurface} Surface`}
    {disabled}
    labelWidth="5rem"
    value={layer.outboard_surface.type}
    options={[
      { value: "text", label: "Text Input" },
      { value: "reference", label: "Reference" },
      { value: "query", label: "Query" },
      // { value: "option", label: "Option" },
      { value: "fixed", label: "Fixed" },
      { value: "none", label: "None" },
    ]}
    on:input={(e) => updateProductType("outboard_surface", "surface", e.detail.value)} />
  {#if layer.outboard_surface?.type && layer.outboard_surface?.type !== "none"}
    <svelte:component
      this={productProps[layer.outboard_surface.type]}
      product={layer.outboard_surface}
      application="surface"
      {disabled}
      {settings}
      {org}
      options={obSurfaceOptions}
      on:update-product={(e) => updateProduct("outboard_surface", "surface", e.detail)} />
  {/if}
  <SelectInput
    label={`#${obSurface + 1} Surface`}
    {disabled}
    labelWidth="5rem"
    value={layer.inboard_surface?.type}
    options={[
      { value: "text", label: "Text Input" },
      { value: "reference", label: "Reference" },
      { value: "query", label: "Query" },
      // { value: "option", label: "Option" },
      { value: "fixed", label: "Fixed" },
      { value: "none", label: "None" },
    ]}
    on:input={(e) => updateProductType("inboard_surface", "surface", e.detail.value)} />
  {#if layer.inboard_surface?.type && layer.inboard_surface?.type !== "none"}
    <svelte:component
      this={productProps[layer.inboard_surface.type]}
      product={layer.inboard_surface}
      application="surface"
      {disabled}
      {settings}
      {org}
      options={ibSurfaceOptions}
      on:update-product={(e) => updateProduct("inboard_surface", "surface", e.detail)} />
  {/if}
{/if}
