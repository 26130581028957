<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import SearchSelect from "./SearchSelect.svelte";
  import { searchCrmContacts, addCrmContact } from "#src/api/crm.js";

  export let border = false;
  export let label = "Label";
  export let labelWidth: string | null = null;
  export let value;
  export let disabled = false;
  export let placeholder = "";
  export let outerBorder = true;
  export let orgid;

  const dispatch = createEventDispatcher();
  let timer;

  // const newContact = {
  //   id: null,
  //   type: "new",
  //   name: "+ Add Contact",
  //   cta: "Click to add new contact",
  //   inputs: [
  //     { label: "Name", type: "text", required: true, prop: "name" },
  //     { label: "Organization", type: "text", prop: "organization" },
  //     { label: "Email", type: "email", required: true, prop: "email" },
  //   ],
  // };

  let searchString = "";
  let results = [];

  function debounce(delay, func) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func();
    }, delay);
  }

  function select(e) {
    const { result } = e.detail;

    dispatch("input", {
      value: {
        id: result.id,
        platform: result.platform,
        name: result.name,
        organization: result.organization,
        email: result.email,
      },
    });
  }

  function deselect() {
    dispatch("input", { value: null });
  }

  async function add(e) {
    const { result } = e.detail;
    const contact = await addCrmContact(result);
    if (!contact.error) {
      dispatch("input", { value: contact.data });
    }
  }

  async function fetchContacts() {
    const c = await searchCrmContacts({ search_string: searchString, organization_id: orgid });
    results = c.data.map((contact) => ({
      ...contact,
      description: contact.organization,
      cta: contact.email,
    }));
  }

  function search() {
    debounce(500, fetchContacts);
  }
</script>

<div class="relative">
  <SearchSelect
    {placeholder}
    {border}
    {outerBorder}
    {labelWidth}
    {label}
    {results}
    selection={value}
    {disabled}
    bind:value={searchString}
    on:select={select}
    on:add={add}
    on:deselect={deselect}
    on:search={search}
    on:focus={search}>
  </SearchSelect>
</div>
