<script>
  import get from "lodash/get";
  import { createEventDispatcher, getContext } from "svelte";
  import SidebarSection from "./SidebarSection.svelte";
  import NewLocationButtons from "./NewLocationButtons.svelte";
  import { bucketArray } from "overline";
  import { docLocationTab as tab } from "#src/stores/ui.js";
  import Viewport from "#lib/drawing/ViewportFixed.svelte";
  import liteDrawingMini from "@local/extensions/drawing/lite-drawing-mini.js";
  import formatDims from "@local/extensions/formatters/format-dims.js";
  import locationsByDocument from "#src/extensions/locations-by-document.js";

  export let group;
  export let document;
  export let items;
  export let types;
  export let collections;
  export let adding;
  export let hoveredLocations;

  const dispatch = createEventDispatcher();
  const typeColors = getContext("typeColors");

  $: docLocations = locationsByDocument($group.locations);
  $: locations = docLocations[document.id] || [];
  $: updateTab(adding);
  $: records = selectRecords($tab, items, types, group);
  $: locationsByRecord = bucketArray(locations, "record_id");

  function updateTab(adding) {
    if (adding?.record_type && adding.record_type !== $tab) {
      $tab = adding.record_type;
    }
  }

  function selectRecords(tab, items, types, group) {
    if (tab === "item") return items;
    if (tab === "type") return types;
    if (tab === "collection") return collections;
    return [];
  }

  function description(item) {
    const mark = item.mark || "";
    const labelBy = item.is_collection
      ? $group.data.settings.collections_label_by
      : $group.data.settings.label_by;
    const label = (labelBy && get(item, labelBy)) || "";

    let textLbl = label && label !== mark ? `${mark} - ${label}` : mark;

    if (item.width && item.height) {
      return `${textLbl} - ${formatDims(item, $group.data.settings)}`;
    } else {
      return textLbl;
    }
  }

  function unhover(id) {
    hoveredLocations = {};
  }

  function hover(id) {
    const locations = locationsByRecord[id];
    if (locations) {
      hoveredLocations = locations.reduce((acc, loc) => {
        acc[loc.id] = true;
        return acc;
      }, {});
    }
  }
</script>

<div class="grow overflow-hidden flex flex-col">
  <SidebarSection>
    <h3 class="mb-2 px-2">Locations</h3>
    <div class="px-2 flex items-center justify-between">
      <div class="flex items-center gap-2">
        <button class="location-tab" class:current={$tab === "item"} on:click={() => ($tab = "item")}
          >Items</button>
        <button
          class="location-tab"
          class:current={$tab === "collection"}
          on:click={() => ($tab = "collection")}>Openings</button>
        <button class="location-tab" class:current={$tab === "type"} on:click={() => ($tab = "type")}
          >Types</button>
      </div>
      <div class="text-gray-500">#</div>
    </div>
  </SidebarSection>

  <div class="overflow-y-auto">
    <SidebarSection>
      <div class="border-t">
        {#each records as record}
          {@const current = adding?.record_id === record?.id}
          {@const locQty = locationsByRecord[record.id]?.length || 0}
          {@const hovered = locationsByRecord[record.id]?.some((l) => hoveredLocations[l.id])}
          {#key record.id}
            <!-- svelte-ignore a11y-no-static-element-interactions -->
            <div
              class="flex w-full justify-between py-1 px-2 border hover:border-gray-300 rounded gap-2"
              class:bg-amber-100={current}
              class:border-amber-500={current}
              class:border-transparent={!hovered}
              class:border-gray-400={hovered}
              on:mouseout={() => unhover(record.id)}
              on:mouseover={() => hover(record.id)}
              on:focus={() => hover(record.id)}
              on:blur={() => unhover(record.id)}>
              {#if $tab === "type"}
                <div class="flex gap-2 grow overflow-hidden">
                  <NewLocationButtons
                    record_type={$tab}
                    record_id={record.id}
                    on:add-location
                    on:clone-location />
                  <button
                    class="truncate grow text-left"
                    on:click={() =>
                      dispatch("click-locations", { locations: locationsByRecord[record.id] || [] })}
                    on:dblclick={() =>
                      dispatch("dblclick-locations", { locations: locationsByRecord[record.id] || [] })}
                    >{record.mark} - {record.name}</button>
                </div>
              {:else}
                <div class="flex gap-2 grow overflow-hidden">
                  <NewLocationButtons
                    record_type={$tab}
                    record_id={record.id}
                    on:add-location
                    on:clone-location />
                  <button
                    class="truncate grow text-left flex gap-2"
                    on:click={() =>
                      dispatch("click-locations", { locations: locationsByRecord[record.id] || [] })}
                    on:dblclick={() =>
                      dispatch("dblclick-locations", { locations: locationsByRecord[record.id] || [] })}>
                    <div class="flex gap-2">
                      <div class="w-4 h-4 relative">
                        <Viewport
                          padding={1}
                          drawing={liteDrawingMini(record, { typeColor: $typeColors[record.type_id] })}
                          width={16}
                          height={16} />
                      </div>
                      <div class="truncate">
                        {description(record)}
                      </div>
                    </div>
                  </button>
                </div>
              {/if}
              <div
                class:text-gray-500={locQty <= record.quantity}
                class:text-black={locQty >= record.quantity}
                class:font-bold={locQty > record.quantity}
                class:italic={locQty > record.quantity}
                class="whitespace-nowrap">
                {locationsByRecord[record.id]?.length || 0}
                {#if $tab === "item"}
                  / {record.quantity}
                {/if}
              </div>
            </div>
          {/key}
        {/each}
      </div>
    </SidebarSection>
  </div>

  <SidebarSection>
    <div
      class="flex justify-end py-1 px-2 border border-transparent rounded"
      class:bg-amber-100={adding && !adding.record_id}
      class:border-amber-500={adding && !adding.record_id}>
      <button
        class="font-bold"
        on:click={() => dispatch("add-location", { record_type: $tab, record_id: null })}>
        {#if $tab === "type"}
          + New type
        {:else if $tab === "collection"}
          + New opening
        {:else if $tab === "item"}
          + New item
        {/if}
      </button>
    </div>
  </SidebarSection>
</div>

<style lang="scss">
  .location-tab {
    @apply border-b py-1 text-gray-500;
    border-color: transparent;

    &.current {
      @apply text-black border-black;
    }
  }
</style>
