<script>
  import { createEventDispatcher } from "svelte";
  import set from "lodash/set";
  import get from "lodash/get";
  import unset from "lodash/unset";
  import cloneDeep from "lodash/cloneDeep";
  import SelectInput from "#lib/sidebar/SelectInput.svelte";
  import { Datagrid } from "datagrid";
  import { nextMark, incrementMark } from "@local/extensions/identifiers/mark.js";
  import {
    sortableList,
    sortArrayBy,
    addToSortableList,
    removeFromSortableList,
  } from "@local/extensions/collections/sortable-list.js";
  import { hashify } from "overline";
  import removeEmptyDims from "#src/extensions/remove-empty-dims.js";

  export let group;
  export let data;
  export let schema;
  export let items;
  export let newCols = {};
  export let importUnit;
  export let pasteImportType;

  const dispatch = createEventDispatcher();

  let order = schema.map((col) => col.prop);
  let emptyData = [
    Array(schema.length).fill(undefined),
    Array(schema.length).fill(undefined),
    Array(schema.length).fill(undefined),
    Array(schema.length).fill(undefined),
  ];

  $: columns = schema.map((col) => {
    const { validator, default: d, ...c } = col;

    if (c.type === "multi-column") {
      c.subcolumns = c.subcolumns.map((subcol) => {
        const { validator, default: d, ...sc } = subcol;
        return sc;
      });
    }

    if (["quantity", "type_id", "collection_id", "item_template"].includes(c.prop)) {
      delete c.type;
    }

    if (newCols[c.prop]) {
      c.deletable = true;
    }

    return c;
  });
  $: orderedCols = sortArrayBy(columns, order, "prop");
  $: invalidData = isDataInvalid(data, emptyData);

  function prev() {
    dispatch("prev");
  }

  function isDataInvalid(data, emptyData) {
    if (pasteImportType === "show_column_header") {
      const hasData = data.some((row) => {
        return Object.values(row).some((val) => val !== undefined && val !== "");
      });
      return !hasData;
    } else {
      const ordered = removeEmptyDims(emptyData);
      return ordered.length < 2;
    }
  }

  async function addColumn(evt) {
    const name = evt.detail;
    const d = cloneDeep($group.data);
    const cc = get(d, "custom_columns") || sortableList([]);
    const id = crypto.randomUUID();
    addToSortableList(cc, {
      id,
      name,
      type: "text",
    });
    newCols[`custom_column_data.${id}`] = true;

    d.custom_columns = cc;
    group.updateProp("data", d);
  }

  function deleteColumn(evt) {
    const index = evt.detail;
    const col = columns[index];
    const idMatch = col.prop.match(/^custom_column_data\.(.+)$/);
    const id = idMatch[1];

    const groupdata = cloneDeep($group.data);
    removeFromSortableList(groupdata.custom_columns, id);

    // Delete data from each item
    data.forEach((row) => {
      unset(row, col.prop);
    });
    delete newCols[col.prop];

    data = data;
    newCols = newCols;

    // Delete column
    group.updateProp("data", groupdata);
  }

  function moveColumns(e) {
    const { columns, target } = e.detail;
    const prevOrder = orderedCols.map((c) => c.prop);
    const l = columns.findIndex((c) => c > target);
    const shiftedTarget = l === -1 ? target - columns.length : target - l;
    const moved = columns.map((index) => prevOrder[index]);
    const chash = hashify(moved);
    const newOrder = prevOrder.filter((c) => !chash[c]);
    newOrder.splice(shiftedTarget, 0, ...moved);
    order = newOrder;
  }

  function nextColumnHeader() {
    const sobj = schema.reduce((o, col) => {
      if (col.type === "multi-column") {
        col.subcolumns.forEach((sc) => {
          o[sc.prop] = sc;
        });
      } else {
        o[col.prop] = col;
      }

      return o;
    }, {});

    const rowsWithData = data.filter((row) => Object.values(row).some((val) => val !== undefined));

    const columns_used = Object.keys(
      rowsWithData.reduce((cols, row) => {
        Object.keys(row).forEach((prop) => {
          if (row[prop] !== undefined && row[prop] !== "") {
            cols[prop] = true;
          }
        });
        return cols;
      }, {}),
    );

    const mapped = rowsWithData.map((row) => {
      const nr = {};

      Object.keys(row).forEach((prop) => {
        const col = sobj[prop];

        let val;
        if (!row[prop]) {
          val = typeof col.default === "function" ? col.default() : col.default;
        } else {
          val = row[prop];
        }

        set(nr, prop, val);
      });

      return nr;
    });

    // set default marks if undefined
    let next = nextMark([...items, mapped]);
    mapped.forEach((row) => {
      if (!row.mark) {
        row.mark = next;
      }
      next = incrementMark(next);
    });

    dispatch("next", { mapped, columns_used });
  }

  function nextPasteHeader() {
    const ordered = removeEmptyDims(emptyData);
    ordered[0] = ordered[0]?.map((h) => h?.toString() || "");
    dispatch("next", { ordered });
  }

  function next() {
    if (pasteImportType === "show_column_header") {
      nextColumnHeader();
    } else {
      nextPasteHeader();
    }
  }
</script>

<div class="space-y-4 flex flex-col overflow-hidden">
  <div class="flex justify-between items-center">
    <p>Add data to spreadsheet.</p>
    <div style="max-width:24rem;" class="text-xs">
      <SelectInput
        label={null}
        bind:value={pasteImportType}
        options={[
          { label: "Show Column Header", value: "show_column_header" },
          { label: "Paste Header & Data", value: "paste_header_data" },
        ]} />
    </div>
  </div>
  {#if pasteImportType === "show_column_header"}
    <Datagrid
      columns={orderedCols}
      bind:data
      rowDelete
      emptyRow
      columnAddable
      reorderable
      on:addColumn={addColumn}
      on:moveColumns={moveColumns}
      on:deleteColumn={deleteColumn} />
  {:else}
    <Datagrid bind:data={emptyData} colHeader={false} columnsPasteable emptyRow />
  {/if}
  <div class="flex justify-between items-center">
    <div style="max-width: 24rem;">
      <SelectInput
        label="Default Import Unit"
        bind:value={importUnit}
        options={[
          { label: "Inches", value: "inches" },
          { label: "Millimeters", value: "millimeters" },
        ]} />
    </div>
    <div class="flex gap-2 items-center">
      <button class="btn w-32" on:click={prev}>Previous</button>
      <button class="btn btn-primary w-32" on:click={next} disabled={invalidData}>Next</button>
    </div>
  </div>
</div>
