<script>
  import { getContext } from "svelte";
  import { navigate } from "svelte-routing";

  import Datatable from "#lib/datatable/Datatable.svelte";
  import ErrorMessage from "#lib/standard-inputs/ErrorMessage.svelte";

  import { orgError } from "#src/stores/org.js";
  import formatDateTime from "@local/extensions/formatters/format-date-time.js";
  import { profile } from "#src/stores/auth.js";

  const org = getContext("org");

  let newUser = null;

  $: canAdmin = $profile && ["org_admin", "developer"].includes($profile.user_role);
  $: userProps = [
    {
      label: "",
      prop: "user_color",
      type: "color",
      editable: false,
    },
    {
      label: "Name",
      prop: "username",
      type: "text",
    },
    {
      label: "Email",
      prop: "email",
      type: "text",
    },
    {
      label: "Role",
      prop: "user_role",
      type: "text",
      editable: false,
    },
    {
      label: "Confirmed",
      prop: "confirmed_at",
      formatter: (v) => (v ? "✓" : ""),
      editable: false,
    },
    ...($org?.org_type === "supplier"
      ? [
          {
            label: "Public",
            prop: "is_public",
            type: "boolean",
          },
        ]
      : []),
    {
      label: "Deactivated",
      prop: "disabled_at",
      formatter: (v) => (v ? "✓" : ""),
      editable: false,
    },
    {
      label: "Last sign in",
      prop: "last_sign_in_at",
      formatter: formatDateTime,
      editable: false,
    },
  ];

  function beginAddingUser() {
    newUser = {
      email: "",
      username: "",
      organization_id: $org.id,
    };
  }

  function clickUser(evt) {
    const { id } = evt.detail;
    const isUser = $profile && $profile.id === id;
    if (!canAdmin && !isUser) return;
    navigate(`/settings/users/${id}`);
  }

  function addNewUser(evt) {
    const { row } = evt.detail;
    org.addUser({
      ...row,
      send_magic_link: true,
    });
    newUser = null;
  }

  function stopAddingUser() {
    newUser = null;
  }

  function removeUser(evt) {
    const { id } = evt.detail;
    org.removeUser(id);
  }
</script>

<div class="px-4 pb-4 border-b border-gray-300">
  <h2>Team - {$org.name}</h2>
</div>

<div class="py-4 sm:px-4 flex justify-end items-center">
  {#if canAdmin}
    <button class="btn btn-primary" on:click={beginAddingUser}>+ Add Team Member</button>
  {/if}
</div>

<ErrorMessage message={$orgError} on:close={() => ($orgError = null)} />
<div class="bg-white border rounded-lg overflow-hidden">
  <Datatable
    data={$org.profiles}
    columns={userProps}
    newRow={newUser}
    deletable
    clickable
    on:clickrow={clickUser}
    on:addrow={addNewUser}
    on:deleterow={removeUser}
    on:stopaddingrow={stopAddingUser} />
</div>
