<script>
  import { appStatus } from "#src/stores/app-status.js";

  function formatMessage(message) {
    let entries = [];

    const keys = ["org", "job", "on", "mode", "api", "dev", "db"];

    for (const key of keys) {
      const value = message[key];
      if (typeof value === "string") {
        entries.push(`${key}: ${value}`);
      }
    }

    return entries.join("&emsp;");
  }


  $: messages = formatMessage($appStatus);
  $: showStatus = typeof $appStatus.mode === "string" || $appStatus.role === "developer";
</script>

<div class="flex-none h-8 px-4 leading-8 text-xs text-gray-500 bg-black">
  <div class="flex flex-row justify-between">
    <div>&copy; Copyright {new Date().getFullYear()} Diagraphics Inc.</div>
    {#if showStatus}
      <div>{@html messages}</div>
    {/if}
  </div>
</div>
