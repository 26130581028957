<script>
  import { createEventDispatcher, tick, getContext } from "svelte";
  import set from "lodash/set";
  import cloneDeep from "lodash/cloneDeep";
  import SelectInput from "#lib/sidebar/SelectInput.svelte";
  import SaveIcon from "@local/assets/icons/save.svg";
  import CheckIcon from "@local/assets/icons/check.svg";
  import { sortableList, addToSortableList } from "@local/extensions/collections/sortable-list.js";
  import { clickOutside } from "svelte-utilities";
  import { nextMark, incrementMark } from "@local/extensions/identifiers/mark.js";
  import parseDim from "@local/extensions/parsers/parse-dim.js";
  import DropGrid from "../DropGrid.svelte";

  export let group;
  export let data;
  export let items;
  export let schema;
  export let importUnit;
  export let hiddenColumns;

  const org = getContext("groupOrg");

  const dispatch = createEventDispatcher();

  let values = [];

  let addingColumn = false;
  let newColumnInput;
  let newColumnButton;
  let newColumnLabel = "";
  let savedMapping = null;
  let savedMappingInput;
  let showSavedMappingInput = false;
  let newMappingName = "";

  $: settings = $group.data.settings;
  $: savedMappings = $org.data.saved_mappings || [];
  $: columns = schema.reduce((cols, col) => {
    if (col.id === "width") {
      cols.push({
        ...col.subcolumns[0],
        label: col.label,
      });

      if (!settings.hide_width_offset) {
        cols.push({
          ...col.subcolumns[1],
          label: `${col.label} Offset`,
        });
      }
    } else if (col.id === "height") {
      cols.push({
        ...col.subcolumns[0],
        label: col.label,
      });

      if (!settings.hide_height_offset) {
        cols.push({
          ...col.subcolumns[1],
          label: `${col.label} Offset`,
        });
      }
    } else {
      cols.push(col);
    }

    return cols;
  }, []);
  $: truncatedData = data.slice(0, 6);
  $: values = guessValues(columns, data);
  $: dimParser = parseDim(importUnit);

  function namesAreSimilar(a, b) {
    if (typeof a !== "string" || typeof b !== "string") return false;
    if (a.toLowerCase().trim() === b.toLowerCase().trim()) return true;
    if (
      a
        .toLowerCase()
        .split(/[\s_]+/)
        .join()
        .trim() ===
      b
        .toLowerCase()
        .split(/[\s_]+/)
        .join()
        .trim()
    )
      return true;
    return false;
  }

  function getCols(data) {
    return data[0].map((c, i) => ({
      label: c || "(empty)",
      value: i,
      prop: c || crypto.randomUUID(),
    }));
  }

  function guessValues(columns, data) {
    const cols = getCols(data);

    const assigned = {};

    return columns.map((column, index) => {
      if (values[index] !== undefined && values[index] !== null) {
        return values[index];
      }

      const colIndex = cols.findIndex((c) => namesAreSimilar(c.label, column.label));
      if (colIndex === -1) return null;

      if (assigned[colIndex]) return null;
      assigned[colIndex] = true;
      return colIndex;
    });
  }

  function next() {
    const schema_map = columns.reduce((o, s, i) => {
      if (values[i] !== null && values[i] !== undefined && values[i] !== "unmapped") {
        o[s.prop] = values[i];
      }
      return o;
    }, {});

    const mapped = data
      .slice(1)
      .map((row) => {
        let keep = false;

        const obj = columns.reduce((o, datum) => {
          const index = schema_map[datum.prop];
          if (index !== undefined) {
            let val = row[index];
            if (val !== undefined) keep = true;

            // We assume all imported values are strings
            // (True in case of CSV, not in case of Excel)
            if (typeof val === "number") val = val.toString();

            // Parse the value if a parser is present
            if (datum.parser && val !== undefined) {
              try {
                val = datum.parser(val);
              } catch (error) {
                val = datum.default;
              }
            }

            // Handle width_offset and height_offset, which cannot
            // have null values
            if (datum.prop === "width_offset" || datum.prop === "height_offset") {
              if (val === undefined || val === null) {
                val = dimParser("0");
              }
            }

            set(o, datum.prop, val);
          } else if (typeof datum.default === "function") {
            set(o, datum.prop, datum.default());
          } else if (datum.default !== undefined) {
            set(o, datum.prop, datum.default);
          } else {
            set(o, datum.prop, undefined);
          }
          return o;
        }, {});

        // Rows with only undefined props will be excluded
        if (!keep) return null;

        return obj;
      })
      .filter((r) => !!r);

    // set default marks if undefined
    let next = nextMark([...items, mapped]);
    mapped.forEach((row) => {
      if (!row.mark) {
        row.mark = next;
      }
      next = incrementMark(next);
    });

    const columns_used = Object.keys(schema_map);
    dispatch("next", { mapped, columns_used, schema_map });
  }

  function prev() {
    dispatch("prev");
  }

  async function beginAddingColumn() {
    newColumnLabel = "";
    addingColumn = true;
    await tick();
    newColumnInput.focus();
  }

  function keydown(e) {
    if (e.key === "Enter" && newColumnLabel !== "") {
      const data = cloneDeep($group.data);
      const cc = data.custom_columns || sortableList([]);

      addToSortableList(cc, {
        id: crypto.randomUUID(),
        name: newColumnLabel,
        type: "text",
      });

      data.custom_columns = cc;
      group.updateProp("data", data);
      values.push(null);

      stopAddingColumn();
    }
  }

  async function saveNewMapping() {
    showSavedMappingInput = true;
    await tick();
    savedMappingInput.focus();
  }

  function selectSavedMapping(e) {
    savedMapping = e.detail.value;
    if (savedMapping === null) {
      values = guessValues(columns, data);
      return;
    }
    const saved = savedMappings.find((m) => m.id === savedMapping);
    values = columns.map((col, i) => saved.columns[i] ?? null);
  }

  function saveMapping() {
    if (newMappingName === "") return;

    const data = cloneDeep($org.data);
    const mappings = data.saved_mappings || [];
    const newMapping = {
      id: crypto.randomUUID(),
      name: newMappingName,
      columns: values,
    };
    mappings.push(newMapping);
    data.saved_mappings = mappings;
    org.updateProp("data", data);

    savedMapping = newMapping.id;
    showSavedMappingInput = false;
    newMappingName = "";
  }

  function deleteMapping(e) {
    const id = e.detail.value;
    const data = cloneDeep($org.data);
    data.saved_mappings = data.saved_mappings.filter((m) => m.id !== id);
    org.updateProp("data", data);
  }

  function stopAddingColumn() {
    addingColumn = false;
  }

  function clickNewColumnButton() {
    if (addingColumn) return;
    beginAddingColumn();
  }
</script>

<div class="space-y-4 flex flex-col overflow-hidden">
  <div class="w-full">
    <div class="flex justify-between items-center">
      <p class="mb-4">Drag the property to its corresponding column.</p>
      <div class="flex gap-2 items-center">
        <SelectInput
          label="Saved Mapping"
          bind:value={savedMapping}
          deletable
          on:input={selectSavedMapping}
          on:delete-option={deleteMapping}
          options={[{ label: "None", value: null, deletable: false }].concat(
            savedMappings.map((m) => ({ label: m.name, value: m.id })),
          )} />
        <div class="relative">
          <button class="btn btn-icon" on:click={saveNewMapping}>
            <SaveIcon />
          </button>
          {#if showSavedMappingInput}
            <div
              class="absolute right-0 mt-1 p-4 border rounded bg-white shadow-lg flex gap-2 items-center text-xs w-52 z-10"
              use:clickOutside
              on:outclick={() => (showSavedMappingInput = false)}>
              <input
                bind:this={savedMappingInput}
                bind:value={newMappingName}
                type="text"
                placeholder="Name"
                class="new-name-input p-2 border rounded grow"
                on:keydown={(e) => {
                  if (e.key === "Enter") {
                    saveMapping();
                  }
                }} />
              <button class="flex-none" on:click={saveMapping}>
                <CheckIcon />
              </button>
            </div>
          {/if}
        </div>
      </div>
    </div>

    <DropGrid options={columns} data={truncatedData} {values} {columns} bind:hiddenColumns>
      <button
        slot="new-button"
        class="rounded border border-gray-300 border-dashed cursor-pointer"
        on:click={clickNewColumnButton}
        bind:this={newColumnButton}>
        {#if addingColumn}
          <input
            bind:this={newColumnInput}
            use:clickOutside={[newColumnButton]}
            type="text"
            placeholder="Column name"
            on:keydown={keydown}
            on:blur={stopAddingColumn}
            on:outclick={stopAddingColumn}
            bind:value={newColumnLabel} />
        {:else}
          <div class="py-1 px-2">+</div>
        {/if}
      </button>
    </DropGrid>
    <p class="text-gray-400 px-2">(Data shown here may be truncated)</p>
  </div>

  <div class="flex justify-between items-center">
    <div style="max-width: 24rem">
      <SelectInput
        label="Default Import Unit"
        bind:value={importUnit}
        options={[
          { label: "Inches", value: "inches" },
          { label: "Millimeters", value: "millimeters" },
        ]} />
    </div>
    <div class="flex gap-2 items-center">
      <button class="btn w-32" on:click={prev}>Previous</button>
      <button class="btn btn-primary w-32" on:click={next}>Next</button>
    </div>
  </div>
</div>

<style lang="scss">
  input {
    @apply rounded;
    min-width: 10px;
    max-width: 100%;
    margin: 0;
    padding: 0.25rem;
    width: 6rem;
    height: 100%;
  }
</style>
