<script>
  import isEmpty from "lodash/isEmpty";
  import cloneDeep from "lodash/cloneDeep";
  import { getContext } from "svelte";
  import SortableThumbnailList from "#lib/SortableThumbnailList.svelte";
  import ListThumbnail from "#lib/ListThumbnail.svelte";
  import SidebarTitle from "#lib/sidebar/SidebarTitle.svelte";
  import Sidebar from "#lib/sidebar/Sidebar.svelte";
  import PrevNext from "#lib/sidebar/PrevNext.svelte";
  import ProductListManager from "#lib/sidebar/ProductListManager.svelte";
  import ProductListSettings from "#lib/sidebar/ProductListSettings.svelte";
  import CategoryProperties from "#lib/sidebar/CategoryProperties.svelte";

  import { selectedCategories, showRightPanel } from "#src/stores/ui.js";
  import {
    orderedList,
    addToSortableList,
    removeFromSortableList,
  } from "@local/extensions/collections/sortable-list.js";
  import imageUrl from "#src/extensions/image-url.js";

  import makeMultiCategory from "#src/extensions/multi-category.js";
  import { api } from "#src/api";

  export let disabled;

  const categories = getContext("categories");
  const org = getContext("org");

  let width;
  let height;

  $: ordered = orderedList($categories);
  $: selectedList = ordered.filter((t) => $selectedCategories[t.id]);
  $: selected = findSelected(selectedList);
  $: selectedIndex = ordered.indexOf(selectedList[0]);
  $: multiProduct = makeMultiCategory(selectedList);
  $: prev = selectedList.length === 1 && ordered[selectedIndex - 1];
  $: next = selectedList.length === 1 && ordered[selectedIndex + 1];

  function findSelected(s) {
    if (s.length !== 1) return null;

    const t = s[0];

    return t;
  }

  function select(e) {
    const { event, id } = e.detail;

    if (event.shiftKey && !isEmpty($selectedCategories)) {
      const allIds = ordered.map((t) => t.id);
      const indices = ordered
        .map((t, i) => i)
        .filter((i) => $selectedCategories[ordered[i].id] || ordered[i].id === id);

      const min = Math.min(...indices);
      const max = Math.max(...indices);

      const ids = allIds.slice(min, max + 1);
      selectedCategories.selectOnly(...ids);
    } else if (event.metaKey) {
      if (!$selectedCategories[id]) {
        selectedCategories.select(id);
      } else {
        selectedCategories.deselect(id);
      }
    } else {
      selectedCategories.selectOnly(id);
    }
  }

  function selectNone() {
    $selectedCategories = {};
  }

  function gotoPrev() {
    if (prev) selectedCategories.selectOnly(prev.id);
  }

  function gotoNext() {
    if (next) selectedCategories.selectOnly(next.id);
  }

  async function updateCategory(e) {
    const { id, prop, value } = e.detail;
    $categories[id][prop] = value;

    await api
      .from("product_categories")
      .update({ [prop]: value })
      .eq("id", id);
  }

  async function addCategory() {
    const id = crypto.randomUUID();
    const name = "New Category";

    const category = { id, name, image: null, organization_id: $org.id };

    addToSortableList($categories, category);
    $categories = $categories;

    await api.from("product_categories").insert(category);
  }

  async function cloneCategory(e) {
    const { selected } = e.detail;
    const cloned = selected
      .filter((id) => $categories[id])
      .map((id) => {
        const cat = $categories[id];

        return {
          ...cat,
          id: crypto.randomUUID(),
          name: `${cat.name} (Copy)`,
        };
      });

    addToSortableList($categories, ...cloned);
    $categories = $categories;

    await api.from("product_categories").insert(cloned);
  }

  async function removeCategory(e) {
    const { selected } = e.detail;
    removeFromSortableList($categories, selected);
    $categories = $categories;

    await api.from("product_categories").delete().in("id", selected);
  }

  async function reorder(e) {
    const { insertion, dragging } = e.detail;
    const order = cloneDeep($categories.order);
    const moved = order.splice(dragging, 1)[0];
    order.splice(insertion, 0, moved);
    $categories.order = order;

    const orgData = $org.data;
    orgData.product_category_order = order;
    await api.from("organizations").update({ data: orgData }).eq("id", $org.id);
  }
</script>

<div class="bg-gray-100 h-full flex" bind:offsetWidth={width} bind:offsetHeight={height}>
  <SortableThumbnailList
    list={ordered}
    {disabled}
    selected={$selectedCategories}
    let:record
    reorderable
    on:select={select}
    on:select-none={selectNone}
    on:add={addCategory}
    on:reorder={reorder}
    on:clone-selected={cloneCategory}
    on:remove-selected={removeCategory}>
    <ListThumbnail {record} wrapText>
      <div class="w-full h-full relative">
        {#if record.image}
          <img src={imageUrl(record.image)} alt={record.name} class="w-full h-full object-cover text-xs" />
        {/if}
      </div>
    </ListThumbnail>
  </SortableThumbnailList>

  {#if $showRightPanel && width >= 640}
    <Sidebar>
      <svelte:fragment slot="header">
        {#if selectedList.length === 1 && selected}
          <PrevNext
            {prev}
            {next}
            on:gotoprev={gotoPrev}
            on:gotonext={gotoNext}
            sticky
            title={selected.name} />
        {/if}
      </svelte:fragment>
      <svelte:fragment slot="content">
        {#if selectedList.length === 0}
          <!-- <div class="px-6 text-xs italic">No {recordName}s selected.</div> -->
          <ProductListManager />
        {:else if selectedList.length === 1}
          {#key selected.id}
            <CategoryProperties category={selected} {disabled} {org} on:update={updateCategory} />
          {/key}
        {:else}
          {#key multiProduct.id}
            <SidebarTitle title="Multiple Categories Selected" />
            <CategoryProperties category={multiProduct} {disabled} {org} />
          {/key}
        {/if}
      </svelte:fragment>
    </Sidebar>
  {/if}
</div>
