<script>
  import { getContext, createEventDispatcher } from "svelte";
  import upperFirst from "lodash/upperFirst";
  import { Dimension, LinearDisplayFormat } from "dimtext";

  import Viewport from "#lib/drawing/ViewportFixed.svelte";
  import QuantityInput from "#lib/standard-inputs/QuantityInput.svelte";
  import typeDrawing from "@local/extensions/drawing/type-drawing.js";
  import liteDrawing from "@local/extensions/drawing/lite-drawing-mini.js";
  import TrashIcon from "@local/assets/icons/trash.svg";
  import PriceOverrideInput from "./PriceOverrideInput.svelte";
  import getTotalThickness from "@local/extensions/formatters/get-total-thickness.js";
  import dimSettings from "@local/extensions/utilities/dim-settings.js";
  import { typeSurfaces } from "@local/lamina-core";
  import imageUrl from "#src/extensions/image-url.js";
  import { itemPriceEntry } from "@local/lamina-core/reporters";

  export let group;
  export let type;
  export let items;
  export let index = 1;
  export let typeIndex;
  export let jobProperties;
  export let job;
  export let sent;
  export let itemPrices;
  export let productList;
  export let fabrications = 0;

  const dispatch = createEventDispatcher();

  const typeColors = getContext("typeColorsTrue");
  const priceEntries = getContext("priceEntries");
  const supplier = getContext("supplier");

  $: du = $group.data.settings.display_unit;
  $: typeSettings = {
    display_unit: $group.data.settings.display_unit,
    mm_precision: $group.data.settings.mm_precision,
    decimal_precision: 3,
    fractional_precision: 5,
    dimension_format: $group.data.settings.dimension_format,
  };
  $: ds = dimSettings($group.data.settings);
  $: thickness = type && getTotalThickness(type, typeSettings);
  $: surfaces = type && typeSurfaces(type);
  $: undefinedItems = items.filter((i) => !i.width || !i.height).length;
  $: productEntries = $priceEntries?.[type.product_id]?.[productList?.id];

  function formatThickness(val) {
    const sdf = "FRACTIONAL";

    if (!val) return "Thickness TBD";

    const v = val.format(LinearDisplayFormat[sdf], sdf === "DECIMAL" ? 3 : 5, {
      displayUnit: "inches",
    });

    return `${v}"`;
  }

  function getItemFabrications(item) {
    if (!item.data.fabrications) return 0;

    const voids = item.data.fabrications.voids?.length || 0;
    const edge = item.data.fabrications.edge?.length || 0;
    const corner = item.data.fabrications.corner?.length || 0;
    const virtual = item.data.fabrications.virtual?.length || 0;

    return voids + edge + corner + virtual;
  }

  function getDimensions(item, type, ds) {
    if (!item) return null;
    if (item.width && item.height) {
      const fmt = {
        DECIMAL: LinearDisplayFormat.DECIMAL,
        FRACTIONAL: LinearDisplayFormat.FRACTIONAL,
      }[ds.dimFormat];
      const unit = ds.displayUnit === "millimeters" ? "mm" : '"';
      const wdim = new Dimension(item.width);
      const hdim = new Dimension(item.height);

      const wtext = wdim.format(fmt, ds.dimPrecision, {
        displayUnit: ds.displayUnit,
        unicodeNumerals: true,
        unicodeSlash: true,
        fractionSeparator: false,
      });

      const htext = hdim.format(fmt, ds.dimPrecision, {
        displayUnit: ds.displayUnit,
        unicodeNumerals: true,
        unicodeSlash: true,
        fractionSeparator: false,
      });

      return `${wtext}${unit} x ${htext}${unit}`;
    }
  }

  function getArea(item) {
    return item.width && item.height ? (item.cache.width / 12) * (item.cache.height / 12) * item.quantity : 0;
  }

  function formattedArea(area, du) {
    return du === "millimeters" ? `${(area * 0.092903).toFixed(2)} m²` : `${area.toFixed(2)} sf`;
  }

  function getWeight(type, item) {
    if (type?.data.layers) {
      if (!item.cache?.weight) return "0 lbs";
      return `${item.cache.weight.toFixed(0)} lbs`;
    } else {
      return "";
    }
  }

  function undefinedItemText(u) {
    if (!u) return "";
    if (u === 1) return " (1 undefined item)";
    return ` (${u} undefined items)`;
  }

  function constraint(entry, prop) {
    const max = entry[`maximum_${prop}`];
    const min = entry[`minimum_${prop}`];
    const p = prop.replace("_", " ");
    if (max != null && min != null) {
      return `${min} ≤ ${p} < ${max}`;
    } else if (max != null) {
      return `${p} < ${max}`;
    } else if (min != null) {
      return `${min} ≤ ${p}`;
    }

    return null;
  }

  function getPrice(itemPrices, item) {
    return itemPrices?.[item.id] || 0;
  }

  function formattedPriceEntry(entry) {
    if (!entry) return "";
    const constraints = ["area", "item_area", "item_quantity"]
      .map((prop) => constraint(entry, prop))
      .filter((c) => c);

    if (constraints.length) {
      return `(${constraints.join(", ")})`;
    } else {
      return "";
    }
  }
</script>

<tbody>
  {#each items as item, itemIndex}
    {@const size = getDimensions(item, type, ds)}
    {@const area = getArea(item)}
    {@const areaFormatted = formattedArea(area, du)}
    {@const weight = getWeight(type, item)}
    {@const quantity = item.quantity}
    {@const priceEntry = itemPriceEntry(item, productEntries, jobProperties, type.pricing_group)}
    {@const priceEach = getPrice(itemPrices, item)}
    {@const definedPrice = itemPrices?.[item.id] != null}
    {@const peText = formattedPriceEntry(priceEntry)}
    {@const priceTotal = priceEach * quantity}
    {@const itemFabrications = getItemFabrications(item)}
    <tr>
      <td>
        {typeIndex + itemIndex + 1}
      </td>
      <td>
        <div class="w-full">
          <div class="font-bold">{type.name}</div>
          <div class="flex gap-2">
            <div class="w-24 h-24 relative flex-none">
              {#if type.use_type_image_as_thumbnail}
                <img src={imageUrl(type.image)} alt={type.name} class="text-xs w-full h-full object-cover" />
              {:else if item.width && item.height && type.use_item_drawing_as_thumbnail}
                <div class="bg-gray-100 w-full h-full">
                  <Viewport
                    padding={8}
                    drawing={liteDrawing(item, { typeColor: $typeColors?.[item.type_id] })}
                    width={96}
                    height={96} />
                </div>
              {:else if !type.data.layers && type.image}
                <img src={imageUrl(type.image)} alt={type.name} class="text-xs w-full h-full object-cover" />
              {:else}
                <Viewport padding={1} drawing={typeDrawing(type)} width={96} height={96} />
              {/if}
            </div>
            <div>
              <table class="type-info">
                {#if type?.data.layers}
                  {#if size}
                    <tr>
                      <td class="label">Size</td>
                      <td>
                        {size}
                      </td>
                    </tr>
                  {/if}
                  {#if type?.description}
                    <tr>
                      <td class="label">Description</td>
                      <td>
                        {type.description}
                      </td>
                    </tr>
                  {/if}
                  <tr>
                    <td class="label">Thickness</td>
                    <td>
                      {thickness || "TBD"}
                    </td>
                  </tr>
                  <tr>
                    <td class="label">Makeup</td>
                    <td>
                      {#each type.data.layers as layer, i}
                        <div>
                          <span>
                            {layer.type === "generic" ? "Layer" : upperFirst(layer.type)}:&nbsp;
                          </span>
                          <span>
                            {formatThickness(layer.material?.data?.thickness)}
                          </span>
                          {#if layer.material?.name}
                            <span>, {layer.material.name}</span>
                          {/if}
                          {#if layer.outboard_surface?.name}
                            <span>, #{surfaces[i]} {layer.outboard_surface.name}</span>
                          {/if}
                          {#if layer.inboard_surface?.name}
                            <span>, #{surfaces[i] + 1} {layer.inboard_surface.name}</span>
                          {/if}
                        </div>
                      {/each}
                    </td>
                  </tr>
                  <tr>
                    <td class="label">Area</td>
                    <td>
                      {areaFormatted}{undefinedItemText(undefinedItems)}
                    </td>
                  </tr>
                  {#if item.notes}
                    <tr>
                      <td class="label">Notes</td>
                      <td>
                        {item.notes}
                      </td>
                    </tr>
                  {/if}
                {/if}
                {#if peText}
                  <tr>
                    <td class="label">Pricing Tier</td>
                    <td>
                      {peText}
                    </td>
                  </tr>
                {/if}
              </table>
            </div>
          </div>
        </div>
      </td>
      <td class="numeric">
        {#if type?.data.layers}
          <div class="aligned-text">
            {areaFormatted}{undefinedItemText(undefinedItems)}
          </div>
        {/if}
      </td>
      <td class="numeric">
        <div class="aligned-text">
          {weight}
        </div>
      </td>
      {#if fabrications}
        <td class="numeric">
          <div class="aligned-text">
            {itemFabrications || ""}
          </div>
        </td>
      {/if}
      <td class="numeric flex gap-1 justify-end">
        <PriceOverrideInput
          {type}
          {item}
          {productList}
          computedPrice={itemPrices?.[item.id]}
          {jobProperties}
          disabled={!productList?.allow_price_override || sent}
          on:input={(e) => dispatch("update-item", { item, price_override: e.detail.value })} />
      </td>
      <td class="numeric">
        {#if !sent}
          <QuantityInput
            {quantity}
            on:input={(e) => dispatch("update-item", { quantity: e.detail.value, item })} />
        {:else}
          <div class="aligned-text">
            {quantity}
          </div>
        {/if}
      </td>
      <td class="numeric">
        <div class="aligned-text">
          {#if definedPrice}
            {priceTotal?.toFixed(2) || ""}
          {:else}
            <span class="text-red-500 font-bold">N/A</span>
          {/if}
        </div>
      </td>
      {#if !sent}
        <td>
          <button class="button-icon" on:click={() => dispatch("delete-item", { type, item })}>
            <TrashIcon />
          </button>
        </td>
      {/if}
    </tr>
  {/each}
</tbody>

<style lang="scss">
  td {
    @apply border-gray-300 align-top p-1;

    &.numeric {
      @apply text-right whitespace-nowrap;

      .aligned-text {
        @apply py-0.5;
      }
    }

    .button-icon {
      @apply text-gray-400 p-0.5 rounded;

      &:hover {
        @apply bg-gray-200 text-black;
      }
    }
  }

  table.type-info {
    td {
      padding: 0rem 0.25rem 0rem 0.25rem;
    }

    td.label {
      @apply text-gray-400 text-right;
    }
  }
</style>
