<!-- <svelte:options strictprops={false} /> -->

<script>
  import { onMount, setContext, getContext } from "svelte";
  import { writable, derived } from "svelte/store";
  import { Router, Route, useLocation } from "svelte-routing";
  import cloneDeep from "lodash/cloneDeep";
  import Mousetrap from "mousetrap";

  /* Components */
  import { Modal } from "svelte-utilities";
  import Redirect from "./Redirect.svelte";
  import Navbar from "#lib/Navbar.svelte";
  import Toolbar from "#lib/toolbar/OrgToolbar.svelte";
  import MaterialProperties from "#lib/sidebar/MaterialProperties.svelte";
  import SurfaceProperties from "#lib/sidebar/SurfaceProperties.svelte";
  import MakeupProperties from "#lib/sidebar/MakeupProperties.svelte";
  import ItemProductProperties from "#lib/sidebar/ItemProductProperties.svelte";
  import EdgeProductProperties from "#lib/sidebar/EdgeProductProperties.svelte";
  import FabProductProperties from "#lib/sidebar/FabProductProperties.svelte";
  import Viewport from "#lib/drawing/ViewportMinimal.svelte";
  import OrgProductList from "#lib/products/OrgProductList.svelte";
  import CategoriesList from "#lib/products/CategoriesList.svelte";
  import SearchSelect from "#lib/sidebar/SearchSelect.svelte";

  /* Lib Functions */
  import materialDrawing from "@local/extensions/drawing/material-drawing.js";
  import typeDrawing from "@local/extensions/drawing/type-drawing.js";

  /* Stores and event bus */
  import { profile } from "#src/stores/auth.js";
  import { createOrg } from "#src/stores/org.js";
  import {
    currentTool,
    navHistory,
    selectedSurfaces,
    selectedMaterials,
    selectedMakeups,
    selectedItemProds,
    selectedEdges,
    selectedFabrications,
  } from "#src/stores/ui.js";
  import { sortableList, orderedList } from "@local/extensions/collections/sortable-list.js";
  import { typeColorTrue } from "@local/extensions/drawing/type-color.js";
  import { createSurface, createMakeup, createMaterial, createItemProduct } from "@local/lamina-core";
  import eb from "#src/extensions/event-bus.js";
  import { orgLibrary } from "#src/extensions/makeup-library";
  import { api } from "#src/api";
  import sanitizePgString from "#src/extensions/sanitize-postgrest-string.js";
  import imageUrl from "#src/extensions/image-url.js";

  export let path = undefined;

  let confirmRemoveModal;
  let confirmShareModal;
  let productsToShare;
  let sharingProductType;
  let productsToDelete;
  let deletingProductType;
  let productFilter = "";

  let orgSearchString;
  let selectedOrg;
  let orgSearchResults = [];

  const org = getContext("org");
  const supplier = getContext("supplier");
  const edgeTreatments = getContext("edgeTreatments");
  const fabrications = getContext("fabrications");

  const location = useLocation();
  const orderedEt = derived(edgeTreatments, ($et) => orderedList($et));
  const orderedFabs = derived(fabrications, ($fab) => orderedList($fab));
  const makeupLibrary = derived(supplier, ($supplier) => orgLibrary($supplier));

  setContext("makeupLibrary", makeupLibrary);

  function transform(coll, xf, defaultValue = []) {
    return coll ? xf(coll) : defaultValue;
  }

  $: canAdmin = $profile && ["org_admin", "developer"].includes($profile.user_role);
  $: makeups = transform($org.makeups, orderedList);
  $: materials = transform($org.materials, orderedList);
  $: surfaces = transform($org.surfaces, orderedList);
  $: itemprods = transform($org.item_products, orderedList);
  $: tab = (path && path.split("/")[0]) || "types";
  $: disabled = !["org_admin", "developer"].includes($profile?.user_role);
  $: updateNavHistory($location);

  function updateFonts() {
    document.fonts.load('10pt "Menlo"');
  }

  function undo(evt) {
    evt.stopPropagation();
    org.undo();
  }

  function redo(evt) {
    evt.stopPropagation();
    org.redo();
  }

  function updateProduct(productType, update) {
    org.update([{ type: productType, ...update }]);
  }

  async function updateMultipleProducts(productType, update) {
    let selected;

    if (productType === "surface") {
      selected = $selectedSurfaces;
    } else if (productType === "material") {
      selected = $selectedMaterials;
    } else if (productType === "makeup") {
      selected = $selectedMakeups;
    } else if (productType === "item_product") {
      selected = $selectedItemProds;
    }

    const ids = Object.keys(selected);

    const updates = ids.map((id) => {
      return { ...update, type: productType, id };
    });

    await org.update(updates);
  }

  function addProduct(productType) {
    let product;
    if (productType === "surface") {
      product = createSurface();
    } else if (productType === "material") {
      product = createMaterial();
    } else if (productType === "makeup") {
      product = createMakeup();
    } else if (productType === "item_product") {
      product = createItemProduct();
    }

    product.id = crypto.randomUUID();
    product.vendor_id = $org.id;
    product.name = "New Product";

    org.addProduct(productType, [product]);
  }

  function confirmRemoveProduct(productType, e) {
    productsToDelete = [...e.selected];
    deletingProductType = productType;
    confirmRemoveModal.open();
  }

  function removeProducts() {
    org.removeProduct(deletingProductType, productsToDelete);
    productsToDelete = [];
    deletingProductType = null;
  }

  function reorderProducts(productType, e) {
    const { insertion, dragging } = e;
    const order = cloneDeep($org[`${productType}s`].order);
    const moved = order.splice(dragging, 1)[0];
    order.splice(insertion, 0, moved);
    org.reorderProducts(productType, order);
  }

  function cloneSurface(e) {
    const { selected } = e.detail;
    const p = selected
      .map((id) => $org.surfaces[id])
      .map((s) => s)
      .map((s) => ({ ...s, published: false, id: crypto.randomUUID() }));
    org.addProduct("surface", p);
    selectedSurfaces.selectOnly(...p.map((s) => s.id));
  }

  function confirmShareSelected(type, prods) {
    productsToShare = [...prods.selected];
    sharingProductType = type;
    confirmShareModal.open();
  }

  async function searchOrgs(e) {
    const name = sanitizePgString(e.detail.value);
    const res = await api
      .from("organizations")
      .select("*")
      .or(`name.ilike.%${name}%,primary_contact_email.ilike.%${name}%`)
      .eq("org_type", "supplier")
      .not("id", "eq", $org.id)
      .range(0, 5);

    if (res.error) {
      orgSearchResults = [];
    } else {
      orgSearchResults = res.data.map((o) => ({
        ...o,
        // description: o.address,
        cta: o.address,
        type: "existing",
        id: o.id,
      }));
    }
  }

  function selectOrg(e) {
    const result = e.detail.result;
    selectedOrg = result;
    orgSearchString = result.name;
  }

  function deselectOrg() {
    selectedOrg = null;
    orgSearchString = "";
  }

  async function shareSelected() {
    if (!selectedOrg || !productsToShare?.length || !sharingProductType) return;

    await api.rpc("clone_products_to_org", {
      product_ids: productsToShare,
      org_id: selectedOrg.id,
    });
  }

  function cloneMaterial(e) {
    const { selected } = e.detail;
    const p = selected
      .map((id) => $org.materials[id])
      .map((m) => m)
      .map((m) => ({ ...m, published: false, id: crypto.randomUUID() }));
    org.addProduct("material", p);
    selectedMaterials.selectOnly(...p.map((m) => m.id));
  }

  async function cloneMakeup(e) {
    const { selected } = e.detail;
    const p = selected
      .map((id) => $org.makeups[id])
      .map((m) => m)
      .map((m) => ({ ...m, published: false, id: crypto.randomUUID() }));
    org.addProduct("makeup", p);
    selectedMakeups.selectOnly(...p.map((m) => m.id));
  }

  async function cloneItemProduct(e) {
    const { selected } = e.detail;
    const p = selected
      .map((id) => $org.item_products[id])
      .map((m) => m)
      .map((m) => ({ ...m, published: false, id: crypto.randomUUID() }));
    org.addProduct("item_product", p);
    selectedMakeups.selectOnly(...p.map((m) => m.id));
  }

  function updateNavHistory(location) {
    if (tab === "items") {
      $navHistory.items = path?.split("/")[1];
    } else if (tab === "openings") {
      $navHistory.collections = path?.split("/")[1];
    } else if (tab === "types") {
      $navHistory.types = path?.split("/")[1];
    } else if (tab === "fabrications") {
      $navHistory.fabrications = path?.split("/")[1];
    } else if (tab === "documents") {
      $navHistory.documents = path?.split("/")[1];
    }
  }

  onMount(() => {
    Mousetrap.bind(["command+z", "ctrl+z"], undo);
    Mousetrap.bind(["command+shift+z", "ctrl+y"], redo);
    Mousetrap.bind(["command+v", "ctrl+v"], () => {
      eb.dispatch("paste-from-clipboard");
    });
    Mousetrap.bind(["command+c", "ctrl+c"], () => {
      eb.dispatch("copy-to-clipboard");
    });

    Mousetrap.bind("esc", () => {
      $currentTool = "select";
    });

    return () => {
      Mousetrap.unbind(["command+z", "ctrl+z"]);
      Mousetrap.unbind(["command+shift+z", "ctrl+y"]);
      Mousetrap.unbind(["command+v", "ctrl+v"]);
      Mousetrap.unbind(["command+c", "ctrl+c"]);
      Mousetrap.unbind("esc");
    };
  });
</script>

<svelte:head>
  <link href="https://fonts.cdnfonts.com/css/menlo" rel="stylesheet" on:load={updateFonts} />
</svelte:head>

<Navbar {tab} {disabled} context="products" />
<div class="grow flex flex-col min-h-0">
  <Router>
    <Toolbar {canAdmin} {org} {tab} {path} {disabled} />
    <div class="grow flex flex-col min-h-0">
      <div class="bg-gray-100 w-full grow min-h-0 relative overflow-y-auto">
        {#if $org?.org_type === "supplier"}
          <Route path="surfaces">
            <OrgProductList
              {org}
              {disabled}
              products={surfaces}
              selectedObj={selectedSurfaces}
              recordName="surface"
              shareable={$profile?.user_role === "developer"}
              bind:filter={productFilter}
              on:add={() => addProduct("surface")}
              on:clone-selected={cloneSurface}
              on:share-selected={(e) => confirmShareSelected("surface", e.detail)}
              on:remove-selected={(e) => confirmRemoveProduct("surface", e.detail)}
              on:reorder={(e) => reorderProducts("surface", e.detail)}>
              <div
                slot="thumbnail"
                let:product
                class="w-full h-full relative"
                style="background-color: {product.color || '#FFFFFF'}">
                {#if product.image}
                  <img
                    src={imageUrl(product.image)}
                    alt={product.name}
                    class="text-xs w-full h-full object-cover" />
                {/if}
                {#if !product.published}
                  <div class="p-1 absolute top-0 right-0 text-xs bg-gray-100/75 rounded">Unpublished</div>
                {/if}
              </div>
              <SurfaceProperties
                slot="sidebar"
                let:selected
                surface={selected}
                {disabled}
                on:update={(e) => updateProduct("surface", e.detail)} />
              <SurfaceProperties
                slot="multi-sidebar"
                let:selected
                surface={selected}
                {disabled}
                on:update={(e) => updateMultipleProducts("surface", e.detail)} />
            </OrgProductList>
          </Route>

          <Route path="materials">
            <OrgProductList
              {org}
              {disabled}
              products={materials}
              selectedObj={selectedMaterials}
              recordName="material"
              shareable={$profile?.user_role === "developer"}
              bind:filter={productFilter}
              on:add={() => addProduct("material")}
              on:clone-selected={cloneMaterial}
              on:share-selected={(e) => confirmShareSelected("material", e.detail)}
              on:remove-selected={(e) => confirmRemoveProduct("material", e.detail)}
              on:reorder={(e) => reorderProducts("material", e.detail)}>
              <div slot="thumbnail" let:product class="w-full h-full relative">
                <Viewport padding={8} drawing={materialDrawing(product)} />
                {#if !product.published}
                  <div class="p-1 absolute top-0 right-0 text-xs bg-gray-100/75 rounded">Unpublished</div>
                {/if}
              </div>
              <MaterialProperties
                slot="sidebar"
                let:selected
                material={selected}
                {disabled}
                settings={$org.data.settings}
                on:update={(e) => updateProduct("material", e.detail)} />
              <MaterialProperties
                slot="multi-sidebar"
                let:selected
                material={selected}
                {disabled}
                settings={$org.data.settings}
                on:update={(e) => updateMultipleProducts("material", e.detail)} />
            </OrgProductList>
          </Route>

          <Route path="makeups">
            <OrgProductList
              {org}
              {disabled}
              products={makeups}
              selectedObj={selectedMakeups}
              recordName="makeup"
              shareable={$profile?.user_role === "developer"}
              bind:filter={productFilter}
              on:add={() => addProduct("makeup")}
              on:clone-selected={cloneMakeup}
              on:share-selected={(e) => confirmShareSelected("makeup", e.detail)}
              on:remove-selected={(e) => confirmRemoveProduct("makeup", e.detail)}
              on:reorder={(e) => reorderProducts("makeup", e.detail)}>
              <div slot="thumbnail" let:product class="w-full h-full relative">
                {@const color = typeColorTrue(product)}
                {#if product.image}
                  <img
                    src={imageUrl(product.image)}
                    alt={product.name}
                    class="text-xs w-full h-full object-cover" />
                {:else}
                  <Viewport padding={8} drawing={typeDrawing(product)} />
                {/if}
                {#if color}
                  <div class="m-1 absolute right-0 bottom-0 w-5 h-5" style="background-color: {color}" />
                {/if}
                {#if !product.published}
                  <div class="p-1 absolute top-0 right-0 text-xs bg-gray-100/75 rounded">Unpublished</div>
                {/if}
              </div>
              <MakeupProperties
                slot="sidebar"
                let:selected
                makeup={selected}
                {disabled}
                {org}
                on:update={(e) => updateProduct("makeup", e.detail)} />
              <MakeupProperties
                slot="multi-sidebar"
                let:selected
                makeup={selected}
                {disabled}
                {org}
                on:update={(e) => updateMultipleProducts("makeup", e.detail)} />
            </OrgProductList>
          </Route>

          <Route path="items">
            <OrgProductList
              {org}
              {disabled}
              products={itemprods}
              selectedObj={selectedItemProds}
              recordName="item"
              shareable={$profile?.user_role === "developer"}
              bind:filter={productFilter}
              on:add={() => addProduct("item_product")}
              on:clone-selected={cloneItemProduct}
              on:share-selected={(e) => confirmShareSelected("item_product", e.detail)}
              on:remove-selected={(e) => confirmRemoveProduct("item_product", e.detail)}
              on:reorder={(e) => reorderProducts("item_product", e.detail)}>
              <div slot="thumbnail" let:product class="w-full h-full relative">
                {@const color = typeColorTrue(product)}
                {#if product.image}
                  <img
                    src={imageUrl(product.image)}
                    alt={product.name}
                    class="text-xs w-full h-full object-cover" />
                {:else if product.data.layers}
                  <Viewport padding={8} drawing={typeDrawing(product)} />
                {/if}
                {#if color}
                  <div class="m-1 absolute right-0 bottom-0 w-5 h-5" style="background-color: {color}" />
                {/if}
                {#if !product.published}
                  <div class="p-1 absolute top-0 right-0 text-xs bg-gray-100/75 rounded">Unpublished</div>
                {/if}
              </div>
              <ItemProductProperties
                slot="sidebar"
                let:selected
                product={selected}
                {disabled}
                {org}
                on:update={(e) => updateProduct("item_product", e.detail)} />
              <ItemProductProperties
                slot="multi-sidebar"
                let:selected
                product={selected}
                {disabled}
                {org}
                on:update={(e) => updateMultipleProducts("item_product", e.detail)} />
            </OrgProductList>
          </Route>

          <Route path="categories">
            <CategoriesList {disabled} />
          </Route>

          <Route path="edges">
            <OrgProductList
              {org}
              disabled
              products={$orderedEt}
              selectedObj={selectedEdges}
              recordName="edge treatment"
              bind:filter={productFilter}>
              <div slot="thumbnail" let:product class="w-full h-full relative">
                <img
                  src={`/images/${product.image?.filename}`}
                  alt={product.name}
                  class="text-xs w-full h-full object-cover" />
              </div>
              <EdgeProductProperties slot="sidebar" let:selected edge={selected} {disabled} {org} />
              <EdgeProductProperties slot="multi-sidebar" let:selected edge={selected} {disabled} {org} />
            </OrgProductList>
          </Route>

          <Route path="fabrications">
            <OrgProductList
              {org}
              disabled
              products={$orderedFabs}
              selectedObj={selectedFabrications}
              recordName="fabrication"
              bind:filter={productFilter}>
              <div slot="thumbnail" let:product class="w-full h-full relative">
                <img
                  src={`/images/${product.image?.filename}`}
                  alt={product.name}
                  class="text-xs w-full h-full object-cover" />
              </div>
              <FabProductProperties slot="sidebar" let:selected fabrication={selected} {disabled} {org} />
              <FabProductProperties
                slot="multi-sidebar"
                let:selected
                fabrication={selected}
                {disabled}
                {org} />
            </OrgProductList>
          </Route>
        {/if}

        <!-- <Route path="types">
            <TypeList {disabled} {types} {org} {makeups} on:updateSupplier={updateSupplier} />
          </Route>

          <Route path="types/:typeid" let:params>
            {#if $org.types[params.typeid]}
              <TypeEditor
                {types}
                typeid={params.typeid}
                {disabled}
                {org}
                {makeups}
                on:updateSupplier={updateSupplier} />
            {:else}
              <Redirect to="types" />
            {/if}
          </Route> -->

        <!-- Default -->
        <Route path="/">
          <Redirect to="/products/makeups" />
        </Route>
      </div>
    </div>
  </Router>
  <!-- <SettingsModal {orgColumns} {standardColumns} {disabled} /> -->
</div>

<Modal
  bind:this={confirmRemoveModal}
  on:confirm={removeProducts}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Delete", type: "confirm", style: "danger" },
  ]}
  closeOnOutclick>
  <div slot="title">Delete Products</div>
  <div slot="content">
    <div class="mb-2">Are you sure you want to delete these products?</div>
    {#each productsToDelete as id}
      {@const product = $org[`${deletingProductType}s`][id]}
      <div>{product.name}</div>
    {/each}
  </div>
</Modal>

{#if $profile?.user_role === "developer"}
  <Modal
    bind:this={confirmShareModal}
    on:confirm={shareSelected}
    buttons={[
      { label: "Cancel", type: "cancel" },
      { label: "Copy Products", type: "confirm", disabled: !selectedOrg },
    ]}>
    <div slot="title">Copy Products to Organization</div>
    <div slot="content">
      <div class="mb-2">The following products will be copied:</div>
      <div class="px-4 mb-2">
        {#each productsToShare as id}
          {@const product = $org[`${sharingProductType}s`][id]}
          <div>{product.name}</div>
        {/each}
      </div>
      <SearchSelect
        placeholder="Destination Organization"
        border
        bind:value={orgSearchString}
        selection={selectedOrg}
        results={orgSearchResults}
        on:focus={searchOrgs}
        on:search={searchOrgs}
        on:select={selectOrg}
        on:deselect={deselectOrg} />
    </div>
  </Modal>
{/if}
