import { writable } from "svelte/store";
import cloneDeep from "lodash/cloneDeep";
import partition from "lodash/partition";
import lset from "lodash/set";

import { Revision } from "./revision.js";
import {
  api,
  updateProp,
  addUser,
  addRecords,
  removeRecords,
  addContact as apiAddContact,
} from "../api";
import { alphabeticMultiSort } from "@local/extensions/utilities/sort.js";
import {
  addToSortableList,
  removeFromSortableList,
} from "@local/extensions/collections/sortable-list.js";
import { hydrateType, hydrateMaterial, hydrateOrg } from "@local/lamina-core";
import {
  updateCommit,
  addCommit,
  removeCommit,
  reorderCommit,
  remExtdItemProdProps,
  remExtdMakeupProps,
} from "./update-commit.js";

const orgError = writable(null);
const contactError = writable(null);

function removeNonDbProps(item) {
  const { approver, updater, cache, ...rest } = item;
  return rest;
}

async function getOrgData(orgid) {
  return await api
    .from("organizations")
    .select(
      `*,
        types!types_organization_id_fkey(*),
        products(*),
        makeups(*),
        item_products(*),
        product_lists!product_lists_vendor_id_fkey(*, price_entries(*), quote_item_scripts(*)),
        profiles!profiles_organization_id_fkey(*),
        contacts!contacts_organization_id_fkey(*,contact:contact_id(*),primary_contact:primary_contact_id(*))`,
    )
    .eq("id", orgid)
    .single();
}

function createOrg() {
  const { subscribe, set, update } = writable(null);

  return {
    subscribe,
    set,
    revision: new Revision(),
    id: null,

    init(org) {
      this.revision = new Revision();
      if (!org) return;
      this.id = org.id;
      set(hydrateOrg(org));
    },

    undoable() {
      return this.revision.undoable;
    },

    redoable() {
      return this.revision.redoable;
    },

    undo() {
      update((n) => {
        this.revision.undo(n);
        return n;
      });
    },

    redo() {
      update((n) => {
        this.revision.redo(n);
        return n;
      });
    },

    updateProp(prop, value) {
      update((n) => {
        if (typeof prop === "string") {
          n[prop] = value;
        } else if (typeof prop === "object") {
          Object.entries(prop).forEach(([key, val]) => {
            n[key] = val;
          });
        }
        updateProp("organizations", n.id, prop, value);
        return n;
      });
    },

    addUser: async (user) => {
      orgError.set(null);

      try {
        const { profile, error } = await addUser(user);
        if (error) throw error;

        update((n) => {
          n.profiles.push(profile);
          return n;
        });
      } catch (error) {
        orgError.set(error.message);
      }
    },

    removeUser: async (id) => {
      orgError.set(null);

      try {
        const { data, error } = await api
          .from("profiles")
          .update({ organization_id: null })
          .eq("id", id);

        if (error) throw error;

        update((n) => {
          const index = n.profiles.findIndex((p) => p.id === id);
          n.profiles.splice(index, 1);
          return n;
        });
      } catch (error) {
        orgError.set(error.message);
      }
    },

    addContact: async (contact) => {
      contactError.set(null);

      try {
        const c = await apiAddContact({ ...contact, organization_id: this.id });

        if (c.error) throw c.error;

        update((n) => {
          n.contacts.push(c);
          n.contacts.sort(alphabeticMultiSort);
          return n;
        });
      } catch (error) {
        contactError.set(error.message);
      }
    },

    removeContact: async (id) => {
      contactError.set(null);

      try {
        const { error } = await api.from("contacts").delete().eq("id", id);

        if (error) throw error;
        update((n) => {
          const index = n.contacts.findIndex((c) => c.id === id);
          n.contacts.splice(index, 1);
          return n;
        });
      } catch (error) {
        contactError.set(error.message);
      }
    },

    async addProductList(list) {
      try {
        const { error, data } = await api
          .from("product_lists")
          .insert({
            ...list,
            vendor_id: this.id,
          })
          .select("*")
          .single();

        if (error) throw error;
        data.price_entries = {};

        update((n) => {
          n.product_lists.push(data);
          return n;
        });
      } catch (error) {
        console.log(error);
      }
    },

    async updateProductList(list) {
      try {
        const { id, ...rest } = list;

        updateProp("product_lists", id, rest);

        update((n) => {
          const index = n.product_lists.findIndex((l) => l.id === id);
          n.product_lists[index] = {
            ...n.product_lists[index],
            ...list,
          };
          return n;
        });
      } catch (error) {
        console.log(error);
      }
    },

    async removeProductList(id) {
      try {
        removeRecords("product_lists", [id]);

        update((n) => {
          const index = n.product_lists.findIndex((l) => l.id === id);
          n.product_lists.splice(index, 1);
          return n;
        });
      } catch (error) {
        console.log(error);
      }
    },

    update(updates) {
      const commit = updateCommit(updates);

      update((n) => {
        this.revision.commitTransaction(commit, n, []);
        return n;
      });
    },

    addType(...types) {
      const ids = types.map((t) => t.id);
      const commit = async (n) => {
        const old_data = cloneDeep(n.data);
        old_data.type_order = cloneDeep(n.types.order);

        addToSortableList(n.types, ...types.map((t) => hydrateType(t)));
        n.data.type_order = [...n.types.order];

        await addRecords(
          "types",
          types.map((t) => removeNonDbProps(t)),
        );

        updateProp("organizations", n.id, "data", n.data);

        return async (n) => {
          removeFromSortableList(n.types, ...ids);
          await removeRecords("types", ids);
          updateProp("organizations", n.id, "data", old_data);
        };
      };

      return new Promise((resolve) => {
        update((n) => {
          this.revision.commitTransaction(commit, n).then(() => {
            resolve(true);
          });

          return n;
        });
      });
    },

    removeType(...ids) {
      const commit = (n) => {
        const old_data = cloneDeep(n.data);
        old_data.type_order = [...n.types.order];

        removeFromSortableList(n.types, ...ids);
        n.data.type_order = [...n.types.order];

        const res = {};
        removeRecords("types", ids).then((r) => (res.types = r));
        updateProp("organizations", n.id, "data", n.data);

        return (n) => {
          addToSortableList(n.types, ...res.types.map((t) => hydrateType(t)));
          addRecords(
            "types",
            res.types.map((t) => removeNonDbProps(t)),
          );
          updateProp("organizations", n.id, "data", old_data);
        };
      };

      update((n) => {
        this.revision.commitTransaction(commit, n);
        return n;
      });
    },

    updateType(id, prop, value, diff) {
      this.update([{ type: "type", id, prop, value, diff }]);
    },

    updateTypeOrder(order) {
      const commit = (n) => {
        const oldOrder = n.types.order;
        const oldData = n.data;

        n.types.order = order;
        const data = cloneDeep(n.data);
        lset(data, "type_order", order);

        updateProp("organizations", n.id, "data", data);

        return (n) => {
          n.types.order = oldOrder;
          lset(n.data, oldData);
          updateProp("organizations", n.id, "data", oldData);
        };
      };

      update((n) => {
        this.revision.commitTransaction(commit, n);
        return n;
      });
    },

    updateMultipleTypes(ids, prop, value) {
      const updates = ids.map((id) => ({ type: "type", id, prop, value }));
      this.update(updates);
    },

    addProduct(type, products) {
      let commit;
      if (type === "makeup") {
        commit = addCommit(
          type,
          "product_entries",
          "organizations",
          products,
          hydrateType,
          remExtdMakeupProps,
        );
      } else if (type === "material") {
        commit = addCommit(
          type,
          "product_entries",
          "organizations",
          products,
          hydrateMaterial,
          removeNonDbProps,
        );
      } else if (type === "item_product") {
        commit = addCommit(
          type,
          "product_entries",
          "organizations",
          products,
          undefined,
          remExtdItemProdProps,
        );
      } else if (type === "surface") {
        commit = addCommit(type, "product_entries", "organizations", products);
      }

      update((n) => {
        this.revision.commitTransaction(commit, n);
        return n;
      });
    },

    removeProduct(type, ids) {
      let commit;
      if (type === "makeup") {
        commit = removeCommit(
          type,
          "product_entries",
          "organizations",
          ids,
          hydrateType,
          remExtdMakeupProps,
        );
      } else if (type === "material") {
        commit = removeCommit(
          type,
          "product_entries",
          "organizations",
          ids,
          hydrateMaterial,
          removeNonDbProps,
        );
      } else if (type === "item_product") {
        commit = removeCommit(type, "product_entries", "organizations", ids);
      } else if (type === "surface") {
        commit = removeCommit(type, "product_entries", "organizations", ids);
      }

      update((n) => {
        this.revision.commitTransaction(commit, n);
        return n;
      });
    },

    reorderProducts(type, order) {
      const commit = reorderCommit(type, "organizations", order);

      update((n) => {
        this.revision.commitTransaction(commit, n);
        return n;
      });
    },

    addPriceEntry(list_id, product_ids) {
      const commit = async (n) => {
        const pe = product_ids.map((product_id) => ({ list_id, product_id }));
        const pl = n.product_lists.find((pl) => pl.id === list_id);
        pe.forEach((p) => {
          pl.price_entries[p.product_id] = p;
        });
        await addRecords("price_entries", pe);

        return async (n) => {
          const pl = n.product_lists.find((pl) => pl.id === list_id);
          product_ids.forEach((id) => {
            delete pl.price_entries[id];
          });

          await api
            .from("price_entries")
            .delete()
            .eq("list_id", list_id)
            .in("product_id", product_ids);
        };
      };

      update((n) => {
        this.revision.commitTransaction(commit, n);
        return n;
      });
    },

    removePriceEntry(list_id, product_ids) {
      const commit = async (n) => {
        const pl = n.product_lists.find((pl) => pl.id === list_id);
        const pe = product_ids.map((id) => pl.price_entries[id]);
        product_ids.forEach((id) => {
          delete pl.price_entries[id];
        });

        await api
          .from("price_entries")
          .delete()
          .eq("list_id", list_id)
          .in("product_id", product_ids);

        return async (n) => {
          const pl = n.product_lists.find((pl) => pl.id === list_id);
          pe.forEach((p) => {
            pl.price_entries[p.product_id] = p;
          });

          await addRecords("price_entries", pe);
        };
      };

      update((n) => {
        this.revision.commitTransaction(commit, n);
        return n;
      });
    },

    updatePriceEntry(list_id, product_ids, entry) {
      const commit = async (n) => {
        const pl = n.product_lists.find((pl) => pl.id === list_id);
        const [updated, inserted] = partition(
          product_ids,
          (id) => pl.price_entries[id],
        );

        const old = updated.map((id) => pl.price_entries[id]);

        product_ids.forEach((product_id) => {
          pl.price_entries[product_id] = {
            ...entry,
            list_id,
            product_id,
          };
        });

        await api
          .from("price_entries")
          .update(entry)
          .eq("list_id", list_id)
          .in("product_id", updated);

        await api
          .from("price_entries")
          .insert(
            inserted.map((id) => ({ ...entry, list_id, product_id: id })),
          );

        return async (n) => {
          const pl = n.product_lists.find((pl) => pl.id === list_id);
          old.forEach((pe) => {
            pl.price_entries[pe.product_id] = pe;
          });
          inserted.forEach((id) => {
            delete pl.price_entries[id];
          });

          await Promise.all(
            old.map((pe) =>
              api
                .from("price_entries")
                .update(pe)
                .eq("product_id", pe.product_id),
            ),
          );

          await api
            .from("price_entries")
            .delete()
            .eq("list_id", list_id)
            .in("product_id", inserted);
        };
      };

      update((n) => {
        this.revision.commitTransaction(commit, n);
        return n;
      });
    },
  };
}

export { getOrgData, createOrg, orgError, contactError };
