<script>
  import { onMount, createEventDispatcher } from "svelte";
  import CheckIcon from "@local/assets/icons/check.svg";
  import get from "lodash/get";

  export let props;
  export let job;
  export let item;
  export let optionMap;
  export let options;

  const dispatch = createEventDispatcher();

  let container;
  let inputValue = storedValue(props);
  let selectedOption = null;

  $: inputPosition = `left:${props.pt.x}px;top:${props.pt.y}px`;

  function storedValue(props) {
    if (props.path) {
      return get(item, props.path);
    }

    return get(item, props.id);
  }

  function chooseOption(option) {
    if (option !== inputValue) {
      inputValue = option;
      job.updateItem(item.id, props.path || props.id, inputValue);
    }
    dispatch("stopEditing");
  }

  function keydown(e) {
    if (e.key === "Enter" && selectedOption !== null) {
      chooseOption(options[selectedOption]);
    } else if (e.key === "ArrowDown") {
      if (selectedOption === null) {
        selectedOption = 0;
      } else if (options.length > selectedOption + 1) {
        selectedOption += 1;
      }
    } else if (e.key === "ArrowUp") {
      if (selectedOption > 0) selectedOption -= 1;
    } else if (e.key === "Escape") {
      dispatch("stopEditing");
    }
  }

  function blur() {
    dispatch("stopEditing");
  }

  onMount(() => {
    container.focus();
  });
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<div
  class="container absolute rounded bg-white drop-shadow-lg text-xs"
  bind:this={container}
  tabindex="0"
  style={inputPosition}
  on:keydown={keydown}
  on:blur={blur}>
  {#each options as option, index}
    <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <li
      class="option-item flex p-1 hover:bg-gray-100 items-center cursor-pointer"
      class:selected={index === selectedOption}
      on:click|stopPropagation={() => chooseOption(option)}>
      <div class="flex-none w-6">
        {#if option === inputValue}
          <CheckIcon />
        {/if}
      </div>
      <div class="truncate">{optionMap[option]}</div>
    </li>
  {/each}
</div>

<style lang="scss">
  div.container {
    max-width: 8rem;
    transform: translate(-50%, 1rem);
  }

  li.option-item.selected {
    @apply bg-blue-400 text-white;
  }
</style>
