function locationsByDocument(locations) {
  const result = {};

  locations.order.forEach((id) => {
    const location = locations[id];
    if (!result[location.document_id]) {
      result[location.document_id] = [];
    }

    result[location.document_id].push(location);
  });

  return result;
}

export default locationsByDocument;
