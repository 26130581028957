<script>
  import SidebarSection from "./SidebarSection.svelte";
  import SelectInput from "./SelectInput.svelte";
  import Viewport from "../drawing/ViewportMinimal.svelte";
  import fabDrawing from "@local/extensions/drawing/fab-drawing.js";
  import { orderedList } from "@local/extensions/collections/sortable-list.js";
  import { tempFeatureEdgeFabrication, tempFeatureCornerFabrication } from "#src/stores/ui.js";
  import { isEdgeFabrication, isCornerFabrication } from "@local/extensions/fabrications/is-fabrication";
  import { drawingSrc } from "#src/api";

  export let job;
  export let feature;

  $: tempFab =
    feature.props.type === "edge-fabrication" ? $tempFeatureEdgeFabrication : $tempFeatureCornerFabrication;
  $: fabrication = $job.data.fabrications[tempFab.fab_id];
  $: dwg = fabrication && fabrication.drawing;
  $: drawing = fabDrawing(fabrication);
  $: fabrications = orderedList($job.data.fabrications);
  $: fabOptions = fabrications
    .filter((f) =>
      feature.props.type === "edge-fabrication" ? isEdgeFabrication(f) : isCornerFabrication(f),
    )
    .map((f) => ({ label: `${f.mark}${f.name && ` - ${f.name}`}`, value: f.id }));

  function updateFeature(prop, value) {
    if (feature.props.type === "edge-fabrication") {
      $tempFeatureEdgeFabrication[prop] = value;
    } else if (feature.props.type === "corner-fabrication") {
      $tempFeatureCornerFabrication[prop] = value;
    }
  }
</script>

<SidebarSection>
  <div class="w-full p-2">
    {#if dwg}
      <div class="w-full border border-gray-300">
        <img class="w-full object-contain" src={drawingSrc(dwg)} alt="" />
      </div>
    {:else}
      <div class="w-full h-64 relative border border-gray-300 bg-gray-100">
        <Viewport padding={0} {drawing} />
      </div>
    {/if}
  </div>
  <SelectInput
    label="Fabrication"
    labelWidth="5.5rem"
    value={tempFab.fab_id}
    options={fabOptions}
    placeholder="No name"
    on:input={(e) => updateFeature("fab_id", e.detail.value)} />
</SidebarSection>
