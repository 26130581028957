<script>
  import { Link } from "svelte-routing";
  import uniqBy from "lodash/uniqBy";

  import SidebarSection from "./SidebarSection.svelte";
  import SidebarTitle from "./SidebarTitle.svelte";
  import ItemProperties from "./ItemProperties.svelte";
  import CollectionItemList from "./CollectionItemList.svelte";
  import TypeProperties from "./TypeProperties.svelte";
  import LayerProperties from "./LayerProperties.svelte";
  import LocationThumbnailViewer from "#lib/sidebar/LocationThumbnailViewer.svelte";
  import Attachments from "./Attachments.svelte";
  import History from "./History.svelte";
  import Viewport from "#lib/drawing/ViewportFixed.svelte";
  import ViewportLg from "#lib/drawing/Viewport.svelte";
  import SelectedLocations from "./SelectedLocations.svelte";

  import typeDrawing from "@local/extensions/drawing/type-drawing.js";
  import typeDrawingDetailed from "@local/extensions/drawing/type-drawing-detailed.js";

  import { bucketArray, bucketArrayMultiple } from "overline";
  import makeMultiItem from "#src/extensions/multi-item.js";
  import makeMultiType from "#src/extensions/multi-type.js";

  import { expandRightPanel } from "#src/stores/ui.js";

  export let locations;
  export let group;
  export let items;
  export let types;
  export let collections;
  export let disabled;
  export let customColumns;
  export let customColColumns;
  export let standardColumns;
  export let collectionItems;

  let refreshKey = crypto.randomUUID();

  $: allAttachments = bucketArrayMultiple($group.attachments, "item_id", "type_id");
  $: uniqLocations = uniqBy(locations, "record_id").filter((l) => l && !!relatedRecord(l, $group));
  $: location = uniqLocations[0];
  $: rec = record(location, allAttachments);
  $: customCols = rec?.is_collection ? customColColumns : customColumns;
  $: multiRecord = makeMultiRecord(uniqLocations, $group, customCols);
  $: (rec || multiRecord) && refresh();
  $: locsByRecordId = bucketArray(locations, "record_id");
  $: locationCounts = countLocations(locations);
  $: itemsWithLocs = items.filter((i) => locsByRecordId[i.id]);
  $: collectionsWithLocs = collections.filter((c) => locsByRecordId[c.id]);
  $: typesWithLocs = types.filter((t) => locsByRecordId[t.id]);
  $: title = getTitle(uniqLocations, locations, location, $group);
  $: settings = $group.data.settings;
  $: typeSettings = {
    display_unit: $group.data.settings.display_unit,
    mm_precision: $group.data.settings.mm_precision,
    decimal_precision: 3,
    fractional_precision: 5,
    dimension_format: $group.data.settings.dimension_format,
  };

  function relatedRecord(location, grp) {
    if (!location) return null;
    return location.record_type === "type" ? grp.types[location.record_id] : grp.items[location.record_id];
  }

  function getTitle(uniqLocations, locations, location, grp) {
    if (uniqLocations.length > 1) return `${locations.length} locations selected`;
    const record = relatedRecord(location, grp);
    if (!record) return "";
    return `Location: ${record.mark}`;
  }

  function makeMultiRecord(ul, grp, cc) {
    if (ul.length <= 1) return null;
    if (ul.every((loc) => loc.record_type === "type")) {
      const types = ul.map((loc) => grp.types[loc.record_id]);
      return makeMultiType(grp, types);
    } else if (ul.every((loc) => loc.record_type === "item")) {
      const items = ul.map((loc) => grp.items[loc.record_id]);
      return makeMultiItem(grp, items, cc);
    } else if (ul.every((loc) => loc.record_type === "collection")) {
      const colls = ul.map((loc) => grp.items[loc.record_id]);
      return makeMultiItem(grp, colls, cc);
    }

    return null;
  }

  function refresh() {
    refreshKey = crypto.randomUUID();
  }

  function countLocations(locations) {
    return locations.reduce((counts, loc) => {
      if (counts[loc.record_id]) {
        counts[loc.record_id] += 1;
      } else {
        counts[loc.record_id] = 1;
      }
      return counts;
    }, {});
  }

  function record(loc, allAttachments) {
    if (!loc) return null;
    if (loc.record_type === "type") {
      return {
        ...$group.types[loc.record_id],
        attachments: allAttachments[loc.record_id] || [],
      };
    } else {
      return {
        ...$group.items[loc.record_id],
        attachments: allAttachments[loc.record_id] || [],
      };
    }
  }

  async function updateItem(evt) {
    const { id, prop, value, diff } = evt.detail;
    group.updateItem(id, prop, value, diff);
  }

  async function updateType(evt) {
    const { id, prop, value, diff } = evt.detail;
    group.updateType(id, prop, value, diff);
  }

  function updateMultiType(e) {
    const { prop, value } = e.detail;
    const ids = uniqLocations.map((l) => l.record_id);
    group.updateMultipleTypes(ids, prop, value);
  }

  function updateMultiItem(e) {
    const { prop, value, diff } = e.detail;
    const ids = uniqLocations.map((l) => l.record_id);
    group.updateMultipleItems(ids, prop, value, diff);
  }
</script>

{#if uniqLocations.length > 1}
  <SidebarTitle>
    {title}
  </SidebarTitle>
{/if}
<SelectedLocations
  single={uniqLocations.length === 1}
  {group}
  {itemsWithLocs}
  {locationCounts}
  {collectionsWithLocs}
  {typesWithLocs}
  on:add-location
  on:clone-location />
{#if multiRecord && location.record_type === "type"}
  <SidebarTitle title="Type Properties" />
  <TypeProperties
    {disabled}
    type={multiRecord}
    settings={typeSettings}
    on:updateType={updateMultiType}
    on:updateSupplier />
{:else if multiRecord}
  <SidebarTitle title="Item Properties" />
  <ItemProperties
    {group}
    {disabled}
    item={multiRecord}
    customColumns={customCols}
    {types}
    {collections}
    {standardColumns}
    isCollection={location.record_type === "collection"}
    settings={$group.data.settings}
    multiple
    on:updateItem={updateMultiItem} />
{:else if uniqLocations.length === 1 && location.record_type === "type"}
  {#key rec.id}
    {#if $expandRightPanel}
      <SidebarSection>
        <Link to={`types/${rec.id}`}>
          <div class="flex items-center justify-center">
            {#key refreshKey}
              <div class="w-full h-80 relative">
                <ViewportLg padding={30} paddingBottom={70} drawing={typeDrawingDetailed(rec)} />
              </div>
            {/key}
          </div>
        </Link>
        <Link to={`types/${rec.id}`}><div class="px-2 mt-2 text-blue-500">View Type</div></Link>
      </SidebarSection>
    {:else}
      <SidebarSection>
        <Link to={`types/${rec.id}`}>
          <div class="flex items-center justify-center">
            {#key refreshKey}
              <div class="w-36 h-36 relative">
                <Viewport padding={1} drawing={typeDrawing(rec)} width={136} height={136} />
              </div>
            {/key}
          </div>
        </Link>
        <Link to={`types/${rec.id}`}><div class="px-2 mt-2 text-blue-500">View Type</div></Link>
      </SidebarSection>
    {/if}
    <TypeProperties
      {disabled}
      type={rec}
      settings={typeSettings}
      on:updateType={updateType}
      on:updateSupplier />
    {#if rec?.data?.layers}
      <LayerProperties type={rec} {group} {disabled} on:updateType={updateType} />
    {/if}
    <LocationThumbnailViewer recordid={rec.id} {group} record={rec} {disabled} />
    <Attachments attachments={rec.attachments || []} {group} typeid={rec.id} {disabled} />
    <History
      updatedBy={rec.updater}
      updatedOn={rec.updated_at}
      approvedBy={rec.approver}
      approvalStatus={rec.approval_status}
      approvedOn={rec.approval_status_updated_at} />
  {/key}
{:else if uniqLocations.length === 1}
  {#key rec.id}
    <ItemProperties
      {group}
      itemid={rec.id}
      {disabled}
      item={rec}
      {collections}
      customColumns={customCols}
      {types}
      {standardColumns}
      {settings}
      isCollection={rec.is_collection}
      showEditor={$expandRightPanel}
      on:updateItem={updateItem} />
    {#if rec.is_collection}
      <CollectionItemList
        {group}
        {items}
        collectionId={rec.id}
        collectionItems={collectionItems[rec.id]}
        {disabled} />
    {/if}
    <LocationThumbnailViewer recordid={rec.id} {group} record={rec} {disabled} />
    <Attachments attachments={rec.attachments || []} {group} itemid={rec.id} {disabled} />
    <History
      updatedBy={rec.updater}
      updatedOn={rec.updated_at}
      approvedBy={rec.approver}
      approvalStatus={rec.approval_status}
      approvedOn={rec.approval_status_updated_at} />
  {/key}
{:else}
  <div class="px-6 text-xs text-gray-500 font-bold italic">Mixed selection</div>
{/if}
<!-- <SidebarSection>
  <DeleteButton
    on:delete={() => dispatch("remove-locations", { locations: locations.map((l) => l.id) })}
    text={`Delete Location Marker${uniqLocations.length > 1 ? "s" : ""}`} />
</SidebarSection> -->
