export class Revision {
  constructor({ max = 15, room = null } = {}) {
    this.max = max + 1;
    this.undos = [];
    this.redos = [];
    this.room = room;
  }

  get undoable() {
    return this.undos.length > 0;
  }

  get redoable() {
    return this.redos.length > 0;
  }

  async commit(transaction, n) {
    this.undos.push(transaction);

    if (this.undos.length > this.max) {
      this.undos.shift();
    }

    this.redos = [];

    await transaction.commit(n);

    if (this.room) {
      this.room.send({
        type: "broadcast",
        event: "update",
        payload: { updated: transaction.updated },
      });
    }
  }

  async commitNotUndoable(transaction, n) {
    await transaction.commit(n);
  }

  undo(n) {
    if (!this.undoable) return;
    const transaction = this.undos.pop();
    transaction.isUndo = true;
    transaction.undo(n);
    this.redos.push(transaction);
  }

  redo(n) {
    if (!this.redoable) return;
    const transaction = this.redos.pop();
    delete transaction.isUndo;
    transaction.commit(n);
    this.undos.push(transaction);
  }

  async commitTransaction(commit, n, updated = []) {
    const commitId = crypto.randomUUID();

    const transaction = {
      commitId,
      updated,
      async commit(n) {
        this.undo = await commit(n);
      },
    };

    await this.commit(transaction, n);
  }

  async commitTransactionNotUndoable(commit, n, updated) {
    const commitId = crypto.randomUUID();

    const transaction = {
      commitId,
      updated,
      async commit(n) {
        await commit(n);
      },
    };

    await this.commitNotUndoable(transaction, n);
  }
}
