<script>
  import { getContext } from "svelte";
  import { Link, navigate } from "svelte-routing";

  import Navbar from "#lib/Navbar.svelte";
  import Datatable from "#lib/datatable/Datatable.svelte";
  import CaretIcon from "@local/assets/icons/caret-left.svg";
  import Pager from "#lib/Pager.svelte";
  import ArchiveQuoteListItem from "#lib/home/ArchiveQuoteListItem.svelte";
  import ArchiveRfqListItem from "#lib/home/ArchiveRfqListItem.svelte";
  import FilterInput from "#lib/standard-inputs/FilterInput.svelte";

  import { api } from "#src/api";
  import { quotes } from "#src/stores/quotes.js";
  import { rfqs } from "#src/stores/rfqs.js";
  import { jobs } from "#src/stores/jobs.js";
  import sanitizePgString from "#src/extensions/sanitize-postgrest-string.js";

  export let queryParams;

  const maxRows = 20;
  const org = getContext("org");

  let filter = "";
  let sortBy = "sent_at";
  let sortAsc = false;
  let filterTimer;

  $: inputPage = parseInt(queryParams?.page) || 1;
  $: page = Math.max(inputPage - 1, 0);

  let records;
  let count;

  const rfqColumns = [
    { label: "Sent at", prop: "sent_at" },
    { label: "Name", prop: "job_name" },
    { label: "Location", prop: "job_location" },
    { label: "Reference", prop: "seller_reference" },
    { label: "Items", prop: "items_count" },
    { label: "Price", prop: "price" },
    { label: "status", prop: "status" },
  ];

  const quoteColumns = [
    { label: "Sent at", prop: "sent_at" },
    { label: "Name", prop: "name" },
    { label: "Organization", prop: "recipient_organization_name" },
    { label: "Location", prop: "location" },
    { label: "Reference", prop: "seller_reference" },
    { label: "Items", prop: "items_count" },
    // { label: "Draft", prop: "type" },
    { label: "Price" },
    { label: "status" },
  ];

  function debounce() {
    clearTimeout(filterTimer);
    filterTimer = setTimeout(() => {
      fetchJobs();
    }, 500);
  }

  function gotoQuote(e) {
    const { id } = e.detail;
    navigate(`/quotes/${id}`);
  }

  function gotoJob(e) {
    const { id } = e.detail;
    const record = records.find((r) => r.id === id);
    if (!record) return;
    if (record.type === "job") {
      navigate(`/jobs/${id}`);
    } else {
      navigate(`/quotes/${id}`);
    }
  }

  async function unarchiveQuote(e) {
    const { row, id } = e.detail;
    if (row.type === "job") {
      await jobs.unarchive(id);
    } else if (row.type === "quote") {
      await quotes.unarchive(id);
    }
    init();
  }

  async function unarchiveRfq(e) {
    await rfqs.unarchive(e.detail.id);
    init();
  }

  function gotoPage(e) {
    const p = e.detail;
    navigate(`/archive?page=${p + 1}`);
  }

  async function fetchJobs() {
    let query;
    let cquery;

    if ($org?.org_type === "supplier") {
      query = api.from("draft_and_response_list_view").select("*");
      cquery = api.from("draft_and_response_list_view").select("*", { count: "exact", head: true });
    } else {
      query = api.from("request_list_view").select("*");
      cquery = api.from("quote_requests").select("*", { count: "exact", head: true });
    }

    const min = page * maxRows;
    const max = min + maxRows - 1;

    query = query.eq("organization_id", $org.id).not("archived_at", "is", null);

    cquery = cquery.eq("organization_id", $org.id).not("archived_at", "is", null);

    if (filter) {
      query = query.or(
        `name.ilike.%${sanitizePgString(filter)}%,recipient_organization_name.ilike.%${sanitizePgString(filter)}%,location.ilike.%${sanitizePgString(filter)}%,seller_reference.ilike.%${sanitizePgString(filter)}%`,
      );
      cquery = cquery.or(
        `name.ilike.%${sanitizePgString(filter)}%,recipient_organization_name.ilike.%${sanitizePgString(filter)}%`,
      );
    }

    query = query.limit(maxRows).range(min, max).order(sortBy, { ascending: sortAsc, nullsFirst: !sortAsc });

    const { data, error } = await query;
    if (error) return;
    const { count: c, error: countError } = await cquery;
    if (countError) return;

    records = data;
    count = c;
  }

  function sortTable(e) {
    const { column } = e.detail;
    if (!column.prop) return;
    if (sortBy === column.prop) {
      sortAsc = !sortAsc;
    } else {
      sortAsc = true;
      sortBy = column.prop;
    }

    fetchJobs();
  }

  async function init() {
    await fetchJobs();
  }
</script>

<Navbar page="archive" />

<div class="grow bg-gray-100 flex flex-col min-h-0">
  {#key page}
    {#await init() then _}
      {#if $org}
        <div class="flex justify-between items-end">
          <div>
            <div class="py-4 px-8 flex flex-row items-center justify-between">
              <h2>Archive</h2>
            </div>
            <div class="text-blue-500 text-sm px-8 mb-4">
              <Link to="/">
                <div class="flex items-center gap-1">
                  <CaretIcon />
                  <div>Home</div>
                </div>
              </Link>
            </div>
          </div>
          <div class="text-sm p-4 flex items-center gap-2">
            <div>Filter list:</div>
            <FilterInput bind:value={filter} on:input={() => debounce()} />
          </div>
        </div>
        <div class="px-4 pb-4 grow overflow-hidden">
          <div
            class="content-container h-full flex flex-col justify-between w-full bg-white border rounded-lg mb-4 overflow-hidden">
            <div class="overflow-y-auto">
              {#if $org?.org_type === "supplier"}
                <Datatable
                  sticky
                  visitable
                  sortable
                  unarchivable
                  columns={quoteColumns}
                  data={records}
                  {sortBy}
                  sortDirection={sortAsc ? "asc" : "desc"}
                  rowComponent={ArchiveQuoteListItem}
                  on:dblclickrow={gotoJob}
                  on:gotorow={gotoQuote}
                  on:sort={sortTable}
                  on:unarchiverow={unarchiveQuote} />
              {:else}
                <Datatable
                  sticky
                  visitable
                  sortable
                  unarchivable
                  columns={rfqColumns}
                  data={records}
                  {sortBy}
                  sortDirection={sortAsc ? "asc" : "desc"}
                  rowComponent={ArchiveRfqListItem}
                  on:dblclickrow={gotoQuote}
                  on:gotorow={gotoQuote}
                  on:sort={sortTable}
                  on:unarchiverow={unarchiveRfq} />
              {/if}
            </div>
            <Pager {page} {count} on:gotopage={gotoPage} />
          </div>
        </div>
      {/if}
    {/await}
  {/key}
</div>

<style lang="scss">
  .content-container {
    min-width: 32rem;
  }
</style>
