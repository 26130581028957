<script>
  import { downloadBlob } from "downloaders";
  import { api } from "#src/api";
  import PdfIcon from "@local/assets/icons/file-pdf-lg.svg";
  import nameMinusExtension from "@local/extensions/utilities/name-minus-extension.js";

  export let attachments;

  async function download(attachment) {
    try {
      const { data, error } = await api.storage.from("quotes").download(attachment.path);
      if (error) throw error;

      downloadBlob(data, attachment.name);
    } catch (error) {
      console.error(error);
    }
  }
</script>

{#each attachments as attachment}
  <button class="text-center flex flex-col items-center" on:click={() => download(attachment)}>
    <PdfIcon />
    <div class="flex whitespace-nowrap">
      <div style="max-width:6rem;" class="truncate">{nameMinusExtension(attachment.name)}</div>
      <div>.{attachment.extension}</div>
    </div>
  </button>
{/each}
