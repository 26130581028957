<script>
  import LaminaIcon from "@local/assets/icons/lamina.svg";

  export let title = "Welcome to Shape Exchange";

  /** @type { "post" | "get" | "dialog" }*/
  export let method = null;

  /** @type {string | Function | null} */
  export let action = null;

  export let name = null;

  export let autocomplete = null;

  $: formAttrs = {
    autocomplete,
    name,
    method: typeof action === "string" ? method : null,
    action: typeof action === "string" ? action : null,
  };

  /**
   * @param event {SubmitEvent}
   */
  function handleSubmit(event) {
    if (typeof action === "function") {
      event.preventDefault();
      action(event);
    }
  }
</script>

<div class="w-full h-full flex flex-col justify-center">
  <div class="mx-auto w-96 p-4 border rounded bg-white drop-shadow-md text-center">
    <form class="space-y-4" on:submit={handleSubmit} {...formAttrs}>
      <div class="flex justify-center my-2">
        <LaminaIcon />
      </div>
      <h1>{title}</h1>
      <slot />
    </form>
  </div>
</div>
