<script>
  import upperCase from "lodash/upperCase";
  import ProfileIcon from "@local/assets/icons/profile.svg";
  import formatHumanTime from "@local/extensions/formatters/format-human-time.js";
  import getUserInitials from "@local/extensions/formatters/get-user-initials.js";

  export let datum;

  $: status = getStatus(datum);
  $: unsent = status === "unsent";
  $: canceled = status === "canceled" || status === "rfq-canceled";
  $: hasNotifications = datum.notifications_count > 0;

  function getStatus(datum) {
    if (datum.request_status === "canceled") return "rfq-canceled";
    if (datum.status === "canceled") return "canceled";
    if (!datum.sent_at) return "unsent";

    return null;
  }

  function items(count) {
    if (!count) return "No units";
    if (count === 0) return "No units";
    if (count === 1) return "1 unit";
    return `${count} units`;
  }
</script>

<td class="relative select-none">
  <div class="absolute top-4 left-4">
    <div class="rounded-full w-2 h-2" class:bg-blue-500={hasNotifications} />
  </div>
  <div class="border-l-4" class:border-transparent={!unsent} class:border-amber-400={unsent}>
    <div class="pl-6 py-2 flex gap-2 items-center" class:opacity-40={canceled}>
      <div class="grow overflow-x-hidden">
        <div class="flex items-center justify-between gap-2 overflow-x-hidden">
          <div class="font-bold text-md overflow-hidden">
            <div class="truncate">
              {datum.job_name}
            </div>
          </div>
          <div class="text-xs whitespace-nowrap">
            {items(datum.items_quantity || 0)}
          </div>
        </div>
        <div class="flex items-center justify-between gap-2">
          <div class="text-xs flex items-center gap-2">
            <div class="truncate">
              {datum.recipient_organization_name}
            </div>
            <!-- <div class="text-gray-500">
              #{datum.quote_request?.group?.version}
            </div> -->
          </div>
          <div class="text-xs italic whitespace-nowrap">
            {#if status === "rfq-canceled"}
              RFQ Canceled
            {:else if status === "canceled"}
              Canceled
            {:else if datum.sent_at}
              {formatHumanTime(datum.sent_at)}
            {/if}
          </div>
        </div>
      </div>
      <div class="flex-none">
        {#if datum.assigned_to_id}
          <div
            class="profile-circle relative"
            style="background-color: {datum.assigned_to_user_color || '#000'};
                   border-color: {datum.assigned_to_user_color || '#000'}">
            {upperCase(
              getUserInitials({ username: datum.assigned_to_username, email: datum.assigned_to_email }),
            )}
          </div>
        {:else if !datum.assigned_to}
          <div class="text-gray-400">
            <ProfileIcon />
          </div>
        {/if}
      </div>
    </div>
  </div>
</td>
