<script>
  import { getContext } from "svelte";
  import Datatable from "#lib/datatable/Datatable.svelte";
  import ErrorMessage from "#lib/standard-inputs/ErrorMessage.svelte";
  import NewContactModal from "#lib/NewContactModal.svelte";

  import { profile } from "#src/stores/auth.js";
  import { contactError } from "#src/stores/org.js";

  const org = getContext("org");

  const contactProps = [
    {
      label: "Company",
      prop: "company_name",
      type: "text",
    },
    {
      label: "Contact Name",
      prop: "name",
      type: "text",
    },
    {
      label: "Email",
      prop: "email",
      type: "text",
    },
    {
      label: "Address",
      prop: "address",
      type: "text",
    },
  ];

  let newContactModal;

  $: canAdmin = $profile && ["org_admin", "developer"].includes($profile.user_role);
  $: contacts = $org?.contacts ? normalizeContacts($org.contacts) : [];

  function normalizeContacts(contacts) {
    return contacts
      .map((c) => {
        if (c.contact) {
          return {
            company_name: c.contact.name,
            address: c.contact.address,
            name: c.primary_contact?.username,
            email: c.primary_contact?.email || c.contact.primary_contact_email,
            id: c.id,
          };
        }
        return {
          ...c,
        };
      })
      .sort((a, b) => {
        if (a.company_name && b.company_name) {
          return a.company_name.localeCompare(b.company_name);
        }

        if (a.company_name && !b.company_name) {
          return -1;
        }

        if (!a.company_name && b.company_name) {
          return 1;
        }

        return 0;
      });
  }

  function beginAddingSupplier() {
    newContactModal.open();
  }

  function removeContact(evt) {
    const { id } = evt.detail;
    org.removeContact(id);
  }
</script>

<div class="px-4 pb-4 border-b border-gray-300">
  <h2>Contacts</h2>
</div>

<div class="py-4 sm:px-4 flex justify-end items-center">
  {#if canAdmin}
    <button class="btn btn-primary" on:click={beginAddingSupplier}>+ Add New Contact</button>
  {/if}
</div>

<ErrorMessage message={$contactError} on:close={() => ($contactError = null)} />
<div class="bg-white border rounded-lg overflow-hidden">
  <Datatable data={contacts} columns={contactProps} deletable clickable on:deleterow={removeContact} />
</div>

<NewContactModal bind:this={newContactModal} {org} />
