<script>
  import { onMount, onDestroy } from "svelte";
  import { fade } from "svelte/transition";
  import { flip } from "svelte/animate";
  import { notifications } from "#src/stores/notifications.js";

  import InfoIcon from "@local/assets/icons/info.svg";
  import XIcon from "@local/assets/icons/x.svg";

  let container;
  let ref;

  onMount(() => {
    container = document.createElement("div");
    container.style.position = "fixed";
    container.style.top = "0";
    container.style.left = "0";
    container.style.width = "100dvw";
    container.style.pointerEvents = "none";
    document.body.appendChild(container);
    container.appendChild(ref);
  });

  onDestroy(() => {
    if (container && container.parentNode) {
      container.parentNode.removeChild(container);
    }
  });
</script>

<div bind:this={ref} class="flex flex-col gap-2 items-center text-sm py-4 pointer-events-none">
  {#each $notifications as notification (notification.id)}
    <div
      class="bg-amber-100 border border-amber-300 text-gray-800 p-2 rounded shadow flex gap-2 items-center"
      in:fade={{ duration: 300 }}
      out:fade={{ duration: 300 }}
      animate:flip={{ duration: 300 }}>
      <div class="text-amber-400">
        <InfoIcon />
      </div>
      <div>
        {notification.message}
      </div>
      <button class="text-amber-400 hover:text-amber-600" on:click={() => notifications.remove(notification)}>
        <XIcon />
      </button>
    </div>
  {/each}
</div>
