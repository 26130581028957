import { copyToClipboard } from "overline";
import { clipboard } from "#src/stores/ui.js";

function copy(contents) {
  if (typeof contents === "string") {
    copyToClipboard(contents);
    clipboard.set(null);
  } else if (typeof contents === "object") {
    copyToClipboard("");
    clipboard.set(contents);
  } else {
    copyToClipboard("");
    clipboard.set(null);
  }
}

export default copy;
